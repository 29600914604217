import { Group, Text, Button, ActionIcon, Select, Input, Table } from '@mantine/core';
import { IconHelp, IconTrash, IconCheck, IconX } from '@tabler/icons-react';
import React, { useState, useEffect } from 'react';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { useNavigate } from "react-router-dom";
import SettingsApi from "../../../api/SettingsApi";
import { SelectMemberWebsiteList } from "../../GeneralComponent/GeneralComponent";

export function CustomTags({ SelectMemberWebsiteListValue }:any) {

	const navigate = useNavigate();
	const [activeURLBuilder,setActiveURLBuilder] = useState('Custom-tags');
	const [websitePageList, setWebsitePageList] = useState([]);
	const [customTagsData, setCustomTagsData] = useState([]);
	const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');
	const [selectedPageValue, setSelectedPageValue] = useState('');
	const [customTagName, setCustomTagName] = useState('');

	useEffect(() => {
  	if(SelectMemberWebsiteListValue !== "" && SelectMemberWebsiteListValue !== selectedMemberWebsite){
  		setSelectedMemberWebsite(SelectMemberWebsiteListValue);
  	}
  }, [SelectMemberWebsiteListValue, selectedMemberWebsite]);

	const fetchCustomTagDetails = () => {
		SettingsApi.getcustomtagdetails()
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){       	
          setCustomTagsData(data.data);          
        }
      })
      .catch(error => {
        console.log(error);
      });
    };

	const deleteCustomTagConfirm = (deleteCustomTagId:any) => modals.openConfirmModal({
    title: 'Delete Custom Tag',
    children: (
      <Text size="sm">
        Are you sure you want to delete this Custom Tag?
      </Text>
    ),
    labels: { confirm: 'Delete Custom Tag', cancel: "No don't delete it" },
    confirmProps: { color: 'red' },
    onCancel: () => console.log('Cancel'),
    onConfirm: () => console.log('Confirmed',handleCustomTagDelete(deleteCustomTagId)),
  });


  const handleCustomTagDelete = (deleteCustomTagId:any) => {
    SettingsApi.deletecustomtag({
      custom_tag_id: deleteCustomTagId,
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        }); 
        fetchCustomTagDetails();       
      }else{
      	notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: data.message,
        });
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  const handleMemberWebsiteChange = (e:any) => {
  		setSelectedMemberWebsite(e);			
  };

  const fetchMemberPages = () => {
  	SettingsApi.getwebsitepages({
	      system_website_id: selectedMemberWebsite,
	    })
	    .then(response => {
	      if (response.ok) {
	        return response.json();
	      } else {
	        if (response.status === 401 || response.status === 400) {
	          navigate("/logout");
	        }
	      }
	    })
	    .then(data => {
	    	setWebsitePageList(data.data)
	    })
	    .catch(error => {
	      console.log(error);
	    });	
  };

  const handleAddCustomTag = (e:any) => {
  	e.preventDefault();  		
  	SettingsApi.addcustomtag({
      page_id:selectedPageValue,
  		custom_tag:customTagName,
  		system_website_id:selectedMemberWebsite
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });  
        setSelectedMemberWebsite('');
        setSelectedPageValue('');
        setCustomTagName('');
        fetchCustomTagDetails();   
      }else{
      	notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: data.message,
        });
      }
    })
    .catch(error => {
      console.log(error);
    });  	
  };

  useEffect(() => {
      fetchCustomTagDetails();
  },[]);

  useEffect(() => {
    if (selectedMemberWebsite) {
      fetchMemberPages(); 
    }
  }, [selectedMemberWebsite]);

	return (
		<>  	
			<div style={{ display: activeURLBuilder === "Custom-tags" ? "block" : "none"}}>
				<Group position="apart" mb="xs">
		      <Text weight={500}>CUSTOME TAGS <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
		    </Group>
		    <form onSubmit={handleAddCustomTag}>
			    <Group mb={15}>		    		
		          <Text fw={500}>Select domain :</Text>
		          <SelectMemberWebsiteList SelectMemberWebsiteListValue={selectedMemberWebsite} SelectMemberWebsiteListOnChange={handleMemberWebsiteChange} SelectMemberWebsiteListWidth={250} SelectMemberWebsiteListUsedIn="settings" SelectMemberWebsiteListMaxDropdownHeight={300} SelectMemberWebsiteListdropdownPosition="bottom" SelectMemberWebsiteListdropdownPositionMB={10} SelectMemberWebsiteListML={4}/>
		          <Text fw={500}>Page :</Text>
		          <Select
				        data={[
							    { value: '', label: 'Select Page' }, 
							    ...websitePageList.slice(1).map((page, index) => ({
							      value: page.page_id,
							      label: page.page_url
							    }))
							  ]}
				        value={selectedPageValue}
				        onChange={(value) => setSelectedPageValue(value)}	            
		          />
		      		<Input 
		      			w={200} 
		      			ml={0} 
		      			placeholder='Custom Tag' 
		      			value={customTagName} 
		      			onChange={(e) => setCustomTagName(e.target.value)} 
		      		/>	
		      		<Button type="submit" size="sm" w={80}>
	             Save
	          </Button>	      	         
		      </Group>
		    </form>
	      <Table>
	      	<thead>
	      		<tr>
	      			<th>ID</th>
	      			<th>Custom Tag</th>
	      			<th>Domain</th>
	      			<th>Page Name</th>
	      			<th></th>
	      		</tr>
	      	</thead>
	      	<tbody>
	      		{customTagsData.map((data,index) => (
	      			<tr key={index}>
		      			<td>{index+1}</td>
		      			<td>{data.custom_tag}</td>
		      			<td>{data.domain_name}</td>
		      			<td>{data.title}</td>
		      			<td>
		      				<ActionIcon
	                  variant="default"
	                  size="lg"
	                  mx={3} 
	                  onClick={() => deleteCustomTagConfirm(data.custom_tag_id)}                 
	                	>
	                  <IconTrash size="1.1rem" />
	                </ActionIcon>
	              </td>
		      		</tr> 
	      		))}	      		     	
	      	</tbody>
	      </Table>
	      </div>
		</>
	);
}