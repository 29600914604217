import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Text, Table, Select, Input, Button, Flex } from '@mantine/core';
import { IconSearch, IconCheck } from '@tabler/icons-react';
import TestsApi from "../../api/TestsApi";

export function ReviewPageOptions({
    ViewSelect,
    ViewSelectChange,
    ShowNumberChange,
    ActivePageNo,
    SelectedTestPageValue,
    KeywordChange,
  }:any) {

  const AllTestPagesoptions = [
    { value: '0', label: 'All Pages' },
    { value: '1', label: 'Pages with Active Tests' },
    { value: '2', label: 'Pages with "Winner Set"' }
  ];

  const handleSelectView = (selectedViewOption) => {
    ViewSelectChange(selectedViewOption);
  };

  const handleSelectAllTestPagesValueChange = (selectedAllTestPageOption) => {
    SelectedTestPageValue(selectedAllTestPageOption);
    ActivePageNo(1);
  };

  const handleShowNumberChange = (selectedShowNumberOption) => {
    ShowNumberChange(selectedShowNumberOption);
    ActivePageNo(1);
  };

  const handleKeywordChange = (e) => {
    KeywordChange(e.target.value);
    ActivePageNo(1);
  };

  return (
    <>
      <div style={{ display: 'flex'}}>
          <Text mt={5} mr={5}>Configured pages:</Text>
          <Select 
            w={200}
            defaultValue="0"
            data={AllTestPagesoptions}
            onChange={(selectedAllTestPageOption) => handleSelectAllTestPagesValueChange(selectedAllTestPageOption)}
            searchable
            nothingFound="No options"
            maxDropdownHeight={300}
            dropdownPosition="bottom"
          />
          <Text mt={5} ml={20} mr={5}>View:</Text>
          <Select
            onChange={handleSelectView}
            defaultValue="grid"
            value={ViewSelect}
            data={[
              { value: 'grid', label: 'Grid' },
              { value: 'list', label: 'List' }
            ]}
            w={80}
          />
          <Text mt={5} ml={20} mr={5}>Show: </Text>
          <Select
            onChange={handleShowNumberChange}
            defaultValue="25"
            data={[
              { value: '10', label: '10' },
              { value: '25', label: '25' },
              { value: '50', label: '50' },
              { value: '100', label: '100' }
            ]}
            w={80}
          />
          <Flex
            style={{marginLeft:"auto"}}
            justify="flex-end"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Input icon={<IconSearch size={16} />} 
              placeholder="Search"
              maxLength={50}
              autoFocus
              onChange={handleKeywordChange}
             />
          </Flex>
        </div>
    </>
  );
}