import { Group, Text, Flex, Container, Card, Button, Image } from '@mantine/core';
import { IconHelp } from '@tabler/icons-react';
import { useState, useEffect, useRef } from 'react';
import { useDocumentTitle } from '@mantine/hooks';
import { HeaderMenu } from "../../components/HeaderMenu/HeaderMenu";
import { useLocation, useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import './RecordingView.css';
import { Helmet } from 'react-helmet';
import { visiUserToken } from '../../GeneralFunction';
import { SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";
import { AssetsUrl } from './AssetsUrl';
import InsightsApi from "../../api/InsightsApi";

const loadResource = (tag, attributes) => {
  return new Promise((resolve, reject) => {
    const element = document.createElement(tag);
    Object.keys(attributes).forEach(key => element.setAttribute(key, attributes[key]));
    element.onload = resolve;
    element.onerror = reject;
    document.head.appendChild(element);
  });
};

export function RecordingView() {
  useDocumentTitle("Visiopt - Insights");
  const navigate = useNavigate();
  const location = useLocation();
  const funnelId = location.state?.funnelId;
  const userSession = location.state?.userSession;
  const [selectedFunnelId] = useState(funnelId);
  const [selectedRecordingUserSession] = useState(userSession);
  const [recordingDate, setRecordingDate] = useState('');
  const [recordingBrowserType, setRecordingBrowserType] = useState('');
  const [recordingWindowHeight, setRecordingWindowHeight] = useState('');
  const [recordingWindowWidth, setRecordingWindowWidth] = useState('');
  const [recordingPages, setRecordingPages] = useState('');
  const [recordingDuration, setRecordingDuration] = useState('');
  const [recordingCountry, setRecordingCountry] = useState('');
  const [recordingActivity, setRecordingActivity] = useState([]);
  const [isAssetsUrlModalOpen, setIsAssetsUrlModalOpen] = useState(false);
  const [assetsUrlValue, setAssetsUrlValue] = useState('');
  const recordingViewIconRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  localStorage.setItem("nextSession2Play", selectedRecordingUserSession);
  localStorage.setItem("selected-visi-speedPlay", '500');
  localStorage.setItem("visi-playing", 'false');

  const [loadResourceLoaded, setLoadResourceLoaded] = useState(0);

  const [pageUrl, setPageUrl] = useState('');
  const [pageIdDecode, setPageIdDecode] = useState('');
  const token = visiUserToken() ? visiUserToken() : '';

  useEffect(() => {
    const loadResources = async () => {
      try {
        handleShowHideLoader(true);
        const frontendPublicUrl = process.env.REACT_APP_FRONTEND_PUBLIC_URL;
        await loadResource('script', { src: frontendPublicUrl+'js/jquery.min.js' });
        setLoadResourceLoaded(1);
        handleButtonClickTest();
      } catch (error) {
        console.error('Error loading resource:', error);
      }
    };
    if(pageUrl !== ""){
      loadResources();
    }
    
  }, [pageUrl]);

  const handleButtonClickTest = async () => {
    handleShowHideLoader(true);
    let params = {
      page_url : pageUrl,
      site_id : selectedMemberWebsite,
      funnel_id : selectedFunnelId,
      visi_token: token
    };   
    let paramsJSON = JSON.stringify(params);
    let paramsJSONEncoded = btoa(paramsJSON);

  try {
    const response = await axios.get(
      process.env.REACT_APP_BACKEND_API_PATH+"recordingsetup-data.php",
      {
        params: {
          recordingParams: paramsJSONEncoded,
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    handleShowHideLoader(false);
    const iframe = window.parent.parent.document.getElementById('viewerRecorded') as HTMLIFrameElement;
    iframe.srcdoc = response.data;
  } catch (error) {
    handleShowHideLoader(false);
    console.error('Error:', error);
  }
  };

  const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');

  const handleHeaderMenuComponentChange = (Value:any) => {
    setSelectedMemberWebsite(Value);
  };

  const fetchRecordingPlayDetails = () => {    
    const dataObject = {
      system_website_id: selectedMemberWebsite,
      funnel_id: selectedFunnelId,
      user_session: selectedRecordingUserSession
    };
    handleShowHideLoader(true);
    InsightsApi.getrecordingplaydetails(dataObject)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setRecordingDate(data.data.date);
          setRecordingBrowserType(data.data.browser_type);
          setRecordingWindowHeight(data.data.window_height);
          setRecordingWindowWidth(data.data.window_width);
          setRecordingPages(data.data.pages);
          setRecordingDuration(data.data.duration);
          setRecordingCountry(data.data.country_code);
          setRecordingActivity(data.data.activities);       
          setPageUrl(data.data.page_url);
          setPageIdDecode(data.data.page_id);
          let decodedUrl = atob(data.data.test_assets_url);
          setAssetsUrlValue(decodedUrl);
          handleShowHideLoader(false);
        }else{
          handleShowHideLoader(false);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
        handleShowHideLoader(false);
      });
  };

  const handleAssetsUrlModalOpen = () => {
    setIsAssetsUrlModalOpen(true);
  };

  const handleAssetsUrlModalClose = () => {
    setIsAssetsUrlModalOpen(false);
  };

  useEffect(() => {
    if(selectedRecordingUserSession !== '' && selectedFunnelId !== '' && selectedMemberWebsite !== ''){
      fetchRecordingPlayDetails();
    }    
  }, [selectedRecordingUserSession, selectedFunnelId, selectedMemberWebsite]);

  const handleSpeedPlay = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    localStorage.setItem("selected-visi-speedPlay", selectedValue);
  };

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    if (recordingViewIconRef.current) {
      const { top, left, width } = recordingViewIconRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        top: top + scrollOffset,
        left: left + width + 10, 
      });
    }
  };

  const handleBackToVideos = () => {
    navigate('/recordings-list', { state: { funnel_id: selectedFunnelId} });
  };

  const handleShowHideLoader = (value:any) => {
    var overlay = window.parent.parent.document.getElementById('overlay');
    if(value === true){
      overlay.innerHTML = '<div id="small_overlay" style="visibility: visible; display: block; background: none;"><h1>Page Loading...</h1><img src="img/loading.gif" height="22" width="126" alt="Loading..."></div>';
      overlay.style.display = 'block';
      overlay.style.visibility = 'visible';
    }else{
      overlay.style.display = 'none';
      overlay.style.visibility = 'hidden';
    }
  };

	return (
		<>
      {showHelpModal && <SelectTooltip isVisible={showHelpModal} setIsVisible={setShowHelpModal} id={helpTargetId} tooltipPosition={tooltipPosition} />}
    {/*START ASSETS URL*/}
      <AssetsUrl
          isAssetsUrlModalOpen={isAssetsUrlModalOpen}
          setIsAssetsUrlModalOpen={setIsAssetsUrlModalOpen}
          handleAssetsUrlModalClose={handleAssetsUrlModalClose}
          assetsUrlValue={assetsUrlValue}
          setAssetsUrlValue={setAssetsUrlValue}
          pageId={pageIdDecode}
          assetsredirecturl={fetchRecordingPlayDetails}
      />
      {/*END ASSETS URL*/}
      <HeaderMenu HeaderMenuValue={selectedMemberWebsite} HeaderMenuOnChange={handleHeaderMenuComponentChange}/>
      <div style={{ height: '100vh' }}>
        <Container size="xxl" style={{ height: '100%' }}>
          <Card shadow="sm" padding="sm" radius="md" withBorder style={{ height: '100%' }}>
            <div style={{ display: 'flex',marginBottom: '10px' }}>
              <Group position="apart" mb="xs">
                <Text weight={500}>RECORDINGS <IconHelp  ref={recordingViewIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(150)}/></Text>
              </Group>
              <div style={{ display: 'flex',marginLeft:"auto"}}>
                <Button variant="default" onClick={handleAssetsUrlModalOpen}>Image/Style Not Loaded</Button>
                <Button variant="default" style={{ marginLeft: '5px' }} onClick={handleBackToVideos}>Back to the Video List</Button>
              </div>
            </div>
            <div style={{ display: 'flex', height: '100%' }}>
              <div style={{ width: '18%' }}>
                <div style={{ width: '200px', height: '180px' }}>
                  <Flex gap={3}>
                    <Text weight={500}>Date:</Text>
                    <Text>{recordingDate}</Text>
                  </Flex>
                  <Flex gap={3}>
                    <Text weight={500}>Browser:</Text>
                    <Text>{recordingBrowserType}</Text>
                  </Flex>
                  <Flex gap={3}>
                    <Text weight={500}>Window Size:</Text>
                    <Text>{recordingWindowWidth} x {recordingWindowHeight}</Text>
                  </Flex>
                  <Flex gap={3}>
                    <Text weight={500}>Pages:</Text>
                    <Text>{recordingPages}</Text>
                  </Flex>
                  <Flex gap={3}>
                    <Text weight={500}>Duration:</Text>
                    <Text>{recordingDuration}</Text>
                  </Flex>
                  <Flex gap={3}>
                    <Text weight={500}>Location:</Text>
                    <Text>
                      <Image
                        src={`./img/flags/${recordingCountry}.svg`}
                        style={{ height: '22px', width: '22px', marginTop: '5px' }}
                      />
                    </Text>
                  </Flex>
                </div>
                <Card shadow="sm" radius="sm" withBorder style={{ width: 'auto', maxWidth: '100%', height: 'auto', maxHeight: '615px', overflowY: 'auto', marginTop: '-10px' }}>
                  {recordingActivity.map((activity, activityIndex) => (
                    <div style={{ width: '100%' }} key={activityIndex}>
                      <div style={{ display: 'flex', gap: '4px', height: '40px' }}>
                        <Text weight={500}>Page:</Text>
                        <Link
                          to={activity.page_url}
                          target="_blank"
                          style={{ textDecoration: 'none', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', color: '#1c7ed6' }}
                          title={activity.page_url}
                        >
                          {activity.page_url}
                        </Link>
                      </div>
                      <div className="btn-group">
                        {activity.buttons.map((button, btnIndex) => (
                          <button
                            key={btnIndex}
                            data-page_url={button.page_url}
                            className={button.class}
                            id={button.id}
                          >
                            {button.text}
                          </button>
                        ))}
                      </div>
                    </div>
                  ))}
                </Card>
              </div>              
              <div className="test-wrapper flexed" id="box" style={{ height: '87%', width: '82%', marginLeft: '10px', position: 'relative', backgroundColor: '#1e1a1a', borderRadius: '3px' }}>
                <Helmet>
                  <link rel="stylesheet" type="text/css" href="/css/normalize.css"/>
                  <link rel="stylesheet" type="text/css" href="/css/visiopt-visual.css"/>
                  {loadResourceLoaded === 1 ?                    
                  <script type="text/javascript">
                    {`
                      var jQcustom = jQuery.noConflict();

                      jQcustom(window).resize(function(){
                          var w_width = ${recordingWindowWidth},
                            w_height = ${recordingWindowHeight},
                            r = this.getScaleRatio(w_width, w_height);
                          jQcustom(".playback-wrap > .playback").css({
                              width: w_width * r,
                              height: w_height * r
                          });
                          this.scaleBrowser(r,w_width,w_height);                          
                      });

                      function getScaleRatio(w_width, w_height) {
                          var n = jQcustom(".playback-wrap").height(),
                            r = jQcustom(".playback-wrap").width();
                          return Math.min(r / w_width, n / w_height);
                      };
                      function scaleBrowser(e,w_width,w_height) {
                        var playbackWrapWidth = jQcustom(".playback-wrap").width();
                        var scaledWidth = w_width * e;
                        var leftOffset = (playbackWrapWidth - scaledWidth) / 2;
                        var t =  jQcustom(window),
                            n = w_width,
                            r = w_height;
                          jQcustom(".playback").css({
                            width: n,
                            height: r,
                            "-webkit-transform": "scale(" + e + ")",
                            "-webkit-transform-origin": "0 0",
                            "-moz-transform": "scale(" + e + ")",
                            "-moz-transform-origin": "0 0",
                            transform: "scale(" + e + ")",
                            "transform-origin": "0 0",
                            marginLeft: leftOffset + 'px',
                        });
                      };

                      var w_width = ${recordingWindowWidth},
                        w_height = ${recordingWindowHeight},
                        r = this.getScaleRatio(w_width, w_height);
                      jQcustom(".playback-wrap > .playback").css({
                          width: w_width * r,
                          height: w_height * r
                      });
                      this.scaleBrowser(r,w_width,w_height);
                    `}
                  </script>
                  : ''}
                </Helmet>
                <link rel="stylesheet" id="test_added_font" type="text/css" />
                <style type="text/css" id="test_added_css"></style>
                <div className="selection-site" style={{ borderRadius: "5px", height: '100%', width: '100%' }}>
                  <div className="playback-wrap" style={{ height: '80%', width: '100%' }}>
                    <div className="playback" style={{ height: `${recordingWindowHeight}px`, width: `${recordingWindowWidth}px`, transform: 'scale(0.391125)', transformOrigin: '0px 0px' }}>
                      <iframe
                        width='100%'
                        height='100%'
                        style={{
                          backgroundColor: 'white',
                          border: 'none',
                          pointerEvents: 'none',
                          userSelect: 'none',
                          display: 'inline'
                        }}
                        id="viewerRecorded"
                        title="Outer Iframe"
                        sandbox="allow-top-navigation allow-scripts allow-same-origin allow-forms allow-popups allow-modals"
                        referrerPolicy="same-origin"
                        scrolling="no"
                        data-pagecount={recordingPages}
                        srcDoc=''
                      />
                    </div>
                  </div>                  
                  <div id="video-controls" className="controls" data-state="hidden" style={{ marginTop: '10px' }}>
                    <div id="progressbar">
                        <input type="range" min="0" max="100" className="slider" style={{ width: "100%" }} id="video_range" />
                        <div id="activities_progress" style={{ marginTop: '-30px' }}>
                        </div>
                    </div>
                    <div className="icon" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
                      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <span className="icon-resume" id="btn" style={{ backgroundColor: "transparent", cursor: "pointer", marginTop: '5px' }} data-value="Play" title="Play"></span>
                        <span className="icon-pause" id="pause-btn" style={{ display: "none", backgroundColor: "transparent", cursor: "pointer", marginTop: '5px' }} data-value="Pause" title="Pause"></span>
                        <Text id="minutes-counter" color="white" style={{ marginLeft: '10px' }}></Text>
                        <Text id="seconds-counter" color="white"></Text>
                        <Text color="white">/</Text>
                        <Text id="total-time" color="white"></Text>
                      </div>                    
                      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '4px' }}>
                        <Button                          
                          id="Skip_pause"
                          className="button-control"
                          style={{
                            backgroundColor: 'grey',
                            color: 'white',
                            borderRadius: '5px'
                          }}
                        >
                          Skip Pauses
                        </Button> 
                        <select name="speedPlay" id="speedPlay" className="button-control" onChange={handleSpeedPlay}></select>
                      </div>
                    </div>
                  </div>
                </div>                 
              </div>
            </div>
          </Card>
        </Container>
      </div>
      <div id="overlay" style={{ visibility: "hidden", display: "none", backgroundImage: "none", backgroundColor: 'rgba(255, 255, 255, 0.7)' }} />
    </>
	);
}