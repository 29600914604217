import { ThemeProvider } from "./ThemeProvider";
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import ProtectedRoute from './ProtectedRoute';

import { LogIn } from "./pages/LogIn/LogIn";
import { SelectMember } from "./pages/SelectMember/SelectMember";
import { DashBoard } from "./pages/DashBoard/DashBoard";
import { Funnel } from "./pages/Funnel/Funnel";
import { ReviewTests } from "./pages/Tests/ReviewTests";
import { ReviewingTest } from "./pages/Tests/ReviewingTest";
import { Settings } from "./pages/Settings/Settings";
import { Logout } from "./pages/Logout/Logout";
import { ForgotPassword } from "./pages/ForgotPassword/ForgotPassword";
import { ResetPassword } from "./pages/ResetPassword/ResetPassword";
import { CreateTest } from "./pages/Tests/CreateTest";
import { ManageCampaigns } from "./pages/Campaigns/ManageCampaigns";
import { CreateCampaign } from "./pages/Campaigns/CreateCampaign";
import { TestEditorVisual } from "./pages/Tests/TestEditorVisual";
import { TestEditorVisualmodern } from "./pages/Tests/TestEditorVisualmodern";
import { TestHistory } from "./pages/Tests/TestHistory";
import { TestHistoryReport } from "./pages/Tests/TestHistoryReport";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { visiUserSession } from './GeneralFunction';
import { visiUserToken } from './GeneralFunction';
import { useEffect, useState } from 'react';

const App = () => {
    return (
      <ThemeProvider>
        <AuthProvider>
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
             <Router>
                <Routes>
                    <Route path="/" element={<PreAuthCheck />} />
                    <Route path="/login" element={<PreAuthCheck />} />
                    <Route path="/select-member" element={<SelectMember />} />
                    <Route path="/dashboard" element={
                      <ProtectedRoute>
                          <DashBoard />
                      </ProtectedRoute>
                    } />
                    <Route path="/funnels" element={
                      <ProtectedRoute>
                          <Funnel />
                      </ProtectedRoute>
                    } />
                    <Route path="/review-tests" element={
                      <ProtectedRoute>
                          <ReviewTests />
                      </ProtectedRoute>
                    } />
                    <Route path="/reviewing-test" element={
                      <ProtectedRoute>
                          <ReviewingTest />
                      </ProtectedRoute>
                    } />
                    <Route path="/test-history" element={
                      <ProtectedRoute>
                          <TestHistory />
                      </ProtectedRoute>
                    } />
                    <Route path="/test-history-report" element={
                      <ProtectedRoute>
                          <TestHistoryReport />
                      </ProtectedRoute>
                    } />
                    <Route path="/test-editor-visual/:pageid/:taguchi_type_id" element={
                      <ProtectedRoute>
                          <TestEditorVisual />
                      </ProtectedRoute>
                    } />
                    <Route path="/test-editor-visual-modern/:pageid/:taguchi_type_id" element={
                      <ProtectedRoute>
                          <TestEditorVisualmodern />
                      </ProtectedRoute>
                    } />
                    <Route path="/create-test" element={
                      <ProtectedRoute>
                          <CreateTest />
                      </ProtectedRoute>
                    } />
                    <Route path="/manage-campaigns" element={
                      <ProtectedRoute>
                          <ManageCampaigns />
                      </ProtectedRoute>
                    } />
                    <Route path="/create-campaign" element={
                      <ProtectedRoute>
                          <CreateCampaign />
                      </ProtectedRoute>
                    } />
                    <Route path="/settings" element={
                      <ProtectedRoute>
                          <Settings />
                      </ProtectedRoute>
                    } />
                    <Route path="/logout" element={
                      <ProtectedRoute>
                          <Logout />
                      </ProtectedRoute>
                    } />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                </Routes>
            </Router>
          </GoogleOAuthProvider>
        </AuthProvider>
      </ThemeProvider>
    );
};

function PreAuthCheck() {
  const navigate = useNavigate();
  const [shouldLogin, setShouldLogin] = useState(false);
  useEffect(() => {
    if(visiUserToken() && visiUserSession() && visiUserSession().page){
      navigate(visiUserSession().page);
    }else{
      setShouldLogin(true);
    }
  }, []);
  return shouldLogin ? <LogIn /> : null;
}

export default App;