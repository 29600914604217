import { ServerAPI } from '../GeneralFunction';

export default {
	//Start EmailIntegration
		getemailintegrationconnectedlist: () => {
			let url = "get_email_integration_connected_list";
			const requestOptions = ServerAPI(url, 'GET');
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		getandsaveemailintegrationsettings: (data) => {
			let url = "get_and_save_email_integration_settings";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
	//End EmailIntegration

	//Start GoogleAnalyticsIntegration
		getgoogleanalyticsintegrationdata: () => {
			let url = "get_google_analytics_integration_data";
			const requestOptions = ServerAPI(url, 'GET');
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		savegoogleanalyticssettings: (data) => {
			let url = "save_google_analytics_settings";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
	//End GoogleAnalyticsIntegration

	//Start ManageYourDomains
		addeditdomain: (data) => {
			let url = "add_edit_domain";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		adddeletedomainsubdomain: (data) => {
			let url = "add_delete_domain_subdomain";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		getsingledomain: (data) => {
			let url = "get_single_domain";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		getdomainsubdomainlist: (data) => {
			let url = "get_domain_subdomain_list";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		uninstalldomain: (data) => {
			let url = "uninstall_domain";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		getdomainlist: () => {
			let url = "get_domain_list";
			const requestOptions = ServerAPI(url, 'GET');
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
	//End ManageYourDomains

	//Start ManageCustomTags
		getcustomtagsbypage: (data) => {
			let url = "get_custom_tag_details_by_page";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},	
		getcustomtagdetails: () => {
			let url = "get_custom_tag_details";
			const requestOptions = ServerAPI(url, 'GET');
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},	
		getwebsitepages: (data) => {
			let url = "get_website_pages";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		addcustomtag: (data) => {
			let url = "add_custom_tag";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},	
		saveupdateurlbuilder: (data) => {
			let url = "save_update_url";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},	
		deletecustomtag: (data) => {
			let url = "delete_custom_tag";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		geturlbuilders: () => {
			let url = "get_url_builder_and_custom_tags_list";
			const requestOptions = ServerAPI(url, 'GET');
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		getsingleurl: (data) => {
			let url = "get_single_url_builder_detail";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		deleteurlbuilder: (data) => {
			let url = "delete_builded_custom_tag_url";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
	//End ManageCustomTags
		
	//Start ManageYourPages
		managedeletepage: (data) => {
			let url = "manage_delete_page";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		refreshscreenshot: (data) => {
			let url = "refresh_screenshot";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		getmanageyourpageslist: (data) => {
			let url = "get_manage_your_pages_list";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
	//End ManageYourPages

	//Start ManageYourPostbacks
		saveupdatepostback: (data) => {
			let url = "save_update_postback";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		getsinglepostback: (data) => {
			let url = "get_single_postback";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		getfunnelpagelistbyfunnelid: (data) => {
			let url = "get_funnel_page_list_by_funnel_id";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		getfunnellist: (data) => {
			let url = "get_funnel_list";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		deletepostback: (data) => {
			let url = "delete_postback";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		getpostbacklist: () => {
			let url = "get_postback_list";
			const requestOptions = ServerAPI(url, 'GET');
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		getaffiliatenetworklist: () => {
			let url = "get_affiliate_network_list";
			const requestOptions = ServerAPI(url, 'GET');
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		generataffiliatenetworkurl: (data) => {
			let url = "generate_affiliate_network_url";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
	//End ManageYourPostbacks

	//Start AccessManagement		
		getaccessmanagementlist: () => {
			let url = "get_access_management_list";
			const requestOptions = ServerAPI(url, 'GET');
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		checkaddusers: () => {
			let url = "check_add_users";
			const requestOptions = ServerAPI(url, 'GET');
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		getrecentactivitylist: (data) => {
			let url = "get_recent_activity_list";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		deletememberuser: (data) => {
			let url = "delete_member_user";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		checkmemberuseremail: (data) => {
			let url = "check_member_user_email";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		addeditmemberuser: (data) => {
			let url = "add_edit_member_user";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		getsingleuser: (data) => {
			let url = "get_single_user";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
	//End AccessManagement

	//Start MyAccount
		getcountrylist: () => {
			let url = "get_country_list";
			const requestOptions = ServerAPI(url, 'GET');
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		gettimezonelist: () => {
			let url = "get_timezone_list";
			const requestOptions = ServerAPI(url, 'GET');
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		getmyaccountdatails: () => {
			let url = "get_my_account_datails";
			const requestOptions = ServerAPI(url, 'GET');
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		getstatelist: (data) => {
			let url = "get_state_list";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		changepassword: (data) => {
			let url = "change_password";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		apitokengenerate: (data) => {
			let url = "api_token_generate";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		savemyaccountdetail: (data) => {
			let url = "save_my_account_detail";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
	//End MyAccount

	//Start NotificationsSettings
		getnotificationsettings: () => {
			let url = "get_notification_settings";
			const requestOptions = ServerAPI(url, 'GET');
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		savenotificationsettings: (data) => {
			let url = "save_notification_settings";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
	//End NotificationsSettings

	//Start TestControl
		gettestcontroldata: () => {
			let url = "get_test_control_data";
			const requestOptions = ServerAPI(url, 'GET');
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		savetestcontrolsettings: (data) => {
			let url = "save_test_control_settings";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
	//End TestControl

	//Start Payment Processing & Carts
		getpaymentprocessorscartlist: () => {
			let url = "get_payment_processors_cart_list";
			const requestOptions = ServerAPI(url, 'GET');
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		connectdisconnectpaymentprocessor: (data) => {
			let url = "connect_disconnect_payment_processor";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
	//End Payment Processing & Carts
};	