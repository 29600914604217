import { useDocumentTitle } from '@mantine/hooks';
import { HeaderMenu } from "../../components/HeaderMenu/HeaderMenu";
import { ManageYourDomains } from "../../components/Settings/ManageYourDomains/ManageYourDomains";
import { ManageYourPages } from "../../components/Settings/ManageYourPages/ManageYourPages";
import { TestControl } from "../../components/Settings/TestControl/TestControl";
import { GoogleAnalyticsIntegration } from "../../components/Settings/GoogleAnalyticsIntegration/GoogleAnalyticsIntegration";
import { EmailIntegration } from "../../components/Settings/EmailIntegration/EmailIntegration";
import { NotificationsSettings } from "../../components/Settings/NotificationsSettings/NotificationsSettings";
import { URLBuilderAndCustomTags } from "../../components/Settings/URLBuilderAndCustomTags/URLBuilderAndCustomTags";
import { CustomTags } from "../../components/Settings/CustomTags/CustomTags";
import { ManageYourPostbacks } from "../../components/Settings/ManageYourPostbacks/ManageYourPostbacks";
import { AccessManagement } from "../../components/Settings/AccessManagement/AccessManagement";
import { PaymentProcessingAndCarts } from "../../components/Settings/PaymentProcessingAndCarts/PaymentProcessingAndCarts";
import { MyAccount } from "../../components/Settings/MyAccount/MyAccount"

import { Container, Card, Tabs } from '@mantine/core';
import { IconWorld, IconFileText, IconFileCheck, IconAntennaBars5, IconMail, IconBell, IconTag, IconLink, IconUser, IconShoppingCart } from '@tabler/icons-react';
import classes from './DoubleNavbar.module.css';
import Cookies from 'js-cookie';
import { useState, useEffect } from 'react';
import PermissionWrapper from '../../PermissionWrapper';
import { Link, useLocation } from "react-router-dom";

export function Settings() {

  const location = useLocation();
  const fromPage = location.state?.from || 'Unknown';

  const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');

  const handleHeaderMenuComponentChange = (Value:any) => {
    setSelectedMemberWebsite(Value);
  };

  useDocumentTitle("Visiopt - Settings");

  const [activeTab, setActiveTab] = useState('');

  const onTabChange = (event:any) => {
    setActiveTab(event);
    Cookies.set('setting_page',event);
  };
 
  useEffect(() => {
    const SettingPage = (Cookies.get('setting_page')) ? Cookies.get('setting_page') : 'tab1';
    onTabChange(SettingPage);
  }, []);

  useEffect(() => {
    if(fromPage === "my_account"){
      setActiveTab('tab11');
    }
  }, [fromPage]);

  const handleSelectMemberWebsiteListComponentChange = (Value:any) => {
    setSelectedMemberWebsite(Value);
  };

  return (
    <>
      <HeaderMenu HeaderMenuValue={selectedMemberWebsite} HeaderMenuOnChange={handleHeaderMenuComponentChange}/>
      <PermissionWrapper permission="operational_access">
        <Container  size="xl">
          <Card shadow="sm" padding="0" radius="md" withBorder mb={10}>
            <Tabs variant="pills" orientation="vertical" value={activeTab} onTabChange={onTabChange}>
              <Tabs.List className={classes.aside} mt={10} mb={10}>
                <Tabs.Tab className={classes.mainLink} value="tab1"><Link to="/settings" state={{ from: 'manage_your_domains' }}  style={{ textDecoration: 'none', color: 'inherit' }}><IconWorld stroke={1.5} /></Link></Tabs.Tab>
                <Tabs.Tab className={classes.mainLink} value="tab2"><Link to="/settings" state={{ from: 'manage_your_pages' }}  style={{ textDecoration: 'none', color: 'inherit' }}><IconFileText stroke={1.5} /></Link></Tabs.Tab>
                <Tabs.Tab className={classes.mainLink} value="tab3"><Link to="/settings" state={{ from: 'test_control' }}  style={{ textDecoration: 'none', color: 'inherit' }}><IconFileCheck stroke={1.5} /></Link></Tabs.Tab>
                <PermissionWrapper permission="ga_integration_access">
                  <Tabs.Tab className={classes.mainLink} value="tab4"><Link to="/settings" state={{ from: 'ga_integration' }}  style={{ textDecoration: 'none', color: 'inherit' }}><IconAntennaBars5 stroke={1.5} /></Link></Tabs.Tab>
                </PermissionWrapper>
                <PermissionWrapper permission="email_integration_access">
                  <Tabs.Tab className={classes.mainLink} value="tab5"><Link to="/settings" state={{ from: 'email_integration' }}  style={{ textDecoration: 'none', color: 'inherit' }}><IconMail stroke={1.5} /></Link></Tabs.Tab>
                </PermissionWrapper>
                <PermissionWrapper permission="notification_settings_access">
                  <Tabs.Tab className={classes.mainLink} value="tab6"><Link to="/settings" state={{ from: 'notification_settings' }}  style={{ textDecoration: 'none', color: 'inherit' }}><IconBell stroke={1.5} /></Link></Tabs.Tab>
                </PermissionWrapper>
                <PermissionWrapper permission="url_builder_custom_tags_access">
                  <Tabs.Tab className={classes.mainLink} value="tab7"><Link to="/settings" state={{ from: 'url_builder_custom_tags' }}  style={{ textDecoration: 'none', color: 'inherit' }}><IconTag stroke={1.5} /></Link></Tabs.Tab>
                </PermissionWrapper>
                <PermissionWrapper permission="custom_tags_access">
                  <Tabs.Tab className={classes.mainLink} value="tab8"><Link to="/settings" state={{ from: 'custom_tag' }}  style={{ textDecoration: 'none', color: 'inherit' }}><IconTag stroke={1.5} /></Link></Tabs.Tab>
                </PermissionWrapper>
                <PermissionWrapper permission="postback_access">
                  <Tabs.Tab className={classes.mainLink} value="tab9"><Link to="/settings" state={{ from: 'postback' }}  style={{ textDecoration: 'none', color: 'inherit' }}><IconLink stroke={1.5} /></Link></Tabs.Tab>
                </PermissionWrapper>
                <PermissionWrapper permission="payments_processing_and_carts_access">
                  <Tabs.Tab className={classes.mainLink} value="tab10"><Link to="/settings" state={{ from: 'payments_processing_and_carts' }}  style={{ textDecoration: 'none', color: 'inherit' }}><IconShoppingCart stroke={1.5} /></Link></Tabs.Tab>
                </PermissionWrapper>
                <PermissionWrapper permission="access_management_access">
                  <Tabs.Tab className={classes.mainLink} value="tab11"><Link to="/settings" state={{ from: 'access_management' }}  style={{ textDecoration: 'none', color: 'inherit' }}><IconUser stroke={1.5} /></Link></Tabs.Tab>
                </PermissionWrapper>
              </Tabs.List>

              <Tabs.Panel value="tab1" m={10}>
                <ManageYourDomains SelectMemberWebsiteListValue={selectedMemberWebsite} SelectMemberWebsiteListOnChange={handleSelectMemberWebsiteListComponentChange} />
              </Tabs.Panel>
              <Tabs.Panel value="tab2" m={10}>
                <ManageYourPages SelectMemberWebsiteListValue={selectedMemberWebsite} SelectMemberWebsiteListOnChange={handleSelectMemberWebsiteListComponentChange}/>
              </Tabs.Panel>
              <Tabs.Panel value="tab3" m={10}>
                <TestControl />
              </Tabs.Panel>
              <PermissionWrapper permission="ga_integration_access">
                <Tabs.Panel value="tab4" m={10}>
                  <GoogleAnalyticsIntegration  SelectMemberWebsiteListValue={selectedMemberWebsite} SelectMemberWebsiteListOnChange={handleSelectMemberWebsiteListComponentChange}/>
                </Tabs.Panel>
              </PermissionWrapper>
              <PermissionWrapper permission="email_integration_access">
                <Tabs.Panel value="tab5" m={10}>
                  <EmailIntegration />
                </Tabs.Panel>
              </PermissionWrapper>
              <PermissionWrapper permission="notification_settings_access">
                <Tabs.Panel value="tab6" m={10}>
                  <NotificationsSettings />
                </Tabs.Panel>
              </PermissionWrapper>
              <PermissionWrapper permission="url_builder_custom_tags_access">
                <Tabs.Panel value="tab7" m={10}>
                  <URLBuilderAndCustomTags SelectMemberWebsiteListValue={selectedMemberWebsite} SelectMemberWebsiteListOnChange={handleSelectMemberWebsiteListComponentChange} />
                </Tabs.Panel>
              </PermissionWrapper>
              <PermissionWrapper permission="custom_tags_access">
                <Tabs.Panel value="tab8" m={10}>
                  <CustomTags SelectMemberWebsiteListValue={selectedMemberWebsite} SelectMemberWebsiteListOnChange={handleSelectMemberWebsiteListComponentChange} />
                </Tabs.Panel>
              </PermissionWrapper>
              <PermissionWrapper permission="postback_access">
                <Tabs.Panel value="tab9" m={10}>
                  <ManageYourPostbacks SelectMemberWebsiteListValue={selectedMemberWebsite} SelectMemberWebsiteListOnChange={handleSelectMemberWebsiteListComponentChange}/>
                </Tabs.Panel>
              </PermissionWrapper>
              <PermissionWrapper permission="payments_processing_and_carts_access">
                <Tabs.Panel value="tab10" m={10}>
                  <PaymentProcessingAndCarts />
                </Tabs.Panel>
              </PermissionWrapper>
              <PermissionWrapper permission="access_management_access">
                <Tabs.Panel value="tab11" m={10}>
                {fromPage === "access_management" ? <AccessManagement /> : <MyAccount />}
                </Tabs.Panel>
              </PermissionWrapper>
            </Tabs>
          </Card>
        </Container>
      </PermissionWrapper>
    </>
  );
}