import React from 'react';
import { useNavigate } from "react-router-dom";
import { Text, Button, Table, Image, Anchor } from '@mantine/core';

export function GridRotationPage({
  SelectedPageCreateTests,
  SelectedThumbnail,
  SelectedThumbnailChange,
  }:any) {

  const navigate = useNavigate();

  const handleMultipleThumbnailClick = (pageId) => {
    SelectedThumbnailChange((prevSelectedThumbnails) => {
      if (prevSelectedThumbnails.includes(pageId)) {
        return prevSelectedThumbnails.filter(id => id !== pageId);
      } else {
        return [...prevSelectedThumbnails, pageId];
      }
    });
  };

  return (
    <>
      {SelectedPageCreateTests.map((item, index) => (
          <div className="visi-page" key={index} style={{marginRight:10}} >
            <div className={item.pageClass}
                  style={{ cursor: 'pointer', backgroundColor: (item.selected || SelectedThumbnail.includes(item.page_id)) ? '#00b975' : '' }} 
                  onClick={item.selected ? undefined : () => handleMultipleThumbnailClick(item.page_id)}
                >
              <span title={item.page_url}>
                <span className="page-url">{item.page_title}</span>
              </span>
              <div className="thumbnail" style={{ backgroundImage: `url(${'https://static.visiopt.com/page-thumb/'+item.img_url})` }} title={item.page_url}>
                {(item.marge_img !== '' && (
                    <Image
                    id="merge_img"
                    src={item.marge_img}
                    title="This Page is merged with other links"
                  />
                  ))
                }
                {(item.track_check !== '' && (
                  <Image
                    id="track_img"
                    src={item.track_check}
                    title={item.track_check_title}
                  />
                  ))
                }
              </div>
              <span className="page-title">{item.custom_title}</span>
            </div>
          </div>
      ))}
    </>
  );
}