import { Group, Text, Button, Box, Grid, Flex, ActionIcon, Modal, Space, Select, Input, CopyButton, Switch, Skeleton } from '@mantine/core';
import { IconHelp, IconPencil, IconTrash, IconCheck, IconX } from '@tabler/icons-react';
import React, { useEffect, useState, useRef } from 'react';
import { modals } from '@mantine/modals';
import { useNavigate } from "react-router-dom";
import ReactDOM from 'react-dom';
import { notifications } from '@mantine/notifications';
import SettingsApi from "../../../api/SettingsApi";
import { SelectTooltip } from "../../GeneralComponent/GeneralComponent";

export function ManageYourPostbacks({ SelectMemberWebsiteListValue, SelectMemberWebsiteListOnChange }:any) {

  const [addPostbackOpened, setAddPostback] = useState(false);

  const [AffiliateLinkParam, setAffiliateLinkParam] = useState('');

  const [AffiliateLinkURL, setAffiliateLinkURL] = useState('');

  const [AffiliateURLisVisible, setAffiliateURLIsVisible] = useState(false);


  const navigate = useNavigate();
  const [errorModelOpen, seterrorCloseModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorHeader, setShowErrorHeader] = useState(false);
  const [showMessageHeader, setShowMessageHeader] = useState(false);
  const [postbackList, setPostbackList] = useState([]);
  const [affiliateNetworkList, setAffiliateNetworkList] = useState([]);
  const [funnelPageList, setFunnelPageList] = useState([]);

  const [FunnelPageValue, setFunnelPage] = useState('');
  const [LandingPageValue, setLandingPage] = useState('');

  const [AffiliateNetworkValue, setAffiliateNetworkValue] = useState('');
  const [PostbackNameValue, setPostbackNameValue] = useState('');

  const [recordUniqueConversionchecked, setRecordUniqueConversionchecked] = useState(0);
  const [PostbackUpdateId, setPostbackUpdateId] = useState('');

  const [FunnelIdValue, setFunnelIdValue] = useState('');
  const [PostbackUrlValue, setPostbackUrlValue] = useState('');
  const [funnelList, setfunnelList] = useState([]);
  const [NetworkValue, setNetwork] = useState('');
  const [NetworkText, setNetworkText] = useState('');
  const [AffiliateUrlValue, setAffiliateUrl] = useState('https://');
  const [marksalestime, setMarksalestime] = useState(0);

  const managePostbackIconRef = useRef(null);
  const addPostbackIconRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({});
  const [showSkeleton, setShowSkeleton] = useState(false);

  const handleFunnelPageChange = (value:any) => {
    setFunnelPage(value);
  }

  const handleLandingPageChange = (value:any) => {
    setLandingPage(value);
  }

  const handleNetworkChange = (selectedValue) => {
  const selectedNetwork = affiliateNetworkList.find(network => network.value === selectedValue);
  setNetwork(selectedValue);
  setNetworkText(selectedNetwork.label);
};


  const handleSwitchChange = (event) => {
    const isChecked = event.currentTarget.checked;
    setRecordUniqueConversionchecked(isChecked ? 1 : 0);
  };

  const handleUpdateAffiliateLink = () => {
    SettingsApi.generataffiliatenetworkurl({
      network_value: NetworkValue,
      affiliate_url: AffiliateUrlValue
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        setAffiliateLinkParam(data.data); 
        let myArr = AffiliateUrlValue.split("?");
        console.log(myArr);
        if (myArr.length > 1) {
          let myArrparam = data.data.split("?");
          if (myArrparam.length > 1) {
            let param = data.data.replace("?", "&");
            if (AffiliateUrlValue.slice(-1) === '/' && data.data.slice(0, 1) === '/') {
              param = data.data.replace("/", "");
              setAffiliateLinkURL(AffiliateUrlValue + param);
            } else {
              setAffiliateLinkURL(AffiliateUrlValue + param);
            }
           }else {
            if (AffiliateUrlValue.slice(-1) === '/' && data.data.slice(0, 1) === '/') {
              data.data = data.data.replace("/", "");
              setAffiliateLinkURL(AffiliateUrlValue + data.data);
            } else {
              setAffiliateLinkURL(AffiliateUrlValue + data.data);
            }
          }

        } else {
          let param = data.data.replace("&", "?");
          if (AffiliateUrlValue.slice(-1) === '/' && data.data.slice(0, 1) === '/') {
            param = data.data.replace("/", "");
            setAffiliateLinkURL(AffiliateUrlValue + param);
          } else {
            setAffiliateLinkURL(AffiliateUrlValue + param);
          }
        }
        setAffiliateURLIsVisible(true);
      } else {
        notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: data.message,
        });
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  }

  const handleAffiliateNetworkChange = (value:any) => {
    setAffiliateNetworkValue(value);
  }

  const closePostbackModal = () => {
    setAddPostback(false);
    setFunnelPage('');
    setLandingPage('');
    setAffiliateNetworkValue('');
    setPostbackNameValue('');
    setFunnelIdValue('');
    setAffiliateLinkParam('');
    setPostbackUrlValue('');
    setNetwork('');
    setNetworkText('');
    setAffiliateUrl('https://');
    setAffiliateLinkURL('');
    setAffiliateURLIsVisible(false);
  }

  const handleAddPostbackSubmit = (e:any) => {
    e.preventDefault();
    SettingsApi.saveupdatepostback({
      funnel_page: FunnelPageValue,
      landing_page:LandingPageValue,
      affiliate_network:AffiliateNetworkValue,
      postback_name:PostbackNameValue,
      sales_conversion: recordUniqueConversionchecked,
      postback_id: PostbackUpdateId ? PostbackUpdateId : ''
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        closePostbackModal();
        setMarksalestime(data.data.updated_time);
        setPostbackUpdateId('');
      }else{
        notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: data.message,
        });
        setErrorMessage(data.message);
        seterrorCloseModal(true);
        setShowErrorHeader(false); 
        setShowMessageHeader(false);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };


  const updatePostback = (UpdatePostbackId:any,funnelId:any) => {
    setPostbackUpdateId(UpdatePostbackId);
    setAddPostback(true);
    SettingsApi.getsinglepostback({
      postback_id: UpdatePostbackId
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setAffiliateNetworkValue(data.data.affiliate_network);
        setPostbackNameValue(data.data.name);
        setFunnelIdValue(data.data.funnel_id);
        setLandingPage(data.data.page_id);
        setPostbackUrlValue(data.data.postback_url);
      }else{
        setErrorMessage(data.message);
        seterrorCloseModal(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });

    SettingsApi.getfunnelpagelistbyfunnelid({
      funnel_id: funnelId
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setFunnelPageList(data.data);
      }else{
        setErrorMessage(data.message);
        seterrorCloseModal(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  }

  useEffect(() => {
    if(FunnelIdValue !== ''){
      SettingsApi.getfunnellist({
        system_website_id: SelectMemberWebsiteListValue
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setfunnelList(data.data);
          if(data.data.length > 0){
            var i;
            for(i = 0; i <data.data.length; i++){
              if(data.data[i].funnel_id === FunnelIdValue){
                setFunnelPage(data.data[i].funnel_id);
                break;
              }
            }
          }else{
            setFunnelPage('');
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
  }, [FunnelIdValue]);

  const handlePostbackDelete = (deletePostbackId:any,deletePageId:any) => {
    setShowSkeleton(true);
    SettingsApi.deletepostback({
      postback_id: deletePostbackId,
      landing_page: deletePageId,
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
          setShowSkeleton(false);
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        setMarksalestime(data.updated_time);
        setShowSkeleton(false);
      }
    })
    .catch(error => {
      console.log(error);
      setShowSkeleton(false);
    });
  }

  const deletePostbackConfirm = (deletePostbackId:any,deletePageId:any) => modals.openConfirmModal({
    title: 'Please confirm your action',
    children: (
      <Text size="sm">
        Are you sure you want to delete this Postbacks?
      </Text>
    ),
    labels: { confirm: 'Confirm', cancel: 'Cancel' },
    onCancel: () => console.log('Cancel'),
    onConfirm: () => handlePostbackDelete(deletePostbackId,deletePageId),
  });
  
  useEffect(() => {
    if(SelectMemberWebsiteListValue !== ''){
      seterrorCloseModal(false);
      setShowSkeleton(true);
      SettingsApi.getpostbacklist()
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
            setShowSkeleton(false);
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setPostbackList(data.data);
          setShowSkeleton(false);
        }else{
          setErrorMessage(data.message);
          seterrorCloseModal(true);
          setShowSkeleton(false);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
        setShowSkeleton(false);
      });

      SettingsApi.getaffiliatenetworklist()
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          const formattedData = Object.entries(data.data).map(([value, label]) => ({ value, label }));
          setAffiliateNetworkList(formattedData);
        }else{
          setErrorMessage(data.message);
          seterrorCloseModal(true);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });

      SettingsApi.getfunnellist({
        system_website_id: SelectMemberWebsiteListValue
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setfunnelList(data.data);
        }
      })
      .catch(error => {
        console.log(error);
      });

      if(FunnelPageValue !== ''){
        SettingsApi.getfunnelpagelistbyfunnelid({
          funnel_id: FunnelPageValue
        })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            if (response.status === 401 || response.status === 400) {
              navigate("/logout");
            }
          }
        })
        .then(data => {
          if(data.status === 1){
            setFunnelPageList(data.data);
          }else{
            setErrorMessage(data.message);
            seterrorCloseModal(true);
          }
        })
        .catch(error => {
          console.error("API Error:", error);
        });
      }
    }
  }, [SelectMemberWebsiteListValue,marksalestime,navigate,FunnelPageValue]);

  const handlerrorCloseModal = () => {
    seterrorCloseModal(false);
  };

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    const iconRef = id === 290 ? managePostbackIconRef : addPostbackIconRef;
    if (iconRef.current) {
      const { top, left, width } = iconRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        [id]: {top: top + scrollOffset, left: left + width + 10},
      });
    }
  };

  return (
    <>
      {showHelpModal &&
        ReactDOM.createPortal(
          <div>
            {Object.keys(tooltipPosition).map((key) => (
              <SelectTooltip
                isVisible={showHelpModal}
                setIsVisible={setShowHelpModal}
                id={helpTargetId}
                tooltipPosition={tooltipPosition[key]} 
              />
            ))}
          </div>,
        document.body
      )}
      <Modal.Root size={1100} opened={addPostbackOpened} onClose={closePostbackModal}>
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title><Text weight={500}>POSTBACKS URL BUILDER <IconHelp ref={addPostbackIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(161)}/></Text></Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleAddPostbackSubmit}>
              <Group mb={10}>
                <Box
                  mt={10}
                  sx={(theme) => ({
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#f8f9fa",
                    padding: "10px",
                    borderRadius: "4px"
                  })}
                >
                  <Text weight={500}>Don't have direct access to a Thank You Page?</Text>
                  <Space h="xs" />
                  <Text>Postback URL's allow you to get accurate conversion data sent direct from your affiliate network or any server that allows Postbacks.</Text>
                </Box>
              </Group>
              <Grid>
                <Grid.Col span={2}>
                  <Text>Select a Funnel:</Text>
                </Grid.Col>
                <Grid.Col span={2}>
                  <Select
                    data={funnelList.map(option => ({
                      value: option.funnel_id,
                      label: option.funnel_name
                    }))}
                    w={250}
                    value={FunnelPageValue}
                    name="funnel_page"
                    onChange={handleFunnelPageChange}
                  />
                </Grid.Col>
              </Grid>
              <Grid mb={10}>
                <Grid.Col span={2}>
                  <Text>Select a Landing Page:</Text>
                </Grid.Col>
                <Grid.Col span={2}>
                  <Select
                    data={funnelPageList.map(option => ({
                      value: option.page_id,
                      label: option.title
                    }))}
                    w={250}
                    value={LandingPageValue}
                    name="landing_page"
                    onChange={handleLandingPageChange}
                  />
                </Grid.Col>
              </Grid>
              <Text mb={10}>Simply follow these 3 simple steps:</Text>
              <Group mb={10}>
                <Text weight={500}>1.</Text><Text>Create Your Affiliate / Offer link below:</Text>
              </Group>
              <Group mb={10} ml={20}>
                <Box
                  mt={10}
                  sx={(theme) => ({
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#f8f9fa",
                    padding: "10px",
                    borderRadius: "4px"
                  })}
                >
                  <Text>Postback URLs allow you to accurately track your affiliate sales, upsells, downsells and refunds with a simple 2-step process. The first step is to pass a unique tracking ID to your affiliate links.</Text>
                  <Space h="xs" />
                  <Text>Just use this simple form and we'll show you exactly how to do that. If your affiliate network isn't listed, please see What if my affiliate network isn't listed?</Text>
                </Box>
              </Group>
              <Grid>
                <Grid.Col span={2} ml={20}>
                  <Text>Select Network:</Text>
                </Grid.Col>
                <Grid.Col span={2}>
                  <Select
                    data={affiliateNetworkList}
                    value={NetworkValue}
                    name="network"
                    w={200}
                    onChange={handleNetworkChange}
                  />
                </Grid.Col>
              </Grid>
              <Grid mb={10}>
                <Grid.Col span={2} ml={20}>
                  <Text>Select Link:</Text>
                </Grid.Col>
                <Grid.Col span={2}>
                  <Input
                    w={200}
                    name="affiliate_url"
                    onChange={(event) => setAffiliateUrl(event.currentTarget.value)}
                    value={AffiliateUrlValue}
                  />
                </Grid.Col>
              </Grid>
              <Button ml={20} mb={10} onClick={handleUpdateAffiliateLink}>
                Update My Affiliate Link
              </Button>
              {AffiliateURLisVisible && <div>
                <Text ml={20}>{NetworkText} it is, Here you go...</Text>
                <Group>
                  <Text ml={20} mb={10} mt={10}>To pass the correct tracking ID to your affiliate link, just add this to the end of your link so it looks like the URL below.</Text>
                  <Input
                    variant="filled"
                    value={AffiliateLinkParam}
                  />
                </Group>
                <Group ml={20} mb={10}>
                  <Input
                    variant="filled"
                    value={AffiliateLinkURL}
                    w={500}
                  />
                  <CopyButton value={AffiliateLinkURL}>
                    {({ copied, copy }) => (
                      <Button color={copied ? 'teal' : 'blue'} onClick={copy}>
                        {copied ? 'Copied url' : 'Copy url'}
                      </Button>
                    )}
                  </CopyButton>
                </Group>
              </div>}
              <Text ml={20} mb={10}>Next, we will create our Postback URL:</Text>
              <Group mb={10}>
                <Text weight={500}>2.</Text><Text>Select your affiliate network:</Text>
                <Select
                  data={affiliateNetworkList}
                  value={AffiliateNetworkValue}
                  name="affiliate_network"
                  onChange={handleAffiliateNetworkChange}
                />
              </Group>
              <Group mb={10}>
                <Text weight={500}>3.</Text><Text>Record unique conversion only : </Text>
                <Switch 
                  checked={recordUniqueConversionchecked === 1} 
                  onChange={handleSwitchChange} 
                />
              </Group>
              <Group mb={10}>
                <Text weight={500}>4.</Text><Text>Enter Postback Name: </Text>
                <Input
                  name="postback_name"
                  onChange={(event) => setPostbackNameValue(event.currentTarget.value)}
                  value={PostbackNameValue}
                />
              </Group>
              {PostbackUrlValue !== '' && (
                <Group mb={10}>
                  <Text weight={500}>5.</Text><Text>Copy the generated Postback url below, and paste it to the selected affiliate site or server: <br/>{PostbackUrlValue}</Text>
                  <CopyButton value={PostbackUrlValue}>
                    {({ copied, copy }) => (
                      <Button color={copied ? 'teal' : 'blue'} onClick={copy}>
                        {copied ? 'Copied url' : 'Copy url'}
                      </Button>
                    )}
                  </CopyButton>
                </Group>
              )}
              <Group mb={10}>
                <Button type="submit">
                  Save & generate Postback url
                </Button>
                <Button color="red" onClick={closePostbackModal}>
                  Cancel
                </Button>
              </Group>
            </form>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
      <Group position="apart" mb="xs">
        <Text weight={500}>MANAGE YOUR POSTBACKS <IconHelp ref={managePostbackIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(290)}/></Text>
      </Group>
      <Button onClick={() => setAddPostback(true)}>
        Add a New Postback
      </Button>
      {showSkeleton ? (
        <Flex direction="column" gap="sm" mt={10}>
          {Array.from({ length: 12 }).map((_, index) => (
            <Skeleton key={index} height={44} width={1262} />
          ))}
        </Flex>
      ) : (
        postbackList.map((item,index) => (
          <Box
           key={index}
            p={4}
            pl={20}
            mt={10}
            sx={(theme) => ({
              backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : '#f8f9fa',
              padding: "10px",
              borderRadius: theme.radius.md,
              border: "0.0625rem solid transparent",
              borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[1] : '#dee2e6'
            })}
          >
            <Grid gutter="xs">
              <Grid.Col span={3}>
                <Text mt={3}>
                  {item.postback_name}
                </Text>
              </Grid.Col>
              <Grid.Col span={3}>
                <Text mt={3}>
                  {item.page_name}
                </Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Flex
                  justify="flex-end"
                  align="center"
                  direction="row"
                  wrap="wrap"
                >
                  <ActionIcon
                    variant="default"
                    size="lg"
                    mx={3}
                    title="Edit"
                    onClick={() => updatePostback(item.postback_id, item.funnel_id)}
                  >
                    <IconPencil size="1.1rem" />
                  </ActionIcon>
                  <ActionIcon
                    variant="default"
                    size="lg"
                    mx={3}
                    title="Delete"
                    onClick={() => deletePostbackConfirm(item.postback_id, item.page_id)}
                  >
                    <IconTrash size="1.1rem" />
                  </ActionIcon>
                </Flex>
              </Grid.Col>
            </Grid>
          </Box>
        ))
      )}
      {/*Error Model*/}
      {errorModelOpen && (
      <Modal.Root opened={true} onClose={handlerrorCloseModal} style={{ zIndex: 2 }}>
        <Modal.Overlay />
        <Modal.Content>
          {showMessageHeader ? (
            <Modal.Header>
              <Modal.Title>Select Smart Relational Test Alert</Modal.Title>
              <Modal.CloseButton />
            </Modal.Header>
          ): showErrorHeader ? (
            <Modal.Header>
              <Modal.Title>ERROR</Modal.Title>
              <Modal.CloseButton />
            </Modal.Header>
          ): (
            <Modal.Header>
            </Modal.Header>
          )}
          <Modal.Body>
            <form>
              <div style={{ display: 'flex'}}>
                  <Text weight={500}>{errorMessage}</Text>
              </div>
            </form>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
      )}
    </>
  );
}