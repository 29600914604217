import {
  Select,
  Modal,
  Flex,
  Button,
  LoadingOverlay,
  Text,
  Box,
  ActionIcon
} from "@mantine/core";
import { DatePickerInput } from '@mantine/dates';
import {
  IconChevronDown, IconX,
} from "@tabler/icons-react";
import { visiUserSession, setVisiUserSession } from '../../GeneralFunction';
import { useNavigate, Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import moment from 'moment';
import $ from 'jquery';
import 'magnific-popup';
import 'magnific-popup/dist/magnific-popup.css';
import GeneralComponentApi from "../../api/GeneralComponentApi";
import './GeneralComponent.css';

export function SelectMemberWebsiteList({ SelectMemberWebsiteListValue, SelectMemberWebsiteListOnChange, SelectMemberWebsiteListWidth, SelectMemberWebsiteListUsedIn, SelectMemberWebsiteListMaxDropdownHeight, SelectMemberWebsiteListdropdownPosition, SelectMemberWebsiteListdropdownPositionMB, SelectMemberWebsiteListML  }:any) {

  const handleMemberWebsiteChange = (e:any) => {
    SelectMemberWebsiteListOnChange(e);
    if(SelectMemberWebsiteListUsedIn && SelectMemberWebsiteListUsedIn === 'HeaderMenu'){
      setVisiUserSession('website', e);
    }
  };

  const navigate = useNavigate();

  type Website = {
    website_id: string;
    website_name: string;
  }

  const [memberWebsiteList, setmemberWebsiteList] = useState<Website[]>([]);

  useEffect(() => {
    GeneralComponentApi.getmemberwebsitelist()
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setmemberWebsiteList(data.data);
        if(data.data.length > 0){
          if(visiUserSession() && visiUserSession().website && visiUserSession().website !== ''){
            SelectMemberWebsiteListOnChange(visiUserSession().website);
          }else{
            SelectMemberWebsiteListOnChange(data.data[0].website_id);
          }
        }
      }
    })
    .catch(error => {
      console.log(error);
    });

  }, []);

  return (
    <>
      <Select
        rightSection={<IconChevronDown size="1rem" />}
        rightSectionWidth={30}
        styles={{ rightSection: { pointerEvents: 'none' } }}
        data={memberWebsiteList.map(website => ({
            value: website.website_id,
            label: website.website_name
        }))}
        value={SelectMemberWebsiteListValue}
        onChange={handleMemberWebsiteChange}
        w={SelectMemberWebsiteListWidth}
        searchable
        maxDropdownHeight={SelectMemberWebsiteListMaxDropdownHeight}
        dropdownPosition={SelectMemberWebsiteListdropdownPosition}
        mb={SelectMemberWebsiteListdropdownPositionMB}
        ml={SelectMemberWebsiteListML}
      />
    </>
  );
}

export function SelectFilterType({ SelectFilterTypeValue, SelectFilterTypeOnChange }:any) {

  const handleFilterTypeChange = (e:any) => {
    SelectFilterTypeOnChange(e);
  };

  const [filterTypeList, setfilterTypeList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    GeneralComponentApi.getfiltertypelist()
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        let filterTypeListData = [];
        for(const key in data.data[0]){
          filterTypeListData.push({
            value: key, label: data.data[0][key]
          });
        }
        setfilterTypeList(filterTypeListData);
        if(data.data.length > 0){
          const filterTypeListKeys = Object.keys(data.data[0]);
          SelectFilterTypeOnChange(filterTypeListKeys[0]);
        }
      }
    })
    .catch(error => {
      console.log(error);
    });

  }, []);

  return (
    <>
      <Select
        data={filterTypeList}
        value={SelectFilterTypeValue}
        onChange={handleFilterTypeChange}
        searchable
      />
    </>
  );
}

export function SelectTimePicker({ SelectTimePickerValue, SelectTimePickerOnChange, SelectTimePickerFromDateOnChange, SelectTimePickerToDateOnChange, SelectTestStartDate, SelectTestEndDate='', SelectStartDate='' }:any) {

  const [isTimeRangeModalOpen, setIsTimeRangeModalOpen] = useState(false);
  const [FromDatevalue, setFromDateValue] = useState<Date | null>(SelectTestStartDate);
  const [ToDatevalue, setToDateValue] = useState<Date | null>(null);
  const [MinStartDateValue, setMinStartDateValue] = useState<Date | null>(null);
  const [lastSelectedValue, setLastSelectedValue] = useState(SelectTimePickerValue);
  const [displayedDropdownValue, setDisplayedDropdownValue] = useState(null);

  const handleTimePickerChange = (e:any) => {
    SelectTimePickerOnChange(e);
    setVisiUserSession('time', e);
    if(e === -2){
      const [year, month, day] = SelectTestStartDate.split("-");
      const formattedDate = new Date(`${year}-${month}-${day}`);
      let endformattedDate, startFormattedDate = new Date();
      if(SelectTestEndDate){
        const [endYear, endMonth, endDay] = SelectTestEndDate.split("-");
        endformattedDate = new Date(`${endYear}-${endMonth}-${endDay}`);
      }
      if(SelectStartDate){
        const [startYear, startMonth, startDay] = SelectStartDate.split("-");
        startFormattedDate = new Date(`${startYear}-${startMonth}-${startDay}`);
      }
      setFromDateValue(formattedDate);
      setToDateValue(endformattedDate);
      setMinStartDateValue(startFormattedDate);
      setIsTimeRangeModalOpen(true);
    }else if (e === 11) {
      SelectTimePickerOnChange(-2);
      setIsTimeRangeModalOpen(true);
    }else{
      setLastSelectedValue(e);
      setFromDateValue(null);
      setToDateValue(null);
      SelectTimePickerFromDateOnChange("");
      SelectTimePickerToDateOnChange("");
      if (e !== -2) {
        setTimePickerList((prev) => prev.filter(item => item.value !== 11));
      }
    }
    setDisplayedDropdownValue(e);
  };

  const [TimePickerList, setTimePickerList] = useState([]);

  const navigate = useNavigate();
  let pagename = visiUserSession().page.replace("/","");
  useEffect(() => {
    GeneralComponentApi.gettimepicker({
      type:pagename
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        let TimePickerListData = [];
        for(const key in data.data[0]){
          TimePickerListData.push({
            value: data.data[0][key]['option_value'], label: data.data[0][key]['option_name']
          });
        }
        setTimePickerList(TimePickerListData);
        if(data.data.length > 0){
          if(pagename === "dashboard"){
            SelectTimePickerOnChange(-3);
            setLastSelectedValue(-3);
          }else{
            if(visiUserSession().time){
              SelectTimePickerOnChange(visiUserSession().time);
              setLastSelectedValue(visiUserSession().time);
            }else{
              SelectTimePickerOnChange(-1);
              setLastSelectedValue(-1);
            }
          }
        }
      }
    })
    .catch(error => {
      console.log(error);
    });

  }, []);

  const handleTimeRangeCloseModal = () => {
    setIsTimeRangeModalOpen(false);
    if(lastSelectedValue !== -2){
      setDisplayedDropdownValue(lastSelectedValue);
    }else{
      setDisplayedDropdownValue(11);
    }
    SelectTimePickerOnChange(lastSelectedValue);
  };
  const currentDate = moment();
  const handleDateFilterSubmit = (event:any) => {
    event.preventDefault();
    const currentDate = moment();
    const FromDate = (FromDatevalue) ? moment(FromDatevalue).format("DD-MM-YYYY") : moment(currentDate).format("DD-MM-YYYY");
    const ToDate = (ToDatevalue) ? moment(ToDatevalue).format("DD-MM-YYYY") : moment(currentDate).format("DD-MM-YYYY");
    const formattedDateRange = `${FromDate} - ${ToDate}`;
    if(SelectTimePickerValue === -2){
      SelectTimePickerFromDateOnChange(FromDate);
      SelectTimePickerToDateOnChange(ToDate);
      setVisiUserSession('start_date', FromDate);
      setVisiUserSession('end_date', ToDate);
      setTimePickerList((prev) => {
        const updatedList = prev.filter((item) => item.value !== 11);
        updatedList.push({ value: 11, label: formattedDateRange });
        return updatedList;
      });
      setLastSelectedValue(-2);
      SelectTimePickerOnChange(-2);
      setDisplayedDropdownValue(11);
    }else{
      SelectTimePickerFromDateOnChange("");
      SelectTimePickerToDateOnChange("");
      setTimePickerList((prev) => prev.filter((item) => item.value !== 11));
      setLastSelectedValue(SelectTimePickerValue);
      setDisplayedDropdownValue(SelectTimePickerValue);
    }
    setIsTimeRangeModalOpen(false);
  };

  return (
    <>
      <Modal opened={isTimeRangeModalOpen} onClose={handleTimeRangeCloseModal} title="TIME RANGE FILTER">
        <form onSubmit={handleDateFilterSubmit}>
          <DatePickerInput
            mb={10}
            popoverProps={{ withinPortal: true }}
            valueFormat="DD-MM-YYYY"
            label="From Date"
            name="from_date"
            value={FromDatevalue}
            onChange={setFromDateValue}
            maxDate={pagename === "dashboard" ? undefined : currentDate.toDate()}
            minDate={pagename === "heatmap-view" || pagename === "scrollmap-view" ? MinStartDateValue : undefined}
            mx="auto"
            maw={400}
          />
          <DatePickerInput
            mb={10}
            popoverProps={{ withinPortal: true }}
            valueFormat="DD-MM-YYYY"
            label="To Date"
            name="to_date"
            value={ToDatevalue}
            onChange={setToDateValue}
            maxDate={pagename === "dashboard" ? undefined : currentDate.toDate()}
            mx="auto"
            maw={400}
          />
          <Flex
            justify="flex-end"
            align="flex-start"
            direction="row"
            wrap="wrap"
          >
            <Button type="submit" color="green">Filter</Button>
          </Flex>
        </form>
      </Modal>
      <Select
        withinPortal
        onChange={handleTimePickerChange}
        value={displayedDropdownValue || SelectTimePickerValue}
        data={TimePickerList}
      />
    </>
  );
}

export function SelectWebsitePages({ SelectWebsitePagesValue, SelectWebsitePagesOnChange, SelectWebsitePagesSelectedMemberWebsite }:any) {

  const handleWebsitePagesChange = (e:any) => {
    SelectWebsitePagesOnChange(e);
  };

  const navigate = useNavigate();

  type Page = {
    page_id: string;
    custom_title: string;
  }

  const [WebsitePages, setWebsitePages] = useState<Page[]>([]);

  useEffect(() => {
    if(SelectWebsitePagesSelectedMemberWebsite !== ''){
      GeneralComponentApi.getwebsitepages({
        system_website_id: SelectWebsitePagesSelectedMemberWebsite
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setWebsitePages(data.data);
          const allPagesOption = data.data.find((page: Page) => page.custom_title === "All pages");
          if(allPagesOption){
            SelectWebsitePagesOnChange(allPagesOption.page_id);
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
  }, [SelectWebsitePagesSelectedMemberWebsite]);

  return (
    <>
      <Select
        data={WebsitePages.map(Page => ({
          value: Page.page_id,
          label: Page.custom_title
        }))}
        value={SelectWebsitePagesValue}
        onChange={handleWebsitePagesChange}
        searchable
      />
    </>
  );
}

export function VisiLoader({ VisiLoaderVisible }:any) {

  return (
    <>
      <LoadingOverlay zIndex={9999999999} visible={VisiLoaderVisible} overlayBlur={2} loaderProps={{variant:"bars", color:"gray"}} style={{width:'100%', height:'100%', display:'flex', position:'fixed'}} />
    </>
  );
}

export function SelectActiveTest({ SelectActiveTestValue, SelectActiveTestOnChange, SelectActiveTestSelectedMemberWebsite, setActiveTests }:any) {

  const handleActiveTestChange = (e:any) => {
    SelectActiveTestOnChange(e);
  };

  const navigate = useNavigate();

  type Test = {
    id: string;
    value: string;
  }

  const [ActiveTest, setActiveTest] = useState<Test[]>([]);

  useEffect(() => {
    if(SelectActiveTestSelectedMemberWebsite !== ''){
      GeneralComponentApi.getdashboardactivetestdropdown({
        system_website_id: SelectActiveTestSelectedMemberWebsite
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setActiveTest(data.data);
          setActiveTests(data.data);
          if(data.data.length > 0){
            SelectActiveTestOnChange(data.data[0].id);
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
  }, [SelectActiveTestSelectedMemberWebsite]);

  return (
    <>
      <Select
        data={ActiveTest.map(Test => ({
          value: Test.id,
          label: Test.value
        }))}
        value={SelectActiveTestValue}
        onChange={handleActiveTestChange}
        searchable
      />
    </>
  );
}

export function SelectGetGoal({ SelectGetGoalValue, SelectGetGoalOnChange, SelectGetGoalSelectedMemberWebsite, SelectGetGoalSelectedActiveTestPage, SelectGetGoalSelectedGoalType }:any) {

  const handleGetGoalChange = (e:any) => {
    SelectGetGoalOnChange(e);
  };

  const navigate = useNavigate();

  type Goal = {
    id: string;
    value: string;
  }

  const [Goal, setGoal] = useState<Goal[]>([]);

  useEffect(() => {
    if(SelectGetGoalSelectedMemberWebsite !== '' && SelectGetGoalSelectedActiveTestPage !== '' && SelectGetGoalSelectedGoalType){
      GeneralComponentApi.getgoal({
        system_website_id: SelectGetGoalSelectedMemberWebsite,
        active_test_page_id: SelectGetGoalSelectedActiveTestPage,
        goal_type: SelectGetGoalSelectedGoalType,
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setGoal(data.data);
          if(data.data.length > 0){
            SelectGetGoalOnChange(data.data[0].id);
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
  }, [SelectGetGoalSelectedMemberWebsite, SelectGetGoalSelectedActiveTestPage, SelectGetGoalSelectedGoalType]);

  return (
    <>
      <Select
        data={Goal.map(Goal => ({
          value: Goal.id,
          label: Goal.value
        }))}
        value={SelectGetGoalValue}
        onChange={handleGetGoalChange}
        searchable
      />
    </>
  );
}

export function BoxPageThumb({ BoxPageThumbSelectedMemberWebsite, BoxPageThumbSelectedActiveTestPage }:any) {

  const navigate = useNavigate();

  const [ThumbTitle, setThumbTitle] = useState('');
  const [ThumbCustomTitle, setThumbCustomTitle] = useState('');
  const [Thumb, setThumb] = useState('');
  const [ThumbURL, setThumbURL] = useState('');

  useEffect(() => {
    if(BoxPageThumbSelectedMemberWebsite !== '' && BoxPageThumbSelectedActiveTestPage !== ''){
      GeneralComponentApi.getpagethumb({
        system_website_id: BoxPageThumbSelectedMemberWebsite,
        active_test_page_id: BoxPageThumbSelectedActiveTestPage
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          if(data){
            if(data.data.title){
              setThumbTitle(data.data.title);
            }
            if(data.data.custom_title){
              setThumbCustomTitle(data.data.custom_title);
            }
            if(data.data.thumb){
              setThumb(data.data.thumb);
            }
            if(data.url){
              setThumbURL(data.data.url);
            }
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
  }, [BoxPageThumbSelectedMemberWebsite, BoxPageThumbSelectedActiveTestPage]);
  return (
    <>
      <div className="visi-page">
        <div className="page">
          <a href="">
            <span className="page-url">{ThumbTitle}</span>
          </a>
          <div className="thumbnail" style={{backgroundImage : `url(${Thumb})`}}>
          </div>
          <span className="page-title">{ThumbCustomTitle}</span>
        </div>
      </div>
    </>
  );
}

export function SelectFunnelList({updated_time, Value, OnChange, MemberWebsiteId, Width, IsSelected = true, IsFieldDisabled }:any) {

  const handleFunnelListChange = (e:any) => {
    OnChange(e);
  };

  const navigate = useNavigate();

  type Funnel = {
    funnel_id: string;
    funnel_name: string;
  }

  const [funnelList, setfunnelList] = useState<Funnel[]>([]);

  useEffect(() => {
    if(MemberWebsiteId !== ''){
      GeneralComponentApi.getfunnellist({
          system_website_id: MemberWebsiteId
        })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })

      .then(data => {
        if(data.status === 1){
          setfunnelList(data.data);
          if(data.data.length > 0){
            if(IsSelected){
              var i;
              for(i = 0; i <data.data.length; i++){
                if(data.data[i].selected === 'true'){
                  OnChange(data.data[i].funnel_id);
                  break;
                }
              }
            }else{
              OnChange('');
            }
          }else{
            OnChange('');
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
  }, [MemberWebsiteId,updated_time]);
  return (
    <>
      {IsFieldDisabled === true ? ( 
        <Select
          data={funnelList.map(funnel => ({
            value: funnel.funnel_id ? funnel.funnel_id : '',
            label: funnel.funnel_name ? funnel.funnel_name : ''
          }))}
          value={Value}
          onChange={handleFunnelListChange}
          w={Width}
          searchable
          placeholder="Select a funnel"
          disabled={true}
        />
      ) : ( 
        <Select
          data={funnelList.map(funnel => ({
            value: funnel.funnel_id ? funnel.funnel_id : '',
            label: funnel.funnel_name ? funnel.funnel_name : ''
          }))}
          value={Value}
          onChange={handleFunnelListChange}
          w={Width}
          searchable
          placeholder="Select a funnel"
        />
      )}
    </>
  );
}

export function SelectTooltip({isVisible, setIsVisible, id, tooltipPosition, type=''}:any) {

  const navigate = useNavigate();
  const [helpTitle, setHelpTitle] = useState('');
  const [helpText, setHelpText] = useState('');
  const [helpVideoLink, setHelpVideoLink] = useState('');
  const [showVideo, setShowVideo] = useState('');

  useEffect(() => {
    GeneralComponentApi.gethelpdetails({
      help_id: id
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setHelpTitle(data.data.title);
        setHelpText(data.data.text);
        setHelpVideoLink(data.data.video_link);
        setShowVideo(data.data.show_video);
      }
    })
    .catch(error => {
      console.log(error);
    });
  }, [id]);

  useEffect(() => {
    if(showVideo === '1'){
      $('.help-video').magnificPopup({
        disableOn: 700,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false,
        callbacks: {
          open: function () {
            $('.tooltip_container').css('z-index', '99999990');
            $('.mfp-wrap').css('z-index', '99999999999');
            $('.mfp-bg').css('z-index', '99999999999');
          },
          close: function () {
            $('.tooltip_container').css('z-index', type === 'test_editor' ? '99999999' : '500');
          },
        },
      });
    }
  }, [showVideo]);

  const openVideoPopup = () => {
    const videoLink = $('.help-video');
    if (videoLink.length > 0) {
      videoLink.magnificPopup('open');
    } else {
      console.log('Video link not found');
    }
  };

  return (
    <>
      {isVisible === true ? (
          <Box
          className="tooltip_container"
          style={{
            position: 'absolute',
            top: tooltipPosition.top,
            left: tooltipPosition.left,
            width: '400px',
            backgroundColor: '#f8d7da',
            color: '#721c24',
            borderRadius: '8px',
            padding: '20px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            border: '1px solid #f5c6cb',
            zIndex: type === 'test_editor' ? 99999999 : 500,
            transition: 'opacity 0.3s ease',
            opacity: isVisible ? 1 : 0,
          }}
        >
          <Flex justify="space-between" align="center">
            <Text weight={500} size="lg">{helpTitle}</Text>
            <ActionIcon variant="transparent" onClick={() => setIsVisible(false)}>
              <IconX size={18} color="#721c24" />
            </ActionIcon>
          </Flex>
          <Text mt="sm" size="sm" dangerouslySetInnerHTML={{ __html: helpText }} />
          {showVideo === '1' && (
            <>
              <Button
                variant="filled"
                fullWidth
                mt="lg"
                style={{ backgroundColor: '#c82333', color: '#fff' }}
                onClick={openVideoPopup}
              >
                Watch video tutorial
              </Button>
              <a href={helpVideoLink} className="help-video" style={{ display: 'none' }}>
                Watch Video
              </a>
            </>
          )}          
          <Flex justify="center" mt="sm">
            <Link
              to="https://visiopt.com/help-videos.html"
              target="_blank"
              style={{ color: '#0056b3', textDecoration: 'underline', cursor: 'pointer' }}
            >
              Click Here To Reach Our Help Library
            </Link>
          </Flex>
        </Box>
        ) : null
      }
    </>
  );
}

export function ErrorModal({ errorModelOpen,errorMessage,showErrorHeader,showMessageHeader,onClose}:any) {
  return (
    <>
      {errorModelOpen && (
      <Modal.Root opened={true} onClose={onClose} style={{ zIndex: 2 }}>
        <Modal.Overlay />
        <Modal.Content>
          {showMessageHeader ? (
            <Modal.Header>
              <Modal.Title>MESSAGE</Modal.Title>
              <Modal.CloseButton />
            </Modal.Header>
          ) : showErrorHeader ? (
            <Modal.Header>
              <Modal.Title>ERROR</Modal.Title>
              <Modal.CloseButton />
            </Modal.Header>
          ) : (
            <Modal.Header>
            </Modal.Header>
          )}
          <Modal.Body>
            <form>
              <div style={{ display: 'flex'}}>
                  <Text weight={500}>{errorMessage}</Text>
              </div>
            </form>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
      )}
    </>
  );
}