import { Group, Text } from '@mantine/core';

export function FunnelLegend(){
	return (
		<>
		<div className="legend-holder">
			<Group spacing="xs" m={0}>
			<span className="legend selected" />
			<Text size="sm" ml={0} fz={12} className="legend-title">Selected</Text>
			<span className="legend active" />
			<Text size="sm" ml={0} fz={12} className="legend-title">Active Test</Text>
			<span className="legend rotation" />
			<Text size="sm" ml={0} fz={12} className="legend-title">Rotation Page</Text>
			<span className="legend no-test" />
			<Text size="sm" ml={0} fz={12} className="legend-title">No Test</Text>
			<span className="legend completed" />
			<Text size="sm" ml={0} fz={12} className="legend-title">Test Completed</Text>
			<span className="legend unique" />
			<Text size="sm" ml={0} fz={12} className="legend-title">Record Unique Conversions Only</Text>
			<span className="legend winner" />
			<Text size="sm" ml={0} fz={12} className="legend-title">Winner Set</Text>
			<span className="legend draft" />
			<Text size="sm" ml={0} fz={12} className="legend-title">Draft Test</Text>
			</Group>
		</div>
		</>
	);
}