import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { IconCheck } from '@tabler/icons-react';
import TestsApi from "../../api/TestsApi";
import { notifications } from '@mantine/notifications';

export function TestMode({ selectedMemberWebsite, pageId, taguchiTestId, testModeShow, isTestMode, setMarksalestime, TestOptimizeReport, errorModelOpen, errorMessage, showErrorHeader, showMessageHeader, systemUsageLoader}:any) {
	const navigate = useNavigate();

  useEffect(() => {
    if (isTestMode) {
	    systemUsageLoader(true);
	  	TestsApi.savetestmode({
	      system_website_id: selectedMemberWebsite,
	      page_id:pageId,
	      taguchi_type_id:taguchiTestId,
	      testmode:testModeShow
	    })
	    .then(response => {
	      if (response.ok) {
	        return response.json();
	      } else {
	        if (response.status === 401 || response.status === 400) {
	          navigate("/logout");
	        }
	      }
	    })
	    .then(data => {
	      if (data.status === 1) {
	        systemUsageLoader(false);
	        setMarksalestime(data.data.updated_time);
	        TestOptimizeReport();
	        notifications.show({
	          color: 'green',
	          icon: <IconCheck size="1.1rem" />,
	          title: 'Success',
	          message: data.message,
	        });
	      } else {
	        errorMessage(data.message);
	        errorModelOpen(true);
	        showErrorHeader(true);
	        systemUsageLoader(false);
	      }
	    })
	    .catch(error => {
	      systemUsageLoader(false);
	      console.error("API Error:", error);
	    });
    }
  }, [isTestMode, pageId]);

  return (
    <>
    </>
  );
}