import React from 'react';
import { IconHelp } from "@tabler/icons-react";
import { modals } from '@mantine/modals';
import { Text, Button } from '@mantine/core';
import TestsApi from "../../api/TestsApi";

export function UnsetWinner({
    SelectedThumbnail,
    SystemUsageLoader,
    SelectedMemberWebsite,
    MarkSalesTime,
    ErrorMessageSet,
    ErrorCloseModal,
    ShowErrorHeader
  }:any) {

  const handleRemoveSetWinner = async (SelectedThumbnail) => {
    modals.openConfirmModal({
      title: 'Unset Winner',
      children: (
        <Text size="sm">
         Are you sure you want to remove "Set winner"?<br/>
         If you do this winner variation will stop being served to visitors, instead they will start seeing the control variation of the page again.
        </Text>
      ),
      labels: { confirm: 'Unset Winner', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => {
        handleUnsetwinner(SelectedThumbnail);
      },
    });
  }

  const handleUnsetwinner = async (SelectedThumbnail) =>{
    try {
      SystemUsageLoader(true);
      const APIResponse = await TestsApi.unsetwinner({
        page_id: SelectedThumbnail,
        system_website_id : SelectedMemberWebsite,
      });
      const APIResultJSON = await APIResponse.json();
      MarkSalesTime(APIResultJSON.data.updatedtime);
      if (APIResultJSON.status === 1) {
        SystemUsageLoader(false);
      }else{
        ErrorMessageSet(APIResultJSON.message);
        ErrorCloseModal(true);
        ShowErrorHeader(true);
        SystemUsageLoader(false);
      }
    } catch (error) {
      SystemUsageLoader(false);
      console.error("API Error:", error);
    }
  };

  return (
    <>
      <Text weight={500}>
        <IconHelp size="1.1rem" color="red" style={{ marginTop: 11, marginLeft: 91 }} />
      </Text>
      <Button color="red" type="button" style={{ marginLeft: 7 }} onClick={() => handleRemoveSetWinner(SelectedThumbnail)}>
        Remove "Set Winner"
      </Button>
    </>
  );
}