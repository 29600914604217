import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Text, ActionIcon, Input, Anchor } from '@mantine/core';
import { IconCheck, IconPencil } from '@tabler/icons-react';
import TestsApi from "../../api/TestsApi";
import { notifications } from '@mantine/notifications';

export function CustomTitle({ varId, verId, currentVariations, testnumber, previewadtest, editvariationhide, pageId, taguchiTestId, testsIdShow, setMarksalestime, TestOptimizeReport }:any) {
  const navigate = useNavigate();

  const [editingVarId, setEditingVarId] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [editClickVarId, setEditClickVarId] = useState(null);
  const [editClickVerId, setEditClickVerId] = useState(null);

  const handleEditCustomTitle = (varId, verId, currentVariations, testnumber) => {
    setEditClickVarId(varId);
    setEditClickVerId(verId);
    setEditingVarId(testnumber);
    setInputValue(currentVariations);
  };

  const handleCustomTitleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputBlur = () => {
    setEditingVarId(null);
    TestsApi.savecustomtitleintest({
      taguchi_test_id:taguchiTestId,
      page_id:pageId,
      variable: editClickVarId,
      version: editClickVerId,
      tests_id: testsIdShow,
      custom_title:inputValue
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
      setMarksalestime(data.data.updated_time);
      TestOptimizeReport();
      notifications.show({
        color: 'green',
        icon: <IconCheck size="1.1rem" />,
        title: 'Success',
        message: data.message,
      });
      } else {
        console.log("Error:","Error");
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  const handleCustomTitlePress = (event) => {
    if (event.key === 'Enter') {
      setEditingVarId(null);
      TestsApi.savecustomtitleintest({
        taguchi_test_id:taguchiTestId,
        page_id:pageId,
        variable: editClickVarId,
        version: editClickVerId,
        tests_id: testsIdShow,
        custom_title:inputValue
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if (data.status === 1) {
          setMarksalestime(data.data.updated_time);
          TestOptimizeReport();
          notifications.show({
            color: 'green',
            icon: <IconCheck size="1.1rem" />,
            title: 'Success',
            message: data.message,
          });
        } else {
          console.log("Error:","Error");
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
    }
  };
  return (
    <>
      {editingVarId === testnumber ? (
        <Input
          value={inputValue}
          onChange={handleCustomTitleChange}
          onBlur={handleInputBlur}
          onKeyPress={handleCustomTitlePress}
          autoFocus
          size="xs"
          w={150}
          mr={10}
        />
      ) : (
        <Text mt={6} w={150}><Anchor href={previewadtest} target="_blank" color="inherit">{currentVariations}</Anchor></Text>
      )}
      {editvariationhide === "show" && (
        <ActionIcon
          variant="default"
          size="md"
          title="Edit Title"
          onClick={() => handleEditCustomTitle(varId, verId, currentVariations, testnumber)}
        >
          <IconPencil size="1.1rem" />
        </ActionIcon>
      )}
    </>
  );
}