import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import ReactDOM from 'react-dom';
import { Modal, Button, Input, Group } from '@mantine/core';
import { IconHelp, IconCheck } from '@tabler/icons-react';
import TestsApi from "../../api/TestsApi";
import { notifications } from '@mantine/notifications';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import { TextEditorToolbar } from '../GeneralComponent/TextEditorToolbar';
import { RichTextEditor, Link } from '@mantine/tiptap';
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import { SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";

export function TestHistoryNotes({ selectedMemberWebsite, PageId, TestId, handleTestsNotesOpenModal, isTestsNotesModalOpen, onTestsNotesClose, noteTitle, setNoteTitle, noteContent, setNoteContent, errorModelOpen, errorMessage, showErrorHeader, showMessageHeader }:any) {
  const navigate = useNavigate();
  const testNoteRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const testnotesEditor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      Color,
      TextStyle,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
    ],
    content: '',
    onUpdate: ({ editor }) => {
      setNoteContent(editor.getHTML());
    },
  });

  const saveSelection = (editor) => {
    const { from, to } = editor.state.selection;
    return { from, to };
  };

  const restoreSelection = (editor, selection) => {
    editor.commands.setTextSelection(selection);
  };

  useEffect(() => {
    if (testnotesEditor) {
      if (noteContent === '') {
        testnotesEditor.commands.clearContent();
      } else {
        const selection = saveSelection(testnotesEditor);
        testnotesEditor.commands.setContent(noteContent, true);
        restoreSelection(testnotesEditor, selection);
      }
    }
  }, [noteContent, testnotesEditor]);

  const handleSaveTestNotes = (e) => {
  e.preventDefault();
  TestsApi.savetestsnote({
      system_website_id: selectedMemberWebsite,
      page_id: PageId,
      tests_id: TestId,
      tests_note_title:noteTitle,
      tests_note:noteContent,
      type: "test_history"
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        onTestsNotesClose();
      } else {
        errorMessage(data.message);
        errorModelOpen(true);
        showErrorHeader(true);
        onTestsNotesClose();
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  }

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    if (testNoteRef.current) {
      const { top, left, width } = testNoteRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        top: top + scrollOffset,
        left: left + width + 10, 
      });
    }
  };

  return (
    <>
      {showHelpModal &&
        ReactDOM.createPortal(
          <div style={{ zIndex: 9999 }}>
            <SelectTooltip
              isVisible={showHelpModal}
              setIsVisible={setShowHelpModal}
              id={helpTargetId}
              tooltipPosition={tooltipPosition}
            />
          </div>,
        document.body
      )}
      <Modal.Root opened={isTestsNotesModalOpen} onClose={onTestsNotesClose} size="80%">
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>TESTS NOTES <IconHelp ref={testNoteRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(151)}/></Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body h={480}>
            <form onSubmit={handleSaveTestNotes}>
              <Input.Wrapper label="Note Title:" mb={10}>
                <Input value={noteTitle} onChange={(e) => setNoteTitle(e.target.value)} />
              </Input.Wrapper>
              <RichTextEditor editor={testnotesEditor}>
                <TextEditorToolbar />
                <div style={{height: '300px', overflowY: 'auto', cursor: 'pointer'}} onClick={() => testnotesEditor?.chain().focus().run()}>
                  <RichTextEditor.Content />
                </div>
              </RichTextEditor>
              <Group position="right">
                <Button type="submit" size="sm" w={80} mt={10} mb={10}>
                  Save
                </Button>
              </Group>
            </form>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  );
}