import React from 'react';
import { useNavigate } from "react-router-dom";
import { ActionIcon } from '@mantine/core';
import { IconCheck, IconPlayerPause, IconPlayerPlay  } from '@tabler/icons-react';
import TestsApi from "../../api/TestsApi";
import { notifications } from '@mantine/notifications';

export function PauseVariations({ selectedMemberWebsite, pageId, taguchiTestId, varId, currentVariations, testsIdShow, pausedvariation, setMarksalestime, TestOptimizeReport, errorModelOpen, errorMessage, showErrorHeader, showMessageHeader, systemUsageLoader }:any) {
  const navigate = useNavigate();

  const handlePauseVariations = (varId, currentVariations) => { 
    systemUsageLoader(true);
    TestsApi.pauseresumevariation({
      system_website_id:selectedMemberWebsite,
      taguchi_test_id:taguchiTestId,
      page_id:pageId,
      variation_no: varId,
      tests_id: testsIdShow
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        systemUsageLoader(false);
        setMarksalestime(data.data.updated_time);
        TestOptimizeReport();
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
      } else {
        errorMessage(data.message);
        errorModelOpen(true);
        showErrorHeader(true);
        systemUsageLoader(false);
      }
    })
    .catch(error => {
      systemUsageLoader(false);
      console.error("API Error:", error);
    });
  }

  return (
    <>
      {pausedvariation === 0 ? (
        <ActionIcon
          variant="default"
          size="md"
          title="Pause Test"
          onClick={() => handlePauseVariations(varId, currentVariations)}
        >
          <IconPlayerPause size="1.1rem" />
        </ActionIcon>
      ) : (
        <ActionIcon
          variant="default"
          size="md"
          title="Resume Test"
          onClick={() => handlePauseVariations(varId, currentVariations)}
        >
          <IconPlayerPlay size="1.1rem" />
        </ActionIcon>
      )}
    </>
  );
}