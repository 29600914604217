import React, { useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { modals } from '@mantine/modals';
import { Text, Button } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import TestsApi from "../../api/TestsApi";

export function DiscardDraft({
  SelectedMemberWebsite,
  SelectedDraftThumbnail,
  SelectedDraftThumbnailChange,
  SelectedDraftPageId,
  SalesMarkTimeChange,
  ErrorMessageChange,
  CloseErrorModal,
  ErrorHeaderChange}:any) {

  const navigate = useNavigate();

  const discardDraftConfirm = (SelectedDraftThumbnail:any) => modals.openConfirmModal({
      title: 'Discard Draft',
      children: (
        <Text size="sm">
          Are you sure you want to discard the "Draft test"? <br/>
                  If you discard, all edits saved in the draft will be lost.
        </Text>
      ),
      labels: { confirm: 'Discard Draft', cancel: "Cancel" },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
        onConfirm: () => { 
        handleDiscarddraft(SelectedDraftThumbnail);
      }
  });

  const handleDiscarddraft = (SelectedDraftThumbnail:any) => {
    TestsApi.discarddraft({
      system_website_id: SelectedMemberWebsite,
      page_id:SelectedDraftPageId,
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        SelectedDraftThumbnailChange(null);
        SalesMarkTimeChange(data.data.updated_time);
      } else {
        ErrorMessageChange(data.message);
        CloseErrorModal(true);
        ErrorHeaderChange(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  }

  return (
    <>
      <Button
        type="button"
        style={{ marginLeft: 7, backgroundColor: '#ff8125' }}
        onClick={() => discardDraftConfirm(SelectedDraftThumbnail)}
      >
      Discard Draft test
      </Button>
    </>
  );
}