import { Group, Text, Select, Divider, Switch, Button } from '@mantine/core';
import { IconHelp, IconX, IconCheck } from '@tabler/icons-react';
import { useState, useEffect } from 'react';
import { VisiLoader } from "../../GeneralComponent/GeneralComponent";      
import { useNavigate } from "react-router-dom";
import { notifications } from '@mantine/notifications';
import SettingsApi from "../../../api/SettingsApi";

export function NotificationsSettings() {

  const navigate = useNavigate();

  const [frequency, setFrequency] = useState('daily');
  const [multipleUserLogin, setMultipleUserLogin] = useState(false);
  const [emailNotification, setEmailNotification] = useState(false);
  const [smsNotification, setSmsNotification] = useState(false);
  const [NotificationSettingsLoader, setNotificationSettingsLoader] = useState(false);

  useEffect(() => {
    setNotificationSettingsLoader(true);
    SettingsApi.getnotificationsettings()
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setNotificationSettingsLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1 && data.data){
        if(data.data.notification_setting){
          setFrequency(data.data.notification_setting);
        }
        if(data.data.multiple_user_login && data.data.multiple_user_login == '1'){
          setMultipleUserLogin(true);
        }else{
          setMultipleUserLogin(false);
        }
        if(data.data.sms_notification && data.data.sms_notification == '1'){
          setSmsNotification(true);
        }else{
          setSmsNotification(false);
        }
        if(data.data.email_notification && data.data.email_notification == '1'){
          setEmailNotification(true);
        }else{
          setEmailNotification(false);
        }
        setNotificationSettingsLoader(false);
      }
      setNotificationSettingsLoader(false);
    })
    .catch(error => {
      console.log(error);
      setNotificationSettingsLoader(false);
    });
  }, []);

  const submitFrequencySetting = () => {
    setNotificationSettingsLoader(true);
    let dataObject = {
      notification_action : "save_notification_setting",
      notification_setting : frequency
    }
    SettingsApi.savenotificationsettings(dataObject)
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setNotificationSettingsLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setNotificationSettingsLoader(false);
        notifications.show({
          color: 'teal',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
      }else{
        setNotificationSettingsLoader(false);
        notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: data.message,
        })
      }
    })
    .catch(error => {
      console.log(error);
      setNotificationSettingsLoader(false);
    });
  }

  const setMultipleUserLoginChange = (e) => {
    let multiple_user_login = e ? "1" : "0";
    setNotificationSettingsLoader(true);
    let dataObject = {
      notification_action : "save_multiple_user_login",
      multiple_user_login : multiple_user_login
    }
    SettingsApi.savenotificationsettings(dataObject)
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setNotificationSettingsLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setMultipleUserLogin(e);
        setNotificationSettingsLoader(false);
        notifications.show({
          color: 'teal',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
      }else{
        setNotificationSettingsLoader(false);
        notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: data.message,
        })
      }
    })
    .catch(error => {
      console.log(error);
      setNotificationSettingsLoader(false);
    });
  }

  const setEmailNotificationChange = (e) => {
    let email_notification = e ? "1" : "0";
    setNotificationSettingsLoader(true);
    let dataObject = {
      notification_action : "save_email_notification",
      email_notification : email_notification
    }
    SettingsApi.savenotificationsettings(dataObject)
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setNotificationSettingsLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setEmailNotification(e);
        setNotificationSettingsLoader(false);
        notifications.show({
          color: 'teal',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
      }else{
        setNotificationSettingsLoader(false);
        notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: data.message,
        })
      }
    })
    .catch(error => {
      console.log(error);
      setNotificationSettingsLoader(false);
    });
  }

  const setSmsNotificationChange = (e) => {
    let sms_notification = e ? "1" : "0";
    setNotificationSettingsLoader(true);
    let dataObject = {
      notification_action : "save_sms_notification",
      sms_notification : sms_notification
    }
    SettingsApi.savenotificationsettings(dataObject)
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setNotificationSettingsLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setSmsNotification(e);
        setNotificationSettingsLoader(false);
        notifications.show({
          color: 'teal',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
      }else{
        setNotificationSettingsLoader(false);
        notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: data.message,
        })
      }
    })
    .catch(error => {
      console.log(error);
      setNotificationSettingsLoader(false);
    });
  }

  return (
    <>
      <VisiLoader VisiLoaderVisible={NotificationSettingsLoader} />
      <Group position="apart" mb="xs">
        <Text weight={500}>NOTIFICATION SETTINGS <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
      </Group>
      
      <form>
        <Group mb={15}>
          <Text fw={500}>Select frequency for account notification emails:</Text>
          <Select
            data={[
              { value: 'daily', label: 'Daily' },
              { value: 'three_times', label: 'Three times a week' },
              { value: 'one_time', label: 'One time only' },
            ]}
            value={frequency}
            onChange={setFrequency}
            searchable
            nothingFound="No options"
            maxDropdownHeight={120}
            size="sm"
            dropdownPosition="bottom"
          />
          <Button type="button" size="sm" w={120} onClick={submitFrequencySetting}>
             Save Setting
          </Button>
        </Group>
      </form>
      <Divider my="md" />
      <div style={{ display: 'flex', marginBottom:10}}>
        <Text fw={500} mr={10} mt={-3}>
          Multiple-User Login:
        </Text>
        <Switch 
          size="md"
          onLabel="ON"
          offLabel="OFF"
          checked={multipleUserLogin}
          onChange={(event) => setMultipleUserLoginChange(event.currentTarget.checked)}
        />
      </div>
      <Text>Turning on this feature prevents two users editing the same test the at the same time, which prevents errors from occurring.</Text>
      <Divider my="md" />
      <div style={{ display: 'flex', marginBottom:10}}>
        <Text fw={500} mr={10} mt={-3}>
          Email Notification:
        </Text>
        <Switch 
          size="md"
          onLabel="ON"
          offLabel="OFF"
          checked={emailNotification}
          onChange={(event) => setEmailNotificationChange(event.currentTarget.checked)}
        />
      </div>
      <Text>Turn off to stop receiving Email messages.</Text>
      <div style={{ display: 'flex', marginBottom:10,marginTop:20}}>
        <Text fw={500} mr={10} mt={-3}>
          SMS Notification:
        </Text>
        <Switch 
          size="md"
          onLabel="ON"
          offLabel="OFF"
          checked={smsNotification}
          onChange={(event) => setSmsNotificationChange(event.currentTarget.checked)}
        />
      </div>
      <Text>Turn off to stop receiving SMS messages.</Text>
    </>
  );
}