import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { ActionIcon } from '@mantine/core';
import { IconCheck, IconPlayerPause, IconPlayerPlay } from '@tabler/icons-react';
import TestsApi from "../../api/TestsApi";
import { notifications } from '@mantine/notifications';

export function PauseResume({ selectedMemberWebsite, pageId, taguchiTestId, TestStatusShow, errorModelOpen, errorMessage, showErrorHeader, showMessageHeader, systemUsageLoader }:any) {
  const navigate = useNavigate();
  const [, setIsTestPaused] = useState(false);
  const [testPauseResume, setTestPauseResume] = useState('');
  const [testPauseResumeTitle, setTestPauseResumeTitle] = useState('Pause Test');
  const [testPauseResumeIcon, setTestPauseResumeIcon] = useState(<IconPlayerPause size="1.1rem" />);
  
  const handlePauseResumeTest = (value:any) => {
    if (!pageId) {
      navigate('/review-tests');
      return;
    }
    systemUsageLoader(true);
    TestsApi.pauseresumetest({
      system_website_id: selectedMemberWebsite,
      page_id:pageId,
      taguchi_id:taguchiTestId
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        systemUsageLoader(false);
        const newStatus = data.data.teststatus;
        setIsTestPaused(newStatus === 'pause');
        setTestPauseResume(newStatus);
        setTestPauseResumeTitle(newStatus === 'pause' ? 'Resume Test' : 'Pause Test');
        setTestPauseResumeIcon(
          newStatus === 'pause' ? (
            <IconPlayerPlay size="1.1rem" />
          ) : (
            <IconPlayerPause size="1.1rem" />
          )
        );
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
      } else {
        errorMessage(data.message);
        errorModelOpen(true);
        showErrorHeader(true);
        systemUsageLoader(false);
      }
    })
    .catch(error => {
      systemUsageLoader(false);
      console.error("API Error:", error);
    });
  };

  useEffect(() => {
    if(TestStatusShow){
      setIsTestPaused(TestStatusShow === 'pause');
      setTestPauseResume(TestStatusShow);
      setTestPauseResumeTitle(TestStatusShow === 'pause' ? 'Resume Test' : 'Pause Test');
      setTestPauseResumeIcon(
        TestStatusShow === 'pause' ? (
          <IconPlayerPlay size="1.1rem" />
        ) : (
          <IconPlayerPause size="1.1rem" />
        )
      );
    }
  }, [TestStatusShow]);

  return (
    <>
      <ActionIcon
        variant="default"
        size="lg"
        sx={(theme) => ({
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : '#fff',
        })}
        title={testPauseResumeTitle}
        ml={5}
        onClick={handlePauseResumeTest}
      >
        {testPauseResumeIcon}
      </ActionIcon>
    </>
  );
}