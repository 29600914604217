import { useState } from 'react';
import { useDocumentTitle } from '@mantine/hooks';
import { HeaderMenu } from "../../components/HeaderMenu/HeaderMenu";
import { Container, Card, Group, Text, ActionIcon, CopyButton, Button, Menu, Modal, Input, Textarea, Anchor, Space } from '@mantine/core';
import { IconSettings, IconHelp, IconX, IconCheck } from '@tabler/icons-react';
import './Funnel.css';
import { modals } from '@mantine/modals';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { SelectFunnelList } from "../../components/GeneralComponent/GeneralComponent";
import { SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import PermissionWrapper from '../../PermissionWrapper';
import { VisiLoader } from "../../components/GeneralComponent/GeneralComponent";
import FunnelApi from "../../api/FunnelApi";
import { AddUpdateFunnel } from "../../components/Funnel/AddUpdateFunnel";
import { FunnelData } from "../../components/Funnel/FunnelData";
import { FunnelLegend } from "../../components/Funnel/FunnelLegend";
import { AddButtonToFunnel } from "../../components/Funnel/AddButtonToFunnel";
import { BlockIP } from "../../components/Funnel/BlockIP";
import { AddFunnelPage } from "../../components/Funnel/AddFunnelPage";

export function Funnel() {

  const navigate = useNavigate();
  
  useDocumentTitle("Visiopt - Funnel");

  const [loading, setLoading] = useState(false);
  const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');
  const [SelectedFunnelValue, setSelectedFunnelValue] = useState(null);
  const [selectedFunnelTitle, setselectedFunnelTitle] = useState('');
  const [SystemUsageLoader, setSystemUsageLoader] = useState(false);
  const [PageTimeout, setPageTimeout] = useState('');
  const [FlickerControl, setFlickerControl] = useState('');
  const [editPageDetail, setEditPageDetail] = useState({name: '',url: ''});
  const [selectedPageId, setSelectedPageId] = useState(null);
  const [settingModalOpen, setSettingModalOpen] = useState(false);
  const [marksalestime, setMarksalestime] = useState(0);
  const [updatedtime, setUpdatedtime] = useState(0);
  const [selectedOption, setSelectedOption] = useState({});
  const [editPageModalOpen, setEditPageModalOpen] = useState(false);
  const [data, setData] = useState({ edit_funnel_name: '' });
  const [customtitle, setCustomTitle] = useState({ name: '' });
  const [customtitlepageid, setCustomTitlePageId] = useState({ pageId: ''});
  const [settingdata, setSettingData] = useState({ savepagetimeout: '',saveflickercontrol:'' });
  const [visiSmartCodeModalOpen, setVisiSmartCodeModalOpen] = useState(false);
  const [trackingScriptId, setTrackingScriptId] = useState('334.168');
  const [visiSmartCodePageURL, setvisiSmartCodePageURL] = useState('');
  const [visiSmartCodezipURL, setvisiSmartCodezipURL] = useState('');
  const [visiSmartCode, setVisiSmartCode] = useState("<!-- VisiSmart Code - DO NOT MODIFY--><script async type='text/javascript'>window.visiopt_code=window.visiopt_code||(function(){var visi_wid=334,visi_pid=168,visi_flicker_time=4000,visi_flicker_element='html',c=false,d=document,visi_fn={begin:function(){var a=d.getElementById('visi_flicker');if(!a){var a=d.createElement('style'),b=visi_flicker_element?visi_flicker_element+'{opacity:0!important;background:none!important;}':'',h=d.getElementsByTagName('head')[0];a.setAttribute('id','visi_flicker');a.setAttribute('type','text/css');if(a.styleSheet){a.styleSheet.cssText=b;}else{a.appendChild(d.createTextNode(b));}h.appendChild(a);}},complete:function(){c=true;var a=d.getElementById('visi_flicker');if(a){a.parentNode.removeChild(a);}},completed:function(){return c;},pack:function(a){var b=d.createElement('script');b.src=a;b.type='text/javascript';b.innerText;b.onerror=function(){visi_fn.complete();};d.getElementsByTagName('head')[0].appendChild(b);},init:function(){visi_fn.begin();setTimeout(function(){visi_fn.complete()},visi_flicker_time);this.pack('http://localhost/visiopt/client/js_test/test.'+visi_wid+'.'+visi_pid+'.js');return true;}};window.visiopt_code_status=visi_fn.init();return visi_fn;}());</script><!--End Of VisiSmart Code -->");
  const [errorMessage, setErrorMessage] = useState('');
  const [checkTracking, setCheckTracking] = useState('');
  const [errorModelOpen, seterrorCloseModal] = useState(false);
  const [checkTrackingModelOpen, setcheckTrackingCloseModal] = useState(false);
  const [showErrorHeader, setShowErrorHeader] = useState(true);
  const [showDeleteErrorHeader, setShowDeleteErrorHeader] = useState(false);
  const [showRemovefromfunnelErrorHeader, setShowRemovefromfunnelErrorHeader] = useState(false);
  const [editTitleModelOpen, setEditTitleModelOpen] = useState(false);
  const [selectedThumbnails, setSelectedThumbnails] = useState([]);
  const [handleEditFunnelOpenModalProp, sethandleEditFunnelOpenModalProp] = useState(false);
  const [handleAddbuttonFunnelOpenModalProp, sethandleAddbuttonFunnelOpenModalProp] = useState(false);
  const [handleBlockIPOpenModalProp, sethandleBlockIPOpenModalProp] = useState(false);
  const [handleSelectPageOpenModalProp, sethandleSelectPageOpenModalProp] = useState(false);

  const handleHeaderMenuComponentChange = (Value:any) => {
    setSelectedMemberWebsite(Value);
  };

  const handleSelectFunnelListComponentChange = (Value:any) => {
    setSelectedFunnelValue(Value);
    fetchData(Value);
  };

  /*Use All Funnel Form Name*/
  const form = useForm({
    initialValues: {
      funnel_name: '',
      edit_funnel_name: '',
      blockipaddress: '',
      savepagetimeout: '',
      saveflickercontrol:'',
      url: ''
    },
  });

  /*Rename Edit Funnel*/
  const handleEditFunnelOpenModal = (value:any) => {
    sethandleEditFunnelOpenModalProp(value);
  };

  useEffect(() => {
    if (selectedFunnelTitle) {
      setData(prevData => ({ ...prevData, edit_funnel_name: selectedFunnelTitle }));
    }
  }, [selectedFunnelTitle]);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  /*Delete Funnel*/
  const deleteFunnelConfirm = (selectedMemberWebsite:any) => {
    modals.openConfirmModal({
      title: 'Delete the Funnel',
      children: (
        <Text size="sm">
          This page has an active recording called Smart Relational Test. You cannot delete pages from a funnel while they're part of active insights
        </Text>
      ),
      labels: { confirm: 'Delete the Funnel', cancel: "No don't delete it" },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: async () => {
        try {
          const APIResponse = await FunnelApi.deletefunnel({
            system_website_id: selectedMemberWebsite
          });
          const APIResultJSON = await APIResponse.json();
          if (APIResultJSON.status === 1) {
            notifications.show({
              color: 'green',
              icon: <IconCheck size="1.1rem" />,
              title: 'Success',
              message: APIResultJSON.message,
            })
            setUpdatedtime(APIResultJSON.data.updated_time);
            handleSelectFunnelListComponentChange(APIResultJSON.data.selected_funnel_id);
          } else {
            notifications.show({
              color: 'red',
              icon: <IconX size="1.1rem" />,
              title: 'Error',
              message: APIResultJSON.message,
            })
          }
        } catch (error) {
          console.log('Error Something Went Wrong', error);
        }
      },
    });
  };

  /*Get Page in Add Button To The Funnel*/
  const handleAddbuttonFunnelOpenModal = () => {
    sethandleAddbuttonFunnelOpenModalProp(true);
  };

  /*Get Block IP Address*/
  const handleBlockIPOpenModal = () => {
    sethandleBlockIPOpenModalProp(true);
  };

  /*Select Funnel*/
  const fetchData = (funnelValue) => {
    if (funnelValue !== null) {
      FunnelApi.selectfunnel({
        system_website_id: selectedMemberWebsite,
        funnel_id: funnelValue
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
      })
      .then(data => {
        if (data.status === 1) {
          setselectedFunnelTitle(data.data.funnel_name);
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
  };

  /*Get Page Timeout*/
  const handleSettingOpenModal = (selectedPageId) => {
    setSelectedPageId(selectedPageId);
    setSettingModalOpen(true);
    FunnelApi.getpagetimeout({
      system_website_id: selectedMemberWebsite,
      page_id: selectedPageId
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
            navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data) {
        setPageTimeout(data.data.visiopt_timeout);
        setFlickerControl(data.data.flicker_control);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  const handleSettingCloseModal = () => {
    setSettingModalOpen(false);
  };

  useEffect(() => {
    if (PageTimeout || FlickerControl) {
      setSettingData(prevSettingData => ({ ...prevSettingData, savepagetimeout: PageTimeout, saveflickercontrol:FlickerControl  }));
    }
  }, [PageTimeout,FlickerControl]);

  const handleChangePageTime = (e) => {
    setSettingData({ ...settingdata, savepagetimeout: e.target.value });
  };

  const handleChangeFlickerControl = (e) => {
    setSettingData({ ...settingdata, saveflickercontrol: e.target.value });
  };

  /*Save Page Timeout*/
  const handleSavePageTimeoutSubmit = async (formData:any) => {
    setSelectedPageId(selectedPageId);
    const { savepagetimeout,saveflickercontrol } = formData;
    FunnelApi.savepagetimeout({
      page_id: selectedPageId,
      visiopt_timeout: settingdata.savepagetimeout,
      flicker_control: settingdata.saveflickercontrol
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        if(data.data.msg === ""){
          notifications.show({
            color: 'green',
            icon: <IconCheck size="1.1rem" />,
            title: 'Success',
            message: data.message,
          });
          setMarksalestime(data.updated_time);
          handleSettingCloseModal();
        } else {
          modals.open({
            title: 'Message',
            children: (
            <>
              <Text size="sm">
                {data.data.msg}
              </Text>
              <Group position="right" mt="md">
                <Button onClick={() => {
                  modals.closeAll();
                  setVisiSmartCodeModalOpen(true);
                  handleGetTreckingCode(selectedPageId);
                }}>OK</Button>
              </Group>
            </>
            ),
          });
          setMarksalestime(data.updated_time);
          handleSettingCloseModal();
        }
      } else {
        form.setErrors({ savepagetimeout: data.message });
        form.setErrors({ saveflickercontrol: data.message });
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  }

  /*Edit Page Detail*/
  const handleEditOpenModal = (selectedPageId) => {
    setSelectedPageId(selectedPageId);
    setEditPageModalOpen(true);
    FunnelApi.getpagedetail({
      page_id: selectedPageId
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data) {
        setEditPageDetail(data.data);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  const handleEditCloseModal = () => {
    setEditPageModalOpen(false);
  };

  useEffect(() => {
    if (editPageDetail) {
      setEditPageDetail(editPageDetail);
    }
  }, [editPageDetail]);

  const handleChangeEditPage = (e) => {
    const { name, value } = e.target;
    setEditPageDetail({ ...editPageDetail, [name]: value });
  };

  const handleEditPageSubmit = async (formData :any) =>  {
    try {
      const APIResponse = await FunnelApi.editpage({
        system_website_id: selectedMemberWebsite,
        page_id: selectedPageId,
        name : editPageDetail.name,
        url : editPageDetail.url
      });
      const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.status === 1) {
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: APIResultJSON.message,
        });
        setMarksalestime(APIResultJSON.data.updated_time);
        handleEditCloseModal();
      } else {
        form.setErrors({ name: APIResultJSON.message, url: APIResultJSON.message });
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  const handleRecordConversion = async (selectedPageId) => {
    setSelectedPageId(selectedPageId);
    try {
      const APIResponse = await FunnelApi.marksalesconversion({
        page_id: selectedPageId,
        system_website_id : selectedMemberWebsite
      });
       const APIResultJSON = await APIResponse.json();
        setMarksalestime(APIResultJSON.data.updated_time);
      if (APIResultJSON.status === 1) {
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const handleGetTreckingCode = async (selectedPageId) => {
    setSelectedPageId(selectedPageId);
    try {
      const APIResponse = await FunnelApi.gettrackingcode({
        page_id: selectedPageId,
        system_website_id : selectedMemberWebsite
      });
       const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.status === 1) {
        setVisiSmartCode(APIResultJSON.data.tracking_code);
        setTrackingScriptId(APIResultJSON.data.tracking_script_id);
        setvisiSmartCodePageURL(APIResultJSON.data.page_url);
        setvisiSmartCodezipURL(APIResultJSON.data.wp_plugin_url);
      }else{
        setErrorMessage(APIResultJSON.message);
        seterrorCloseModal(true);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const handleUnsetwinner = async (selectedPageId) =>{
    setSelectedPageId(selectedPageId);
    try {
      const APIResponse = await FunnelApi.unsetwinner({
        page_id: selectedPageId,
        system_website_id : selectedMemberWebsite
      });
       const APIResultJSON = await APIResponse.json();
        setMarksalestime(APIResultJSON.data.updatedtime);
      if (APIResultJSON.status === 1) {
      }else{
        setErrorMessage(APIResultJSON.message);
        seterrorCloseModal(true);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const handleRemoveFromfunnel = async (selectedPageId) =>{
    setSelectedPageId(selectedPageId);
    try {
      const APIResponse = await FunnelApi.removefromfunnel({
        page_id: selectedPageId,
        system_website_id : selectedMemberWebsite,
        alert_check:1
      });
      const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.message === '' && APIResultJSON.status === 0) {
        modals.openConfirmModal({
          title: 'REMOVE FROM FUNNEL',
          children: (
            <Text size="sm">
              Are you sure you want to remove this page from the Funnel?
            </Text>
          ),
          labels: { confirm: 'Remove from Funnel', cancel: "No don't Remove it" },
          confirmProps: { color: 'red' },
          onCancel: () => console.log('Cancel'),
          onConfirm: () => {
            handleRemoveFromfunnelAlertCheck(selectedPageId);
          },
        });
      }else{
        setErrorMessage(APIResultJSON.message);
        seterrorCloseModal(true);
        setShowDeleteErrorHeader(false);
        setShowRemovefromfunnelErrorHeader(true);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  
  const handleRemoveFromfunnelAlertCheck = async (selectedPageId) =>{
    setSelectedPageId(selectedPageId);
    try {
      const APIResponse = await FunnelApi.removefromfunnel({
        page_id: selectedPageId,
        system_website_id : selectedMemberWebsite,
        alert_check:0
      });
      const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.status === 1) {
        handleChooseActionReset('');
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: APIResultJSON.message,
        });
        setMarksalestime(APIResultJSON.data.updatedtime);
      }else{
        notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: APIResultJSON.message,
        });
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleRemoveButtonFromfunnel = async (selectedPageId) =>{
    setSelectedPageId(selectedPageId);
    try {
      const APIResponse = await FunnelApi.removebuttonfromfunnel({
        button_id: selectedPageId,
        system_website_id : selectedMemberWebsite
      });
       const APIResultJSON = await APIResponse.json();
        setMarksalestime(APIResultJSON.data.updatedtime);
      if (APIResultJSON.status === 1) {
      }else{
        setErrorMessage(APIResultJSON.message);
        seterrorCloseModal(true);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const handleCheckTrecking = async (selectedPageId) =>{
    setSelectedPageId(selectedPageId);
    setSystemUsageLoader(true);
    try {
      const APIResponse = await FunnelApi.checktracking({
        page_id: selectedPageId,
      });
       const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.status === 1) {
        setSystemUsageLoader(false);
        setMarksalestime(APIResultJSON.data.updatedtime);
        setCheckTracking(APIResultJSON.data.message);
        setcheckTrackingCloseModal(true);
      }else{
        setSystemUsageLoader(false);
        setCheckTracking(APIResultJSON.message);
        setcheckTrackingCloseModal(true);
      }
    } catch (error) {
      console.error("API Error:", error);
      setSystemUsageLoader(false);
    }
  };

  const handleDiscarddraft = async (selectedPageId) =>{
    setSelectedPageId(selectedPageId);
    try {
      const APIResponse = await FunnelApi.discarddraft({
        page_id: selectedPageId,
        system_website_id : selectedMemberWebsite
      });
       const APIResultJSON = await APIResponse.json();
        setMarksalestime(APIResultJSON.data.updatedtime);
      if (APIResultJSON.status === 1) {
      }else{
        setErrorMessage(APIResultJSON.message);
        seterrorCloseModal(true);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const handleDeletePage = async (selectedPageId) =>{
    setSelectedPageId(selectedPageId);
    try {
      const APIResponse = await FunnelApi.deletepage({
        system_website_id: selectedMemberWebsite,
        page_id: selectedPageId,
        alert_check:1
      });
      const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.message === '' && APIResultJSON.status === 0) {
        modals.openConfirmModal({
          title: 'DELETE PAGE',
          children: (
            <Text size="sm">
              Are you sure you want to remove this page?
            </Text>
          ),
          labels: { confirm: 'Delete the Page', cancel: "No don't Delete it" },
          confirmProps: { color: 'red' },
          onCancel: () => console.log('Cancel'),
          onConfirm: () => { 
            handleDeletePageAlertCheck(selectedPageId);
            handleChooseActionReset('');
            notifications.show({
              color: 'green',
              icon: <IconCheck size="1.1rem" />,
              title: 'Success',
              message: APIResultJSON.message,
            });
            setMarksalestime(APIResultJSON.data.updated_time);
          },
        });
      } else {
        setErrorMessage(APIResultJSON.message);
        seterrorCloseModal(true);
        setShowRemovefromfunnelErrorHeader(false);
        setShowDeleteErrorHeader(true);
        setShowErrorHeader(false); 
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleDeletePageAlertCheck = async (selectedPageId) =>{
    setSelectedPageId(selectedPageId);
    try {
      const APIResponse = await FunnelApi.deletepage({
        system_website_id: selectedMemberWebsite,
        page_id: selectedPageId,
        alert_check:0
      });
      const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.status === 1) {
        setMarksalestime(APIResultJSON.data.updated_time);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  /*Save Custom Title*/
  const handleEditTitleOpenModal = (item:any, pageId:any) => {
    setEditTitleModelOpen(true);
    setCustomTitle({ ...customtitle, name: item });
    setCustomTitlePageId({ ...customtitlepageid, pageId: pageId });
  };

  const handleChangeCustomTitle = (e) => {
    setCustomTitle({ ...customtitle, [e.target.name]: e.target.value });
  };

  const handleSaveCustomTitleSubmit = async (formData:any, pageId:any) =>{
    try {
      const APIResponse = await FunnelApi.savecustomtitle({
        system_website_id: selectedMemberWebsite,
        page_id: customtitlepageid.pageId,
        custom_title:customtitle.name
      });
      const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.status === 1) {
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: APIResultJSON.message,
        });
        setMarksalestime(APIResultJSON.data.updated_time);
        handleEditTitleCloseModal();
      } else {
        form.setErrors({ name: APIResultJSON.message });
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  const handleEditTitleCloseModal = () => {
    setEditTitleModelOpen(false);
    form.reset();
  };

  /*Select pages from funnel*/
  const handleSelectPageOpenModal = () => {
    sethandleSelectPageOpenModalProp(true);
  };

  const resetSelection = () => {
    setSelectedThumbnails([]);
  };

  const handlerrorCloseModal = () => {
    seterrorCloseModal(false);
  };

  const handleCheckTrackingCloseModal = () => {
    setcheckTrackingCloseModal(false);
  };

  const handleChooseActionReset = (event:any) => {
    const result = event.split('==');
    const page_id = result[1];
    setSelectedOption(prevOptions => ({
        ...prevOptions,
        [page_id]: ''
    }));
  };

  const handleChooseActionChange = (event:any) => {
    const result = event.split('==');
    const value = result[0];
    const page_id = result[1];
    setSelectedOption(prevOptions => ({
        ...prevOptions,
        [page_id]: value
    }));
    if(value === "mark_sales"){
      handleRecordConversion(page_id);
      handleChooseActionReset('');
    } else if(value === "edit_page"){
      handleEditOpenModal(page_id);
      handleChooseActionReset('');
    } else if(value === "checkTracking"){
      handleCheckTrecking(page_id);
      handleChooseActionReset('');
    } else if(value === "trackingCode"){
      handleGetTreckingCode(page_id);
      setVisiSmartCodeModalOpen(true);
      handleChooseActionReset('');
    } else if(value === "remove"){
      handleRemoveFromfunnel(page_id);
      handleChooseActionReset('');
    } else if(value === "delete"){
      handleDeletePage(page_id);
      handleChooseActionReset('');
    } else if(value === "setting"){
      handleSettingOpenModal(page_id);
      handleChooseActionReset('');
    } else if(value === "unsetWinner"){
      modals.openConfirmModal({
        title: 'Unset Winner',
        children: (
          <Text size="sm">
           Are you sure you want to Unset Winner this page?
          </Text>
        ),
        labels: { confirm: 'Unset Winner', cancel: 'Cancel' },
        onCancel: () => console.log('Cancel'),
        onConfirm: () => {
          handleUnsetwinner(page_id);
          handleChooseActionReset('');
        },
      });
    } else if(value === "discardDraft"){
      modals.openConfirmModal({
        title: 'Discard Draft',
        children: (
          <Text size="sm">
            Are you sure you want to Discard Draft this page?
          </Text>
        ),
        labels: { confirm: 'Discard Draft', cancel: 'Cancel' },
        onCancel: () => console.log('Cancel'),
        onConfirm: () => {
          handleDiscarddraft(page_id);
          handleChooseActionReset('');
        },
      });
    } else if(value === "removebutton"){
      modals.openConfirmModal({
        title: 'REMOVE FROM FUNNEL',
        children: (
          <Text size="sm">
            Are you sure you want to remove this button from the Funnel?
          </Text>
        ),
        labels: { confirm: 'Remove from Funnel', cancel: "No don't Remove it" },
        confirmProps: { color: 'red' },
        onCancel: () => console.log('Cancel'),
        onConfirm: () => {
          handleRemoveButtonFromfunnel(page_id);
          handleChooseActionReset('');
        },
      });
    }
  };
  const handleVisiSmartCodeCloseModal = () => {
    setVisiSmartCodeModalOpen(false);
  };
  const handleEditPageCloseModal = () => {
    setEditPageModalOpen(false);
    form.reset();
  };

  return (
    <>
    <VisiLoader VisiLoaderVisible={SystemUsageLoader}/>
    <HeaderMenu HeaderMenuValue={selectedMemberWebsite} HeaderMenuOnChange={handleHeaderMenuComponentChange}/>
    <AddButtonToFunnel handleAddbuttonFunnelOpenModalProp={handleAddbuttonFunnelOpenModalProp} selectedMemberWebsite={selectedMemberWebsite} setUpdatedtime={setUpdatedtime} setSelectedPageId={setSelectedPageId} selectedPageId={selectedPageId} sethandleAddbuttonFunnelOpenModalProp={sethandleAddbuttonFunnelOpenModalProp}/>
    <BlockIP handleBlockIPOpenModalProp={handleBlockIPOpenModalProp}  sethandleBlockIPOpenModalProp={sethandleBlockIPOpenModalProp} selectedMemberWebsite={selectedMemberWebsite}/>
    {/*Edit Page*/}
    {editPageModalOpen && (
    <Modal.Root opened={true} onClose={handleEditPageCloseModal} style={{ zIndex: 2 }}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>EDIT PAGE <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={form.onSubmit((formData) => { handleEditPageSubmit(formData); })}>
            <Input.Wrapper label="Name" error={form.errors.name}>
              <Input name="name" value={editPageDetail.name} onChange={handleChangeEditPage} error={form.errors.name && 'Page is Required field'}/>
            </Input.Wrapper>
            <Input.Wrapper label="URL" error={form.errors.url}>
              <Input name="url" value={editPageDetail.url} onChange={handleChangeEditPage} error={form.errors.url && 'URL is Required field'}/>
            </Input.Wrapper>
            <Group position="right">
              <Button type="submit" size="sm" w={80} mt={25}>
                Save
              </Button>
            </Group>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
    )}
    {/*VisiSmart Code*/}
    {visiSmartCodeModalOpen && (
    <Modal.Root opened={true} onClose={handleVisiSmartCodeCloseModal} style={{ zIndex: 2 }} size="xl">
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>VISISMART CODE  <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <Text size="sm" ml={0} fz={14}>Please Copy/Paste this code on the following page </Text>
          <div style={{ display: 'flex',marginBottom: '10px' }}>
            <Text fw={700} size="sm" ml={0} fz={14}>{visiSmartCodePageURL},</Text>
            <Text size="sm" ml={3} fz={14}>right before the</Text>
            <Text fw={700} size="sm" ml={3} fz={14}>&lt;/head&gt;</Text>
            <Text size="sm" ml={3} fz={14}>tag:</Text>
          </div>
          <Textarea
            value={visiSmartCode}
            size="xs"
            autosize
          />
          <CopyButton value={visiSmartCode} timeout={5000}>
            {({ copied, copy }) => (
              <Button color={copied ? 'teal' : 'blue'} onClick={copy} size="sm" w={190} mt={10} mb={10}>
                {copied ? 'Copied VisiSmart Code' : ' Copy VisiSmart Code'}
              </Button>
            )}
          </CopyButton>
          <Text size="sm" ml={0} fz={14}>Using a WordPress site? Install our WordPress plugin for easy integration.</Text>
          <div style={{ display: 'flex',marginBottom: '10px' }}>
            <Text size="sm" fz={14}>You can download the plug in from this 
              <Anchor href={visiSmartCodezipURL} target="_blank" ml={5} fw={700}>
              LINK.
              </Anchor>
            </Text>
          </div>
          <div style={{ display: 'flex',marginBottom: '10px' }}>
            <Text size="sm" fz={14} mt={10}>Tracking script ID : </Text>
            <Space w="xs" />
            <Text size="sm" fz={14} mt={10} fw={700}>{trackingScriptId}</Text>
            <Space w="md" />
            <CopyButton value={trackingScriptId} timeout={5000}>
              {({ copied, copy }) => (
                <Button color={copied ? 'teal' : 'blue'} onClick={copy} size="sm" w={100}>
                  {copied ? 'Copied ID' : ' Copy ID'}
                </Button>
              )}
            </CopyButton>
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
    )}
    {/*setting*/}
    <Modal.Root opened={settingModalOpen} onClose={handleSettingCloseModal}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>SETTING <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={form.onSubmit((formData) => { handleSavePageTimeoutSubmit(formData); })}>
            <Input.Wrapper label="Set Page Delay Timeout (in milliseconds)" error={form.errors.savepagetimeout}>
              <Input id="savepagetimeout" type="number" name="savepagetimeout" value={settingdata.savepagetimeout} hidden={PageTimeout ? true : false} onChange={handleChangePageTime}/>
            </Input.Wrapper>        
            <Input.Wrapper label="Flicker Control (in milliseconds)" error={form.errors.saveflickercontrol}>
              <Input id="saveflickercontrol" type="number" name="saveflickercontrol" value={settingdata.saveflickercontrol} hidden={FlickerControl ? true : false} onChange={handleChangeFlickerControl}/>
            </Input.Wrapper>
            <Space w="xs" />
            <Group position="right">
              <Button type="submit" size="sm" w={80} mt={25}>
                Save
              </Button>
            </Group>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
    {/*Edit Title*/}
    <Modal.Root opened={editTitleModelOpen} onClose={handleEditTitleCloseModal}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>EDIT TITLE <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={form.onSubmit((formData) => { handleSaveCustomTitleSubmit(formData, selectedPageId); })}>
            <div style={{ display: 'flex'}}>
              <Input.Wrapper label="Name" error={form.errors.name}>
                <Input name="name" value={customtitle.name} onChange={handleChangeCustomTitle} error={form.errors.name && 'Title is Required field'} w={315}/>
              </Input.Wrapper>
              <Space w="xs" />          
              <Button type="submit" size="sm" w={80} mt={25}>
                Save
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
    {/*Error Model*/}
    {errorModelOpen && (
    <Modal.Root opened={true} onClose={handlerrorCloseModal} style={{ zIndex: 2 }}>
      <Modal.Overlay />
      <Modal.Content>
        {showRemovefromfunnelErrorHeader ? (
          <Modal.Header>
            <Modal.Title>REMOVE FROM FUNNEL</Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
        ) : showDeleteErrorHeader ? (
          <Modal.Header>
            <Modal.Title>DELETE PAGE</Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
        ) : showErrorHeader && (
          <Modal.Header>
            <Modal.Title>Error</Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
        )}
        <Modal.Body>
          <form>
            <div style={{ display: 'flex'}}>
                <Text weight={500}>{errorMessage}</Text>
            </div>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
    )}
    {/*Check Tracking Model*/}
    {checkTrackingModelOpen && (
    <Modal.Root opened={true} onClose={handleCheckTrackingCloseModal} style={{ zIndex: 2 }}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>CHECK TRACKING</Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <form>
            <div style={{ display: 'flex'}}>
                <Text weight={500}>{checkTracking}</Text>
            </div>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
    )}
    <AddFunnelPage handleSelectPageOpenModalProp={handleSelectPageOpenModalProp} sethandleSelectPageOpenModalProp={sethandleSelectPageOpenModalProp} setSystemUsageLoader={setSystemUsageLoader} selectedMemberWebsite={selectedMemberWebsite} setErrorMessage={setErrorMessage} seterrorCloseModal={seterrorCloseModal} setShowErrorHeader={setShowErrorHeader} setLoading={setLoading} marksalestime={marksalestime} resetSelection={resetSelection} setMarksalestime={setMarksalestime} loading={loading} selectedThumbnails={selectedThumbnails} setSelectedThumbnails={setSelectedThumbnails} setVisiSmartCodeModalOpen={setVisiSmartCodeModalOpen} handleRemoveFromfunnel={handleRemoveFromfunnel} handleCheckTrecking={handleCheckTrecking} handleGetTreckingCode={handleGetTreckingCode} handleEditOpenModal={handleEditOpenModal} handleDeletePage={handleDeletePage} setSelectedPageId={setSelectedPageId}/>
    <Container size="auto">
      <Card shadow="sm" padding="sm" radius="md" withBorder>
        <Group position="apart" mb="xs">
          <Text weight={500}>FUNNELS  <SelectTooltip/></Text>
        </Group>
        <Group spacing="xs" mb={10} mt={20}>
          <Text size="sm" fz={12} fw={500} maw={150}>Select Funnel:</Text>
          <SelectFunnelList updated_time={updatedtime} Value={SelectedFunnelValue} OnChange={handleSelectFunnelListComponentChange} MemberWebsiteId={selectedMemberWebsite} Width={180}/>
          <PermissionWrapper permission="operational_access">
            <Menu width={210} position="bottom-start">
              <Menu.Target>
                <ActionIcon variant="default" size="lg"><IconSettings size="1rem"/></ActionIcon>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item px={10} py={6} onClick={() => handleEditFunnelOpenModal(1)}>Rename the Funnel</Menu.Item>
                <Menu.Item px={10} py={6} onClick={handleAddbuttonFunnelOpenModal}>Add Button to the Funnel</Menu.Item>
                <Menu.Item px={10} py={6} component="a" href="">Assign New URLs</Menu.Item>
                <Menu.Item px={10} py={6} onClick={handleBlockIPOpenModal}>Block IP Address</Menu.Item>
                <Menu.Item px={10} py={6} onClick={() => deleteFunnelConfirm(selectedMemberWebsite)}>Delete the Funnel</Menu.Item>
              </Menu.Dropdown>
            </Menu>
            <AddUpdateFunnel handleEditFunnelOpenModalProp={handleEditFunnelOpenModalProp} selectedMemberWebsite={selectedMemberWebsite} data={data} SelectedFunnelValue={SelectedFunnelValue} setUpdatedtime={setUpdatedtime} handleSelectFunnelListComponentChange={handleSelectFunnelListComponentChange} setselectedFunnelTitle={setselectedFunnelTitle} handleChange={handleChange} sethandleEditFunnelOpenModalProp={sethandleEditFunnelOpenModalProp}/>
          </PermissionWrapper>
        </Group>
        <FunnelData selectedFunnelTitle={selectedFunnelTitle} selectedMemberWebsite={selectedMemberWebsite} setLoading={setLoading} setselectedFunnelTitle={setselectedFunnelTitle} marksalestime={marksalestime} updatedtime={updatedtime} setSystemUsageLoader={setSystemUsageLoader} loading={loading} handleEditTitleOpenModal={handleEditTitleOpenModal} selectedOption={selectedOption} handleChooseActionChange={handleChooseActionChange} handleSelectPageOpenModal={handleSelectPageOpenModal}/>
        <FunnelLegend />
      </Card>
    </Container>
    </>
  );
}