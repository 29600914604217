import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Modal, Select, Button, Input, Group, Slider, Grid, Text, Divider } from '@mantine/core';
import { IconHelp, IconCheck, IconCircleMinus, IconPlus } from '@tabler/icons-react';
import TestsApi from "../../api/TestsApi";
import { notifications } from '@mantine/notifications';
import { modals } from '@mantine/modals';

export function BehavioralFilter({ selectedMemberWebsite, pageId, isBehavioralFilterModalOpen, onBehavioralFilterClose, setMarksalestime, TestOptimizeReport, getnetworkhierarchicaldata, getbehavioraldimensionfilterdata, errorModelOpen, errorMessage, showErrorHeader, showMessageHeader }:any) {
  const navigate = useNavigate();
  const [behavioralFilterFirstDropdown, setBehavioralFilterFirstDropdown] = useState([]);
  const [behavioralFilterSecondDropdown, setBehavioralFilterSecondDropdown] = useState({});
  const [behavioralFilterThirdDropdown, setBehavioralFilterThirdDropdown] = useState({});
  const [firstDropdown, setFirstDropdown] = useState('');
  const [, setSecondDropdown] = useState('');
  const [, setThirdDropdown] = useState('');
  const [showFirstDropdown, setShowFirstDropdown] = useState(false);
  const [showSecondDropdown, setShowSecondDropdown] = useState({});
  const [showThirdDropdown, setShowThirdDropdown] = useState({});
  const [dropdownSets, setDropdownSets] = useState([]);

  useEffect(() => {
    if (isBehavioralFilterModalOpen) {
      TestsApi.getbehavioraldimensions({
        page_id: pageId
      })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate('/logout');
          }
        }
      })
      .then((data) => {
        if (data.status === 1) {

          const dropdownValues1 = data.data.behavioral_filter.map((filter, index) => ({
            id: index,
            firstDropdown: filter.device_filter || '',
            secondDropdown: filter.traffic_source_key || '',
            thirdDropdown: filter.ad_network_type || '',
          }));
          setDropdownSets(dropdownValues1);

          if(dropdownValues1){
            TestsApi.behavioraldimensionsaddfilterfirstdropdown()
            .then(response => {
              if (response.ok) {
                return response.json();
              } else {
                if (response.status === 401 || response.status === 400) {
                  navigate("/logout");
                }
              }
            })
            .then(data => {
              if (data.status === 1) {
                setBehavioralFilterFirstDropdown(data.data.first_dropdown);
                setShowFirstDropdown(true);
              }
            })
            .catch(error => {
              console.error("API Error:", error);
            });
          }

          const dropdownValues = data.data.behavioral_filter.forEach((filter, index) => {
            if(filter.device_type === "Tracking tags" || filter.device_type === "Network Paramters" || filter.device_type === "Custom tags"){
              TestsApi.behavioraldimensionsaddfilterseconddropdown({
                device_filter:filter.device_filter,
                page_id:pageId,
                ad_network_type:filter.ad_network_type,
              })
              .then(response => {
                if (response.ok) {
                  return response.json();
                } else {
                  if (response.status === 401 || response.status === 400) {
                    navigate("/logout");
                  }
                }
              })
              .then(data => {
                if (data.status === 1) {
                  setBehavioralFilterSecondDropdown(prev => ({ ...prev, [index]: data.data.second_dropdown }));
                  setShowSecondDropdown(prev => ({ ...prev, [index]: true }));
                }
              })
              .catch(error => {
                console.error("API Error:", error);
              });

              if(filter.device_filter === 'VDMzenM4ZGxuUDVSYTJpTDRnUUk3UT09'){
                TestsApi.behavioraldimensionsaddfilterthirddropdown({
                  device_filter:filter.device_filter
                })
                .then(response => {
                  if (response.ok) {
                    return response.json();
                  } else {
                    if (response.status === 401 || response.status === 400) {
                      navigate("/logout");
                    }
                  }
                })
                .then(data => {
                  if (data.status === 1) {
                    setBehavioralFilterThirdDropdown(prev => ({ ...prev, [index]: data.data.third_dropdown }));
                    setThirdDropdown(filter.traffic_source_key);
                    setShowThirdDropdown(prev => ({ ...prev, [index]: true }));
                    setShowSecondDropdown(prev => ({ ...prev, [index]: true }));
                  }
                })
                .catch(error => {
                  console.error("API Error:", error);
                });
              }
            }
          });

        }
      })
      .catch((error) => {
        console.error('API Error:', error);
      });
    }
  }, [isBehavioralFilterModalOpen, pageId]);
  
  const handleAddFilter = () => {
    const newId = dropdownSets.length > 0 ? dropdownSets[dropdownSets.length - 1].id + 1 : 0;
    const newSet = { id: newId, firstDropdown: '', secondDropdown: '', thirdDropdown: '' };
    setDropdownSets([...dropdownSets, newSet]);

    setShowSecondDropdown(prev => ({ ...prev, [newId]: false }));
    setShowThirdDropdown(prev => ({ ...prev, [newId]: false }));

    TestsApi.behavioraldimensionsaddfilterfirstdropdown()
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        const firstDropdownData = data.data.first_dropdown;
        setBehavioralFilterFirstDropdown(firstDropdownData);
        setDropdownSets(prevSets => prevSets.map(set => {
          if (set.id === newId) {
            return { ...set, firstDropdown: firstDropdownData.length > 0 ? firstDropdownData[0].key : null };
          }
          return set;
        }));
        setShowFirstDropdown(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  const handleFirstDropdown = (value:any,setId) => {
    setFirstDropdown(value);
    setDropdownSets((prevSets) =>
      prevSets.map((set) =>
        set.id === setId ? { ...set, firstDropdown: value } : set
      )
    );

    if(value === 'VDMzenM4ZGxuUDVSYTJpTDRnUUk3UT09'){
      TestsApi.behavioraldimensionsaddfilterthirddropdown({
        device_filter:value
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if (data.status === 1) {
          const thirdDropdownData = data.data.third_dropdown;
          setBehavioralFilterThirdDropdown(prev => ({ ...prev, [setId]: thirdDropdownData }));
          setDropdownSets(prevSets => prevSets.map(set => {
            if (set.id === setId) {
              return { ...set, thirdDropdown: thirdDropdownData.length > 0 ? thirdDropdownData[0].key : null };
            }
            return set;
          }));
          setShowThirdDropdown(prev => ({ ...prev, [setId]: true }));
          setShowSecondDropdown(prev => ({ ...prev, [setId]: true }));
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
    }else{
      setShowThirdDropdown(prev => ({ ...prev, [setId]: false }));
      setShowSecondDropdown(prev => ({ ...prev, [setId]: false }));
    }
    const thirdDropdown = 'facebook';
    TestsApi.behavioraldimensionsaddfilterseconddropdown({
      device_filter:value,
      page_id:pageId,
      ad_network_type:thirdDropdown,
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        const secondDropdownData = data.data.second_dropdown;
        setBehavioralFilterSecondDropdown(prev => ({ ...prev, [setId]: secondDropdownData }));

        setDropdownSets(prevSets => prevSets.map(set => {
          if (set.id === setId) {
            return { ...set, secondDropdown: secondDropdownData.length > 0 ? secondDropdownData[0].key : null };
          }
          return set;
        }));
        setShowSecondDropdown(prev => ({ ...prev, [setId]: true }));
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  const handleSecondDropdown = (value:any,setId) => {
    setSecondDropdown(value);
    setDropdownSets((prevSets) =>
      prevSets.map((set) =>
        set.id === setId ? { ...set, secondDropdown: value } : set
      )
    );
  }

  const handleThirdDropdown = (value:any,setId) => {
    setThirdDropdown(value);
    setDropdownSets((prevSets) =>
      prevSets.map((set) =>
        set.id === setId ? { ...set, thirdDropdown: value } : set
      )
    );
    TestsApi.behavioraldimensionsaddfilterseconddropdown({
      device_filter: "VDMzenM4ZGxuUDVSYTJpTDRnUUk3UT09", //Only firstDropdown network parameter value
      page_id: pageId,
      ad_network_type:value,
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate('/logout');
        }
      }
    })
    .then((data) => {
      if (data.status === 1) {
        const secondDropdownData = data.data.second_dropdown;
        setBehavioralFilterSecondDropdown(prev => ({ ...prev, [setId]: secondDropdownData }));

        setDropdownSets(prevSets => prevSets.map(set => {
          if (set.id === setId) {
            return { ...set, secondDropdown: secondDropdownData.length > 0 ? secondDropdownData[0].key : null };
          }
          return set;
        }));
      }
    })
    .catch((error) => {
      console.error('API Error:', error);
    });
  }

  const handleRemoveFilter = (setId) => {
    const updatedDropdownSets = dropdownSets.filter(set => set.id !== setId);
    setDropdownSets(updatedDropdownSets);
  };

  const handleSaveBehavioralFilter = (e) => {
    e.preventDefault();
    const deviceFilters = [];
    const adNetworkTypes = [];
    const trafficSourceKeys = [];

    dropdownSets.forEach((set) => {
      deviceFilters.push(set.firstDropdown);
      adNetworkTypes.push(set.thirdDropdown);
      trafficSourceKeys.push(set.secondDropdown);
    });

    TestsApi.savebehavioraldimensions({
      page_id: pageId,
      device_filter: deviceFilters,
      ad_network_type: adNetworkTypes,
      traffic_source_key: trafficSourceKeys
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate('/logout');
        }
      }
    })
    .then((data) => {
      if (data.status === 1) {
        TestOptimizeReport();
        getnetworkhierarchicaldata();
        getbehavioraldimensionfilterdata();
        setMarksalestime(data.data.updated_time);
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        handleBehavioralFilterCloseModal();
      }
    })
    .catch((error) => {
      console.error('API Error:', error);
    });
  };

  const handleResetBehavioralFilter = () => {
    modals.openConfirmModal({
      title: 'Reset Filter',
      children: (
        <Text size="sm">
         Filter reset successfully!
        </Text>
      ),
      labels: { confirm: 'Reset Filter', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => {
        ResetBehavioralFilter();
      },
    });
  };

  const ResetBehavioralFilter = () => {
    TestsApi.resetfilterbehavioraldimensions({
      page_id: pageId
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate('/logout');
        }
      }
    })
    .then((data) => {
      if (data.status === 1) {
        setMarksalestime(data.data.updated_time);
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        handleBehavioralFilterCloseModal();
      }
    })
    .catch((error) => {
      console.error('API Error:', error);
    });
  };

  const handleBehavioralFilterCloseModal = () => {
    onBehavioralFilterClose();
    setDropdownSets([]);
    setShowFirstDropdown(false);
    setShowSecondDropdown(false);
    setShowThirdDropdown(false); 
  };

  return (
    <>
      <Modal.Root opened={isBehavioralFilterModalOpen} onClose={onBehavioralFilterClose} size="80%">
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>BEHAVIORAL DIMENSIONS <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSaveBehavioralFilter}>
              <Group>
              <div>
                {dropdownSets.map((set) => (
                  <div key={set.id} style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                    {showFirstDropdown && (
                    <Select
                      data={behavioralFilterFirstDropdown.map((item) => ({ value: item.key, label: item.value }))}
                      value={set.firstDropdown}
                      onChange={(value) => handleFirstDropdown(value, set.id)}
                      searchable
                      placeholder="Select value"
                      nothingFound="No options"
                      maxDropdownHeight={120}
                      size="sm"
                      dropdownPosition="bottom"
                      mb={10}
                      style={{marginRight:15}}
                    />
                    )}
                    {showThirdDropdown[set.id] && (
                    <Select
                      data={(behavioralFilterThirdDropdown[set.id] || []).map((item) => ({ value: item.key, label: item.value }))}
                      value={set.thirdDropdown}
                      onChange={(value) => handleThirdDropdown(value, set.id)}
                      searchable
                      nothingFound="No options"
                      maxDropdownHeight={120}
                      size="sm"
                      dropdownPosition="bottom"
                      mb={10}
                      style={{marginRight:15}}
                    />
                    )}
                    {showSecondDropdown[set.id] && (
                    <Select
                      data={(behavioralFilterSecondDropdown[set.id] || []).map((item) => ({ value: item.key, label: item.value }))}
                      value={set.secondDropdown}
                      onChange={(value) => handleSecondDropdown(value, set.id)}
                      searchable
                      nothingFound="No options"
                      maxDropdownHeight={120}
                      size="sm"
                      dropdownPosition="bottom"
                      mb={10}
                      style={{ marginRight: 15 }}
                    />
                    )}
                    {showFirstDropdown && (
                    <IconCircleMinus size="1.1rem" color="red" style={{marginBottom:8}} onClick={() => handleRemoveFilter(set.id)}/>
                    )}
                  </div>
                ))}
              </div>
              </Group>
              <Button onClick={handleAddFilter}>
                <IconPlus size="1.1rem" color="white"/><Text ml={5}>Add Filter</Text>
              </Button>
              <Divider my="sm" />  
              <Group position="right">
                <Button type="button" size="sm" color="orange" onClick={handleResetBehavioralFilter}>
                  Reset Filter
                </Button>
                <Button type="submit" size="sm" w={80}>
                  Save
                </Button>
              </Group>
            </form>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  );
}