import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Modal, Button, Input, Group } from '@mantine/core';
import { IconHelp, IconCheck } from '@tabler/icons-react';
import TestsApi from "../../api/TestsApi";
import { notifications } from '@mantine/notifications';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import { TextEditorToolbar } from '../GeneralComponent/TextEditorToolbar';
import { RichTextEditor, Link } from '@mantine/tiptap';
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';

export function TestNotes({ selectedMemberWebsite, pageId, taguchiTestId, isTestNotesModalOpen, onTestNotesClose, setMarksalestime, TestOptimizeReport, errorModelOpen, errorMessage, showErrorHeader, showMessageHeader }:any) {
  const navigate = useNavigate();
  const [noteTitle, setNoteTitle] = useState('');
  const [noteContent, setNoteContent] = useState('');

  useEffect(() => {
    if (isTestNotesModalOpen) {
      if (!pageId) {
        navigate('/review-tests');
        return;
      } else {
        TestsApi.gettestnote({
          page_id:pageId,
          taghchi_test_id:taguchiTestId
        })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            if (response.status === 401 || response.status === 400) {
              navigate("/logout");
            }
          }
        })
        .then(data => {
          if (data.status === 1) {
            setNoteTitle(data.data.tests_note_title);
            setNoteContent(data.data.tests_note);
          } else {
            errorMessage(data.message);
            errorModelOpen(true);
            showErrorHeader(true);
            onTestNotesClose();
          }
        })
        .catch(error => {
          console.error("API Error:", error);
        });
      }
    }
  }, [isTestNotesModalOpen, pageId]);

  const testnotesEditor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      Color,
      TextStyle,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
    ],
    content: '',
    onUpdate: ({ editor }) => {
      setNoteContent(editor.getHTML());
    },
  });

  const saveSelection = (editor) => {
    const { from, to } = editor.state.selection;
    return { from, to };
  };

  const restoreSelection = (editor, selection) => {
    editor.commands.setTextSelection(selection);
  };

  useEffect(() => {
    if (testnotesEditor && noteContent !== '') {
      const selection = saveSelection(testnotesEditor);
      testnotesEditor.commands.setContent(noteContent, true);
      restoreSelection(testnotesEditor, selection);
    }
  }, [noteContent, testnotesEditor]);

  const handleSaveTestNotes = (e) => {
  e.preventDefault();
  TestsApi.savetestsnote({
      system_website_id: selectedMemberWebsite,
      page_id:pageId,
      tests_note_title:noteTitle,
      tests_note:noteContent
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        onTestNotesClose();
      } else {
        errorMessage(data.message);
        errorModelOpen(true);
        showErrorHeader(true);
        onTestNotesClose();
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  }

  return (
    <>
      <Modal.Root opened={isTestNotesModalOpen} onClose={onTestNotesClose} size="80%">
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>TEST NOTES <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body h={480}>
            <form onSubmit={handleSaveTestNotes}>
              <Input.Wrapper label="Note Title:" mb={10}>
                <Input value={noteTitle} onChange={(e) => setNoteTitle(e.target.value)} />
              </Input.Wrapper>
              <RichTextEditor editor={testnotesEditor}>
                <TextEditorToolbar />
                <div style={{height: '300px', overflowY: 'auto'}}>
                  <RichTextEditor.Content />
                </div>
              </RichTextEditor>
              <Group position="right">
                <Button type="submit" size="sm" w={80} mt={10} mb={10}>
                  Save
                </Button>
              </Group>
            </form>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  );
}