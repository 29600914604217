  import { Modal, Group, Text, Button, Container, Card, Select, MultiSelect, Input, Divider, Space, Table, Grid, Box, Checkbox, ActionIcon, Title, Switch, TextInput } from '@mantine/core';
import { IconHelp, IconCheck, IconCaretUpFilled, IconCaretDownFilled, IconCaretRightFilled, IconClockHour4 } from '@tabler/icons-react';
import { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Chart } from "react-google-charts";
import { useDocumentTitle } from '@mantine/hooks';
import { HeaderMenu } from "../../components/HeaderMenu/HeaderMenu";
import CampaignReport from "../../api/CampaignReport";
import { CampaignSetting } from "../../components/CreateReport/CampaignSetting";
import { BehavioralDimensions } from '../../components/CreateReport/BehavioralDimensions';
import { ResetStats } from "../../components/CreateReport/ResetStats";
import { CustomerJourney } from "../../components/CreateReport/CustomerJourney";
import { SelectMetrics } from "../../components/CreateReport/SelectMetrics";
import { VisiLoader, SelectTimePicker, ErrorModal, SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";
import { notifications } from '@mantine/notifications';

export function CreateReport() {
  useDocumentTitle("Visiopt - Create Report");
  const createReportRef = useRef(null);
  const campaignRef = useRef(null);
  const comparisonCampaignRef = useRef(null);
  const attributionRef = useRef(null);
  const saveReportRef = useRef(null);
  const showAllAdIconRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  let reportId = location.state?.report_id;  /*GET REPORT ID FROM CAMPAIGN REPORT*/
  const [updateReportId, setUpdateReportId] = useState('');
  const [isVisiLoaderVisible, setIsVisiLoaderVisible] = useState(false);
  const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');
  const [selectedVisioptCampaign, setSelectedVisioptCampaign] = useState('');
  const [comparisonVisiCampaign, setComparisonVisiCampaign] = useState([]);
  const [trafficSourceData, setTrafficSourceData] = useState<{ key: string; show: boolean }[]>([]);
  const [showBacktoCampButton, setShowBacktoCampButton] = useState<{ rowIndex: string; show: boolean; trafficName: string }[]>([]);
  const [showBacktoAdsetButton, setShowBacktoAdsetButton] = useState<{ rowIndex: string; show: boolean; trafficName: string }[]>([]);
  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const [campaignColumns, setCampaignColumns] = useState({});
  const [sortConfig, setSortConfig] = useState([]);
  const [trafficSortConfig, setTrafficSortConfig] = useState([]);

  const [saveReportModalOpen, setSaveReportModalOpen] = useState(false);
  const [selectedGraphType, setSelectedGraphType] = useState('1');
  const [SelectedGraphData, setSelectedGraphData] = useState([]);
  const [reportName, setReportName] = useState('');
  const [visioptCampaignOptions, setVisioptCampaignOptions] = useState([]);
  const [compVisioptCampaignOptions, setCompVisioptCampaignOptions] = useState([]);
  const [attributionList, setAttributionList] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [conversionGoalOptions, setConversionGoalOptions] = useState([]);
  const [selectedConversionGoal, setSelectedConversionGoal] = useState([{ campaignid: "", selectedGoal: "" }]);
  const [selectedCustomerGoal, setSelectedCustomerGoal] = useState([{ campaignid: "", selectedGoal: "" }]);
  const [selectedSourceData, setSelectedSourceData] = useState([{ campaignid: "", sourceValues: [] }]);
  const [selectedTrackingTagData, setSelectedTrackingTagData] = useState([{ campaignid: "", trackingTagKey: "", trackingTagValues: [], arrayId: "" }]);
  const [selectedCustomTagData, setSelectedCustomTagData] = useState([{ campaignid: "", customTagKey: "", customTagValues: [], arrayId:"" }]);
  const [selectedOrganicCustomTag, setSelectedOrganicCustomTag] = useState([{ campaignid: "", selectedValues: [] }]);
  const [selectedOrganicTrackingTag, setSelectedOrganicTrackingTag] = useState([{ campaignid: "", selectedValues: [] }]);
  const [selectedFacebookDropdownData, setSelectedFacebookDropdownData] = useState([{ campaignid: "", selectedValue: "" }]);
  const [allCampaignTableData, setAllCampaignTableData] = useState([]);
  const [allTrafficSourceData, setAllTrafficSourceData] = useState([]);
  const [originalAllCampaignTableData, setOriginalAllCampaignTableData] = useState([]);
  const [showGoalTable, setShowGoalTable] = useState<{ campaignid: string; show: boolean }[]>([]);
  const [showTestVariation, setShowTestVariation] = useState<{ campaignid: string; show: boolean }[]>([]);
  const [showAdsetTable, setShowAdsetTable] = useState<{ show: boolean; trafficName: string }[]>([]);
  const [showAdsTable, setShowAdsTable] = useState<{ show: boolean; trafficName: string }[]>([]);
  const [showTrafficSourceTable, setShowTrafficSourceTable] = useState<{ show: boolean; trafficName: string }[]>([]);
  const [showTrafficTables, setShowTrafficTables] = useState(false);

  const [sourceDataTable, setSourceDataTable] = useState([]);
  const [customTagDataTable, setCustomTagDataTable] = useState([]);
  const [trackingTagDataTable, setTrackingTagDataTable] = useState([]);
  const [organicTrafficDataTable, setOrganicTrafficDataTable] = useState([]);
  const [filterByValue, setFilterByValue] = useState('');
  const [filterFromValue, setFilterFromValue] = useState('');
  const [filterToValue, setFilterToValue] = useState('');

  const [errorModelOpen, seterrorCloseModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorHeader, setShowErrorHeader] = useState(false);
  const [showMessageHeader, setShowMessageHeader] = useState(false);

  const [selectedTimePicker, setSelectedTimePicker] = useState<number>();
  const [selectedTimePickerToDate, setSelectedTimePickerToDate] = useState('');
  const [selectedTimePickerFromDate, setSelectedTimePickerFromDate] = useState('');
  const [reportLoader, setReportLoader] = useState(false);
  const [reportchartLoader, setReportchartLoader] = useState(false);
  const [showColumnColor, setShowColumnColor] = useState<{ campaignid: string; tableName: string; columnName: string; show: boolean }[]>([]);
  const [showTrafficColumnsColor, setShowTrafficColumnsColor] = useState<{ tableName: string; columnName: string; show: boolean }[]>([]);
  const generalMetricsColumns = [
    { key: 'visiopt_campaign', value: 'Visiopt Campaign', show: true },
    { key: 'uniques', value: 'Uniques', show: true },
    { key: 'visits', value: 'Visits', show: true },
    { key: 'ad_spent', value: 'Ad Spent', show: true },
    { key: 'revenue', value: 'Revenue', show: true },
    { key: 'sub_revenue', value: 'Visiopt Subscription Revenue', show: false },
    { key: 'roas', value: 'ROAS', show: true },
    { key: 'roi', value: 'ROI', show: true },
    { key: 'aov', value: 'AOV', show: true },
    { key: 'epc', value: 'EPC', show: true },
    { key: 'unique_epc', value: 'Unique EPC', show: true },
    { key: 'profit', value: 'Profit', show: true },
    { key: 'avg_revenue_per_unique', value: 'Avg. Revenue per Unique', show: true },
  ];

  const goalMetricsColumns = [
      { key: 'goalName', value: 'GoalName', show: true },
      { key: 'gcr', value: 'GCR', show: true },
      { key: 'ucr', value: 'UCR', show: true },
      { key: 'cpg', value: 'CPG', show: true },
      { key: 'epg', value: 'EPG', show: true },
      { key: 'ad_spent', value: 'Ad Spent', show: false },
      { key: 'visiopt_revenue', value: 'Visiopt Revenue', show: false },
      { key: 'visiopt_sub_revenue', value: 'Visiopt Subscription Revenue', show: false },
      { key: 'network_reported_revenue', value: 'Network Reported Revenue', show: false },
      { key: 'revenue_diffrence', value: 'Revenue Difference', show: false },
      { key: 'visiopt_conversions', value: 'Visiopt Conversion', show: false },
      { key: 'network_conversions', value: 'Network Conversion', show: false },
      { key: 'conversions_diffrence', value: 'Conversion Difference', show: false },
      { key: 'roas', value: 'ROAS', show: false },
      { key: 'roi', value: 'ROI', show: false },
      { key: 'aov', value: 'AOV', show: false },
      { key: 'epc', value: 'EPC', show: false },
      { key: 'unique_epc', value: 'Unique EPC', show: false },
      { key: 'profit', value: 'Profit', show: false },
      { key: 'avg_revenue_per_unique', value: 'Avg. Revenue per Unique', show: false },
  ];

  const trafficSourceMetricsColumns = [
      { key: 'traffic_source', value: 'Traffic Source', show: true },
      { key: 'campaign_name', value: 'Campaign Name', show: true },
      { key: 'uniques', value: 'Uniques', show: true },
      { key: 'visits', value: 'Visits', show: true },
      { key: 'ad_spend', value: 'Ad Spend', show: true },
      { key: 'visiopt_revenue', value: 'Visiopt Revenue', show: true },
      { key: 'visiopt_subscription_revenue', value: 'Visiopt Subscription Revenue', show: false },
      { key: 'ucr', value: 'UCR', show: true },
      { key: 'cr', value: 'CR', show: false },
      { key: 'network_reported_revenue', value: 'Network Reported Revenue', show: false },
      { key: 'revenue_difference', value: 'Revenue Difference', show: false },
      { key: 'visiopt_conversions', value: 'Visiopt Conversion', show: true },
      { key: 'network_conversions', value: 'Network Conversion', show: false },
      { key: 'conversion_difference', value: 'Conversion Difference', show: false },
      { key: 'cpa', value: 'CPA', show: false },
      { key: 'impressions', value: 'Impressions', show: false },
      { key: 'ctr', value: 'CTR', show: false },
      { key: 'cpc', value: 'CPC', show: false },
      { key: 'cpm', value: 'CPM', show: false },
      { key: 'roas', value: 'ROAS', show: false },
      { key: 'roi', value: 'ROI', show: false },
      { key: 'aov', value: 'AOV', show: false },
      { key: 'epc', value: 'EPC', show: false },
      { key: 'unique_epc', value: 'Unique EPC', show: false },
      { key: 'profit', value: 'Profit', show: false },
      { key: 'avg_revenue_per_unique', value: 'Avg. Revenue per Unique', show: false },
  ];

  const [organicTrafficMetricsColumns, setOrganicTrafficMetricsColumns] = useState([
      { key: 'goalname', value: 'Organic Traffic', show: true },
      { key: 'uniques', value: 'Uniques', show: true },
      { key: 'visits', value: 'Visits', show: true },
      { key: 'visiopt_revenue', value: 'Visiopt Revenue', show: true },
      { key: 'visiopt_subscription_revenue', value: 'Visiopt Subscription Revenue', show: false },
      { key: 'visiopt_conversions', value: 'Visiopt Conversions', show: true },
      { key: 'aov', value: 'AOV', show: false },
      { key: 'epc', value: 'EPC', show: false },
      { key: 'unique_epc', value: 'Unique EPC', show: false },
      { key: 'avg_revenue_per_unique', value: 'AVG.Revenue per unique', show: false },
  ]);

  const [sourceMetricsColumns, setSourceMetricsColumns] = useState([
      { key: 'source_name', value: 'Source name', show: true },
      { key: 'uniques', value: 'Uniques', show: true },
      { key: 'visits', value: 'Visits', show: true },
      { key: 'visiopt_revenue', value: 'Visiopt Revenue', show: true },
      { key: 'visiopt_subscription_revenue', value: 'Visiopt Subscription Revenue', show: false },
      { key: 'visiopt_conversions', value: 'Visiopt Conversions', show: true },
      { key: 'aov', value: 'AOV', show: false },
      { key: 'epc', value: 'EPC', show: false },
      { key: 'unique_epc', value: 'Unique EPC', show: false },
      { key: 'avg_revenue_per_unique', value: 'AVG.Revenue per unique', show: false },
  ]);

  const testVariationMetricsColumns = [
      { key: 'variation', value: 'Variation', show: true },
      { key: 'Conversion', value: 'Conversion', show: true },
      { key: 'gcr', value: 'GCR', show: true },
      { key: 'ucr', value: 'UCR', show: true },
      { key: 'ad_spent', value: 'Ad Spent', show: false },
      { key: 'visiopt_revenue', value: 'Visiopt Revenue', show: false },
      { key: 'visiopt_sub_revenue', value: 'Visiopt Subscription Revenue', show: false },
      { key: 'cpg', value: 'CPG', show: true },
      { key: 'epg', value: 'EPG', show: true },     
      { key: 'network_reported_revenue', value: 'Network Reported Revenue', show: false },
      { key: 'revenue_diffrence', value: 'Revenue Difference', show: false },
      { key: 'visiopt_conversions', value: 'Visiopt Conversion', show: false },
      { key: 'network_conversions', value: 'Network Conversion', show: false },
      { key: 'conversions_diffrence', value: 'Conversion Difference', show: false },
      { key: 'roas', value: 'ROAS', show: false },
      { key: 'roi', value: 'ROI', show: false },
      { key: 'aov', value: 'AOV', show: false },
      { key: 'epc', value: 'EPC', show: false },
      { key: 'unique_epc', value: 'Unique EPC', show: false },
      { key: 'profit', value: 'Profit', show: false },
      { key: 'avg_revenue_per_unique', value: 'Avg. Revenue per Unique', show: false },
  ];

  const [generalColumns, setGeneralColumns] = useState<{ key: string; value: string; show: boolean }[]>(generalMetricsColumns);
  const [goalColumns, setGoalColumns] = useState<{ key: string; value: string; show: boolean }[]>(goalMetricsColumns);
  const [organicTrafficColumns, setOrganicTrafficColumns] = useState({});
  const [sourceColumns, setSourceColumns] = useState({});
  const [customTagColumns, setCustomTagColumns] = useState({});
  const [trackingTagColumns, setTrackingTagColumns] = useState({});
  const [trafficSourceColumns, setTrafficSourceColumns] = useState<{ key: string; value: string; show: boolean }[]>(trafficSourceMetricsColumns); 
  const [facebookData, setFacebookData] = useState([]);
  const [googleData, setGoogleData] = useState([]);
  const [youtubeData, setYoutubeData] = useState([]);
  const [tiktokData, setTiktokData] = useState([]);
  const [microsoftData, setMicrosoftData] = useState([]);
  const [applovinData, setApplovinData] = useState([]);

  const [facebookAdsetData, setFacebookAdsetData] = useState([]);
  const [googleAdsetData, setGoogleAdsetData] = useState([]);
  const [youtubeAdsetData, setYoutubeAdsetData] = useState([]);
  const [tiktokAdsetData, setTiktokAdsetData] = useState([]);
  const [microsoftAdsetData, setMicrosoftAdsetData] = useState([]);

  const [facebookAdsData, setFacebookAdsData] = useState([]);
  const [googleAdsData, setGoogleAdsData] = useState([]);
  const [youtubeAdsData, setYoutubeAdsData] = useState([]);
  const [tiktokAdsData, setTiktokAdsData] = useState([]);
  const [microsoftAdsData, setMicrosoftAdsData] = useState([]);
  const [applovinAdsetData, setApplovinAdsetData] = useState([]);

  const [facebookTrafficChecked, setFacebookTrafficChecked] = useState(false);
  const [googleTrafficChecked, setGoogleTrafficChecked] = useState(false);
  const [youtubeTrafficChecked, setYoutubeTrafficChecked] = useState(false);
  const [tiktokTrafficChecked, setTiktokTrafficChecked] = useState(false);
  const [microsoftTrafficChecked, setMicrosoftTrafficChecked] = useState(false);
  const [applovinTrafficChecked, setApplovinTrafficChecked] = useState(false);

  const [facebookAdsetTrafficChecked, setFacebookAdsetTrafficChecked] = useState(false);
  const [googleAdsetTrafficChecked, setGoogleAdsetTrafficChecked] = useState(false);
  const [youtubeAdsetTrafficChecked, setYoutubeAdsetTrafficChecked] = useState(false);
  const [tiktokAdsetTrafficChecked, setTiktokAdsetTrafficChecked] = useState(false);
  const [microsoftAdsetTrafficChecked, setMicrosoftAdsetTrafficChecked] = useState(false);
  const [testVariationColumns, setTestVariationColumns] = useState<{ key: string; value: string; show: boolean }[]>(testVariationMetricsColumns);

  const [pieChartSourceData, setPieChartSourceData] = useState([]);
  const [pieChartSourceColors, setPieChartSourceColors] = useState([]);

  const [showTableByIcons, setShowTableByIcons] = useState<{ campaign_id: string; tableName: string; show: boolean }[]>();
  const [showAllAdCosts, setShowAllAdCosts] = useState(false);
  const [campaignSearchValue, setCampaignSearchValue] = useState<{ value: string; trafficName: string; tableName: string }[]>([]);
  const [adsetSearchValue, setAdsetSearchValue] = useState<{ value: string; trafficName: string; tableName: string }[]>([]);
  const [adsSearchValue, setAdsSearchValue] = useState<{ value: string; trafficName: string; tableName: string }[]>([]);
  const [filteredData, setFilteredData] = useState([]);
  const [adsetAdsData, setAdsetAdsData] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [graphCampaignList, setGraphCampaignList] = useState([]);
  const [selectedGraphCampaign, setSelectedGraphCampaign] = useState('');
  const [showGraphCampaignDropdown, setShowGraphCampaignDropdown] = useState(false);
  const [lastTrafficSourceData, setLastTrafficSourceData] = useState<{ table: string; data: any }[]>([]);
  const [trafficAdsetData, setTrafficAdsetData] = useState([]);
  let apiCallCounter = 0;

  const setReportNameValue = (value:any) => {
    setReportName(value);
  };

  const handleHeaderMenuComponentChange = (Value:any) => {
    setSelectedMemberWebsite(Value);
  };

  const handleCancelReport = () => {
    setUpdateReportId('');
    navigate("/campaign-reports");
  };

  const handleSaveReportModalOpenModal = (value:any) => {
    setSaveReportModalOpen(true);
  };

  const handleSaveReportModalCloseModal = () => {
    setSaveReportModalOpen(false);
  };

  const fetchCampaignList = useCallback(() => {
    setComparisonVisiCampaign([]);
    setConversionGoalOptions([]);    
    if((reportId && reportId !== '') || updateReportId !== ''){   
      setIsVisiLoaderVisible(true);   
      CampaignReport.getcampaignlistbydomain({
        report_id: reportId
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          const selectedCampaignId = Object.entries(data.data.campaign_list)
                                    .flatMap(([domain, campaigns]) => 
                                        (campaigns as { campaign_id: string; campaign_name: string; selected: string }[])
                                            .find(campaign => campaign.selected === 'selected')
                                    )
                                    .find(campaign => campaign)?.campaign_id || null;
          setSelectedVisioptCampaign(selectedCampaignId);
          setSelectedDate(data.data.selected_date);
          setReportName(data.data.report_name);
          setAttributionList(data.data.attribution_list);
          setVisioptCampaignOptions(data.data.campaign_list);
          const showUpdate = data.data.update_report_button_display === 1 ? true : false;
          const showAllAd = data.data.show_all_ad && data.data.show_all_ad === '1' ? true : false;
          setShowAllAdCosts(showAllAd);
          setIsVisiLoaderVisible(false);
          setShowUpdateButton(showUpdate);
          setSelectedTimePicker(Number(data.data.time));
        }else{
          setIsVisiLoaderVisible(false);
          setErrorMessage(data.message);
          seterrorCloseModal(true);
          setShowErrorHeader(true);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
        setIsVisiLoaderVisible(false);
      });
    }else{
      setIsVisiLoaderVisible(true);
      CampaignReport.getcampaignlistbydomain()
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setSelectedDate(data.data.selected_date);
          setAttributionList(data.data.attribution_list);
          setVisioptCampaignOptions(data.data.campaign_list);
          setIsVisiLoaderVisible(false);
          const showUpdate = data.data.update_report_button_display === 1 ? true : false;
          setShowUpdateButton(showUpdate);
          setSelectedTimePicker(Number(data.data.time));
        }else{
          setIsVisiLoaderVisible(false);
          setErrorMessage(data.message);
          seterrorCloseModal(true);
          setShowErrorHeader(true);
        }

      })
      .catch(error => {
        console.error("API Error:", error);
        setIsVisiLoaderVisible(false);
      });
    }
}, [navigate, reportId]);

  useEffect(() => {
    if(selectedMemberWebsite !== '') {
      fetchCampaignList();
    }
  }, [fetchCampaignList, selectedMemberWebsite]);

  useEffect(() => {
    const selectedCampaignIds = conversionGoalOptions.map(group => group.campaign_id);
    setSelectedCampaigns(selectedCampaignIds);
    const visibleTrafficSourceColumns = trafficSourceColumns.filter((column) => column.show);
    const visibleOrganicTrafficColumns = organicTrafficMetricsColumns.filter((column) => column.show);
    const visibleSourceColumns = sourceMetricsColumns.filter((column) => column.show);
    if(conversionGoalOptions.length > 0){
      conversionGoalOptions.forEach((currentCampaign, currentCampaignIndex) => {
      if (!campaignColumns[currentCampaign.campaign_id]) {
        setOrganicTrafficColumns((prev) => ({
          ...prev,
          [currentCampaign.campaign_id]: visibleOrganicTrafficColumns,
        }));
        setSourceColumns((prev) => ({
          ...prev,
          [currentCampaign.campaign_id]: visibleSourceColumns,
        }));
        setCustomTagColumns((prev) => ({
          ...prev,
          [currentCampaign.campaign_id]: sourceMetricsColumns,
        }));
        setTrackingTagColumns((prev) => ({
          ...prev,
          [currentCampaign.campaign_id]: sourceMetricsColumns,
        }));
        const trafficSourceNames = ['facebook', 'google', 'microsoft', 'tiktok', 'youtube', 'applovin'];
        const trafficSourceAdsetNames = ['facebookAdset', 'googleAdset', 'microsoftAdset', 'tiktokAdset', 'youtubeAdset', 'applovinAdset'];
        const trafficSourceAdsNames = ['facebookAds', 'googleAds', 'microsoftAds', 'tiktokAds', 'youtubeAds'];
        setCampaignColumns((prev) => {
          const updatedCampaigns = {};

          trafficSourceNames.forEach((traffic) => {
            updatedCampaigns[traffic] = visibleTrafficSourceColumns.flatMap((col) => {
              if (col.key === 'traffic_source') {
                return [
                  [col.key, col.value],
                  ['checkbox', false],
                ];
              }
              return [[col.key, col.value]];
            });
          });

          trafficSourceAdsetNames.forEach((traffic) => {
            updatedCampaigns[traffic] = visibleTrafficSourceColumns.flatMap((col) => {
              if (col.key === 'traffic_source') {
                return [
                  [col.key, col.value],
                  ['checkbox', false],
                ];
              }
              if (col.key === 'campaign_name' && traffic !== 'applovinAdset') {
                return [
                  [col.key, col.value],
                  ['adset_name', 'Ad Set / Group Name'],
                ];
              }
              if (col.key === 'campaign_name' && traffic === 'applovinAdset') {
                return [
                  [col.key, col.value],
                  ['ad_name', 'Ad'],
                ];
              }
              return [[col.key, col.value]];
            });
          });

          trafficSourceAdsNames.forEach((traffic) => {
            updatedCampaigns[traffic] = visibleTrafficSourceColumns.flatMap((col) => {
              if (col.key === 'traffic_source') {
                return [
                  [col.key, col.value],
                  ['checkbox', false],
                ];
              }
              if (col.key === 'campaign_name') {
                return [
                  [col.key, col.value],
                  ['adset_name', 'Ad Set / Group Name'],
                  ['ad_name', 'Ad'],
                ];
              }
              return [[col.key, col.value]];
            });
          });

          return {
            ...prev,
            ...updatedCampaigns,
          };
        });
      }
    });
    }
  }, [conversionGoalOptions]);

  const handleSelectedVisioptCampaign = (value:any) => {
    setSelectedVisioptCampaign(value);
    setComparisonVisiCampaign([]);
    setConversionGoalOptions([]);
  };

  const handleComparisonVisiCampaign = (selectedValue) => {
    const removedValues = comparisonVisiCampaign.filter(
      (value) => !selectedValue.includes(value)
    );
    if (removedValues.length > 0) {
      setSelectedConversionGoal((prev) =>
        prev.filter((goal) => !removedValues.includes(goal.campaignid))
      );
      setSelectedCustomerGoal((prev) =>
        prev.filter((goal) => !removedValues.includes(goal.campaignid))
      );
      setSelectedFacebookDropdownData((prev) =>
        prev.filter((data) => !removedValues.includes(data.campaignid))
      );
      setSelectedTrackingTagData((prev) =>
        prev.filter((data) => !removedValues.includes(data.campaignid))
      );
      setSelectedCustomTagData((prev) =>
        prev.filter((data) => !removedValues.includes(data.campaignid))
      );
      setSelectedSourceData((prev) =>
        prev.filter((data) => !removedValues.includes(data.campaignid))
      );
      setSelectedOrganicCustomTag((prev) =>
        prev.filter((data) => !removedValues.includes(data.campaignid))
      );
      setSelectedOrganicTrackingTag((prev) =>
        prev.filter((data) => !removedValues.includes(data.campaignid))
      );
    }
    setComparisonVisiCampaign(selectedValue);
    if(selectedValue.length > 0){
      getVisioptCampaignGoalDropDown(selectedValue, 'comparison_campaign');
    }    
    const visibleCampaignNames = [...selectedValue, selectedVisioptCampaign];
    const filteredCampaigns = conversionGoalOptions.filter(option => visibleCampaignNames.includes(option.campaign_id));
    setConversionGoalOptions(filteredCampaigns);
  };

  /*START HANDLING DRAG & DROP AND SORTING*/
  const handleDragStart = (e, index, table, campaignID='') => {
    e.dataTransfer.setData('campaignID', campaignID);
    e.dataTransfer.setData('columnIndex', index);
    e.dataTransfer.setData('table', table);
  };

  const handleDrop = (e, dropIndex, customTagName='') => {
    const dragIndex = e.dataTransfer.getData('columnIndex');
    const table = e.dataTransfer.getData('table');
    const campaignID = e.dataTransfer.getData('campaignID');

    if(table === 'organicTraffic') {
      let updatedCampaignColumns = { ...organicTrafficColumns};
      if (updatedCampaignColumns[campaignID]) {
        const updatedColumns = [...updatedCampaignColumns[campaignID]];
        const [draggedColumn] = updatedColumns.splice(dragIndex, 1);
        updatedColumns.splice(dropIndex, 0, draggedColumn);

        updatedCampaignColumns[campaignID] = updatedColumns;
        setOrganicTrafficColumns(updatedCampaignColumns);
      }
    } else if(table === 'tracking_tag') {
      let updatedCampaignColumns = { ...trackingTagColumns};
      if (updatedCampaignColumns[campaignID]) {
        const trackingColumns = updatedCampaignColumns[campaignID].filter((column) => column.key !== 'source_name');
        const updatedColumns = [...trackingColumns];
        const [draggedColumn] = updatedColumns.splice(dragIndex, 1);
        updatedColumns.splice(dropIndex, 0, draggedColumn);
        
        updatedCampaignColumns[campaignID] = updatedColumns;
        setTrackingTagColumns(updatedCampaignColumns);
      }
    }else if(table === 'custom_tag') {
      let updatedCampaignColumns = { ...customTagColumns};
      if (updatedCampaignColumns[campaignID]) {
        const customTagColumns = updatedCampaignColumns[campaignID][customTagName];
        const updatedColumns = [...customTagColumns];
        const [draggedColumn] = updatedColumns.splice(dragIndex, 1);
        updatedColumns.splice(dropIndex, 0, draggedColumn);
        
        updatedCampaignColumns[campaignID][customTagName] = updatedColumns;
        setCustomTagColumns(updatedCampaignColumns);
      }
    }else if(table === 'source') {
      let updatedCampaignColumns = { ...sourceColumns};
      if (updatedCampaignColumns[campaignID]) {
        const updatedColumns = [...updatedCampaignColumns[campaignID].slice(-9)];
        const [draggedColumn] = updatedColumns.splice(dragIndex, 1);
        updatedColumns.splice(dropIndex, 0, draggedColumn);
        
        updatedCampaignColumns[campaignID] = updatedColumns;
        setSourceColumns(updatedCampaignColumns);
      }
    }else {
      let updatedCampaignColumns = { ...campaignColumns };
      if (updatedCampaignColumns) {
        const updatedColumns = [...updatedCampaignColumns[table]];
        const [draggedColumn] = updatedColumns.splice(dragIndex, 1);
        updatedColumns.splice(dropIndex, 0, draggedColumn);
        
        updatedCampaignColumns[table] = updatedColumns;
        setCampaignColumns(updatedCampaignColumns);
      }
    }
  };

  const handleSorting = (key, table, campaignID, totalKeyName='') => {
    setShowColumnColor((prev) =>
      prev.some((column) => column.campaignid === campaignID && column.tableName === table && column.columnName === key)
        ? prev.map((column) =>
            column.campaignid === campaignID && column.tableName === table && column.columnName === key
              ? { ...column, show: !column.show }
              : column
          )
        : [...prev, { campaignid: campaignID, tableName: table, columnName: key, show: true }]
    );
    setShowTrafficColumnsColor((prev) =>
      prev.some((column) => column.tableName === table && column.columnName === key)
        ? prev.map((column) =>
            column.tableName === table && column.columnName === key
              ? { ...column, show: !column.show }
              : column
          )
        : [...prev, { tableName: table, columnName: key, show: true }]
    );
    const campaignSortConfig = sortConfig[campaignID] || [];
    const existingSortIndex = campaignSortConfig.findIndex(config => config.key === key && config.table === table);

    let newCampaignSortConfig = [...campaignSortConfig];
    let direction = 'ascending';

    if (existingSortIndex !== -1) {
        const existingSort = newCampaignSortConfig[existingSortIndex];
        if (existingSort.direction === 'ascending') {
            direction = 'descending';
        } else if (existingSort.direction === 'descending') {
            direction = null;
        }

        if (direction === null) {
            newCampaignSortConfig.splice(existingSortIndex, 1);
        } else {
            newCampaignSortConfig[existingSortIndex] = { key, direction, table, totalKeyName };
        }
    } else {
        newCampaignSortConfig.push({ key, direction, table, totalKeyName });
    }

    setSortConfig(prev => ({
        ...prev,
        [campaignID]: newCampaignSortConfig
    })); 

    const trafficSourceMapData = {
      organicTraffic: organicTrafficDataTable,  
      tracking_tag: trackingTagDataTable,    
      source: sourceDataTable,
    };

    const trafficSourceMapSetData = {
      organicTraffic: setOrganicTrafficDataTable,
      tracking_tag: setTrackingTagDataTable,
      source: setSourceDataTable,      
    };

    if (table === 'custom_tag') {
      const trafficSortConfig = newCampaignSortConfig.filter(config => config.table === table);
      const customData = [...customTagDataTable[campaignID][totalKeyName]];
      if(customData.length > 1){
        let lastRow = [] as any;
        if (customData[customData.length - 1][totalKeyName] === "total") {
          lastRow = customData.pop();
        }
        const rowsToSort = customData;

        const sortedTrafficData = rowsToSort.sort((a, b) => {
          for (const { key, direction } of trafficSortConfig) {
            let valueA: string | number = '';
            let valueB: string | number = '';
            if(key === totalKeyName){
              valueA = a[key].toString();
              valueB = b[key].toString();
            }else {
              valueA = parseFloat(a[key].toString().replace(/,/g, ""));
              valueB = parseFloat(b[key].toString().replace(/,/g, ""));
            }                
            if (valueA < valueB) return direction === 'ascending' ? -1 : 1;
            if (valueA > valueB) return direction === 'ascending' ? 1 : -1;
          }
          return 0;
        });

      if (lastRow && lastRow[totalKeyName] === "Total") {
        sortedTrafficData.push(lastRow);
      }

      setCustomTagDataTable(prev => ({
        ...prev,
        [campaignID]: {
          ...prev[campaignID],
          [totalKeyName]: sortedTrafficData
        }
      }));

        return;
      }
    }

    if(trafficSourceMapData[table]) {
      const trafficSortConfig = newCampaignSortConfig.filter(config => config.table === table);
      const data = [...trafficSourceMapData[table][campaignID].data];
      if(data.length > 1) {
        let lastRow = [] as any;
        if (data[data.length - 1].traffic_source === 'Total' || data[data.length - 1].goalname === 'Total' || data[data.length - 1].source_name === 'Total') {
          lastRow = data.pop();
        }else if(table === 'tracking_tag'){
          let keyName = totalKeyName.toLowerCase().replace(/\s/g, "_").replace(/\./g, "_");
          if(data[data.length - 1].totalKeyName === 'Total'){
            lastRow = data.pop();
          }
        }
        const rowsToSort = data;
        const sortedTrafficData = rowsToSort.sort((a, b) => {
            for (const { key, direction } of trafficSortConfig) {
                const keysName = ['source_name', 'goalname', 'traffic_source', 'campaign_name', 'adset_name', 'ad_name'];
                let valueA: string | number = a[key];
                let valueB: string | number = b[key];
                if(keysName.includes(key)){
                  valueA = valueA.toString();
                  valueB = valueB.toString();
                }else if(table === 'tracking_tag' && key === totalKeyName) {
                  valueA = valueA.toString();
                  valueB = valueB.toString();
                }else {
                  valueA = parseFloat(valueA.toString().replace(/,/g, ""));
                  valueB = parseFloat(valueB.toString().replace(/,/g, ""));
                }                
                if (valueA < valueB) return direction === 'ascending' ? -1 : 1;
                if (valueA > valueB) return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });     
        const tables = ['organicTraffic', 'source', 'tracking_tag', 'custom_tag'];
        if (lastRow && !tables.includes(table) && lastRow.traffic_source === 'Total') {
          sortedTrafficData.push(lastRow);
        }
        if (lastRow && table === 'organicTraffic' && lastRow.goalname === 'Total') {
          sortedTrafficData.push(lastRow);
        }
        if (lastRow && table === 'source' && lastRow.source_name === 'Total') {
          sortedTrafficData.push(lastRow);
        }      
        if (lastRow && table === 'tracking_tag') {
          let keyName = totalKeyName.toLowerCase().replace(/\s/g, "_").replace(/\./g, "_");
          if(lastRow[keyName] === 'Total')
            sortedTrafficData.push(lastRow);
        }
        trafficSourceMapSetData[table](prev => ({
            ...prev,
            [campaignID]: { ...prev[campaignID], data: sortedTrafficData }
        }));
      }
    }
  };

  const handleTrafficSorting = (key, table) => {
    const existingSortIndex = trafficSortConfig.findIndex(config => config.key === key && config.table === table);

    let newCampaignSortConfig = [...trafficSortConfig];
    let direction = 'ascending';

    if (existingSortIndex !== -1) {
        const existingSort = newCampaignSortConfig[existingSortIndex];
        if (existingSort.direction === 'ascending') {
            direction = 'descending';
        } else if (existingSort.direction === 'descending') {
            direction = null;
        }

        if (direction === null) {
            newCampaignSortConfig.splice(existingSortIndex, 1);
        } else {
            newCampaignSortConfig[existingSortIndex] = { key, direction, table };
        }
    } else {
        newCampaignSortConfig.push({ key, direction, table });
    }
    setTrafficSortConfig(() => [...newCampaignSortConfig]);

    const trafficSourceMapData = {
      facebook: facebookData,
      facebookAdset: facebookAdsetData,
      facebookAds: facebookAdsData,
      google: googleData,
      googleAdset: googleAdsetData,
      googleAds: googleAdsData,
      microsoft: microsoftData,
      microsoftAdset: microsoftAdsetData,
      microsoftAds: microsoftAdsData,
      tiktok: tiktokData,
      tiktokAdset: tiktokAdsetData,
      tiktokAds: tiktokAdsData,
      youtube: youtubeData,
      youtubeAdset: youtubeAdsetData,
      youtubeAds: youtubeAdsData,
      applovin: applovinData,
      applovinAdset: applovinAdsetData
    };

    const trafficSourceMapSetData = {
      facebook: setFacebookData,
      facebookAdset: setFacebookAdsetData,
      facebookAds: setFacebookAdsData,
      google: setGoogleData,
      googleAdset: setGoogleAdsetData,
      googleAds: setGoogleAdsData,
      microsoft: setMicrosoftData,
      microsoftAdset: setMicrosoftAdsetData,
      microsoftAds: setMicrosoftAdsData,
      tiktok: setTiktokData,
      tiktokAdset: setTiktokAdsetData,
      tiktokAds: setTiktokAdsData,
      youtube: setYoutubeData,
      youtubeAdset: setYoutubeAdsetData,
      youtubeAds: setYoutubeAdsData,    
      applovin: setApplovinData,
      applovinAdset: setApplovinAdsetData,
    };

    if(trafficSourceMapData[table]) {
      const trafficSortConfig = newCampaignSortConfig.filter(config => config.table === table);
      const data = [...trafficSourceMapData[table]];
      if(data.length > 1) {
        let lastRow = [] as any;
        if (data[data.length - 1].traffic_source === "Total") {
          lastRow = data.pop();
        }
        const rowsToSort = data;
        const sortedTrafficData = rowsToSort.sort((a, b) => {
            for (const { key, direction } of trafficSortConfig) {
                const keysName = ['traffic_source', 'campaign_name', 'adset_name', 'ad_name'];
                let valueA: string | number = a[key];
                let valueB: string | number = b[key];
                if(keysName.includes(key)){
                  valueA = valueA.toString();
                  valueB = valueB.toString();
                }else {
                  valueA = parseFloat(valueA.toString().replace(/,/g, ""));
                  valueB = parseFloat(valueB.toString().replace(/,/g, ""));
                }                
                if (valueA < valueB) return direction === 'ascending' ? -1 : 1;
                if (valueA > valueB) return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        if (lastRow && lastRow.traffic_source === 'Total') {
          sortedTrafficData.push(lastRow);
        }
        trafficSourceMapSetData[table](() => [...sortedTrafficData]);
      }
    }
  };
  /*END HANDLING DRAG & DROP AND SORTING*/

  /*START HANDLING TRAFFIC SOURCE CHECKBOX*/
  const handleTrafficSourceColumnChecked = (trafficName:any) => {
    const checkedTrafficSourcesMap = {
      facebook: facebookTrafficChecked,
      facebookAdset: facebookAdsetTrafficChecked,
      google: googleTrafficChecked,
      googleAdset: googleAdsetTrafficChecked,
      microsoft: microsoftTrafficChecked,
      microsoftAdset: microsoftAdsetTrafficChecked,
      youtube: youtubeTrafficChecked,
      youtubeAdset: youtubeAdsetTrafficChecked,
      tiktok: tiktokTrafficChecked,
      tiktokAdset: tiktokAdsetTrafficChecked,
      applovin: applovinTrafficChecked
    };

    const setTrafficSourceChecked = {
      facebook: setFacebookTrafficChecked,
      facebookAdset: setFacebookAdsetTrafficChecked,
      google: setGoogleTrafficChecked,
      googleAdset: setGoogleAdsetTrafficChecked,
      microsoft: setMicrosoftTrafficChecked,
      microsoftAdset: setMicrosoftAdsetTrafficChecked,
      youtube: setYoutubeTrafficChecked,
      youtubeAdset: setYoutubeAdsetTrafficChecked,
      tiktok: setTiktokTrafficChecked,
      tiktokAdset: setTiktokAdsetTrafficChecked,
      applovin: setApplovinTrafficChecked,
    };

    const trafficSourceMapSetData = {
      facebook: setFacebookData,
      facebookAdset: setFacebookAdsetData,
      facebookAds: setFacebookAdsData,
      google: setGoogleData,
      googleAdset: setGoogleAdsetData,
      googleAds: setGoogleAdsData,
      microsoft: setMicrosoftData,
      microsoftAdset: setMicrosoftAdsetData,
      microsoftAds: setMicrosoftAdsData,
      tiktok: setTiktokData,
      tiktokAdset: setTiktokAdsetData,
      tiktokAds: setTiktokAdsData,
      youtube: setYoutubeData,
      youtubeAdset: setYoutubeAdsetData,
      youtubeAds: setYoutubeAdsData,
      applovin: setApplovinData,
      applovinAdset: setApplovinAdsetData,
    };

    const newCheckedValue = checkedTrafficSourcesMap[trafficName];
    setTrafficSourceChecked[trafficName](!newCheckedValue);
    trafficSourceMapSetData[trafficName]((prev) => {
      const updatedData = prev.map((row) => ({
        ...row,
        checkbox: !newCheckedValue,
      }));
      const existingTrafficTableData = lastTrafficSourceData.find((traffic) => traffic.table === trafficName);
      if(existingTrafficTableData){
        setLastTrafficSourceData((prev) => 
          prev.map((traffic) => 
            traffic.table === trafficName
              ? { ...traffic, data: updatedData }
              : traffic
          )
        );
      }else{
        setLastTrafficSourceData((prev) => [
          ...prev,
          {data: updatedData, table: trafficName},
        ]);
      }
      return updatedData;
    });
  };

  const handleTrafficRowChecked = (rowIndex: number, trafficName: string) => {
    const trafficSourceMapSetData = {
      facebook: setFacebookData,
      facebookAdset: setFacebookAdsetData,
      facebookAds: setFacebookAdsData,
      google: setGoogleData,
      googleAdset: setGoogleAdsetData,
      googleAds: setGoogleAdsData,
      microsoft: setMicrosoftData,
      microsoftAdset: setMicrosoftAdsetData,
      microsoftAds: setMicrosoftAdsData,
      tiktok: setTiktokData,
      tiktokAdset: setTiktokAdsetData,
      tiktokAds: setTiktokAdsData,
      youtube: setYoutubeData,
      youtubeAdset: setYoutubeAdsetData,
      youtubeAds: setYoutubeAdsData,
      applovin: setApplovinData,
      applovinAdset: setApplovinAdsetData,
    };  

    trafficSourceMapSetData[trafficName]((prevData) =>
      prevData.map((row, index) => {
        if (index === rowIndex) {
          return { ...row, checkbox: !row.checkbox };
        }
        return row;
      })
    );

    const trafficCheckedCheckbox = {
      facebook: facebookTrafficChecked,
      google: googleTrafficChecked,
      microsoft: microsoftTrafficChecked,
      tiktok: tiktokTrafficChecked,
      youtube: youtubeTrafficChecked,
      applovin: applovinTrafficChecked,
    };

    const trafficAdsetCheckedCheckbox = {
      facebookAdset: facebookAdsetTrafficChecked,
      googleAdset: googleAdsetTrafficChecked,
      microsoftAdset: microsoftAdsetTrafficChecked,
      tiktokAdset: tiktokAdsetTrafficChecked,
      youtubeAdset: youtubeAdsetTrafficChecked,
    };

    const setTrafficSourceChecked = {
      facebook: setFacebookTrafficChecked,
      google: setGoogleTrafficChecked,
      microsoft: setMicrosoftTrafficChecked,
      youtube: setYoutubeTrafficChecked,
      tiktok: setTiktokTrafficChecked,
      applovin: setApplovinTrafficChecked,
    };

    const setTrafficAdsetSourceChecked = {
      facebookAdset: setFacebookAdsetTrafficChecked,
      googleAdset: setGoogleAdsetTrafficChecked,
      microsoftAdset: setMicrosoftAdsetTrafficChecked,
      youtubeAdset: setYoutubeAdsetTrafficChecked,
      tiktokAdset: setTiktokAdsetTrafficChecked,
    };

    if(trafficCheckedCheckbox[trafficName]) {
      const isChecked = trafficCheckedCheckbox[trafficName];
      if(isChecked){
        setTrafficSourceChecked[trafficName](false);
      }
    } else if(trafficAdsetCheckedCheckbox[trafficName]) {
      const isChecked = trafficAdsetCheckedCheckbox[trafficName];
      if(isChecked){
        setTrafficAdsetSourceChecked[trafficName](false);
      }
    }
  };
  /*END HANDLING TRAFFIC SOURCE CHECKBOX*/

  const handleTrafficDataChecked = (value, index, trafficName, type, cpc, ctr, cpm) => {

    const clickableButtons = ['trafficSource', 'checkbox'];
    if (clickableButtons.includes(value)) {
      return;
    }

    let updatedCheckboxValue;
    let dataObject = {};

    const trafficSourceMap = {
      facebook: facebookData,
      google: googleData,
      microsoft: microsoftData,
      tiktok: tiktokData,
      youtube: youtubeData,
      applovin: applovinData,
    };
    const trafficSourceSetMap = {
      facebook: setFacebookData,
      google: setGoogleData,
      microsoft: setMicrosoftData,
      tiktok: setTiktokData,
      youtube: setYoutubeData,
      applovin: setApplovinData,
    };

    const campaignData = trafficSourceMap[trafficName];
    if (campaignData) {
      const updatedData = campaignData.map((row, rowIndex) => {
        if (index === rowIndex) {
          updatedCheckboxValue = true;
          return { ...row, checkbox: updatedCheckboxValue };
        }
        return row;
      });
      let test_variations_data = '';
      const testVariationCampaigns = conversionGoalOptions.filter(options => options.test_variation === 'show');
      if(testVariationCampaigns && testVariationCampaigns.length > 0){
        test_variations_data = testVariationCampaigns.reduce((acc, options) => {
          acc[options.campaign_id] = options.test_variation_value;

          return acc;
        }, {})
      }

      let campaign_arr = '';
      if (conversionGoalOptions && conversionGoalOptions.length > 0) {
        campaign_arr = conversionGoalOptions.reduce((acc, options) => {
          const selectedGoal = options.goals.find(goal => goal.selected === 'selected');
          const selectedGoalId = selectedGoal ? selectedGoal.goal_page_id : (options.goals[0] ? options.goals[0].goal_page_id : null);
          acc[options.campaign_id] = selectedGoalId;
          
          return acc;
        }, {});
      }
      const checkedEncodeCampaignNamesArray = updatedData
        .filter(data => data['checkbox'] && data['traffic_source'] !== 'Total')
        .map(data => data['encode_campaign_name']);

      let timeValue: string | number = '';
      if(selectedTimePicker === -2 && selectedTimePickerFromDate === '' && selectedTimePickerToDate === '' && selectedDate){
        timeValue = selectedDate;
      }else if(selectedTimePicker === -2 && selectedTimePickerFromDate !== '' && selectedTimePickerToDate !== ''){
        const [fromDay, fromMonth, fromYear] = selectedTimePickerFromDate.split("-");
        const [endDay, endMonth, endYear] = selectedTimePickerToDate.split("-");
        const fromDate = `${fromMonth}/${fromDay}/${fromYear}`;
        const endDate = `${endMonth}/${endDay}/${endYear}`;
        timeValue = `${fromDate}#${endDate}`;
      }else{
        timeValue = selectedTimePicker;
      }
      
      if (checkedEncodeCampaignNamesArray.length > 1) {
        dataObject = {
          traffic_source: trafficName,
          type: type,
          campaign_name: checkedEncodeCampaignNamesArray,
          attribution_name: selectedAttributionModal,
          time: timeValue,
          campaign_arr: campaign_arr,
          test_variations_data: test_variations_data,
          show_all_ad: showAllAdCosts === true ? 1 : 0,
          report_id: reportId || updateReportId,
        };
      } else {
        dataObject = {
          traffic_source: trafficName,
          type: type,
          campaign_name: checkedEncodeCampaignNamesArray,
          attribution_name: selectedAttributionModal,
          total_CPC: cpc,
          total_CTR: ctr,
          total_CPM: cpm,
          time: timeValue,
          campaign_arr: campaign_arr,
          test_variations_data: test_variations_data,
          show_all_ad: showAllAdCosts === true ? 1 : 0,
          report_id: reportId || updateReportId,
        };
      }
      trafficSourceSetMap[trafficName](() => [...updatedData]);
    }
    if(type && type !== '') {
      setIsVisiLoaderVisible(true);
      CampaignReport.getadsetadsdata(dataObject)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          const existingAdsetTable = showAdsetTable.find((adset) => adset.trafficName === trafficName);
          if(existingAdsetTable){
            setShowAdsetTable((prev) => 
              prev.map((adset) => 
                adset.trafficName === trafficName
                  ? { ...adset, show: !adset.show }
                  : adset
              )
            );
          }else{
            setShowAdsetTable((prev) => [
                ...prev,
                { show: true, trafficName: trafficName },
            ]);
          }
          const existingTrafficTable = showTrafficSourceTable.find((traffic) => traffic.trafficName === trafficName);
          if(existingTrafficTable){
            setShowTrafficSourceTable((prev) => 
              prev.map((traffic) => 
                traffic.trafficName === trafficName
                  ? { ...traffic, show: !traffic.show }
                  : traffic
              )
            );
          }

          setTrafficAdsetData(data.data.traffic_source);
          const trafficSourceMap = {
            facebook: setFacebookAdsetData,
            google: setGoogleAdsetData,
            tiktok: setTiktokAdsetData,
            youtube: setYoutubeAdsetData,
            microsoft: setMicrosoftAdsetData,
            applovin: setApplovinAdsetData
          };

          if (trafficSourceMap[trafficName]) {
            const tableData = (data.data.traffic_source[trafficName] as { td: any[] }).td;
            trafficSourceMap[trafficName](() => [...tableData]);
          }
          setIsVisiLoaderVisible(false);
        }else{
          setIsVisiLoaderVisible(false);
          setErrorMessage(data.message);
          seterrorCloseModal(true);
          setShowErrorHeader(true);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
        setIsVisiLoaderVisible(false);
      });

      const existingShow = showBacktoCampButton.find((config) => config.rowIndex === index && config.show === true && config.trafficName === trafficName);
      if (!existingShow) {
        const newSource = { rowIndex: index, show: true, trafficName: trafficName };
        setShowBacktoCampButton((prevData) => [...prevData, newSource]);
      }
    }
  };

  const handleTrafficAdsetDataClick = (value, index, trafficAdset, trafficName, trafficCampNames, type, cpc, ctr, cpm) => {
    const clickableButtons = ['trafficSource', 'checkbox'];
    if (clickableButtons.includes(value)) {
      return;
    }

    let updatedCheckboxValue;
    let dataObject = {};

    const trafficSourceMap = {
      facebook: facebookAdsetData,
      google: googleAdsetData,
      microsoft: microsoftAdsetData,
      tiktok: tiktokAdsetData,
      youtube: youtubeAdsetData
    };
    const trafficSourceSetMap = {
      facebook: setFacebookAdsetData,
      google: setGoogleAdsetData,
      microsoft: setMicrosoftAdsetData,
      tiktok: setTiktokAdsetData,
      youtube: setYoutubeAdsetData
    };

    const campaignData = trafficSourceMap[trafficName];

    if (campaignData) {
      const updatedData = campaignData.map((row, rowIndex) => {
        if (index === rowIndex) {
          updatedCheckboxValue = true;
          return { ...row, checkbox: updatedCheckboxValue };
        }
        return row;
      });
      let test_variations_data = '';
      const testVariationCampaigns = conversionGoalOptions.filter(options => options.test_variation === 'show');
      if(testVariationCampaigns && testVariationCampaigns.length > 0){
        test_variations_data = testVariationCampaigns.reduce((acc, options) => {
          acc[options.campaign_id] = options.test_variation_value;

          return acc;
        }, {})
      }

      let campaign_arr = '';
      if (conversionGoalOptions && conversionGoalOptions.length > 0) {
        campaign_arr = conversionGoalOptions.reduce((acc, options) => {
          const selectedGoal = options.goals.find(goal => goal.selected === 'selected');
          const selectedGoalId = selectedGoal ? selectedGoal.goal_page_id : (options.goals[0] ? options.goals[0].goal_page_id : null);
          acc[options.campaign_id] = selectedGoalId;
          
          return acc;
        }, {});
      }

      const checkedEncodeCampaignNamesArray = updatedData
        .filter(data => data['checkbox'] && data['traffic_source'] !== 'Total')
        .map(data => data['encode_campaign_name']);

      const checkedTrafficCampName = updatedData
        .slice(0, -1)
        .filter(data => data['checkbox'])
        .map(data => data['traffic_camp_name']);

      let timeValue: string | number = '';
      if(selectedTimePicker === -2 && selectedTimePickerFromDate === '' && selectedTimePickerToDate === '' && selectedDate){
        timeValue = selectedDate;
      }else if(selectedTimePicker === -2 && selectedTimePickerFromDate !== '' && selectedTimePickerToDate !== ''){
        const [fromDay, fromMonth, fromYear] = selectedTimePickerFromDate.split("-");
        const [endDay, endMonth, endYear] = selectedTimePickerToDate.split("-");
        const fromDate = `${fromMonth}/${fromDay}/${fromYear}`;
        const endDate = `${endMonth}/${endDay}/${endYear}`;
        timeValue = `${fromDate}#${endDate}`;
      }else{
        timeValue = selectedTimePicker;
      }

      if (checkedEncodeCampaignNamesArray.length > 1) {
        dataObject = {
          traffic_source: trafficName,
          type: type,
          campaign_name: checkedEncodeCampaignNamesArray,
          attribution_name: selectedAttributionModal,
          time: timeValue,
          test_variations_data: test_variations_data,
          campaign_arr: campaign_arr,
          traffic_camp_name: checkedTrafficCampName,
          show_all_ad: showAllAdCosts === true ? 1 : 0
        };
      } else {
        dataObject = {
          traffic_source: trafficName,
          type: type,
          campaign_name: checkedEncodeCampaignNamesArray,
          attribution_name: selectedAttributionModal,
          total_CPC: cpc,
          total_CTR: ctr,
          total_CPM: cpm,
          time: timeValue,
          test_variations_data: test_variations_data,
          campaign_arr: campaign_arr,
          traffic_camp_name: checkedTrafficCampName,
          show_all_ad: showAllAdCosts === true ? 1 : 0
        };
      }

      trafficSourceSetMap[trafficName](() => [...updatedData]);
    }

    if(type && type !== '') {
      setIsVisiLoaderVisible(true);

      CampaignReport.getadsetadsdata(dataObject)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          const existingAdsTable = showAdsTable.find((ads) => ads.trafficName === trafficName);
          if(existingAdsTable){
            setShowAdsTable((prev) => 
              prev.map((ads) => 
                ads.trafficName === trafficName
                  ? { ...ads, show: !ads.show }
                  : ads
              )
            );
          }else{
            setShowAdsTable((prev) => [
                ...prev,
                { show: true, trafficName: trafficName },
            ]);
          }

          const existingAdsetTable = showAdsetTable.find((traffic) => traffic.trafficName === trafficName);
          if(existingAdsetTable){
            setShowAdsetTable((prev) => 
              prev.map((traffic) => 
                traffic.trafficName === trafficName
                  ? { ...traffic, show: !traffic.show }
                  : traffic
              )
            );
          }

          setAdsetAdsData(data.data.traffic_source);
          const trafficSourceMap = {
            facebook: setFacebookAdsData,
            google: setGoogleAdsData,
            tiktok: setTiktokAdsData,
            youtube: setYoutubeAdsData,
            microsoft: setMicrosoftAdsData,
          };

          if (trafficSourceMap[trafficName]) {
            const tableData = (data.data.traffic_source[trafficName] as { td: any[] }).td;
            trafficSourceMap[trafficName](() => [...tableData]);
          }
          setIsVisiLoaderVisible(false);
        }else{
          setIsVisiLoaderVisible(false);
          setErrorMessage(data.message);
          seterrorCloseModal(true);
          setShowErrorHeader(true);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
        setIsVisiLoaderVisible(false);
      })

      const existingShowAdset = showBacktoAdsetButton.find((config) => config.rowIndex === index && config.show === true && config.trafficName === trafficName);
      if (!existingShowAdset) {
        const newSource = { rowIndex: index, show: true, trafficName: trafficName };
        setShowBacktoAdsetButton((prevData) => [...prevData, newSource]);
      }
    }
  };

  /*START GET SELECTED CAMPAIGN*/
  const getVisioptCampaignGoalDropDown = async (campaignId:any, campaignType:any) => {
    apiCallCounter++;
    setIsVisiLoaderVisible(true);
    try {
        const requestData = {
              campaign_id: campaignId,
              campaign_type: campaignType,
              ...(reportId ? { report_id: reportId } : updateReportId ? { report_id: updateReportId } : {})
          };

        const response = await CampaignReport.getcampaigngoaldropdownbycampaignid(requestData);
        if (response.ok) {
          const data = await response.json();
          if (data.status === 1) {
              const behavioralDetails = data.data.behavioralDetails;
              const allConversionGoals = [];
              for (const key in behavioralDetails) {
                  if (behavioralDetails.hasOwnProperty(key)) {
                      const conversionGoals = behavioralDetails[key].conversion_goal_details;
                      const campaignData = {
                          campaign_id: key,
                          goals: conversionGoals,
                          campaign_name: behavioralDetails[key].first_campaign,
                          site_id: behavioralDetails[key].site_id,
                          test_variation: behavioralDetails[key].test_variations_display,
                          test_variation_value: behavioralDetails[key].test_variations_value,
                          behavioral_dimension_value: behavioralDetails[key].behavioral_filter_button,
                          customTagData: behavioralDetails[key].custom_tags,
                          organicTrafficData: behavioralDetails[key].organic_traffic_data,
                          sourceData: behavioralDetails[key].source_data,
                          trackingTagsData: behavioralDetails[key].tracking_tags,
                          customerPathData: behavioralDetails[key].customer_path_drop,
                          facebookDropDownData: behavioralDetails[key].fb_account_id_arr,
                      };
                      allConversionGoals.push(campaignData);
                  }
                  /*SET ALL GOAL WHEN NO GOAL IS SELECTED*/
                  setSelectedConversionGoal(prevData => [
                          ...prevData,
                          { campaignid: key, selectedGoal: allConversionGoals[0]?.goals[0]?.goal_page_id }
                  ]);
                  /*SET FIRST GOAL WHEN CREATE NEW REPORT*/
                  setSelectedCustomerGoal(prevData => [
                          ...prevData,
                          { campaignid: key, selectedGoal: allConversionGoals[0]?.customerPathData[0]?.goal_id }
                  ]);
                  /*SET FIRST ACCOUNT VALUE*/
                  const facebookDropDownData = allConversionGoals[0]?.facebookDropDownData;
                  if (facebookDropDownData && facebookDropDownData[1]) {
                    setSelectedFacebookDropdownData([
                        { campaignid: key, selectedValue: facebookDropDownData[1].fb_account_id }
                    ]);
                  }
              }
              if(campaignType === 'first_campaign') {
                setCompVisioptCampaignOptions(data.data.campaign_list);
              }
              setConversionGoalOptions(prevOptions => {
                const optionsMap = new Map(prevOptions.map(option => [option.campaign_id, option]));
                allConversionGoals.forEach(campaignData => {
                    optionsMap.set(campaignData.campaign_id, campaignData);
                });
                return Array.from(optionsMap.values());
              });
              // setIsVisiLoaderVisible(false);
              if((reportId && reportId !== '') || (updateReportId && updateReportId !== '')){
                if(campaignType === 'first_campaign') {
                  const selectedCompCampaignId = Object.entries(data.data.campaign_list).flatMap(([domain, campaigns]) =>
                                        (campaigns as { campaign_id: string; campaign_name: string; selected: string }[])
                                            .filter((campaign: { campaign_id: string; selected: string }) => campaign.selected === 'selected')
                                            .map(campaign => campaign.campaign_id)
                                    );
                  setComparisonVisiCampaign(selectedCompCampaignId);
                  if(selectedCompCampaignId.length > 0){
                    getVisioptCampaignGoalDropDown(selectedCompCampaignId, 'comparison_campaign');
                  }
                }                              
                allConversionGoals.forEach(campaign => {
                      if(campaign.test_variation_value === 0){
                        setShowGoalTable((prev) =>
                          prev.some((goal) => goal.campaignid === campaign.campaign_id)
                            ? prev.map((goal) =>
                                goal.campaignid === campaign.campaign_id
                                  ? { ...goal, show: true }
                                  : goal
                              )
                            : [...prev, { campaignid: campaign.campaign_id, show: true }]
                        );

                        setShowTestVariation((prev) =>
                          prev.some((variation) => variation.campaignid === campaign.campaign_id)
                            ? prev.map((variation) =>
                                variation.campaignid === campaign.campaign_id
                                  ? { ...variation, show: false }
                                  : variation
                              )
                            : [...prev, { campaignid: campaign.campaign_id, show: false }]
                        );
                      }else if(campaign.test_variation_value === 1){
                        setShowTestVariation((prev) =>
                          prev.some((variation) => variation.campaignid === campaign.campaign_id)
                            ? prev.map((variation) =>
                                variation.campaignid === campaign.campaign_id
                                  ? { ...variation, show: true }
                                  : variation
                              )
                            : [...prev, { campaignid: campaign.campaign_id, show: true }]
                        );

                        setShowGoalTable((prev) =>
                          prev.some((goal) => goal.campaignid === campaign.campaign_id)
                            ? prev.map((goal) =>
                                goal.campaignid === campaign.campaign_id
                                  ? { ...goal, show: false }
                                  : goal
                              )
                            : [...prev, { campaignid: campaign.campaign_id, show: false }]
                        );   
                      }
                      /*SET SELECTED CONVERSION GOAL VALUES*/
                      if (campaign.goals && Object.entries(campaign.goals).length > 0) {
                        const selectedConversionGoal = Object.values(campaign.goals).find(
                            (dataValue: { goal_page_id: string; selected: string }) => dataValue.selected === "selected"
                        );                        
                        const selectedConversionGoalId = selectedConversionGoal ? (selectedConversionGoal as { goal_page_id: string }).goal_page_id : null;
                      if(selectedConversionGoalId) {
                        setSelectedConversionGoal(prevData => {
                          const goalExists = prevData.some(entry => entry.campaignid === campaign.campaign_id);
                          if (goalExists) {
                            return prevData.map(entry => 
                                entry.campaignid === campaign.campaign_id
                                    ? { ...entry, selectedGoal: selectedConversionGoalId } 
                                    : entry 
                            );
                          }else {
                            const updatedData = [
                                ...prevData,
                                { campaignid: campaign.campaign_id, selectedGoal: selectedConversionGoalId }
                            ];
                            return updatedData;
                          }
                        });
                      }else{
                        setSelectedConversionGoal(prevData => [
                                ...prevData,
                                { campaignid: campaign.campaign_id, selectedGoal: allConversionGoals[0]?.goals[0]?.goal_page_id }
                        ]);
                      }
                      }

                      /*SET SELECTED CUSTOMER GOAL*/
                      if (campaign.customerPathData && Object.entries(campaign.customerPathData).length > 0) {
                        const selectedCustomerGoal = Object.values(campaign.customerPathData).find(
                          (dataValue: { goal_id: string; selected: string }) => dataValue.selected === "selected"
                        );                      
                        const selectedCustomerGoalId = selectedCustomerGoal ? (selectedCustomerGoal as { goal_id: string }).goal_id : null;
                        if(selectedCustomerGoalId) {
                          setSelectedCustomerGoal(prevData => {
                            const customerGoalExists = prevData.some(entry => entry.campaignid === campaign.campaign_id);
                            if (customerGoalExists) {
                              return prevData.map(entry => 
                                  entry.campaignid === campaign.campaign_id
                                      ? { ...entry, selectedGoal: selectedCustomerGoalId } 
                                      : entry 
                              );
                            }else {
                              const updatedData = [
                                  ...prevData,
                                  { campaignid: campaign.campaign_id, selectedGoal: selectedCustomerGoalId }
                              ];
                              return updatedData;
                            }
                          });
                        }else{
                          setSelectedCustomerGoal(prevData => [
                                  ...prevData,
                                  { campaignid: campaign.campaign_id, selectedGoal: allConversionGoals[0]?.customerPathData[0]?.goal_id }
                          ]);
                        }
                      }

                      /*SET SELECTED FACEBOOK ACCOUNT*/
                      if (campaign.facebookDropDownData && Object.entries(campaign.facebookDropDownData).length > 0) {
                        const selectedFacebookAccount = Object.values(campaign.facebookDropDownData).find(
                          (dataValue: { fb_account_id: string; selected: string }) => dataValue.selected === "selected"
                        );                      
                        const selectedFacebookAccountId = selectedFacebookAccount ? (selectedFacebookAccount as { fb_account_id: string }).fb_account_id : null;
                        setSelectedFacebookDropdownData([
                            { campaignid: campaign.campaign_id, selectedValue: selectedFacebookAccountId }
                        ]);
                      }

                      /*SET SELECTED SOURCE VALUES*/
                      if (campaign.sourceData && Array.isArray(campaign.sourceData.dropdown_data)) {
                        const selectedSourceValues = campaign.sourceData.dropdown_data.filter(data => data.selected === "selected").map(data => data.key);
                        setSelectedSourceData(prevData => [
                            ...prevData,
                            { campaignid: campaign.campaign_id, sourceValues: selectedSourceValues }
                        ]);
                      }

                      /*SET SELECTED TRACKING TAG VALUES*/
                      if (campaign.trackingTagsData && Object.entries(campaign.trackingTagsData).length > 0) {
                        Object.entries(campaign.trackingTagsData).forEach((trackingData) => {
                          const trackingTagarrayId = (trackingData[1] as any).array_id;
                          const trackingTagValues = (trackingData[1] as { dropdown_data: Array<any> }).dropdown_data;
                          if (Array.isArray(trackingTagValues)) {
                              const selectedTrackingData = trackingTagValues.filter(data => data.selected === "selected").map(data => data.key);                        
                              setSelectedTrackingTagData(prevData => [
                                  ...prevData,
                                  { campaignid: campaign.campaign_id, trackingTagKey: trackingData[0], trackingTagValues: selectedTrackingData, arrayId: trackingTagarrayId }
                              ]);
                          }
                        })
                      }

                      /*SET SELECTED CUSTOM TAG VALUES*/ 
                      if (campaign.customTagData && Object.entries(campaign.customTagData).length > 0) {
                        Object.entries(campaign.customTagData).forEach((customTagData) => {
                            const customTagarrayId = (customTagData[1] as any).array_id;
                            const customTagValues = (customTagData[1] as { dropdown_data: Array<any> }).dropdown_data;                            
                            if (Array.isArray(customTagValues) && customTagValues.length > 0) {
                                const selectedCustomData = customTagValues.filter(data => data.selected === "selected").map(data => data.key);
                                setSelectedCustomTagData(prevData => [
                                    ...prevData,
                                    { campaignid: campaign.campaign_id, customTagKey: customTagData[0], customTagValues: selectedCustomData, arrayId: customTagarrayId}
                                ]);
                            }
                        });
                      }

                      /*SET SELECTED ORGANIC TRAFFIC CUSTOM TAG VALUES*/
                      if (campaign.organicTrafficData.exclude_organic_custom_tag && Array.isArray(campaign.organicTrafficData.exclude_organic_custom_tag.dropdown_data)) {
                        const selectedValues = campaign.organicTrafficData.exclude_organic_custom_tag.dropdown_data.filter(data => data.selected === "selected").map(data => data.key);
                        setSelectedOrganicCustomTag(prevData => [
                            ...prevData,
                            { campaignid: campaign.campaign_id, selectedValues: selectedValues }
                        ]);
                      }

                      /*SET SELECTED ORGANIC TRAFFIC TRACKING TAG VALUES*/
                      if (campaign.organicTrafficData.exclude_organic_tracking_tag && Array.isArray(campaign.organicTrafficData.exclude_organic_tracking_tag.dropdown_data)) {
                        const selectedValues = campaign.organicTrafficData.exclude_organic_tracking_tag.dropdown_data.filter(data => data.selected === "selected").map(data => data.key);
                        setSelectedOrganicTrackingTag(prevData => [
                            ...prevData,
                            { campaignid: campaign.campaign_id, selectedValues: selectedValues }
                        ]);
                      }
                  });
              }
          }else{
            // setIsVisiLoaderVisible(false);
            setErrorMessage(data.message);
            seterrorCloseModal(true);
            setShowErrorHeader(true);
          }
        } else {
            if (response.status === 401 || response.status === 400) {
                navigate("/logout");
            }
        }
      } catch (error) {
          console.error("API Error:", error);
      }
      finally {
        apiCallCounter--;
        if (apiCallCounter === 0) {
          setIsVisiLoaderVisible(false);
        }
      }      
  };
  /*END GET SELECTED CAMPAIGN*/

  const handleCampaignClick = (campaignID) => {   
    const existingShow = trafficSourceData.find((config) => config.key === campaignID && config.show === true);
    if(existingShow){
      const filterTrafficData = trafficSourceData.filter((config) => config.key === campaignID && config.show === true ? null : config);
      setTrafficSourceData(filterTrafficData);
    }else{
      const newSource = { key: campaignID, show:true};
      setTrafficSourceData((prevData) => [...prevData, newSource]);
    }
  };

  useEffect(() => {
    const fetchGoals = async () => {
      if (selectedVisioptCampaign !== '') {
          await getVisioptCampaignGoalDropDown(selectedVisioptCampaign, 'first_campaign');
      }
    };
    fetchGoals();
  }, [selectedVisioptCampaign]);

  /*START GRAPH*/
  const handleGraphChange = (value:any, campaign:any) => {
    if((reportId && reportId !== '') || updateReportId !== ''){
      const dataObject = {
        report_id: reportId || updateReportId,
        chart_type: value
      } as any;
      if(campaign !== '' && campaign !== selectedVisioptCampaign){
        dataObject.chart_campaign_id = campaign;
      }
      setReportchartLoader(true);
      CampaignReport.getreportchartdata(dataObject)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setSelectedGraphData(data.data);
          setReportchartLoader(false);
          setSelectedGraphType(value);
        }else{
          setReportchartLoader(false);
          setErrorMessage(data.message);
          seterrorCloseModal(true);
          setShowErrorHeader(true);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
        setReportchartLoader(false);
      })
    }
  };

  const pieChartSourceDataArray = [...pieChartSourceData];
  const pieChartSourceColorsArray = [...pieChartSourceColors];

  const pieChartOptions = {
    title: "Sources",
    pieSliceText: 'none',
    colors: pieChartSourceColorsArray,
    titleTextStyle: {
      color: '#4D4D4D',
      fontSize: 18,
    },
  };
  /*END GRAPH*/

  /*START HANDLING DROPDOWN VALUE CHANGE*/
  const handleSourceDataChange = (campaignId:any, selectedValue:any) => {
    const existingSourceData = selectedSourceData.find((data) => data.campaignid === campaignId);
    setSelectedSourceData(prevData => {
      if (existingSourceData) {
        return prevData.map((data) =>
          data.campaignid === campaignId
            ? { ...data, sourceValues: selectedValue }
            : data
        );
      } else {
        return [...prevData, { campaignid: campaignId, sourceValues: selectedValue }];
      }
    });
    const dataObject = {
      ads_source: selectedValue,
      campaign_id: campaignId,
      site_id: selectedMemberWebsite
    };
    setIsVisiLoaderVisible(true);
    CampaignReport.sourcenetworkdd(dataObject)
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setConversionGoalOptions((prev) => {
          const updatedOptions = prev.map((campaign) => 
            campaign.campaign_id === campaignId 
              ? { ...campaign, sourceData: data.data } 
              : campaign
          );
          return updatedOptions;
        });
        setIsVisiLoaderVisible(false);
      }else{
        setIsVisiLoaderVisible(false);
        setErrorMessage(data.message);
        seterrorCloseModal(true);
        setShowErrorHeader(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
      setIsVisiLoaderVisible(false);
    })
  };

  const handleOrganicCustomTagData = (campaignId:any, selectedValue:any) => {
    const existingOrganicCustomData = selectedOrganicCustomTag.find((data) => data.campaignid === campaignId);
    setSelectedOrganicCustomTag(prevData => {
      if (existingOrganicCustomData) {
        return prevData.map((data) =>
          data.campaignid === campaignId
            ? { ...data, selectedValues: selectedValue }
            : data
        );
      } else {
        return [...prevData, { campaignid: campaignId, selectedValues: selectedValue }];
      }
    });
  };

  const handleOrganicTrackingTagData = (campaignId:any, selectedValue:any) => {
    const existingOrganicTrackingData = selectedOrganicTrackingTag.find((data) => data.campaignid === campaignId);
    setSelectedOrganicTrackingTag(prevData => {
      if (existingOrganicTrackingData) {
        return prevData.map((data) =>
          data.campaignid === campaignId
            ? { ...data, selectedValues: selectedValue }
            : data
        );
      } else {
        return [...prevData, { campaignid: campaignId, selectedValues: selectedValue }];
      }
    });
  };

  const handleConversionGoalChange = (campaignId:any, selectedValue:any) => {
    const existingCoversionData = selectedConversionGoal.find((data) => data.campaignid === campaignId);
    setSelectedConversionGoal(prevData => {
      if (existingCoversionData) {
        return prevData.map((data) =>
          data.campaignid === campaignId
            ? { ...data, selectedGoal: selectedValue }
            : data
        );
      } else {
        return [...prevData, { campaignid: campaignId, selectedGoal: selectedValue }];
      }
    });
  };

  const handleFacebookAccountChange = (campaignId:any, selectedId:any) => {
    const existingFacebookAccount = selectedFacebookDropdownData.find((data) => data.campaignid === campaignId);
    setSelectedFacebookDropdownData(prevData => {
      if (existingFacebookAccount) {
        return prevData.map((data) =>
          data.campaignid === campaignId
            ? { ...data, selectedValue: selectedId }
            : data
        );
      } else {
        return [...prevData, { campaignid: campaignId, selectedValue: selectedId }];
      }
    });
  };

  const handleCustomerGoalChange = (campaignId:any, selectedValue:any) => {
    const existingCustomerGoalData = selectedCustomerGoal.find((data) => data.campaignid === campaignId);
    setSelectedCustomerGoal(prevData => {
      if (existingCustomerGoalData) {
        return prevData.map((data) =>
          data.campaignid === campaignId
            ? { ...data, selectedGoal: selectedValue }
            : data
        );
      } else {
        return [...prevData, { campaignid: campaignId, selectedGoal: selectedValue }];
      }
    });
  };

  const handleTrackingTagValues = (selectedValue:any, campaignId:any, trackingKey:any, arrayId:any) => {
    const existingTackingTagData = selectedTrackingTagData.find((data) => data.campaignid === campaignId && data.trackingTagKey === trackingKey);
    const updatedData = existingTackingTagData
      ? selectedTrackingTagData.map((data) =>
          data.campaignid === campaignId && data.trackingTagKey === trackingKey
            ? { ...data, trackingTagValues: selectedValue, arrayId: arrayId }
            : data
        )
      : [...selectedTrackingTagData, { campaignid: campaignId, trackingTagKey: trackingKey, trackingTagValues: selectedValue, arrayId: arrayId }];
    const dataObject = {
        ...Object.fromEntries(
          updatedData
          .filter(data => data.arrayId)
          .map((data) => {
            return [`ads_tracking_${data.arrayId}`, data.trackingTagValues];
          })
        ),
        type: 'custom_report',
        campaign_id: campaignId
      };
    setSelectedTrackingTagData(updatedData);
    setIsVisiLoaderVisible(true);
    CampaignReport.trackingnetworkheraricaldd(dataObject)
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setConversionGoalOptions((prev) => {
          const updatedOptions = prev.map((campaign) => 
            campaign.campaign_id === campaignId 
              ? { ...campaign, trackingTagsData: data.data } 
              : campaign
          );
          return updatedOptions;
        });
        setIsVisiLoaderVisible(false);
      }else{
        setIsVisiLoaderVisible(false);
        setErrorMessage(data.message);
        seterrorCloseModal(true);
        setShowErrorHeader(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
      setIsVisiLoaderVisible(false);
    })
  };

  const handleCustomTagValues = (selectedValue:any, campaignId:any, customTagKey:any, arrayId:any) => {
    const existingCustomTagData = selectedCustomTagData.find((data) => data.campaignid === campaignId && data.customTagKey === customTagKey);
    const updatedData = existingCustomTagData
      ? selectedCustomTagData.map((data) =>
          data.campaignid === campaignId && data.customTagKey === customTagKey
            ? { ...data, customTagValues: selectedValue, arrayId: arrayId }
            : data
        )
      : [...selectedCustomTagData, { campaignid: campaignId, customTagKey: customTagKey, customTagValues: selectedValue, arrayId: arrayId }];
    const selctedValues = Object.fromEntries(
      updatedData
      .filter(data => data.arrayId)
      .map((data) => {
        return [data.arrayId, data.customTagValues];
      })
    );
    const dataObject = {
        ads_custom_array: selctedValues,
        type: 'custom_report',
        campaign_id: campaignId
      };
    setSelectedCustomTagData(updatedData);
    console.log(dataObject);
    setIsVisiLoaderVisible(true);
    CampaignReport.customnetworkheraricaldd(dataObject)
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        Object.entries(data.data).forEach(([customTagName, customTagData], customTagIndex) => {
          setConversionGoalOptions((prev) => {
            const updatedOptions = prev.map((campaign) =>
              campaign.campaign_id === campaignId
                ? {
                    ...campaign,
                    customTagData: {
                      ...campaign.customTagData,
                      [customTagName]: customTagData
                    }
                  }
                : campaign
            );
            return updatedOptions;
          });
        });
        setIsVisiLoaderVisible(false);
      }else{
        setIsVisiLoaderVisible(false);
        setErrorMessage(data.message);
        seterrorCloseModal(true);
        setShowErrorHeader(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
      setIsVisiLoaderVisible(false);
    })
  };
  /*END HANDLING DROPDOWN VALUE CHANGE*/

  /*START FETCH ALL TRAFFIC DATA*/
  useEffect(() => {
    if (reportId && reportId !== '') {
        fetchTrafficTableData({ report_id: reportId || updateReportId });
    }
  }, [reportId]);

  const fetchTrafficTableData = (dataObject:any) => {
    setReportLoader(true);   
    CampaignReport.getcampaignreportdata(dataObject)
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){        
        const allCampaignData = data.data.campaign;
        setPieChartSourceData(data.data.pie_chart_source_data);
        setPieChartSourceColors(data.data.pie_chart_source_color);
        const allTrafficData = [];
        for (const key in allCampaignData) {
          if (allCampaignData.hasOwnProperty(key)) {
              const campaignData = {
                  campaign_name: key,
                  campaign_id: allCampaignData[key].campaign_id,
                  campaignTableData: allCampaignData[key].campaign_data,
                  campaignGoalTableData: allCampaignData[key].campaign_goal,
                  trafficTableData: allCampaignData[key].traffic_table,
              };
              allTrafficData.push(campaignData);
          } 
          setAllCampaignTableData(allTrafficData);
            setAllTrafficSourceData(data.data.network_traffic_table.traffic_source);
          setOriginalAllCampaignTableData(allTrafficData);
          const tableNames = ['goals', 'variation', 'source', 'customTag', 'trackingTag', 'trafficSource', 'organicTraffic'];
          tableNames.forEach((table) => {
            setShowTableByIcons((prev) =>
              prev ? prev.some((column) => column.campaign_id === allCampaignData[key].campaign_id && column.tableName === table )
                  ? prev.map((column) => column.campaign_id === allCampaignData[key].campaign_id && column.tableName === table && column.show === true
                        ? { ...column, show: !column.show }
                        : column
                    )
                  : [...prev, { campaign_id: allCampaignData[key].campaign_id, tableName: table, show: false }]
                : [{ campaign_id: allCampaignData[key].campaign_id, tableName: table, show: false }]
            );
          });
          if (allCampaignData[key].traffic_table) {
            const filteredTrafficTable = Object.entries(allCampaignData[key].traffic_table).filter(([trafficKey]) => trafficKey !== 'traffic_source' && trafficKey !== 'custom_tag');

            filteredTrafficTable.forEach(([trafficTableKey, trafficTableData]) => {
              const networkTrafficMap = {
                source: setSourceDataTable,
                organic_traffic: setOrganicTrafficDataTable,
                tracking_tag: setTrackingTagDataTable,
              };

              if (networkTrafficMap[trafficTableKey]) {
                const tableData = (trafficTableData as { td: any[] }).td;
                networkTrafficMap[trafficTableKey]((prev) => ({
                  ...prev,
                  [allCampaignData[key].campaign_id]: {
                    data: [...tableData],
                  },
                }));
              }
            });
          }
          {allCampaignData[key].traffic_table && Object.entries(allCampaignData[key].traffic_table).forEach((trafficTableData, trafficTableIndex) => {
            if(trafficTableData[0] === 'custom_tag') {
              Object.entries((trafficTableData[1] as { td: any[] }).td).forEach(([customTagName, customTagData]) => {
                setCustomTagDataTable((prev) => ({
                  ...prev,
                  [allCampaignData[key].campaign_id]: {
                    ...prev[allCampaignData[key].campaign_id],
                    [customTagName]: customTagData,
                  },
                }));
                setCustomTagColumns((prev) => {
                  const campaignId = allCampaignData[key].campaign_id;
                  const transformedColumns = Object.entries(sourceMetricsColumns).slice(-10).map(([columnKey, column]) => {
                    const columnData = column as { key: string; value: string; show: boolean }; 

                    return columnData.key === 'source_name'
                      ? { ...columnData, key: customTagName, value: customTagName }
                      : columnData;
                  });

                  return {
                    ...prev,
                    [campaignId]: {
                      ...prev[campaignId],
                      [customTagName]: transformedColumns,
                    },
                  };
                });
              });
            } 
            else if(trafficTableData[0] === 'tracking_tag') {
              const thArray = (trafficTableData[1] as { th: any[] }).th;

              thArray.forEach((utmNameValue, index) => {
                const utmNameKey = utmNameValue.toLowerCase().replace(/\s/g, "_").replace(/\./g, "_");

                setTrackingTagColumns((prev) => {
                  const updatedColumns = prev[allCampaignData[key].campaign_id] ? [...prev[allCampaignData[key].campaign_id]] : [];
                  const utmColumnsExists = updatedColumns.some(column => column.key === utmNameKey);
                  if (!utmColumnsExists) {
                    updatedColumns.unshift({ key: utmNameKey, value: utmNameValue, show: true });
                  }
                  return {
                    ...prev,
                    [allCampaignData[key].campaign_id]: updatedColumns,
                  };
                });
              });
            }
          })}          
        }
        if (data.data.network_traffic_table && data.data.network_traffic_table.traffic_source && Object.keys(data.data.network_traffic_table.traffic_source).length > 0) {
          Object.entries(data.data.network_traffic_table.traffic_source).forEach(([trafficName, trafficData]) => {
            const trafficSourceMap = {
              facebook: setFacebookData,
              google: setGoogleData,
              tiktok: setTiktokData,
              youtube: setYoutubeData,
              microsoft: setMicrosoftData,
              applovin: setApplovinData,
            };

            if (trafficSourceMap[trafficName]) {
              const tableData = (trafficData as { td: any[] }).td;
              trafficSourceMap[trafficName](() => [...tableData]);
            }

            const existingTrafficTable = showTrafficSourceTable.find((traffic) => traffic.trafficName === trafficName);
            if(existingTrafficTable){
              setShowTrafficSourceTable((prev) => 
                prev.map((traffic) => 
                  traffic.trafficName === trafficName
                    ? { ...traffic, show: true }
                    : traffic
                )
              );
            }else{
              setShowTrafficSourceTable((prev) => [
                ...prev,
                {show: true, trafficName: trafficName},
              ]);
            }
          });
        }
      } else{
        setErrorMessage(data.message);
        seterrorCloseModal(true);
        setShowErrorHeader(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    })
    .finally(() => {
      setReportLoader(false);
      handleGraphChange(selectedGraphType, selectedGraphCampaign);
    });
  };
  /*END FETCHING ALL TRAFFIC DATA*/

  /*START TEST VARIATION*/
  const handleTestVariation = (campaignId:any, testVariationValue:any) => {
    const testVariationCampaigns = conversionGoalOptions.filter(options => options.test_variation === 'show');
    const result = {
      report_id: reportId || updateReportId,
      test_variations_data: testVariationCampaigns.reduce((acc, options) => {
        if(options.campaign_id === campaignId){
          acc[options.campaign_id] = options.test_variation_value === 0 ? 1 : 0;
        }else{
          acc[options.campaign_id] = options.test_variation_value;
        }
        return acc;
      }, {})
    };
    apiCallCounter++;
    setIsVisiLoaderVisible(true);
    CampaignReport.savetestvariationreport(result)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setTrafficSourceData([]);
          if(selectedVisioptCampaign === campaignId){
            getVisioptCampaignGoalDropDown(campaignId, 'first_campaign');
          }else{
            getVisioptCampaignGoalDropDown(campaignId, 'comparison_campaign')
          }  
          fetchTrafficTableData({ report_id: reportId || updateReportId });
        }else{
          setErrorMessage(data.message);
          seterrorCloseModal(true);
          setShowErrorHeader(true);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      })
      .finally(() => {
        apiCallCounter--;
        if (apiCallCounter === 0) {
          setIsVisiLoaderVisible(false);
        }
      });
  };
  /*END TEST VARIATION*/

  const [behavioralCampaignId, setBehavioralCampaignId] = useState([]);
  const [behavioralFilterModalOpen, setBehavioralFilterModalOpen] = useState(false);


  const logCampaignId = conversionGoalOptions.map(group => group.campaign_id);
  const [isResetStatsModalOpen, setIsResetStatsModalOpen] = useState(false);
  const [resetStatsCampaignId, setResetStatsCampaignId] = useState('');
  const [resetStatsSiteId, setResetStatsSiteId] = useState('');

  const [isCustomerJourneyModalOpen, setIsCustomerJourneyModalOpen] = useState(false);
  const [customerJourneyCampaignId, setCustomerJourneyCampaignId] = useState('');
  const [customerJourneyCampaignGoalId, setCustomerJourneyCampaignGoalId] = useState('');
  const [customerJourneyCampaignGoalType, setCustomerJourneyCampaignGoalType] = useState('');
  const [customerJourneyCampaignSessionIds, setCustomerJourneyCampaignSessionIds] = useState('');
  const [customerJourneyType, setCustomerJourneyType] = useState('');

  const [selectedAttributionModal, setSelectedAttributionModal] = useState('First Click');

/* ATTRIBUTION MODAL*/
  useEffect(() => {
    const selectedAttribution = attributionList.find((attribution) => attribution.selected === "selected");
    if (selectedAttribution) {
      setSelectedAttributionModal(selectedAttribution.attribution_name);
    }
  }, [attributionList]);
/* END ATTRIBUTION MODAL*/
  
  const handleColumnDataClicked = (campaignId:any='', table:any, columnkey:any) => {
    setShowColumnColor((prev) =>
      prev.some((column) => column.campaignid === campaignId && column.tableName === table && column.columnName === columnkey)
        ? prev.map((column) =>
            column.campaignid === campaignId && column.tableName === table && column.columnName === columnkey
              ? { ...column, show: !column.show }
              : column
          )
        : [...prev, { campaignid: campaignId, tableName: table, columnName: columnkey, show: true }]
    );     

    setShowTrafficColumnsColor((prev) =>
      prev.some((column) => column.tableName === table && column.columnName === columnkey)
        ? prev.map((column) =>
            column.tableName === table && column.columnName === columnkey
              ? { ...column, show: !column.show }
              : column
          )
        : [...prev, { tableName: table, columnName: columnkey, show: true }]
    );
  };

/* BEHAVIORAL DIMENSION*/ 
  const handleBehavioralFilterOpenModal = (value:any) => {
    setBehavioralCampaignId(value);
    setBehavioralFilterModalOpen(true);
  }

  const handleBehavioralFilterCloseModal = () => {
    setBehavioralFilterModalOpen(false);
  };
/* END BEHAVIORAL DIMENSION*/

/* TIME PICKER AND DATE PICKER  */
  const handleselectedTimePickerComponentChange = (Value:any) => {
    setSelectedTimePicker(Value);
  }

  const handleselectedTimePickerToDateChange = (Value:any) => {
    setSelectedTimePickerToDate(Value);
  }

  const handleselectedTimePickerFromDateChange = (Value:any) => {
    setSelectedTimePickerFromDate(Value);
  }

  const saveTimeReport = (reportId:any, reportTime:any) => {
    CampaignReport.savetimereport({
        report_id: reportId || updateReportId,
        report_time: reportTime,
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if (data.status === 1) {
          if(!reportLoader) {
            setTrafficSourceData([]);
            fetchTrafficTableData({ report_id: reportId || updateReportId });
          }
        } else {
          setErrorMessage(data.message);
          seterrorCloseModal(true);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
  }

  const isValidDateRangeFormat = (date) => {
    const regex = /^\d{1,2}\/\d{1,2}\/\d{4}#\d{1,2}\/\d{1,2}\/\d{4}$/;
    return regex.test(date);
  };

  const formattedDate = (date) => {
    const [day, month, year] = date.split('-');
    return `${month}/${day}/${year}`;
  };

  const convertFormattedDate = (date) => {
    const [month, day, year] = date.split('/');
    return `${day}-${month}-${year}`;
  };

  const getTodayDate = () => {
    if(isValidDateRangeFormat(selectedDate) && !selectedTimePickerFromDate) {
      const [startDate] = selectedDate.split('#');
      const selectedFromDate = convertFormattedDate(startDate);
      const [day, month, year] = selectedFromDate.split('-');
      return `${year}-${month}-${day}`; 
    }else if(selectedTimePickerFromDate){
      const [day, month, year] = selectedTimePickerFromDate.split('-');
      return `${year}-${month}-${day}`; 
    }else{
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }    
  };

  const getEndDate = () => {
    if(isValidDateRangeFormat(selectedDate) && !selectedTimePickerToDate) {
      const [, endDate] = selectedDate.split('#');
      const selectedEndDate = convertFormattedDate(endDate);
      const [day, month, year] = selectedEndDate.split('-');
      return `${year}-${month}-${day}`; 
    }else if(selectedTimePickerToDate){
      const [day, month, year] = selectedTimePickerToDate.split('-');
      return `${year}-${month}-${day}`;
    }else{
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }    
  };

  useEffect(() => {
    if(selectedTimePicker && (reportId || updateReportId)){
      if(selectedTimePicker !== -2 && selectedDate){
        saveTimeReport(reportId, selectedTimePicker);
      }
    }
  }, [selectedTimePicker]);

  useEffect(() => {
    if(selectedTimePicker === -2 && selectedTimePickerFromDate && selectedTimePickerToDate && (reportId || updateReportId)) {
      const formattedStart = formattedDate(selectedTimePickerFromDate);
      const formattedEnd = formattedDate(selectedTimePickerToDate);
      const finalformattedDate = `${formattedStart}#${formattedEnd}`;
      saveTimeReport(reportId, finalformattedDate);
    }
  }, [selectedTimePickerFromDate, selectedTimePickerToDate]);

/* END TIME PICKER AND DATE PICKER  */
/* RESET STATS */
  const handleResetStatsModalOpen = (campaignId:any,siteId:any) => {
    setResetStatsCampaignId(campaignId);
    setResetStatsSiteId(siteId);
    setIsResetStatsModalOpen(true);
  };

  const handleResetStatsCloseModal = (value:any) => {
    setIsResetStatsModalOpen(false);
  };
/* END RESET STATS */

  const handlerrorCloseModal = () => {
    seterrorCloseModal(false);
  };

/* Customer Journey*/
  const [emailValue, setEmailValue] = useState('');
  const handleCustomerJourneyOpenModal = (campaign_id:any='',goalTable:any,value:any,type:any) => {
    setCustomerJourneyCampaignId(campaign_id);
    setCustomerJourneyType(type);
    if(type === "goal"){
      setCustomerJourneyCampaignGoalId(goalTable.campaign_goal_id);
      setCustomerJourneyCampaignGoalType(goalTable.campaign_goal_type);
    }
    if(type === "organicTraffic"){
      setCustomerJourneyCampaignGoalId(goalTable.campaign_goal_id);
      setCustomerJourneyCampaignSessionIds(goalTable.session_ids);
    }
    if(type === "traffic"){
      const goalId = selectedConversionGoal.find(data => data.campaignid === selectedVisioptCampaign)?.selectedGoal;
      setCustomerJourneyCampaignGoalId(goalId);
      setCustomerJourneyCampaignSessionIds(goalTable.session_ids);
    }
    if(value > 0){
      setIsCustomerJourneyModalOpen(true);
    }
  };

  const handleCustomerJourneyCloseModal = () => {
    setEmailValue('')
    setIsCustomerJourneyModalOpen(false);
  };
/* END Customer Journey */

  const handleUpdateReport = (value:any) => {
    handleReportData(value);
  };

  const reportTimeValue = () => {
    if(selectedTimePicker === -2 && isValidDateRangeFormat(selectedDate) && !selectedTimePickerFromDate && !selectedTimePickerToDate){
      return selectedDate;
    }else if(selectedTimePicker === -2 && selectedTimePickerFromDate && selectedTimePickerToDate) {
      const formattedStart = formattedDate(selectedTimePickerFromDate);
      const formattedEnd = formattedDate(selectedTimePickerToDate);
      const finalformattedDate = `${formattedStart}#${formattedEnd}`;
      return finalformattedDate;
    }else{
      return selectedTimePicker;
    }
  };

    const formattedCampaignGoalDrop = selectedConversionGoal.reduce((acc, data) => {
    const { campaignid, selectedGoal } = data;
    if (selectedGoal !== null && selectedGoal !== undefined) {
      if (campaignid) {
        acc[campaignid] = selectedGoal;
      }
    }
    return acc;
    }, {});
    
    const formattedTrackingGoal = selectedCustomerGoal.reduce((acc, data) => {
      const { campaignid, selectedGoal } = data;
      if (selectedGoal !== null && selectedGoal !== undefined) {
        if (!acc[campaignid]) {
          acc[campaignid] = {};
        }
        acc[campaignid] = selectedGoal;
      }
      return acc;
    }, {});

    const formattedFacebookAdsNetworksId = selectedFacebookDropdownData.reduce((acc, data) => {
      const { campaignid, selectedValue } = data;
      if (!acc[campaignid]) {
        acc[campaignid] = {};
      }
      acc[campaignid]['facebook'] = selectedValue;
      return acc;
    }, {});

    const formattedTrackingNetwork = selectedTrackingTagData.reduce((acc, data) => {
      const { campaignid, arrayId, trackingTagValues } = data;
      if (Array.isArray(trackingTagValues) && trackingTagValues.length > 0) {
        if (!acc[campaignid]) {
          acc[campaignid] = {};
        }
        acc[campaignid][arrayId] = trackingTagValues;
      }
      return acc;
    }, {});

    const formattedCustomTags = selectedCustomTagData.reduce((acc, data) => {
      const { campaignid, arrayId, customTagValues } = data;
      if (Array.isArray(customTagValues) && customTagValues.length > 0) {
        if (!acc[campaignid]) {
          acc[campaignid] = {};
        }
        acc[campaignid][arrayId] = customTagValues;
      }
      return acc;
    }, {});

    const formattedSource = selectedSourceData.reduce((acc, data) => {
      const { campaignid, sourceValues } = data;
      if (Array.isArray(sourceValues) && sourceValues.length > 0) {
        if (!acc[campaignid]) {
          acc[campaignid] = {};
        }
        acc[campaignid] = sourceValues;
      }
      return acc;
    }, {});

    const formattedExcludeOrganicCustomTag = selectedOrganicCustomTag.reduce((acc, data) => {
      const { campaignid, selectedValues } = data;
      if (Array.isArray(selectedValues) && selectedValues.length > 0) {
        if (!acc[campaignid]) {
          acc[campaignid] = {};
        }
        acc[campaignid] = selectedValues;
      }
      return acc;
    }, {});

    const formattedExcludeOrganicTrackingTag = selectedOrganicTrackingTag.reduce((acc, data) => {
      const { campaignid, selectedValues } = data;
      if (Array.isArray(selectedValues) && selectedValues.length > 0) {
        if (!acc[campaignid]) {
          acc[campaignid] = {};
        }
        acc[campaignid] = selectedValues;
      }
      return acc;
    }, {});

    const testVariationCampaigns = conversionGoalOptions.filter(options => options.test_variation === 'show');
    const testVariationData = testVariationCampaigns.reduce((acc, options) => {
      acc[options.campaign_id] = options.test_variation_value;
      return acc;
    }, {});

  const handleReportData = (value:any) => {
    if(value === 'save'){
      CampaignReport.savereportdata({
        report_id: reportId || updateReportId,
        first_campaign: selectedVisioptCampaign,
        comparision_campaign: comparisonVisiCampaign,
        attribution_name: selectedAttributionModal,
        ads_tracking_network: formattedTrackingNetwork,
        ads_custom_network: formattedCustomTags,
        source: formattedSource,
        campaign_goal_drop: formattedCampaignGoalDrop,
        exclude_organic_custom_tag: formattedExcludeOrganicCustomTag,
        exclude_organic_tracking_tag: formattedExcludeOrganicTrackingTag,
        ads_networks_id: formattedFacebookAdsNetworksId,
        tracking_goal: formattedTrackingGoal,
        report_name: reportName,
        report_time: reportTimeValue(),
        show_all_ad: showAllAdCosts === true ? 1 : 0
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if (data.status === 1) {
          handleSaveReportModalCloseModal();
          if(reportId) {
            getVisioptCampaignGoalDropDown(selectedVisioptCampaign, 'first_campaign');
            fetchTrafficTableData({ report_id: reportId || updateReportId });
          }else{
            reportId = data.data.report_id;
            setUpdateReportId(data.data.report_id);
            setShowUpdateButton(true);
            fetchCampaignList();
            getVisioptCampaignGoalDropDown(selectedVisioptCampaign, 'first_campaign');
            fetchTrafficTableData({ report_id: data.data.report_id || updateReportId });
          }         
          notifications.show({
            color: 'green',
            icon: <IconCheck size="1.1rem" />,
            title: 'Success',
            message: data.message,
          });
        } else {
          setErrorMessage(data.message);
          seterrorCloseModal(true);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
    };

    if(value === 'update'){
      const dataObject = {
        report_id: reportId || updateReportId,
        type: 'update',
        first_campaign: selectedVisioptCampaign,
        comparision_campaign: comparisonVisiCampaign,
        attribution_name: selectedAttributionModal,
        ads_tracking_network: formattedTrackingNetwork,
        ads_custom_network: formattedCustomTags,
        source: formattedSource,
        campaign_goal_drop: formattedCampaignGoalDrop,
        exclude_organic_custom_tag: formattedExcludeOrganicCustomTag,
        exclude_organic_tracking_tag: formattedExcludeOrganicTrackingTag,
        ads_networks_id: formattedFacebookAdsNetworksId,
        tracking_goal: formattedTrackingGoal,
        report_time: reportTimeValue(),
        test_variations_data: testVariationData,
        show_all_ad: showAllAdCosts === true ? 1 : 0
      };
      fetchTrafficTableData(dataObject);
    };
  };

  const handleExportData = () => {
    if((reportId && reportId !== '') || updateReportId !== ''){
      const csvRows = [];
      setIsVisiLoaderVisible(true);
      CampaignReport.getcampaignreportdata({
        report_id: reportId || updateReportId,
        export: 1,
        first_campaign: selectedVisioptCampaign,
        comparision_campaign: comparisonVisiCampaign,
        attribution_name: selectedAttributionModal,
        ads_tracking_network: formattedTrackingNetwork,
        ads_custom_network: formattedCustomTags,
        source: formattedSource,
        campaign_goal_drop: formattedCampaignGoalDrop,
        exclude_organic_custom_tag: formattedExcludeOrganicCustomTag,
        exclude_organic_tracking_tag: formattedExcludeOrganicTrackingTag,
        ads_networks_id: formattedFacebookAdsNetworksId,
        tracking_goal: formattedTrackingGoal,
        report_time: reportTimeValue(),
        test_variations_data: testVariationData,
        show_all_ad: showAllAdCosts === true ? 1 : 0
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
            setIsVisiLoaderVisible(false);
          }
        }
      })
      .then(data => {
        if (data.status === 1) {        
          const allCampaignData = data.data.campaign;
          const allTrafficData = [];
          for (const key in allCampaignData) {
            if (allCampaignData.hasOwnProperty(key)) {
                const campaignData = {
                    campaign_name: key,
                    campaign_id: allCampaignData[key].campaign_id,
                    campaignTableData: allCampaignData[key].campaign_data,
                    campaignGoalTableData: allCampaignData[key].campaign_goal,
                    trafficTableData: allCampaignData[key].traffic_table,
                };
                allTrafficData.push(campaignData);
            }
          }
          allTrafficData.forEach((campaignData, campaignIndex) => {
            const campaignDataKeys = Object.values(generalColumns);
            csvRows.push(campaignDataKeys.map(header => header.value).join(','));
            csvRows.push(campaignDataKeys.map(header => campaignData.campaignTableData[header.key] || '').join(','));
            csvRows.push('');

            if(showGoalTable) {
              showGoalTable.forEach((goals) => {
                if(goals.campaignid === campaignData.campaign_id && goals.show === true) {
                  const goalTableKeys = Object.values(goalColumns.slice(1));
                  campaignData.campaignGoalTableData.forEach((goalTable, goalIndex) => {              
                    const firstKey = Object.keys(goalTable)[0];
                    const goalTableHeaders = goalTableKeys.map(header => header.value).join(',');
                    csvRows.push(`${firstKey},${goalTableHeaders}`);
                    const firstValue = goalTable[firstKey];
                    const goalTableValue = goalTableKeys.map(header => goalTable[header.key] || '').join(',');
                    csvRows.push(`${firstValue},${goalTableValue}`);
                    csvRows.push('');
                  });
                }
              })
            }

            if (showTestVariation) {
              showTestVariation.forEach((variations) => {
                if (variations.campaignid === campaignData.campaign_id && variations.show === true) {
                  const testVariationKeys = Object.values(testVariationColumns);
                  Object.entries(campaignData.campaignGoalTableData).forEach(([goalName, goalData]) => {
                    Object.entries(goalData).forEach(([variationName, variationData]) => {
                      csvRows.push(variationName);
                      csvRows.push(testVariationKeys.map(header => header.value).join(','));
                      if (Array.isArray(variationData) && variationData.length > 0) {
                        variationData.forEach((rowData) => {
                          csvRows.push(testVariationKeys.map(header => rowData[header.key] || '').join(','));
                        });
                      }
                      csvRows.push('');
                    });
                  });
                }
              });
            }

            if(campaignData.trafficTableData !== null && campaignData.trafficTableData.source && Object.entries(campaignData.trafficTableData.source).length > 0) {
              const sourceKeys: { key: string; value: string; show: boolean }[] = Object.values(sourceColumns[campaignData.campaign_id]?.slice(-9));
              csvRows.push(sourceKeys.map(header => header.value).join(','));
              sourceDataTable[campaignData.campaign_id]?.data.forEach((rowData, rowIndex) => {
                csvRows.push(sourceKeys.map(header => rowData[header.key] || '').join(','));
              });
              csvRows.push('');
            }

            if(campaignData.trafficTableData !== null && campaignData.trafficTableData.tracking_tag && Object.entries(campaignData.trafficTableData.tracking_tag).length > 0) {
              const trackingTagKeys: { key: string; value: string; show: boolean }[] = Object.values(trackingTagColumns[campaignData.campaign_id]?.filter((column) => column.key !== 'source_name'));
              csvRows.push(trackingTagKeys.map(header => header.value).join(','));
              trackingTagDataTable[campaignData.campaign_id]?.data.forEach((rowData, rowIndex) => {
                csvRows.push(trackingTagKeys.map(header => rowData[header.key] || '').join(','));
              });
              csvRows.push('');
            }

            if(campaignData.trafficTableData !== null && campaignData.trafficTableData.custom_tag && Object.entries(campaignData.trafficTableData.custom_tag.th).length > 0) {
              Object.entries(campaignData.trafficTableData.custom_tag.th).forEach((customTagName, customTagIndex) => {
                const customTagKeys: { key: string; value: string; show: boolean }[] = Object.values(customTagColumns[campaignData.campaign_id]?.[customTagName[0]]);
                csvRows.push(customTagKeys.map(header => header.value).join(','));
                customTagDataTable[campaignData.campaign_id]?.[customTagName[0]]?.forEach((rowData, rowIndex) => {
                  csvRows.push(customTagKeys.map(header => rowData[header.key] || '').join(','));
                });
              })        
              csvRows.push('');
            }

            if(campaignData.trafficTableData !== null && campaignData.trafficTableData.organic_traffic && Object.entries(campaignData.trafficTableData.organic_traffic).length > 0) {
              const organicTrafficKeys: { key: string; value: string; show: boolean }[] = Object.values(organicTrafficColumns[campaignData.campaign_id]);
              csvRows.push(organicTrafficKeys.map(header => header.value).join(','));
              organicTrafficDataTable[campaignData.campaign_id]?.data.forEach((rowData, rowIndex) => {
                csvRows.push(organicTrafficKeys.map(header => rowData[header.key] || '').join(','));
              });
              csvRows.push('');
            }
          })

          if (data.data.network_traffic_table && data.data.network_traffic_table.traffic_source && Object.keys(data.data.network_traffic_table.traffic_source).length > 0) {
            Object.entries(data.data.network_traffic_table.traffic_source).forEach(([trafficName, trafficData]) => {
              const tableKeys = (trafficData as { th: any[] }).th;
              const tableData = (trafficData as { td: any[] }).td;
              const keysName = tableKeys.map(header => header.toLowerCase().replace(/\s/g, "_").replace(/\./g, "_"));
              csvRows.push(tableKeys.join(','));
              tableData.forEach(rowData => {
                const row = keysName.map(header => rowData[header] || '');
                csvRows.push(row.join(','));
              });
            });
          }
          setIsVisiLoaderVisible(false);
          const csvContent = csvRows.join('\n');
          const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'exported_campaign_report.csv');
          document.body.appendChild(link);
          link.click(); 
          document.body.removeChild(link);
        }else{
          setIsVisiLoaderVisible(false);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
        setIsVisiLoaderVisible(false);
      });
    }
    
  };

  /*START BACK TO CAMPAIGN BUTTON*/
  const handleRemoveCampaignBackButton = (trafficName:any) => {
    const filteredFacebookBtn = showBacktoCampButton.filter((config) => config.trafficName === trafficName ? null : config);
    setShowBacktoCampButton(filteredFacebookBtn);

    const filteredAdsetBtn = showBacktoAdsetButton.filter((config) => config.trafficName === trafficName ? null : config);
    setShowBacktoAdsetButton(filteredAdsetBtn);

    const existingAdsTable = showAdsTable.find((ads) => ads.trafficName === trafficName);
    if(existingAdsTable){
      setShowAdsTable((prev) => 
        prev.map((ads) => 
          ads.trafficName === trafficName
            ? { ...ads, show: false }
            : ads
        )
      );
    } 
    const existingAdsetTable = showAdsetTable.find((adset) => adset.trafficName === trafficName);
    if(existingAdsetTable){
      setShowAdsetTable((prev) => 
        prev.map((adset) => 
          adset.trafficName === trafficName
            ? { ...adset, show: false }
            : adset
        )
      );
    } 

    const existingTrafficTable = showTrafficSourceTable.find((traffic) => traffic.trafficName === trafficName);
    if(existingTrafficTable){
      setShowTrafficSourceTable((prev) => 
        prev.map((traffic) => 
          traffic.trafficName === trafficName
            ? { ...traffic, show: true }
            : traffic
        )
      );
    }
  };
  /*END BACK TO CAMPAIGN BUTTON*/

  /*START BACK TO ADSET BUTTON*/
  const handleRemoveAdsetBackButton = (trafficName:any) => {
    const filteredAdsetBtn = showBacktoAdsetButton.filter((config) => config.trafficName === trafficName ? null : config);
    setShowBacktoAdsetButton(filteredAdsetBtn);

    const existingAdsTable = showAdsTable.find((ads) => ads.trafficName === trafficName);
    if(existingAdsTable){
      setShowAdsTable((prev) => 
        prev.map((ads) => 
          ads.trafficName === trafficName
            ? { ...ads, show: false }
            : ads
        )
      );
    } 
    const existingAdsetTable = showAdsetTable.find((adset) => adset.trafficName === trafficName);
    if(existingAdsetTable){
      setShowAdsetTable((prev) => 
        prev.map((adset) => 
          adset.trafficName === trafficName
            ? { ...adset, show: true }
            : adset
        )
      );
    } 

    const existingTrafficTable = showTrafficSourceTable.find((traffic) => traffic.trafficName === trafficName);
    if(existingTrafficTable){
      setShowTrafficSourceTable((prev) => 
        prev.map((traffic) => 
          traffic.trafficName === trafficName
            ? { ...traffic, show: false }
            : traffic
        )
      );
    }
  };
  /*END BACK TO ADSET BUTTON*/

  /*START FILTER TABLES BY COLUMNS*/
  const handleFilterByChange = (value:any) => {
    setFilterByValue(value);
    handleFilterCampaignTables();
  };

  const handleFilterFromChange = (value: any) => {
    if (!isNaN(value) || value === '') {
      setFilterFromValue(value);
    }
  };

  const handleFilterToChange = (value: any) => {    
    if (!isNaN(value) || value === '') {
      setFilterToValue(value);
    }
  };

  const getFilteredData = (data:any) => {
    const fromvalue = parseFloat(filterFromValue.replace(/,/g, ""));
    const toValue = parseFloat(filterToValue.replace(/,/g, ""));    
    const filteredData = data?.filter((rowData) => {
      const filteredValueData = rowData[filterByValue];
      if (filteredValueData !== null && filteredValueData !== undefined) {
        const filterValue = parseFloat(filteredValueData.toString().replace(/,/g, ""));
        return (
          !isNaN(filterValue) &&
          filterValue >= fromvalue &&
          filterValue <= toValue
        );
      }else{
        return data;
      }      
    });
    return filteredData;
  }

  const handleFilterCampaignTables = () => {   
    const trafficSourceSetMap = {
      facebook: setFacebookData,
      google: setGoogleData,
      microsoft: setMicrosoftData,
      tiktok: setTiktokData,
      youtube: setYoutubeData,
      applovin: setApplovinData,
    };

    const trafficAdsetMap = {
      facebook: setFacebookAdsetData,
      google: setGoogleAdsetData,
      tiktok: setTiktokAdsetData,
      youtube: setYoutubeAdsetData,
      microsoft: setMicrosoftAdsetData,
      applovin: setApplovinAdsetData,
    };

    const trafficAdsMap = {
      facebook: setFacebookAdsData,
      google: setGoogleAdsData,
      tiktok: setTiktokAdsData,
      youtube: setYoutubeAdsData,
      microsoft: setMicrosoftAdsData,
    };

    if (filterByValue !== '' && filterFromValue !== '' && filterToValue !== '') {
      originalAllCampaignTableData.forEach((campaignData) => {
        /*FILTER TRACKING TAG DATA*/
        const trackingTagAllData = campaignData.trafficTableData?.tracking_tag?.td;
        const filteredTrackingTagData = getFilteredData(trackingTagAllData);
        setTrackingTagDataTable((prev) => ({
          ...prev,
          [campaignData.campaign_id]: {
            data: filteredTrackingTagData,
          },
        }));

        /*FILTER SOURCE DATA*/
        const sourceAllData = campaignData.trafficTableData?.source?.td;
        const filteredSourceData = getFilteredData(sourceAllData);
        setSourceDataTable((prev) => ({
          ...prev,
          [campaignData.campaign_id]: {
            data: filteredSourceData,
          },
        }));

        /*FILTER CUSTOM TAG DATA*/
        const customTagAllData = campaignData.trafficTableData?.custom_tag?.td;
        if(customTagAllData !== undefined){
          Object.entries(customTagAllData)?.forEach(([customTagName, customTagData]) => {
            const customData = customTagData as any[];
            const filteredCustomTagData = getFilteredData(customData);
            setCustomTagDataTable((prev) => ({
              ...prev,
              [campaignData.campaign_id]: {
                [customTagName]: filteredCustomTagData,
              },
            }));
          });
        }  

        /*FILTER ORGANIC TRAFFIC DATA*/    
        const organicTrafficAllData = campaignData.trafficTableData?.organic_traffic?.td;
        const filteredOrganicTrafficTableData = getFilteredData(organicTrafficAllData);
        setOrganicTrafficDataTable((prev) => ({
          ...prev,
          [campaignData.campaign_id]: {
            data: filteredOrganicTrafficTableData,
          },
        }));
      });  

      /*FILTER TRAFFIC SOURCE TABLES DATA*/
      const namesArray = ['facebook', 'google', 'microsoft', 'tiktok', 'youtube', 'applovin'];
      if(allTrafficSourceData !== undefined && (allTrafficSourceData.length > 0 || Object.keys(allTrafficSourceData).length > 0)){
        namesArray.forEach((name) => {
          const data = allTrafficSourceData[name]?.td;
          const filteredData = getFilteredData(data);
          trafficSourceSetMap[name](filteredData);
        });
      }
      if(trafficAdsetData !== undefined && (trafficAdsetData.length > 0 || Object.keys(trafficAdsetData).length > 0)){
        namesArray.forEach((name) => {
          const data = trafficAdsetData[name]?.td;
          const filteredData = getFilteredData(data);
          trafficAdsetMap[name](filteredData);
        });
      }
      if(adsetAdsData !== undefined && (adsetAdsData.length > 0 || Object.keys(adsetAdsData).length > 0)){
        namesArray.forEach((name) => {
          const data = adsetAdsData[name]?.td;          
          const filteredData = getFilteredData(data);
          trafficAdsMap[name](filteredData);
        });
      }
    }else{
      originalAllCampaignTableData.forEach((campaignData) => {
        if(campaignData.trafficTableData !== null){
          const filteredTrafficTable = Object.entries(campaignData.trafficTableData).filter(([trafficKey]) => trafficKey !== 'traffic_source' && trafficKey !== 'custom_tag');
          filteredTrafficTable.forEach(([trafficTableKey, trafficTableData]) => {
            const networkTrafficMap = {
              source: setSourceDataTable,
              organic_traffic: setOrganicTrafficDataTable,
              tracking_tag: setTrackingTagDataTable,
            };

            if (networkTrafficMap[trafficTableKey]) {
              const tableData = (trafficTableData as { td: any[] }).td;
              networkTrafficMap[trafficTableKey]((prev) => ({
                ...prev,
                [campaignData.campaign_id]: {
                  data: [...tableData],
                },
              }));
            }
          });
        }
      });

      Object.entries(allTrafficSourceData).forEach(([trafficName, trafficData]) => {
        if (trafficSourceSetMap[trafficName]) {
          const tableData = (trafficData as { td: any[] }).td;
          trafficSourceSetMap[trafficName](() => [...tableData]);
        } 
      });

      Object.entries(trafficAdsetData).forEach(([trafficName, trafficData]) => {
        if (trafficAdsetMap[trafficName]) {
          const tableData = (trafficData as { td: any[] }).td;
          trafficAdsetMap[trafficName](() => [...tableData]);
        } 
      });

      Object.entries(adsetAdsData).forEach(([trafficName, trafficData]) => {
        if (trafficAdsMap[trafficName]) {
          const tableData = (trafficData as { td: any[] }).td;
          trafficAdsMap[trafficName](() => [...tableData]);
        } 
      });
    }
  };
  /*END FILTER TABLES BY COLUMNS*/

  const handleShowIcons = (campaignId:any, table:any) => {
    setShowTableByIcons((prev) =>
      prev.some((column) => column.campaign_id === campaignId && column.tableName === table)
        ? prev.map((column) =>
            column.campaign_id === campaignId && column.tableName === table
              ? { ...column, show: !column.show }
              : column
          )
        : [...prev, { campaign_id: campaignId, tableName: table, show: true }]
    );
  };

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    const iconRefMapping = {
      332: createReportRef,
      333: campaignRef,
      334: comparisonCampaignRef,
      316: attributionRef,
      182: saveReportRef,
      335: showAllAdIconRef
    };
    const iconRef = iconRefMapping[id];
    if (iconRef.current) {
      const { top, left, width } = iconRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        [id]: {top: top + scrollOffset, left: left + width + 10},
      })
    }
  };

  const fetchGraphCampaignList = () => {
    CampaignReport.getcampaigndropdown({
      campaign_id: selectedCampaigns
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        setGraphCampaignList(data.data.campaign_drop_list);
        setShowGraphCampaignDropdown(data.data.campaign_drop_display);
        const selectedCampaign = data.data.campaign_drop_list.find((campaign) => campaign.selected === 'selected')?.campaign_id;
        setSelectedGraphCampaign(selectedCampaign);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  const handleGraphCampaignChange = (value:any) => {
    setSelectedGraphCampaign(value);
    handleGraphChange(selectedGraphType, value);
  };

  const filterSearchData = (totalData:any, data:any, searchOn:any, value:any) => {
    const totalRow = totalData.filter(row => row['traffic_source'] === 'Total');
    const filteredData = data.filter(row => row['traffic_source'] !== 'Total').filter(row => row[searchOn]?.toString().toLowerCase().includes(value.toLowerCase()));
    const finalData = filteredData.concat(totalRow);
    return finalData;
  };

  const handleSearch = (value: any, trafficName:any, tableName: any, searchOn: any) => {
    const searchMapValue = {
      campaign_name: campaignSearchValue,
      adset_name: adsetSearchValue,
      ad_name: adsSearchValue
    };
    const searchMapSetValue = {
      campaign_name: setCampaignSearchValue,
      adset_name: setAdsetSearchValue,
      ad_name: setAdsSearchValue
    };
    const existingTrafficTable = searchMapValue[searchOn].find((traffic) => traffic.trafficName === trafficName && traffic.tableName === tableName);
    if(existingTrafficTable){
      searchMapSetValue[searchOn]((prev) => 
        prev.map((traffic) => 
          traffic.trafficName === trafficName && traffic.tableName === tableName
            ? { ...traffic, value: value }
            : traffic
        )
      );
    }else{
      searchMapSetValue[searchOn]((prev) => [
        ...prev,
        {value: value, trafficName: trafficName, tableName: tableName},
      ]);
    }

    const trafficSourceSetMap = {
      facebook: setFacebookData,
      facebookAdset: setFacebookAdsetData,
      facebookAds: setFacebookAdsData,
      google: setGoogleData,
      googleAdset: setGoogleAdsetData,
      googleAds: setGoogleAdsData,
      microsoft: setMicrosoftData,
      microsoftAdset: setMicrosoftAdsetData,
      microsoftAds: setMicrosoftAdsData,
      tiktok: setTiktokData,
      tiktokAdset: setTiktokAdsetData,
      tiktokAds: setTiktokAdsData,
      youtube: setYoutubeData,
      youtubeAdset: setYoutubeAdsetData,
      youtubeAds: setYoutubeAdsData,
      applovin: setApplovinData,
      applovinAdset: setApplovinAdsetData,
    };

    const trafficSourceMap = {
      facebook: facebookData,
      facebookAdset: facebookAdsetData,
      facebookAds: facebookAdsData,
      google: googleData,
      googleAdset: googleAdsetData,
      googleAds: googleAdsData,
      microsoft: microsoftData,
      microsoftAdset: microsoftAdsetData,
      microsoftAds: microsoftAdsData,
      tiktok: tiktokData,
      tiktokAdset: tiktokAdsetData,
      tiktokAds: tiktokAdsData,
      youtube: youtubeData,
      youtubeAdset: youtubeAdsetData,
      youtubeAds: youtubeAdsData,
      applovin: applovinData,
      applovinAdset: applovinAdsetData,
    };

    let campaignSearchTrafficValue = '';
    let adsetSearchTrafficValue = '';
    let adsSearchTrafficValue = '';
    if(campaignSearchValue && campaignSearchValue.length > 0){
      const matchCampaignValue = campaignSearchValue.find((traffic) => traffic.trafficName === trafficName && traffic.tableName === tableName);
      campaignSearchTrafficValue = matchCampaignValue ? matchCampaignValue.value : '';
    }else if(searchOn === 'campaign_name'){
      campaignSearchTrafficValue = value;
    }
    if(adsetSearchValue && adsetSearchValue.length > 0){
      const matchAdsetValue = adsetSearchValue.find((traffic) => traffic.trafficName === trafficName && traffic.tableName === tableName);
      adsetSearchTrafficValue = matchAdsetValue ? matchAdsetValue.value : '';
    }else if(searchOn === 'adset_name'){
      adsetSearchTrafficValue = value;
    }
    if(adsSearchValue && adsSearchValue.length > 0){
      const matchAdsValue = adsSearchValue.find((traffic) => traffic.trafficName === trafficName && traffic.tableName === tableName);
      adsSearchTrafficValue = matchAdsValue ? matchAdsValue.value : '';
    }else if(searchOn === 'ad_name'){
      adsSearchTrafficValue = value;
    }

    if(searchOn === 'campaign_name'){
      let finalCampaignData = [];      
      if(!tableName.includes('Adset') && !tableName.includes('Ads')){
        const data = allTrafficSourceData[trafficName]?.td;
        finalCampaignData = filterSearchData(data, data, searchOn, value);
      }else if(tableName.includes('Adset') && adsetSearchTrafficValue === ''){
        const data = trafficAdsetData[trafficName]?.td;
        finalCampaignData = filterSearchData(data, data, searchOn, value);
        setFilteredData(finalCampaignData);
      }else if(tableName.includes('Adset') && adsetSearchTrafficValue !== ''){
        finalCampaignData = filterSearchData(filteredData, filteredData, searchOn, value);
      }else if(tableName.includes('Ads') && adsetSearchTrafficValue === '' && adsSearchTrafficValue === ''){
        const data = adsetAdsData[trafficName]?.td;
        finalCampaignData = filterSearchData(data, data, searchOn, value);
        setFilteredData(finalCampaignData);
      }else if(tableName.includes('Ads') && (adsetSearchTrafficValue !== '' || adsSearchTrafficValue !== '')){
        finalCampaignData = filterSearchData(filteredData, filteredData, searchOn, value);
      }
      trafficSourceSetMap[tableName]((prevData) => {
        const updatedData = finalCampaignData.map((data) => {
          const previousData = lastTrafficSourceData.find((prev) => prev.table === tableName)?.data;
          const matchData = previousData && previousData.length > 0 ? previousData.find((prev) => prev.campaign_name === data.campaign_name) : null;
          return {
            ...data,
            ...(matchData ? { checkbox: matchData.checkbox } : {}),
          };
        });
        return updatedData;
      });
    }
    
    if(searchOn === 'adset_name'){
      let finalAdsetData = [];      
      let searchOnName = trafficName === 'applovin' ? 'ad_name' : searchOn;
      if(campaignSearchTrafficValue === '' && tableName.includes('Adset')){
        const data = trafficAdsetData[trafficName]?.td;
        finalAdsetData = filterSearchData(data, data, searchOnName, value);
        setFilteredData(finalAdsetData);
      }else if(campaignSearchTrafficValue !== '' && tableName.includes('Adset')){
        finalAdsetData = filterSearchData(trafficSourceMap[tableName], filteredData, searchOnName, value);
      }else if(tableName.includes('Ads') && campaignSearchTrafficValue === '' && adsSearchTrafficValue === ''){
        const data = adsetAdsData[trafficName]?.td;
        finalAdsetData = filterSearchData(data, data, searchOnName, value);
        setFilteredData(finalAdsetData);
      }else{
        finalAdsetData = filterSearchData(filteredData, filteredData, searchOnName, value);
      }

      trafficSourceSetMap[tableName]((prevData) => {
        const updatedData = finalAdsetData.map((data) => {
          const matchData = prevData.find((prev) => prev.campaign_name === data.campaign_name);
          return {
            ...data,
            ...(matchData ? { checkbox: matchData.checkbox } : {}),
          };
        });
        return updatedData;
      });
    }

    if(searchOn === 'ad_name'){
      let finalAdsData = [];
      if(campaignSearchTrafficValue === '' && adsetSearchTrafficValue === ''){
        const data = adsetAdsData[trafficName]?.td;
        finalAdsData = filterSearchData(data, data, searchOn, value);
        setFilteredData(finalAdsData);
      }else{
        finalAdsData = filterSearchData(trafficSourceMap[tableName], filteredData, searchOn, value);
      }
      trafficSourceSetMap[tableName]((prevData) => {
        const updatedData = finalAdsData.map((data) => {
          const matchData = prevData.find((prev) => prev.campaign_name === data.campaign_name);
          return {
            ...data,
            ...(matchData ? { checkbox: matchData.checkbox } : {}),
          };
        });
        return updatedData;
      });
    }
  };

  const searchRows = (columns:any, trafficName:any, tableName:any) => {
    const campaignSearchTrafficValue = campaignSearchValue.find((traffic) => traffic.trafficName === trafficName && traffic.tableName === tableName)?.value;
    const adsetSearchTrafficValue = adsetSearchValue.find((traffic) => traffic.trafficName === trafficName && traffic.tableName === tableName)?.value;
    const adsSearchTrafficValue = adsSearchValue.find((traffic) => traffic.trafficName === trafficName && traffic.tableName === tableName)?.value;

    return(
      <tr>
        {columns?.map((col, colIndex) => {
          if (col[0] === 'campaign_name') {
            return (
              <td key={colIndex}>
                <TextInput placeholder="Campaign Name Search" value={campaignSearchTrafficValue} onChange={(event) => handleSearch(event.currentTarget.value, trafficName, tableName, 'campaign_name')}></TextInput>
              </td>
            );
          }
          if (col[0] === 'adset_name') {
            return (
              <td key={colIndex}>
                <TextInput placeholder="Ad Set/Group Name Search" value={adsetSearchTrafficValue} onChange={(event) => handleSearch(event.currentTarget.value, trafficName, tableName, 'adset_name')}></TextInput>
              </td>
            );
          }
          if (col[0] === 'ad_name' && trafficName !== 'applovin') {
            return (
              <td key={colIndex}>
                <TextInput placeholder="Ad Name Search" value={adsSearchTrafficValue} onChange={(event) => handleSearch(event.currentTarget.value, trafficName, tableName, 'ad_name')}></TextInput>
              </td>
            );
          }
          if (col[0] === 'ad_name' && trafficName === 'applovin') {
            return (
              <td key={colIndex}>
                <TextInput placeholder="Ad Name Search" value={adsetSearchTrafficValue} onChange={(event) => handleSearch(event.currentTarget.value, trafficName, tableName, 'adset_name')}></TextInput>
              </td>
            );
          }
          return <td key={colIndex}></td>;
        })}
      </tr>
    );
  };

  useEffect(() => {
    if(selectedCampaigns.length > 1){
      fetchGraphCampaignList();
    }
  }, [selectedCampaigns]);

	return (
		<>
    {reportLoader && <VisiLoader VisiLoaderVisible={true}/>}
    <VisiLoader VisiLoaderVisible={isVisiLoaderVisible}/>
    {reportchartLoader && <VisiLoader VisiLoaderVisible={true}/>}
    {showHelpModal && Object.keys(tooltipPosition).map((key) => (
      <SelectTooltip
        isVisible={showHelpModal}
        setIsVisible={setShowHelpModal}
        id={helpTargetId}
        tooltipPosition={tooltipPosition[key]} 
      />
    ))}
    {/*Customer Journey Model*/}
    <CustomerJourney
      setIsVisiLoaderVisible={setIsVisiLoaderVisible}
      emailValue={emailValue}
      setEmailValue={setEmailValue}
      selectedAttributionModal={selectedAttributionModal}
      reportTimeValue={reportTimeValue()}
      selectedCustomTagData={selectedCustomTagData}
      selectedTrackingTagData={selectedTrackingTagData}
      conversionGoalOptions={conversionGoalOptions}
      selectedSourceData={selectedSourceData}
      campaignId={customerJourneyCampaignId}
      selectedCampaignIds={logCampaignId}
      campaignGoalId={customerJourneyCampaignGoalId}
      campaignGoalType={customerJourneyCampaignGoalType}
      customerJourneyCampaignSessionIds={customerJourneyCampaignSessionIds}
      customerJourneyType={customerJourneyType}
      isCustomerJourneyModalOpen={isCustomerJourneyModalOpen}
      onCustomerJourneyClose={handleCustomerJourneyCloseModal}
    />
    {/*Behavioral Filters Model*/}
    <BehavioralDimensions
      selectedMemberWebsite={selectedMemberWebsite}
      campaignId={behavioralCampaignId}
      isBehavioralFilterModalOpen={behavioralFilterModalOpen}
      onBehavioralFilterClose={handleBehavioralFilterCloseModal}
      selectedVisioptCampaign={selectedVisioptCampaign}
      CampaignGoalDropDown={getVisioptCampaignGoalDropDown}
    />
    {/*Save Report Model*/}
    <Modal.Root opened={saveReportModalOpen} onClose={handleSaveReportModalCloseModal}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>SAVE REPORT <IconHelp ref={saveReportRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(182)}/></Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <form>
            <Group>
              <Text>Enter Report Name: </Text>
              <Input
                name="report_name"
                value={reportName}
                onChange={(event) => setReportNameValue(event.currentTarget.value)}
              />
              <Button type="button" size="sm" w={80} mt={5} onClick={() => handleReportData('save')}>
                Save
              </Button>
            </Group>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
    {/*ResetStats Modal*/}
    <ResetStats
      siteId={resetStatsSiteId}
      campaignId={resetStatsCampaignId}
      isResetStatsModalOpen={isResetStatsModalOpen}
      onResetStatsClose={handleResetStatsCloseModal}
      errorModelOpen={seterrorCloseModal}
      errorMessage={setErrorMessage}
      showErrorHeader={setShowErrorHeader}
      showMessageHeader={setShowMessageHeader}
      selectedVisioptCampaign={selectedVisioptCampaign}
      CampaignGoalDropDown={getVisioptCampaignGoalDropDown}
      reportId={reportId || updateReportId}
      fetchTrafficTableData={fetchTrafficTableData}
    />
    {/*Error Model*/}
    <ErrorModal errorModelOpen={errorModelOpen} errorMessage={errorMessage} showErrorHeader={showErrorHeader} showMessageHeader={showMessageHeader} onClose={handlerrorCloseModal} />
    <HeaderMenu HeaderMenuValue={selectedMemberWebsite} HeaderMenuOnChange={handleHeaderMenuComponentChange}/>
    <Container size="xxl">
      <Card shadow="sm" padding="sm" radius="md" withBorder>
  			<Group position="apart" mb="xs">
          <Text weight={500}>CREATE A REPORT <IconHelp ref={createReportRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(332)}/></Text>
            <CampaignSetting
              selectedMemberWebsite={selectedMemberWebsite}
              SelectedVisioptCampaign={logCampaignId}
              ConversionGoalOptions={conversionGoalOptions}
              reportId={reportId}
              updateReportId={updateReportId}
            />
        </Group>       
        <div style={{ display: 'flex',marginBottom: 10}}>
          <Text mr={10} mt={4}>Select Visiopt Campaign:<IconHelp ref={campaignRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(333)}/></Text>
          <Select
            data={Object.entries(visioptCampaignOptions).flatMap(([domain, campaigns]) =>
                  campaigns.map(campaign => ({
                    value: campaign.campaign_id,
                    label: campaign.campaign_name,
                    group: domain,
                  }))
                )}
            withinPortal
            value={selectedVisioptCampaign}
            onChange={handleSelectedVisioptCampaign}
            maxDropdownHeight={280}
            searchable
            styles={{ input: { cursor: 'pointer' } }}
          />
          <Text mx={10} mt={4}><IconHelp ref={comparisonCampaignRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(334)}/> Comparison Visiopt Campaign(s):</Text>
          <MultiSelect
            data={Object.entries(compVisioptCampaignOptions).flatMap(([domain, campaigns]) =>
                  campaigns.map(campaign => ({
                    value: campaign.campaign_id,
                    label: campaign.campaign_name,
                    group: domain,
                  }))
                )}
            withinPortal
            value={comparisonVisiCampaign}
            maxDropdownHeight={280}
            searchable
            onChange={handleComparisonVisiCampaign}
          />
        </div>
        <div style={{ display: 'flex',marginBottom: 10}}>
          <Text mr={10} mt={4}><IconHelp ref={attributionRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(316)}/> Attribution Model:</Text>
          <Select
            data={attributionList.map((attribution) => ({
              value: attribution.attribution_name,
              label: attribution.attribution_name,
            }))}
            maxDropdownHeight={280}
            searchable
            value={selectedAttributionModal}
            onChange={(value) => setSelectedAttributionModal(value)}
            styles={{ input: { cursor: 'pointer' } }}
          />     
          <ActionIcon
            variant="white"
            size="lg"
            title="Time Filter"
          >
            <IconClockHour4 size="1.1rem" />
          </ActionIcon>
          <SelectTimePicker SelectTimePickerValue={selectedTimePicker} SelectTimePickerOnChange={handleselectedTimePickerComponentChange} SelectTimePickerFromDateOnChange={handleselectedTimePickerFromDateChange} SelectTimePickerToDateOnChange={handleselectedTimePickerToDateChange} SelectTestStartDate={getTodayDate()} SelectTestEndDate={getEndDate()}/>     
          <Text mx={10} mt={4}>Filter by :</Text>
          <Select
            data={[
            { value: '',  label: 'Select Metrics', disabled: true },
            { value: 'uniques', label: 'Uniques' },
            { value: 'visits', label: 'Visits' },
            { value: 'ad_spend', label: 'Ad spend' },
            { value: 'revenue', label: 'Visiopt Revenue' },
            { value: 'network_reported_revenue', label: 'Network Reported Revenue' },
            { value: 'revenue_difference', label: 'Revenue Difference' },
            { value: 'visiopt_conversions', label: 'Visiopt Conversions' },
            { value: 'network_conversions', label: 'Network Conversions' },
            { value: 'conversion_difference', label: 'Conversion Difference' },
            { value: 'roas', label: 'ROAS' },
            { value: 'roi', label: 'ROI' },
            { value: 'aov', label: 'AOV' },
            { value: 'epc', label: 'EPC' },
            { value: 'unique_epc', label: 'Unique EPC' },
            { value: 'profit', label: 'Profit' },
            { value: 'avg_revenue_per_unique', label: 'AVG.Revenue per Unique' }
            ]}
            withinPortal
            maxDropdownHeight={280}
            searchable
            value={filterByValue}
            onChange={handleFilterByChange}
            styles={{ input: { cursor: 'pointer' } }}
          />
          <Text mx={10} mt={4}>From:</Text>
          <Input
            maw={100}
            value={filterFromValue}
            onChange={(event) => handleFilterFromChange(event.currentTarget.value)}
            onBlur={() => handleFilterCampaignTables()}
          />
          <Text mx={10} mt={4}>To:</Text>
          <Input
            maw={100}
            value={filterToValue}
            onChange={(event) => handleFilterToChange(event.currentTarget.value)}
            onBlur={() => handleFilterCampaignTables()}
          />
          <div style={{ display: 'flex', gap: '4px', marginTop: '4px' }}>
            <Text mx={10}>Show All Ad Costs :</Text>
            <IconHelp ref={showAllAdIconRef} size="1.1rem" color="red" style={{cursor: 'pointer', marginTop: '4px'}} onClick={() => handleHelpClick(335)}/>
            <Switch 
              size="md"
              onLabel="ON"
              offLabel="OFF"
              checked={showAllAdCosts}
              onChange={(event) => setShowAllAdCosts(event.currentTarget.checked)}
              styles={{
                track: { cursor: 'pointer' },
                thumb: { cursor: 'pointer' } 
              }}
            />
          </div>
        </div>         
        {conversionGoalOptions.map((options, index) => (
          <div key={index}> 
            <Divider my="xs" label={options.campaign_name} labelPosition="center" color="red"/>
            <Group position="apart" mb="xs">
              <Text c="red" size="lg">{options.campaign_name}</Text>
            </Group>
            <div style={{ display: 'flex', marginBottom: 10 }}>
              {/*Conversion Goal Dropdown*/}
              <Text mt={4} mr={10}>Select Conversion Goal: </Text>
              <Select
                data={options.goals.map((goal) => ({
                  value: goal.goal_page_id,
                  label: goal.goal_name
                }))}
                value={selectedConversionGoal.find((data) => data.campaignid === options.campaign_id)
                          ?.selectedGoal || ''}
                maxDropdownHeight={280}
                onChange={(value) => handleConversionGoalChange(options.campaign_id, value)}
              />
              {/*Customer Path Group*/}
              {options.customerPathData.length > 0 && (
                <>
                  <Text mt={4} ml={10} mr={10}>Select Goals Through: </Text>
                  <Select
                    data={options.customerPathData.map((goal) => ({
                      value: goal.goal_id,
                      label: goal.goal_name
                    }))}
                    value={selectedCustomerGoal.find((data) => data.campaignid === options.campaign_id)
                          ?.selectedGoal || ''}
                    maxDropdownHeight={280}
                    onChange={(value) => handleCustomerGoalChange(options.campaign_id, value)}
                  />
                </>                                
              )}
              <Button variant="default" ml={10} onClick={() => handleBehavioralFilterOpenModal(options.campaign_id)}
                sx={options.behavioral_dimension_value === 1 ? {
                    backgroundColor: '#e7fcf5',
                    '&:hover': {
                      backgroundColor: 'inherit',
                    }
                  } : undefined}
              >
                Behavioral Dimensions
              </Button>
              {options.test_variation === 'show' &&
                <Button
                  variant="default"
                  ml={10}
                  sx={options.test_variation_value === 1 ? {
                    backgroundColor: '#e7fcf5',
                    '&:hover': {
                      backgroundColor: 'inherit',
                    }
                  } : undefined}
                  onClick={() => {
                    if ((reportId && reportId !== '') || (updateReportId && updateReportId !== '')) {
                      handleTestVariation(options.campaign_id, options.test_variation_value);
                    }
                  }}
                >
                  Test Variations
                </Button>
              }
              <Button variant="default" ml={10} onClick={() => handleResetStatsModalOpen(options.campaign_id,options.site_id)}>
                Reset Stats
              </Button>
            </div>
            {/*Facebook Account Dropdown*/}
            {options.facebookDropDownData && Object.entries(options.facebookDropDownData).length > 0 && ( 
              <div style={{ display: 'flex', marginBottom: 10 }}>
                <Text weight={500} mt={4} mr={10}>Facebook Account: </Text>
                <Select
                    data={
                      Object.entries(options.facebookDropDownData) && Object.entries(options.facebookDropDownData).length > 0
                        ? Object.entries(options.facebookDropDownData).map(([key, value]) => {
                            const fbData = value as { fb_account_id: string };
                            return { label: fbData.fb_account_id, value: fbData.fb_account_id };
                          })
                        : [{ label: 'No matches found', value: '', disabled: true }]
                    }                 
                  value={selectedFacebookDropdownData?.find((data) => data.campaignid === options.campaign_id)?.selectedValue || ''}
                  maxDropdownHeight={280}
                  onChange={(value) => handleFacebookAccountChange(options.campaign_id, value)}
                />
              </div>
            )}
            {/*Tracking Tag Dropdown*/}
            {options.trackingTagsData && Object.entries(options.trackingTagsData).length > 0 && ( 
              <div className="ads_network">
                <Text weight={500} mt={15}>Tracking Tags</Text>
                {Object.entries(options.trackingTagsData).map(([trackingKey, trackingValue], trackingIndex) => {
                  const value = trackingValue as { dropdown_data: any[] };
                  return (
                    <div className="ads_network" style={{ display: 'flex', marginTop: '15px' }} key={trackingIndex}>
                      <Text color="red" mx={5} mt={4}>{trackingKey}</Text>
                      <MultiSelect                        
                        data={
                          value.dropdown_data && value.dropdown_data.length > 0
                            ? value.dropdown_data.map((dataValue) => ({ label: dataValue.value, value: dataValue.key }))
                            : [{ label: 'No matches found', value: '', disabled: true }]
                        }
                        value={selectedTrackingTagData.find((data) => data.campaignid === options.campaign_id && data.trackingTagKey === trackingKey)
                        ?.trackingTagValues || []}
                        searchable
                        radius="sm"
                        size="sm"
                        mr={10}
                        onChange={(value) => handleTrackingTagValues(value, options.campaign_id, trackingKey, (trackingValue as any).array_id)}
                      />
                    </div>
                  );
                })}
              </div>
            )}
            {/*Custom Tag Dropdown*/}
            {options.customTagData && Object.entries(options.customTagData).length > 0 && ( 
              <div className="ads_network">
                <Text weight={500} mt={15}>Custom tags</Text>
                {Object.entries(options.customTagData).map(([customTagKey, customTagValue], CustomTagIndex) => {
                  const value = customTagValue as { dropdown_data: any[] };
                  return (
                    <div className="ads_network" style={{ display: 'flex', marginTop: '15px' }} key={CustomTagIndex}>
                      <Text color="red" mx={5} mt={4}>{customTagKey}</Text>
                      <MultiSelect
                        data={
                          value.dropdown_data && value.dropdown_data.length > 0
                            ? value.dropdown_data.map((dataValue) => ({ label: dataValue.value, value: dataValue.key }))
                            : [{ label: 'No matches found', value: '', disabled: true }]
                        }
                        value={selectedCustomTagData.find((data) => data.campaignid === options.campaign_id && data.customTagKey === customTagKey)
                        ?.customTagValues || []}
                        searchable
                        radius="sm"
                        size="sm"
                        mr={10}
                        onChange={(value) => handleCustomTagValues(value, options.campaign_id, customTagKey, (customTagValue as any).array_id)}
                      />
                    </div>
                  );
                })}
              </div>
            )}
            {/*Source Dropdown*/}
            {options.sourceData && options.sourceData.dropdown_data && (
              <div className="ads_network">
                <Text weight={500} mt={15}>Source</Text>
                <div className="ads_network" style={{ display: 'flex', marginTop: '15px' }}>
                  <Text color="red" mx={5} mt={4}>Source</Text>
                  <MultiSelect
                    data={options.sourceData.dropdown_data.map((sourceItem) => {
                      const item = sourceItem as { key: string; value: string; selected: string };
                      return {
                        label: item.value,
                        value: item.key
                      };
                    })}
                    value={selectedSourceData.find((data) => data.campaignid === options.campaign_id)
                        ?.sourceValues || []}
                    searchable
                    radius="sm"
                    size="sm"
                    mr={10}
                    onChange={(value) => handleSourceDataChange(options.campaign_id, value)}
                  />
                </div>
              </div>
            )}
            {/*Organic Traffic Dropdown*/}
            {options.organicTrafficData && Object.entries(options.organicTrafficData).length > 0 && ( 
              <div className="ads_network">
                <Text weight={500} mt={15}>Exclude Organic Traffic</Text>
                <div className="ads_network" style={{ display: 'flex', marginTop: '15px' }}>
                  <Text mt={4} mr={10}>Select Custom Tags: </Text>
                  <MultiSelect
                    data={options.organicTrafficData.exclude_organic_custom_tag?.dropdown_data && Object.entries(options.organicTrafficData.exclude_organic_custom_tag.dropdown_data).length > 0
                         ? Object.entries(options.organicTrafficData.exclude_organic_custom_tag.dropdown_data).map(
                              ([dataKey, dataValue]) => {
                                const item = dataValue as { key: string; value: string; selected: string };
                                return {
                                  label: item.value,
                                  value: item.key
                                };
                              })
                         : [{ label: 'No matches found', value: '', disabled: true }]
                    }
                    value={selectedOrganicCustomTag.find((data) => data.campaignid === options.campaign_id)
                        ?.selectedValues || []}
                    searchable
                    radius="sm"
                    size="sm"
                    mr={10}
                    onChange={(value) => handleOrganicCustomTagData(options.campaign_id, value)}
                  />
                  <Text mt={4} mx={10}>Select Tracking Tags: </Text>
                  <MultiSelect
                    data={options.organicTrafficData.exclude_organic_tracking_tag?.dropdown_data && Object.entries(options.organicTrafficData.exclude_organic_tracking_tag.dropdown_data).length > 0
                         ? Object.entries(options.organicTrafficData.exclude_organic_tracking_tag.dropdown_data).map(
                              ([dataKey, dataValue]) => {
                                const item = dataValue as { key: string; value: string; selected: string };
                                return {
                                  label: item.value,
                                  value: item.key
                                };
                              })
                         : [{ label: 'No matches found', value: '', disabled: true }]
                    }  
                    value={selectedOrganicTrackingTag.find((data) => data.campaignid === options.campaign_id)
                        ?.selectedValues || []}                 
                    searchable
                    radius="sm"
                    size="sm"
                    mr={10}
                    onChange={(value) => handleOrganicTrackingTagData(options.campaign_id, value)}
                  />
                </div>
              </div>
            )}
          </div>
        ))}            
        <Space h="xl"/>
        {((reportId && reportId !== '') || updateReportId !== '') && (
        <div>
          <Group position="left">
            {showGraphCampaignDropdown && (
              <>
                <Text mt={4} mr={10}>Select Campaign: </Text>
                <Select
                  data={graphCampaignList.map((campaign) => ({
                    value: campaign.campaign_id,
                    label: campaign.campaign_name,
                  }))}
                  value={selectedGraphCampaign}
                  onChange={handleGraphCampaignChange}
                />
              </>
            )}
            <Text mt={4} mr={10}>Select Graph: </Text>
            <Select
              data={[
                { value: '1', label: 'ROAS all time VS Selected date range'},
                { value: '2', label: 'Total Cost VS Total revenue over the selected time period'},
                { value: '3', label: 'Cumulative Spend and Conversions with ROAS Overlay'},
                { value: '4', label: 'CPC, CPM, and CR Over Time'},
              ]}
              sx={{ width: '20%' }}
              value={selectedGraphType}
              onChange={(value) => handleGraphChange(value, selectedGraphCampaign)}
            />
          </Group>
          <div style={{ display: 'flex',marginBottom: 50}}>
            <Box style={{ width: '70%', marginTop: '1rem', textAlign: 'center' }}>
              {selectedGraphType === '1' && 
                <Chart
                  chartType="LineChart"
                  data={SelectedGraphData}
                  options={{
                    title: "ROAS All Time vs Selected Date Range with Moving Average",
                    titleTextStyle: {
                      color: '#4D4D4D',
                      fontSize: 18,
                    },
                    legend: {
                      position: 'bottom',
                      alignment: 'start',
                      textStyle: { color: 'black', fontSize: 16,
                      layout: 'vertical', },
                    },
                    colors: ['#FF9900', '#0000FF', '#008000'],
                    series: {
                      0: { lineDashStyle: [0], labelInLegend: 'Selected Date Range ROAS' },
                      1: { lineDashStyle: [0], labelInLegend: 'All Time ROAS' },
                      2: { lineDashStyle: [4, 4], labelInLegend: 'Selected Date Range ROAS (Moving Average)' },
                    },
                    chartArea: { height: 'auto', width: '90%' },
                    hAxis: { title: "Date" },
                    vAxis: { title: "ROAS" },
                  }}
                  width="100%"
                  height="300px"
                />
              }
              {selectedGraphType === '2' && 
                <Chart
                  chartType="ColumnChart"
                  data={SelectedGraphData}
                  options={{
                    isStacked: true,
                    title: "Total Revenue vs. Total Cost (Stacked Bar Chart)",
                    titleTextStyle: {
                      color: '#4D4D4D',
                      fontSize: 18,
                    },
                    legend: { position: 'top', alignment: 'end', layout: 'vertical', },
                    bar: { groupWidth: "30%" },
                    colors: ['#FF0800', '#009000'],
                    chartArea: { height: 'auto', width: '90%' },
                    hAxis: {
                      title: "Date"
                    },
                    vAxis: {
                      title: "Amount($)"
                    },
                  }}
                  style={{ width: '100%', height: '300px' }}
                />
              }
              {selectedGraphType === '3' && 
                <Chart
                  chartType="ComboChart"
                  data={SelectedGraphData}
                  options={{
                    title: "Parallel Growth (Spend vs. Conversions) with ROAS Trend Line",
                    titleTextStyle: {
                      color: '#4D4D4D',
                      fontSize: 18,
                    },
                    legend: { position: 'bottom', alignment: 'start' },
                    chartArea: { height: 'auto', width: '90%' },
                    hAxis: {
                      title: 'Date',
                    },
                    vAxes: {
                      0: { title: 'Cumulative Spend (USD) and Conversions' },
                      1: { title: 'ROAS' },
                    },
                    series: {
                      0: { targetAxisIndex: 0, color: '#3357FF', lineWidth: 2 },
                      1: { targetAxisIndex: 0, color: '#33FF57', lineWidth: 2 },
                      2: { targetAxisIndex: 1, color: '#FF5733', lineWidth: 2, lineDashStyle: [4, 4] },
                    },
                  }}
                  style={{ width: '100%', height: '300px' }}
                />
              }
              {selectedGraphType === '4' && 
                <Chart
                  chartType="LineChart"
                  data={SelectedGraphData}
                  options={{
                    title: "CPC, CPM, and CR over Time",
                    titleTextStyle: {
                      color: '#4D4D4D',
                      fontSize: 18,
                    },
                    legend: {
                      position: 'bottom',
                      alignment: 'start',
                      textStyle: { color: 'black', fontSize: 16 },
                      layout: 'vertical',
                    },
                    chartArea: { height: 'auto', width: '90%' },
                    hAxis: {
                      title: 'Date',
                    },
                    vAxes: {
                      0: { title: "Cost ($)" },
                      1: { title: "Conversion Rate (%)" },
                    },
                    series: {
                      0: { targetAxisIndex: 0, color: '#3366CC', lineWidth: 2, pointSize: 5 },
                      1: { targetAxisIndex: 0, color: '#DC3912', lineWidth: 2, pointSize: 5 },
                      2: { targetAxisIndex: 1, color: '#FF9900', lineWidth: 2, lineDashStyle: [4, 4], pointSize: 5 },
                    },
                  }}
                  style={{ width: '100%', height: '300px' }}
                />
              }
            </Box>
            <Box style={{ width: '30%', marginTop: '1rem' }}>
              <Chart
                chartType="PieChart"
                data={pieChartSourceDataArray}
                width={"100%"}
                height={"300px"}
                options={pieChartOptions}
              />
            </Box>
          </div>
        </div>
        )}
        <Space h="xl"/>
        <Grid>
          <Grid.Col span={6}>
            {/*START SELECT METRICS*/}
            <SelectMetrics
              generalColumns={generalColumns}
              setGeneralColumns={setGeneralColumns}
              goalColumns={goalColumns}
              setGoalColumns={setGoalColumns}
              trafficSourceColumns={trafficSourceColumns}
              setTrafficSourceColumns={setTrafficSourceColumns}
              organicTrafficMetricsColumns={organicTrafficMetricsColumns}
              setOrganicTrafficMetricsColumns={setOrganicTrafficMetricsColumns}
              sourceMetricsColumns={sourceMetricsColumns}
              setSourceMetricsColumns={setSourceMetricsColumns}
              conversionGoalOptions={conversionGoalOptions}
              setOrganicTrafficColumns={setOrganicTrafficColumns}
              setSourceColumns={setSourceColumns}
              setCustomTagColumns={setCustomTagColumns}
              setTrackingTagColumns={setTrackingTagColumns}
              setCampaignColumns={setCampaignColumns}
            />
            {/*END SELECT METRICS*/}
          </Grid.Col>
          <Grid.Col span={6}>
            <Group position="right">
              <Button color="orange" onClick={handleExportData}>
                Export Report
              </Button>
            </Group>
          </Grid.Col>
        </Grid>

        {allCampaignTableData.map((campaignData, index) => (
          <>
            {/*Main Campaign Table*/}
            <Table striped highlightOnHover withBorder my={15} fontSize="xs">   
              <thead>
                <tr>
                  {generalColumns.length > 0 &&
                    generalColumns
                    .filter(traffic => traffic.show === true)
                    .map((dataColumns, index) => (
                      <th key={index}>{dataColumns.value}</th>
                    ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {generalColumns
                    .filter(traffic => traffic.show === true)
                    .map((dataColumns, colIndex) => (
                    <td
                      key={colIndex}
                      style={{ width: 200, cursor: "pointer" }}
                      onClick={() => handleCampaignClick(campaignData.campaign_id)}
                    >
                      {campaignData.campaignTableData[dataColumns.key] !== undefined
                        ? campaignData.campaignTableData[dataColumns.key]
                        : null}
                    </td>
                  ))}
                </tr>
                {trafficSourceData.length > 0 && trafficSourceData.map((data, index) => data.key === campaignData.campaign_id ? (
                  <tr style={{display:''}}>
                    <td colSpan={12}>
                      {/*Goal Tables*/}
                      {showGoalTable && showGoalTable.map((goals) => 
                        goals.campaignid === campaignData.campaign_id && goals.show === true ? (
                          <>
                            <Card radius="sm" withBorder style={{ position: 'relative' }}>
                                {showTableByIcons && showTableByIcons.map((table) => 
                                  table.campaign_id === campaignData.campaign_id && table.tableName === "goals" ? (
                                    <>
                                      <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', top: 0, left: 5, cursor: 'pointer', width: '100%' }} onClick={() => handleShowIcons(campaignData.campaign_id, 'goals')}>
                                        <Title order={6} weight={500} color="#262323">Goals</Title>
                                        {table.show && <IconCaretDownFilled />}
                                        {!table.show && <IconCaretRightFilled />}
                                      </div>
                                      {table.show && (
                                        <div style={{ marginLeft: '-10px', marginTop: '20px' }}>
                                          {campaignData.campaignGoalTableData && campaignData.campaignGoalTableData?.map((goalTable, goalIndex) => (
                                            <Table striped highlightOnHover withBorder my={5} fontSize="xs" key={goalIndex}>
                                            <thead>
                                              <tr>
                                                <th style={{ width: '10%' }}>{Object.keys(goalTable)[0]}</th>
                                                {goalColumns.length > 0 && goalColumns
                                                  .slice(1)
                                                  .filter(traffic => traffic.show === true)
                                                  .map((columnData, colIndex) => (
                                                    <th key={colIndex}>
                                                      {columnData.value}
                                                    </th>
                                                ))}
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td onClick={() => handleCustomerJourneyOpenModal(campaignData.campaign_id,goalTable,goalTable[Object.keys(goalTable)[0]],"goal")} style={{ cursor: 'pointer' }}>
                                                  {goalTable[Object.keys(goalTable)[0]]}
                                                </td>
                                                {goalColumns
                                                  .slice(1)
                                                  .filter(traffic => traffic.show === true)
                                                  .map((columnData, colIndex) => (
                                                    <td key={colIndex}>
                                                      {goalTable[columnData.key] !== undefined ? goalTable[columnData.key] : null}
                                                    </td>
                                                ))}
                                              </tr>
                                            </tbody>
                                          </Table>
                                          ))}
                                        </div>
                                      )}
                                    </>
                                  ) : null
                                  )}
                            </Card>
                          </>
                        ) : null
                      )} 
                      {/*Test Variation Tables*/}
                      {showTestVariation && showTestVariation.map((variations) => 
                        variations.campaignid === campaignData.campaign_id && variations.show === true ? (
                          <Card radius="sm" withBorder style={{ position: 'relative' }}>                              
                                {showTableByIcons && showTableByIcons.map((table) => 
                                  table.campaign_id === campaignData.campaign_id && table.tableName === "variation" ? (
                                    <>
                                      <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', top: 0, left: 5, cursor: 'pointer', width: '100%' }} onClick={() => handleShowIcons(campaignData.campaign_id, 'variation')}>
                                        <Title order={6} weight={500} color="#262323">Goals</Title>
                                        {table.show && <IconCaretDownFilled />}
                                        {!table.show && <IconCaretRightFilled />}
                                      </div>
                                      {table.show && (
                                        <div style={{ marginLeft: '-10px', marginTop: '20px' }}>
                                          {Object.entries(campaignData.campaignGoalTableData)?.map(([goalName, goalData], goalIndex) => (
                                            <>
                                              {Object.entries(goalData).map(([variationName, variationData], variationIndex) => (
                                                <Table striped highlightOnHover withBorder my={5} fontSize="xs" key={variationIndex}>
                                                  <thead>
                                                    <tr>
                                                      <th colSpan={testVariationColumns.filter(traffic => traffic.show === true).length} style={{ backgroundColor: '#E4E9ED' }}>
                                                        {variationName}
                                                      </th>
                                                    </tr>
                                                    <tr>
                                                      {testVariationColumns
                                                        .filter(traffic => traffic.show === true)
                                                        .map((column, columnIndex) => (
                                                          <th key={columnIndex}>{column.value}</th>
                                                        ))}
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {(variationData && Array.isArray(variationData) && variationData.length > 0) ? (
                                                      variationData.map((rowData, rowIndex) => (
                                                        <tr key={rowIndex} style={rowIndex === variationData.length - 1 ? { fontWeight: 'bold' } : {}}>
                                                          {testVariationColumns
                                                            .filter(traffic => traffic.show === true)
                                                            .map((column, columnIndex) => (
                                                              <td
                                                                key={columnIndex}
                                                                onClick={() => {
                                                                  if (column.key === 'Conversion' && rowData['variation'] !== 'Total') {
                                                                    handleCustomerJourneyOpenModal(campaignData.campaign_id, rowData, rowData[column.key], "goal");
                                                                  }
                                                                }}
                                                                style={{ cursor:'pointer' }}
                                                              >{rowData[column.key]}</td>
                                                            ))}
                                                        </tr>
                                                      ))
                                                    ) : (
                                                      <tr>
                                                        <td colSpan={testVariationColumns.filter(traffic => traffic.show === true).length}>
                                                          No data available
                                                        </td>
                                                      </tr>
                                                    )}
                                                  </tbody>
                                                </Table>
                                              ))}
                                            </>
                                          ))}
                                        </div>
                                      )}
                                    </>
                                  ) : null
                                )}
                            </Card>
                        ) : null
                      )}
                      {/*Source Table*/}
                      {campaignData.trafficTableData && Object.entries(campaignData.trafficTableData).length > 0 &&
                        <>
                          {campaignData.trafficTableData.source && Object.entries(campaignData.trafficTableData.source).length > 0 &&
                            <>
                              <Card radius="sm" withBorder style={{ position: 'relative', marginTop: '10px' }}>                              
                                {showTableByIcons && showTableByIcons.map((table) => 
                                  table.campaign_id === campaignData.campaign_id && table.tableName === "source" ? (
                                    <>
                                      <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', top: 0, left: 5, cursor: 'pointer', width: '100%' }} onClick={() => handleShowIcons(campaignData.campaign_id, 'source')}>
                                        <Title order={6} weight={500} color="#262323">Source</Title>
                                        {table.show && <IconCaretDownFilled />}
                                        {!table.show && <IconCaretRightFilled />}
                                      </div>
                                      {table.show && (
                                        <div style={{ marginLeft: '-10px', marginTop: '20px' }}>
                                          <Table striped highlightOnHover withBorder my={5} fontSize="xs">
                                            <thead>
                                              <tr>
                                                {sourceColumns[campaignData.campaign_id]?.slice(-9).map((column, columnIndex) => {
                                                  const isColor = showColumnColor.find(d => d.campaignid === campaignData.campaign_id && d.tableName === 'source' && d.columnName === column.key && d.show === true);
                                                  return(
                                                    <th
                                                      key={columnIndex}
                                                      draggable
                                                      onDragStart={(e) => handleDragStart(e, columnIndex, 'source', campaignData.campaign_id)}
                                                      onDrop={(e) => handleDrop(e, columnIndex)}
                                                      onDragOver={(e) => e.preventDefault()}
                                                      onClick={(e) => handleSorting(column.key, 'source', campaignData.campaign_id)}
                                                      style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                                    >
                                                      <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                                          {column.value}{(() => {
                                                            const campaignSortConfig = sortConfig[campaignData.campaign_id] || [];
                                                            const tableSortConfig = campaignSortConfig.filter(config => config.table === 'source');
                                                            const sort = tableSortConfig.find(config => config.key === column.key);

                                                            if (sort) {
                                                              return sort.direction === 'ascending' ? <IconCaretUpFilled /> : <IconCaretDownFilled />;
                                                            }
                                                          })()}
                                                        </span>
                                                    </th>
                                                  );
                                                })}
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {sourceDataTable[campaignData.campaign_id]?.data.map((rowData, rowIndex) => (
                                                  <tr 
                                                    key={rowIndex}
                                                    style={campaignData.trafficTableData.source.td.length > 1 && rowIndex === campaignData.trafficTableData.source.td.length - 1 ? { fontWeight: 'bold' } : {}}
                                                  >
                                                    {sourceColumns[campaignData.campaign_id]?.slice(-9).map((column, columnIndex) => {
                                                      const isColor = showColumnColor.find(d => d.campaignid === campaignData.campaign_id && d.tableName === 'source' && d.columnName === column.key && d.show === true);
                                                      return(
                                                        <td
                                                          onClick={() => {
                                                            if (column.key === 'visiopt_conversions' && rowData['source_name'] !== 'Total') {
                                                              handleCustomerJourneyOpenModal(campaignData.campaign_id, rowData, rowData[column.key], "organicTraffic");
                                                            }
                                                            handleColumnDataClicked(campaignData.campaign_id, 'source', column.key);
                                                          }}
                                                          style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                                        >{rowData[column.key]}</td>
                                                      );
                                                    })}
                                                  </tr>
                                              ))}
                                            </tbody>
                                          </Table>
                                        </div>
                                      )}
                                    </>
                                  ) : null
                                )}
                            </Card>
                            </>
                          }
                          {/*Tracking Tag Table*/}
                          {campaignData.trafficTableData.tracking_tag && Object.entries(campaignData.trafficTableData.tracking_tag).length > 0 &&
                            <Card radius="sm" withBorder style={{ position: 'relative', marginTop: '10px' }}>                              
                                {showTableByIcons && showTableByIcons.map((table) => 
                                  table.campaign_id === campaignData.campaign_id && table.tableName === "trackingTag" ? (
                                    <>
                                      <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', top: 0, left: 5, cursor: 'pointer', width: '100%' }} onClick={() => handleShowIcons(campaignData.campaign_id, 'trackingTag')}>
                                        <Title order={6} weight={500} color="#262323">Tracking Tag</Title>
                                        {table.show && <IconCaretDownFilled />}
                                        {!table.show && <IconCaretRightFilled />}
                                      </div>
                                      {table.show && (
                                        <div style={{ marginLeft: '-10px', marginTop: '20px' }}>
                                          <Table striped highlightOnHover withBorder my={5} fontSize="xs">
                                            <thead>
                                              <tr>
                                                {trackingTagColumns[campaignData.campaign_id]?.filter((column) => column.key !== 'source_name' && column.show === true).map((column, columnIndex) => {
                                                  const isColor = showColumnColor.find(d => d.campaignid === campaignData.campaign_id && d.tableName === 'tracking_tag' && d.columnName === column.key && d.show === true);
                                                  return(
                                                    <th
                                                      key={columnIndex}
                                                      draggable
                                                      onDragStart={(e) => handleDragStart(e, columnIndex, 'tracking_tag', campaignData.campaign_id)}
                                                      onDrop={(e) => handleDrop(e, columnIndex)}
                                                      onDragOver={(e) => e.preventDefault()}
                                                      onClick={(e) => handleSorting(column.key, 'tracking_tag', campaignData.campaign_id, campaignData.trafficTableData.tracking_tag.th[0])}
                                                      style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                                    >
                                                      <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                                        {column.value}{(() => {
                                                          const campaignSortConfig = sortConfig[campaignData.campaign_id] || [];
                                                          const tableSortConfig = campaignSortConfig.filter(config => config.table === 'tracking_tag');
                                                          const sort = tableSortConfig.find(config => config.key === column.key);

                                                          if (sort) {
                                                            return sort.direction === 'ascending' ? <IconCaretUpFilled /> : <IconCaretDownFilled />;
                                                          }
                                                        })()}
                                                      </span>
                                                    </th>
                                                  );
                                                })}
                                              </tr>
                                            </thead>
                                            <tbody>                              
                                              {trackingTagDataTable[campaignData.campaign_id]?.data.map((rowData, rowIndex) => (
                                                  <tr
                                                    key={rowIndex}
                                                    style={campaignData.trafficTableData.tracking_tag.td.length > 1 && rowIndex === campaignData.trafficTableData.tracking_tag.td.length - 1 ? { fontWeight: 'bold' } : {}}
                                                  >
                                                    {trackingTagColumns[campaignData.campaign_id]?.filter((column) => column.key !== 'source_name' && column.show === true).map((columnData, columnIndex) => {
                                                      const isColor = showColumnColor.find(d => d.campaignid === campaignData.campaign_id && d.tableName === 'tracking_tag' && d.columnName === columnData.key && d.show === true);
                                                      return(
                                                        <td
                                                          onClick={() => {
                                                            if (columnData.key === 'visiopt_conversions') {
                                                              const trackingTagName = campaignData.trafficTableData.tracking_tag.th[0];
                                                              const trackingKeyName = trackingTagName.toLowerCase().replace(/\s/g, "_").replace(/\./g, "_");
                                                              if(rowData[trackingKeyName] !== 'Total') {
                                                                handleCustomerJourneyOpenModal(campaignData.campaign_id, rowData, rowData[columnData.key], "organicTraffic");
                                                              }                                                
                                                            }
                                                            handleColumnDataClicked(campaignData.campaign_id, 'tracking_tag', columnData.key)
                                                          }}
                                                          style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                                        >{rowData[columnData.key] !== '' ? rowData[columnData.key] : '-'}</td>
                                                      );
                                                    })}
                                                  </tr>
                                              ))}
                                            </tbody>
                                          </Table>
                                        </div>
                                      )}
                                    </>
                                  ) : null
                                )}
                            </Card>
                          }
                          {/*Custom Tag Tables*/}
                          {campaignData.trafficTableData.custom_tag && Object.entries(campaignData.trafficTableData.custom_tag.th).length > 0 &&
                            <>
                             <Card radius="sm" withBorder style={{ position: 'relative', marginTop: '10px' }}>                              
                                {showTableByIcons && showTableByIcons.map((table) => 
                                  table.campaign_id === campaignData.campaign_id && table.tableName === "customTag" ? (
                                    <>
                                      <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', top: 0, left: 5, cursor: 'pointer', width: '100%' }} onClick={() => handleShowIcons(campaignData.campaign_id, 'customTag')}>
                                        <Title order={6} weight={500} color="#262323">Custom Tag</Title>
                                        {table.show && <IconCaretDownFilled />}
                                        {!table.show && <IconCaretRightFilled />}
                                      </div>
                                      {table.show && (
                                        <div style={{ marginLeft: '-10px', marginTop: '20px' }}>
                                          {Object.entries(campaignData.trafficTableData.custom_tag.th).map((customTagName, customTagIndex) => (
                                            <Table striped highlightOnHover withBorder my={5} fontSize="xs">
                                              <thead>
                                                <tr>                                      
                                                  {customTagColumns[campaignData.campaign_id]?.[customTagName[0]]?.filter(column => column.show === true).map((column, columnIndex) => {
                                                    const isColor = showColumnColor.find(d => d.campaignid === campaignData.campaign_id && d.tableName === 'custom_tag' && d.columnName === column.key && d.show === true);
                                                    return(
                                                      <th
                                                        key={columnIndex}
                                                        draggable
                                                        onDragStart={(e) => handleDragStart(e, columnIndex, 'custom_tag', campaignData.campaign_id)}
                                                        onDrop={(e) => handleDrop(e, columnIndex, customTagName[0])}
                                                        onDragOver={(e) => e.preventDefault()}
                                                        onClick={(e) => handleSorting(column.key, 'custom_tag', campaignData.campaign_id, customTagName[0])}
                                                        style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                                      >
                                                        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                                          {column.value}{(() => {
                                                            const campaignSortConfig = sortConfig[campaignData.campaign_id] || [];
                                                            const tableSortConfig = campaignSortConfig.filter(config => config.table === 'custom_tag');
                                                            const sort = tableSortConfig.find(config => config.key === column.key && config.totalKeyName === customTagName[0]);

                                                            if (sort) {
                                                              return sort.direction === 'ascending' ? <IconCaretUpFilled /> : <IconCaretDownFilled />;
                                                            }
                                                          })()}
                                                        </span>
                                                      </th>
                                                    );
                                                  })}
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {customTagDataTable[campaignData.campaign_id]?.[customTagName[0]]?.map((rowData, rowIndex) => (
                                                  <tr
                                                    key={rowIndex}
                                                    style={
                                                      customTagDataTable[campaignData.campaign_id][customTagName[0]].length > 1 && rowIndex === customTagDataTable[campaignData.campaign_id][customTagName[0]].length - 1
                                                        ? { fontWeight: 'bold' }
                                                        : {}
                                                    }
                                                  >                      
                                                    {customTagColumns[campaignData.campaign_id]?.[customTagName[0]]?.filter(column => column.show === true).map((columnData, columnIndex) => {
                                                      const isColor = showColumnColor.find(d => d.campaignid === campaignData.campaign_id && d.tableName === 'custom_tag' && d.columnName === columnData.key && d.show === true);
                                                      return(
                                                      <td
                                                        key={columnIndex}
                                                        onClick={() => {
                                                          if (columnData.key === 'visiopt_conversions' && rowData[customTagName[0]] !== 'Total') {
                                                            handleCustomerJourneyOpenModal(campaignData.campaign_id, rowData, rowData[columnData.key], "organicTraffic");
                                                          }
                                                          handleColumnDataClicked(campaignData.campaign_id, 'custom_tag', columnData.key);
                                                        }}
                                                        style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                                      >{rowData[columnData.key]}</td>
                                                    );
                                                    })}
                                                  </tr>
                                                ))}
                                              </tbody>
                                            </Table>
                                          ))}
                                        </div>
                                      )}
                                    </>
                                  ) : null
                                )}
                            </Card>
                            </>
                          }
                          {/*Organic Traffic Table*/}
                          {campaignData.trafficTableData.organic_traffic && Object.entries(campaignData.trafficTableData.organic_traffic).length > 0 &&
                            <Card radius="sm" withBorder style={{ position: 'relative', marginTop: '10px' }}>                              
                                {showTableByIcons && showTableByIcons.map((table) => 
                                  table.campaign_id === campaignData.campaign_id && table.tableName === "organicTraffic" ? (
                                    <>
                                      <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', top: 0, left: 5, cursor: 'pointer', width: '100%' }} onClick={() => handleShowIcons(campaignData.campaign_id, 'organicTraffic')}>
                                        <Title order={6} weight={500} color="#262323">Organic Traffic</Title>
                                        {table.show && <IconCaretDownFilled />}
                                        {!table.show && <IconCaretRightFilled />}
                                      </div>
                                      {table.show && (
                                        <div style={{ marginLeft: '-10px', marginTop: '20px' }}>
                                          <Table striped highlightOnHover withBorder my={5} fontSize="xs">
                                            <thead>
                                              <tr>
                                                {organicTrafficColumns[campaignData.campaign_id]?.map((column, columnIndex) => {
                                                  const isColor = showColumnColor.find(d => d.campaignid === campaignData.campaign_id && d.tableName === 'organicTraffic' && d.columnName === column.key && d.show === true);
                                                  return(
                                                  <th 
                                                    key={columnIndex}
                                                    draggable
                                                    onDragStart={(e) => handleDragStart(e, columnIndex, 'organicTraffic', campaignData.campaign_id)}
                                                    onDrop={(e) => handleDrop(e, columnIndex)}
                                                    onDragOver={(e) => e.preventDefault()}
                                                    onClick={(e) => handleSorting(column.key, 'organicTraffic', campaignData.campaign_id)}
                                                    style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                                  >
                                                    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                                      {column.value}{(() => {
                                                        const campaignSortConfig = sortConfig[campaignData.campaign_id] || [];
                                                        const tableSortConfig = campaignSortConfig.filter(config => config.table === 'organicTraffic');
                                                        const sort = tableSortConfig.find(config => config.key === column.key);

                                                        if (sort) {
                                                          return sort.direction === 'ascending' ? <IconCaretUpFilled /> : <IconCaretDownFilled />;
                                                        }
                                                      })()}
                                                    </span>
                                                  </th>
                                                );
                                                })}
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {organicTrafficDataTable[campaignData.campaign_id]?.data.map((rowData, rowIndex) => (
                                                  <tr key={rowIndex} style={campaignData.trafficTableData.organic_traffic.td.length > 1 && rowIndex === campaignData.trafficTableData.organic_traffic.td.length - 1 ? { fontWeight: 'bold' } : {}}>
                                                    {organicTrafficColumns[campaignData.campaign_id]?.map((column, columnIndex) => {
                                                      const isColor = showColumnColor.find(d => d.campaignid === campaignData.campaign_id && d.tableName === 'organicTraffic' && d.columnName === column.key && d.show === true);
                                                      return(
                                                      <td
                                                        onClick={() => {
                                                          if (column.key === 'visiopt_conversions' && rowData['goalname'] !== 'Total') {
                                                            handleCustomerJourneyOpenModal(campaignData.campaign_id, rowData, rowData[column.key], "organicTraffic");
                                                          }
                                                          handleColumnDataClicked(campaignData.campaign_id, 'organicTraffic', column.key);
                                                        }}
                                                        style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                                      >{rowData[column.key]}</td>
                                                    );
                                                    })}
                                                  </tr>
                                              ))}
                                            </tbody>
                                          </Table>
                                        </div>
                                      )}
                                    </>
                                  ) : null
                                )}
                            </Card>
                          }
                        </>
                      }
                      </td>
                  </tr>
                ) : null
                )}
              </tbody>
            </Table>
          </>
        ))}
        {allTrafficSourceData && Object.keys(allTrafficSourceData).length > 0 && (
          <>
            <Card radius="sm" withBorder style={{ position: 'relative', marginTop: '10px' }}>
              <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', top: 0, left: 5, cursor: 'pointer', width: '100%' }} onClick={() => setShowTrafficTables(!showTrafficTables)}>
                <Title order={6} weight={500} color="#262323">Traffic Source</Title>
                {showTrafficTables && <IconCaretDownFilled />}
                {!showTrafficTables && <IconCaretRightFilled />}
              </div>
              {showTrafficTables && (
                <div style={{ marginLeft: '-10px', marginTop: '20px' }}>
                  {Object.entries(allTrafficSourceData).map(([trafficName, trafficData], trafficSourceName) => {
                    if (trafficName === 'facebook') {
                      return (
                        <>
                          {/*Facebook Button*/}
                          {showBacktoCampButton.length > 0 && showBacktoCampButton.map((data, index) => data.trafficName === trafficName ? (
                              <Button size="xs" compact mr={10} onClick={() => handleRemoveCampaignBackButton("facebook")}>
                                  Back to facebook campaign
                                </Button>
                            ) : null
                          )}
                          {showBacktoAdsetButton.length > 0 && showBacktoAdsetButton.map((data, index) => data.trafficName === trafficName ? (
                              <Button size="xs" compact mr={10} onClick={() => handleRemoveAdsetBackButton("facebook")}>
                                  Back to facebook Adset
                                </Button>
                            ) : null
                          )}
                          {/*Facebook Table*/}
                          {showTrafficSourceTable && showTrafficSourceTable.map((traffic) => 
                            traffic.show === true && traffic.trafficName === trafficName ? (
                              <Table striped highlightOnHover withBorder my={5} fontSize="xs">
                                <thead>
                                  <tr>
                                    {campaignColumns[trafficName]?.map((col, index) => {
                                      if(col[0] !== 'checkbox') {
                                        const isColor = showTrafficColumnsColor.find(d => d.tableName === 'facebook' && d.columnName === col[0] && d.show === true);
                                        return(
                                          <th
                                            key={index}
                                            draggable
                                            onDragStart={(e) => handleDragStart(e, index, 'facebook')}
                                            onDrop={(e) => handleDrop(e, index)}
                                            onDragOver={(e) => e.preventDefault()}
                                            onClick={(e) => handleTrafficSorting(col[0], 'facebook')}
                                            style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                          >
                                          <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                            {col[1]}{(() => {
                                              const tableSortConfig = trafficSortConfig.filter(config => config.table === 'facebook');
                                              const sort = tableSortConfig.find(config => config.key === col[0]);

                                              if (sort) {
                                                return sort.direction === 'ascending' ? <IconCaretUpFilled /> : <IconCaretDownFilled />;
                                              }
                                            })()}
                                          </span>
                                          </th>
                                        );
                                      }else {
                                        return(
                                          <th key={index}>
                                            <Checkbox
                                              checked={facebookTrafficChecked}
                                              onChange={() => handleTrafficSourceColumnChecked('facebook')}
                                            />
                                          </th>
                                        );
                                      }})}
                                  </tr>
                                </thead>
                                <tbody>
                                  {searchRows(campaignColumns[trafficName], 'facebook', 'facebook')}
                                  {facebookData.map((rowData, rowIndex) => {                                                         
                                    return (
                                      <tr
                                        key={rowIndex}
                                        style={rowData['traffic_source'] === 'Total' ? { fontWeight: 'bold' } : {}}
                                      >
                                        {campaignColumns[trafficName]?.map((col, colIndex) => {
                                          if (col[0] !== 'checkbox') {
                                            const isColor = showTrafficColumnsColor.find(d => d.tableName === 'facebook' && d.columnName === col[0] && d.show === true);
                                            return (
                                              <td
                                                key={colIndex}
                                                onClick={() => {
                                                  if (col[0] === 'visiopt_conversions' && rowData['traffic_source'] !== 'Total') {
                                                    handleCustomerJourneyOpenModal('', rowData, rowData[col[0]], 'traffic');
                                                  } else {
                                                    handleTrafficDataChecked(col[1], rowIndex, trafficName, rowData['type'], rowData['cpc'], rowData['ctr'], rowData['cpm']);
                                                  }
                                                  handleColumnDataClicked('', 'facebook', col[0]);
                                                }}
                                                style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                              >
                                                {rowData[col[0]]}
                                              </td>
                                            );
                                          } else {
                                            if (rowData['traffic_source'] === 'Total') {
                                              return <td key={colIndex}></td>;
                                            }
                                            return (
                                              <td key={colIndex}>
                                                <Checkbox
                                                  checked={rowData[col[0]]}
                                                  onChange={() => handleTrafficRowChecked(rowIndex, 'facebook')}
                                                />
                                              </td>
                                            );
                                          }
                                        })}
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            ) : null
                          )}
                          {/*Facebook Adset Table*/}
                          {showAdsetTable && showAdsetTable.map((adset) => 
                            adset.show === true && adset.trafficName === trafficName ? (
                              <Table striped highlightOnHover withBorder my={5} fontSize="xs">
                                <thead>
                                  <tr>
                                    {campaignColumns['facebookAdset']?.map((col, index) => {
                                      if(col[0] !== 'checkbox') {
                                        const isColor = showColumnColor.find(d => d.tableName === 'facebookAdset' && d.columnName === col[0] && d.show === true);
                                        return(
                                          <th
                                            key={index}
                                            draggable
                                            onDragStart={(e) => handleDragStart(e, index, 'facebookAdset')}
                                            onDrop={(e) => handleDrop(e, index)}
                                            onDragOver={(e) => e.preventDefault()}
                                            onClick={(e) => handleTrafficSorting(col[0], 'facebookAdset')}
                                            style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                          >
                                          <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                            {col[1]}{(() => {
                                              const tableSortConfig = trafficSortConfig.filter(config => config.table === 'facebookAdset');
                                              const sort = tableSortConfig.find(config => config.key === col[0]);

                                              if (sort) {
                                                return sort.direction === 'ascending' ? <IconCaretUpFilled /> : <IconCaretDownFilled />;
                                              }
                                            })()}
                                          </span>
                                          </th>
                                        );
                                      }else {
                                        return(
                                          <th key={index}>
                                            <Checkbox
                                              checked={facebookAdsetTrafficChecked}
                                              onChange={() => handleTrafficSourceColumnChecked('facebookAdset')}
                                            />
                                          </th>
                                        );
                                      }})}
                                  </tr>
                                </thead>
                                <tbody>
                                  {searchRows(campaignColumns['facebookAdset'], 'facebook', 'facebookAdset')}
                                  {facebookAdsetData.map((rowData, rowIndex) => {

                                    return (
                                      <tr
                                        key={rowIndex}
                                        style={rowData['traffic_source'] === 'Total' ? { fontWeight: 'bold' } : {}}
                                      >
                                        {campaignColumns['facebookAdset']?.map((col, colIndex) => {
                                          if (col[0] !== 'checkbox') {
                                            const isColor = showColumnColor.find(d => d.tableName === 'facebookAdset' && d.columnName === col[0] && d.show === true);
                                            return (
                                              <td
                                                key={colIndex}
                                                onClick={() => {
                                                  if(col[0] === 'visiopt_conversions' && rowData['traffic_source'] !== 'Total'){
                                                    handleCustomerJourneyOpenModal('', rowData, rowData[col[0]], "traffic");
                                                  }else {
                                                    handleTrafficAdsetDataClick(col[1], rowIndex, 'facebookAdset', trafficName, rowData['traffic_camp_name'], rowData['type'], rowData['cpc'], rowData['ctr'], rowData['cpm']);
                                                  }
                                                  handleColumnDataClicked('', 'facebookAdset', col[0]);
                                                }}
                                                style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                              >
                                                {rowData[col[0]]}
                                              </td>
                                            );
                                          } else {
                                            if (rowData['traffic_source'] === 'Total') {
                                              return <td key={colIndex}></td>;
                                            }
                                            return (
                                              <td key={colIndex}>
                                                <Checkbox
                                                  checked={rowData[col[0]]}
                                                  onChange={() => handleTrafficRowChecked(rowIndex, 'facebookAdset')}
                                                />
                                              </td>
                                            );
                                          }
                                        })}
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            ) : null
                          )}
                          {/*Facebook Ads Table*/}
                          {showAdsTable && showAdsTable.map((ads) => 
                            ads.show === true && ads.trafficName === trafficName ? (
                              <Table striped highlightOnHover withBorder my={5} fontSize="xs">
                                <thead>
                                  <tr>
                                    {campaignColumns['facebookAds']?.map((col, index) => {
                                      if(col[0] !== 'checkbox') {
                                        const isColor = showColumnColor.find(d => d.tableName === 'facebookAds' && d.columnName === col[0] && d.show === true);
                                        return(
                                          <th
                                            key={index}
                                            draggable
                                            onDragStart={(e) => handleDragStart(e, index, 'facebookAds')}
                                            onDrop={(e) => handleDrop(e, index)}
                                            onDragOver={(e) => e.preventDefault()}
                                            onClick={(e) => handleTrafficSorting(col[0], 'facebookAds')}
                                            style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                          >
                                          <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                            {col[1]}{(() => {
                                              const tableSortConfig = trafficSortConfig.filter(config => config.table === 'facebookAds');
                                              const sort = tableSortConfig.find(config => config.key === col[0]);

                                              if (sort) {
                                                return sort.direction === 'ascending' ? <IconCaretUpFilled /> : <IconCaretDownFilled />;
                                              }
                                            })()}
                                          </span>
                                          </th>
                                        );
                                      }else{
                                        return(
                                          <th key={index}>
                                            <Checkbox />                                                        
                                          </th>
                                        );
                                      }})}
                                  </tr>
                                </thead>
                                <tbody>
                                  {searchRows(campaignColumns['facebookAds'], 'facebook', 'facebookAds')}
                                  {facebookAdsData.map((rowData, rowIndex) => {

                                    return (
                                      <tr
                                        key={rowIndex}
                                        style={rowData['traffic_source'] === 'Total' ? { fontWeight: 'bold' } : {}}
                                      >
                                        {campaignColumns['facebookAds']?.map((col, colIndex) => {
                                          if (col[0] !== 'checkbox') {
                                            const isColor = showColumnColor.find(d => d.tableName === 'facebookAds' && d.columnName === col[0] && d.show === true);
                                            return (
                                              <td
                                                key={colIndex}
                                                onClick={() => {
                                                  if(col[0] === 'visiopt_conversions' && rowData['traffic_source'] !== 'Total'){
                                                    handleCustomerJourneyOpenModal('', rowData, rowData[col[0]], "traffic");
                                                  }
                                                  handleColumnDataClicked('', 'facebookAds', col[0]);
                                                }}
                                                style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                              >
                                                {rowData[col[0]]}
                                              </td>
                                            );
                                          } else {
                                            if (rowData['traffic_source'] === 'Total') {
                                              return <td key={colIndex}></td>;
                                            }
                                            return (
                                              <td key={colIndex}>
                                                <Checkbox
                                                  checked={rowData[col[0]]}
                                                  onChange={() => handleTrafficRowChecked(rowIndex, 'facebookAds')}
                                                />
                                              </td>
                                            );
                                          }
                                        })}
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            ) : null
                          )}
                        </>
                      )
                    }else if(trafficName === 'google'){
                        return (
                          <>
                            {/*Google Button*/}
                            {showBacktoCampButton.length > 0 && showBacktoCampButton.map((data, index) => data.trafficName === trafficName ? (
                                <Button size="xs" compact mr={10} onClick={() => handleRemoveCampaignBackButton("google")}>
                                    Back to google campaign
                                  </Button>
                              ) : null
                            )}
                            {showBacktoAdsetButton.length > 0 && showBacktoAdsetButton.map((data, index) => data.trafficName === trafficName ? (
                                <Button size="xs" compact mr={10} onClick={() => handleRemoveAdsetBackButton("google")}>
                                    Back to google Adset
                                  </Button>
                              ) : null
                            )}
                            {/*Google Table*/}
                            {showTrafficSourceTable &&
                              showTrafficSourceTable.map((traffic) => {
                                if (traffic.trafficName === "google" && traffic.show === true) {
                                  return (
                                    <>
                                      <Table striped highlightOnHover withBorder my={5} fontSize="xs">
                                        <thead>
                                          <tr>
                                            {campaignColumns[trafficName]?.map((col, index) => {
                                              if(col[0] !== 'checkbox') {
                                                const isColor = showColumnColor.find(d => d.tableName === 'google' && d.columnName === col[0] && d.show === true);
                                                return(
                                                  <th
                                                    key={index}
                                                    draggable
                                                    onDragStart={(e) => handleDragStart(e, index, 'google')}
                                                    onDrop={(e) => handleDrop(e, index)}
                                                    onDragOver={(e) => e.preventDefault()}
                                                    onClick={(e) => handleTrafficSorting(col[0], 'google')}
                                                    style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                                  >
                                                  <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                                    {col[1]}{(() => {
                                                      const tableSortConfig = trafficSortConfig.filter(config => config.table === 'google');
                                                      const sort = tableSortConfig.find(config => config.key === col[0]);

                                                      if (sort) {
                                                        return sort.direction === 'ascending' ? <IconCaretUpFilled /> : <IconCaretDownFilled />;
                                                      }
                                                    })()}
                                                  </span>
                                                  </th>
                                                );
                                              }else{
                                                return(
                                                  <th key={index}>
                                                    <Checkbox
                                                      checked={googleTrafficChecked}
                                                      onChange={() => handleTrafficSourceColumnChecked('google')}
                                                    />
                                                  </th>
                                                );
                                              }})}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {searchRows(campaignColumns[trafficName], 'google', 'google')}
                                          {googleData.map((rowData, rowIndex) => {

                                            return (
                                              <tr
                                                key={rowIndex}
                                                style={rowData['traffic_source'] === 'Total' ? { fontWeight: 'bold' } : {}}
                                              >
                                                {campaignColumns[trafficName].map((col, colIndex) => {
                                                  if (col[0] !== 'checkbox') {
                                                    const isColor = showColumnColor.find(d => d.tableName === 'google' && d.columnName === col[0] && d.show === true);
                                                    return (
                                                      <td
                                                        key={colIndex}
                                                        onClick={() => {
                                                        col[0] === 'visiopt_conversions' && rowData['traffic_source'] !== 'Total' ?
                                                            handleCustomerJourneyOpenModal('', rowData, rowData[col[0]], "traffic")
                                                          : handleTrafficDataChecked(col[1], rowIndex, trafficName, rowData['type'], rowData['cpc'], rowData['ctr'], rowData['cpm'])
                                                        handleColumnDataClicked('', 'google', col[0]);
                                                        }}
                                                        style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                                      >
                                                        {rowData[col[0]]}
                                                      </td>
                                                    );
                                                  } else {
                                                    if (rowData['traffic_source'] === 'Total') {
                                                      return <td key={colIndex}></td>;
                                                    }
                                                    return (
                                                      <td key={colIndex}>
                                                        <Checkbox
                                                          checked={rowData[col[0]]}
                                                          onChange={() => handleTrafficRowChecked(rowIndex, 'google')}
                                                        />
                                                      </td>
                                                    );
                                                  }
                                                })}
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </Table>
                                    </>
                                  );
                                }
                                return null;
                              })}
                              {/*Google Adset Table*/}
                              {showAdsetTable && showAdsetTable.map((adset) => 
                                adset.show === true && adset.trafficName === trafficName ? (
                                  <Table striped highlightOnHover withBorder my={5} fontSize="xs">
                                    <thead>
                                      <tr>
                                        {campaignColumns['googleAdset'].map((col, index) => {
                                          if(col[0] !== 'checkbox') {
                                            const isColor = showColumnColor.find(d => d.tableName === 'googleAdset' && d.columnName === col[0] && d.show === true);
                                            return(
                                              <th
                                                key={index}
                                                draggable
                                                onDragStart={(e) => handleDragStart(e, index, 'googleAdset')}
                                                onDrop={(e) => handleDrop(e, index)}
                                                onDragOver={(e) => e.preventDefault()}
                                                onClick={(e) => handleTrafficSorting(col[0], 'googleAdset')}
                                                style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                              >
                                              <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                                {col[1]}{(() => {
                                                  const tableSortConfig = trafficSortConfig.filter(config => config.table === 'googleAdset');
                                                  const sort = tableSortConfig.find(config => config.key === col[0]);

                                                  if (sort) {
                                                    return sort.direction === 'ascending' ? <IconCaretUpFilled /> : <IconCaretDownFilled />;
                                                  }
                                                })()}
                                              </span>
                                              </th>
                                            );
                                          }else{
                                            return(
                                              <th key={index}>
                                                <Checkbox
                                                  checked={googleAdsetTrafficChecked}
                                                  onChange={() => handleTrafficSourceColumnChecked('googleAdset')}
                                                />
                                              </th>
                                            );
                                          }})}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {searchRows(campaignColumns['googleAdset'], 'google', 'googleAdset')}
                                      {googleAdsetData.map((rowData, rowIndex) => {

                                        return (
                                          <tr
                                            key={rowIndex}
                                            style={rowData['traffic_source'] === 'Total' ? { fontWeight: 'bold' } : {}}
                                          >
                                            {campaignColumns['googleAdset'].map((col, colIndex) => {
                                              if (col[0] !== 'checkbox') {
                                                const isColor = showColumnColor.find(d => d.tableName === 'googleAdset' && d.columnName === col[0] && d.show === true);
                                                return (
                                                  <td
                                                    key={colIndex}
                                                    onClick={() => {
                                                      if(col[0] === 'visiopt_conversions' && rowData['traffic_source'] !== 'Total'){
                                                        handleCustomerJourneyOpenModal('', rowData, rowData[col[0]], "traffic");
                                                      }else {
                                                        handleTrafficAdsetDataClick(col[1], rowIndex, 'googleAdset', trafficName, rowData['traffic_camp_name'], rowData['type'], rowData['cpc'], rowData['ctr'], rowData['cpm']);
                                                      }
                                                      handleColumnDataClicked('', 'googleAdset', col[0]);
                                                    }}
                                                    style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                                  >
                                                    {rowData[col[0]]}
                                                  </td>
                                                );
                                              } else {
                                                if (rowData['traffic_source'] === 'Total') {
                                                  return <td key={colIndex}></td>;
                                                }
                                                return (
                                                  <td key={colIndex}>
                                                    <Checkbox
                                                      checked={rowData[col[0]]}
                                                      onChange={() => handleTrafficRowChecked(rowIndex, 'googleAdset')}
                                                    />
                                                  </td>
                                                );
                                              }
                                            })}
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                ) : null
                              )}
                              {/*Google Ads Table*/}
                              {showAdsTable && showAdsTable.map((ads) => 
                                ads.show === true && ads.trafficName === trafficName ? (
                                  <Table striped highlightOnHover withBorder my={5} fontSize="xs">
                                    <thead>
                                      <tr>
                                        {campaignColumns['googleAds'].map((col, index) => {
                                          if(col[0] !== 'checkbox') {
                                            const isColor = showColumnColor.find(d => d.tableName === 'googleAds' && d.columnName === col[0] && d.show === true);
                                            return(
                                              <th
                                                key={index}
                                                draggable
                                                onDragStart={(e) => handleDragStart(e, index, 'googleAds')}
                                                onDrop={(e) => handleDrop(e, index)}
                                                onDragOver={(e) => e.preventDefault()}
                                                onClick={(e) => handleTrafficSorting(col[0], 'googleAds')}
                                                style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                              >
                                              <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                                {col[1]}{(() => {
                                                  const tableSortConfig = trafficSortConfig.filter(config => config.table === 'googleAds');
                                                  const sort = tableSortConfig.find(config => config.key === col[0]);

                                                  if (sort) {
                                                    return sort.direction === 'ascending' ? <IconCaretUpFilled /> : <IconCaretDownFilled />;
                                                  }
                                                })()}
                                              </span>
                                              </th>
                                            );
                                          }else{
                                            return(
                                              <th key={index}>
                                                <Checkbox />
                                              </th>
                                            );
                                          }})}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {searchRows(campaignColumns['googleAds'], 'google', 'googleAds')}
                                      {googleAdsData.map((rowData, rowIndex) => {

                                        return (
                                          <tr
                                            key={rowIndex}
                                            style={rowData['traffic_source'] === 'Total' ? { fontWeight: 'bold' } : {}}
                                          >
                                            {campaignColumns['googleAds'].map((col, colIndex) => {
                                              if (col[0] !== 'checkbox') {
                                                const isColor = showColumnColor.find(d => d.tableName === 'googleAds' && d.columnName === col[0] && d.show === true);
                                                return (
                                                  <td
                                                    key={colIndex}
                                                    onClick={() => {
                                                      if(col[0] === 'visiopt_conversions' && rowData['traffic_source'] !== 'Total'){
                                                        handleCustomerJourneyOpenModal('', rowData, rowData[col[0]], "traffic");
                                                      }
                                                      handleColumnDataClicked('', 'googleAds', col[0]);
                                                    }}
                                                    style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                                  >
                                                    {rowData[col[0]]}
                                                  </td>
                                                );
                                              } else {
                                                if (rowData['traffic_source'] === 'Total') {
                                                  return <td key={colIndex}></td>;
                                                }
                                                return (
                                                  <td key={colIndex}>
                                                    <Checkbox
                                                      checked={rowData[col[0]]}
                                                      onChange={() => handleTrafficRowChecked(rowIndex, 'googleAds')}
                                                    />
                                                  </td>
                                                );
                                              }
                                            })}
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                ) : null
                              )}
                          </>
                        );
                    }else if(trafficName === 'youtube'){
                      return (
                        <>
                          {/*Youtube Button*/}
                          {showBacktoCampButton.length > 0 && showBacktoCampButton.map((data, index) => data.trafficName === trafficName ? (
                              <Button size="xs" compact mr={10} onClick={() => handleRemoveCampaignBackButton("youtube")}>
                                  Back to youtube campaign
                                </Button>
                            ) : null
                          )}
                          {showBacktoAdsetButton.length > 0 && showBacktoAdsetButton.map((data, index) => data.trafficName === trafficName ? (
                              <Button size="xs" compact mr={10} onClick={() => handleRemoveAdsetBackButton("youtube")}>
                                  Back to youtube Adset
                                </Button>
                            ) : null
                          )}
                          {/*Youtube Table*/}
                          {showTrafficSourceTable &&
                            showTrafficSourceTable.map((traffic) => {
                              if (traffic.trafficName === "youtube" && traffic.show === true) {
                                return (
                                  <>
                                    <Table striped highlightOnHover withBorder my={5} fontSize="xs">
                                      <thead>
                                        <tr>
                                          {campaignColumns[trafficName].map((col, index) => {
                                            if(col[0] !== 'checkbox') {
                                              const isColor = showColumnColor.find(d => d.tableName === 'youtube' && d.columnName === col[0] && d.show === true);
                                              return(
                                                <th
                                                  key={index}
                                                  draggable
                                                  onDragStart={(e) => handleDragStart(e, index, 'youtube')}
                                                  onDrop={(e) => handleDrop(e, index)}
                                                  onDragOver={(e) => e.preventDefault()}
                                                  onClick={(e) => handleTrafficSorting(col[0], 'youtube')}
                                                  style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                                >
                                                <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                                  {col[1]}{(() => {
                                                    const tableSortConfig = trafficSortConfig.filter(config => config.table === 'youtube');
                                                    const sort = tableSortConfig.find(config => config.key === col[0]);

                                                    if (sort) {
                                                      return sort.direction === 'ascending' ? <IconCaretUpFilled /> : <IconCaretDownFilled />;
                                                    }
                                                  })()}
                                                </span>
                                                </th>
                                              );
                                            }else{
                                              return(
                                                <th key={index}>
                                                  <Checkbox
                                                    checked={youtubeTrafficChecked}
                                                    onChange={() => handleTrafficSourceColumnChecked('youtube')}
                                                  />
                                                </th>
                                              );
                                            }})}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {searchRows(campaignColumns[trafficName], 'youtube', 'youtube')}
                                        {youtubeData.map((rowData, rowIndex) => {

                                          return (
                                            <tr
                                              key={rowIndex}
                                              style={rowData['traffic_source'] === 'Total' ? { fontWeight: 'bold' } : {}}
                                            >
                                              {campaignColumns[trafficName].map((col, colIndex) => {
                                                if (col[0] !== 'checkbox') {
                                                  const isColor = showColumnColor.find(d => d.tableName === 'youtube' && d.columnName === col[0] && d.show === true);
                                                  return (
                                                    <td
                                                      key={colIndex}
                                                      onClick={() => {
                                                      col[0] === 'visiopt_conversions' && rowData['traffic_source'] !== 'Total' ?
                                                          handleCustomerJourneyOpenModal('', rowData, rowData[col[0]], "traffic")
                                                        : handleTrafficDataChecked(col[1], rowIndex, trafficName, rowData['type'], rowData['cpc'], rowData['ctr'], rowData['cpm'])
                                                      handleColumnDataClicked('', 'youtube', col[0]);
                                                      }}
                                                      style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                                    >
                                                      {rowData[col[0]]}
                                                    </td>
                                                  );
                                                } else {
                                                  if (rowData['traffic_source'] === 'Total') {
                                                    return <td key={colIndex}></td>;
                                                  }
                                                  return (
                                                    <td key={colIndex}>
                                                      <Checkbox
                                                        checked={rowData[col[0]]}
                                                        onChange={() => handleTrafficRowChecked(rowIndex, 'youtube')}
                                                      />
                                                    </td>
                                                  );
                                                }
                                              })}
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </Table>
                                  </>
                                );
                              }
                              return null;
                            })}
                            {/*Youtube Adset Table*/}
                            {showAdsetTable && showAdsetTable.map((adset) => 
                              adset.show === true && adset.trafficName === trafficName ? (
                                <Table striped highlightOnHover withBorder my={5} fontSize="xs">
                                  <thead>
                                    <tr>
                                      {campaignColumns['youtubeAdset'].map((col, index) => {
                                        if(col[0] !== 'checkbox') {
                                          const isColor = showColumnColor.find(d => d.tableName === 'youtubeAdset' && d.columnName === col[0] && d.show === true);
                                          return(
                                            <th
                                              key={index}
                                              draggable
                                              onDragStart={(e) => handleDragStart(e, index, 'youtubeAdset')}
                                              onDrop={(e) => handleDrop(e, index)}
                                              onDragOver={(e) => e.preventDefault()}
                                              onClick={(e) => handleTrafficSorting(col[0], 'youtubeAdset')}
                                              style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                            >
                                            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                              {col[1]}{(() => {
                                                const tableSortConfig = trafficSortConfig.filter(config => config.table === 'youtubeAdset');
                                                const sort = tableSortConfig.find(config => config.key === col[0]);

                                                if (sort) {
                                                  return sort.direction === 'ascending' ? <IconCaretUpFilled /> : <IconCaretDownFilled />;
                                                }
                                              })()}
                                            </span>
                                            </th>
                                          );
                                        }else{
                                          return(
                                            <th key={index}>
                                              <Checkbox
                                                checked={youtubeAdsetTrafficChecked}
                                                onChange={() => handleTrafficSourceColumnChecked('youtubeAdset')}
                                              />
                                            </th>
                                          );
                                        }})}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {searchRows(campaignColumns['youtubeAdset'], 'youtube', 'youtubeAdset')}
                                    {youtubeAdsetData.map((rowData, rowIndex) => {

                                      return (
                                        <tr
                                          key={rowIndex}
                                          style={rowData['traffic_source'] === 'Total' ? { fontWeight: 'bold' } : {}}
                                        >
                                          {campaignColumns['youtubeAdset'].map((col, colIndex) => {
                                            if (col[0] !== 'checkbox') {
                                              const isColor = showColumnColor.find(d => d.tableName === 'youtubeAdset' && d.columnName === col[0] && d.show === true);
                                              return (
                                                <td
                                                  key={colIndex}
                                                  onClick={() => {
                                                    if(col[0] === 'visiopt_conversions' && rowData['traffic_source'] !== 'Total'){
                                                      handleCustomerJourneyOpenModal('', rowData, rowData[col[0]], "traffic");
                                                    }else {
                                                      handleTrafficAdsetDataClick(col[1], rowIndex, 'youtubeAdset', trafficName, rowData['traffic_camp_name'], rowData['type'], rowData['cpc'], rowData['ctr'], rowData['cpm']);
                                                    }
                                                    handleColumnDataClicked('', 'youtubeAdset', col[0]);
                                                  }}
                                                  style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                                >
                                                  {rowData[col[0]]}
                                                </td>
                                              );
                                            } else {
                                              if (rowData['traffic_source'] === 'Total') {
                                                return <td key={colIndex}></td>;
                                              }
                                              return (
                                                <td key={colIndex}>
                                                  <Checkbox
                                                    checked={rowData[col[0]]}
                                                    onChange={() => handleTrafficRowChecked(rowIndex, 'youtubeAdset')}
                                                  />
                                                </td>
                                              );
                                            }
                                          })}
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </Table>
                              ) : null
                            )}
                            {/*Youtube Ads Table*/}
                            {showAdsTable && showAdsTable.map((ads) => 
                              ads.show === true && ads.trafficName === trafficName ? (
                                <Table striped highlightOnHover withBorder my={5} fontSize="xs">
                                  <thead>
                                    <tr>
                                      {campaignColumns['youtubeAds'].map((col, index) => {
                                        if(col[0] !== 'checkbox') {
                                          const isColor = showColumnColor.find(d => d.tableName === 'youtubeAds' && d.columnName === col[0] && d.show === true);
                                          return(
                                            <th
                                              key={index}
                                              draggable
                                              onDragStart={(e) => handleDragStart(e, index, 'youtubeAds')}
                                              onDrop={(e) => handleDrop(e, index)}
                                              onDragOver={(e) => e.preventDefault()}
                                              onClick={(e) => handleTrafficSorting(col[0], 'youtubeAds')}
                                              style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                            >
                                            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                              {col[1]}{(() => {
                                                const tableSortConfig = trafficSortConfig.filter(config => config.table === 'youtubeAds');
                                                const sort = tableSortConfig.find(config => config.key === col[0]);

                                                if (sort) {
                                                  return sort.direction === 'ascending' ? <IconCaretUpFilled /> : <IconCaretDownFilled />;
                                                }
                                              })()}
                                            </span>
                                            </th>
                                          );
                                        }else{
                                          return(
                                            <th key={index}>
                                              <Checkbox />
                                            </th>
                                          );
                                        }})}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {searchRows(campaignColumns['youtubeAds'], 'youtube', 'youtubeAds')}
                                    {youtubeAdsData.map((rowData, rowIndex) => {

                                      return (
                                        <tr
                                          key={rowIndex}
                                          style={rowData['traffic_source'] === 'Total' ? { fontWeight: 'bold' } : {}}
                                        >
                                          {campaignColumns['youtubeAds'].map((col, colIndex) => {
                                            if (col[0] !== 'checkbox') {
                                              const isColor = showColumnColor.find(d => d.tableName === 'youtubeAds' && d.columnName === col[0] && d.show === true);
                                              return (
                                                <td
                                                  key={colIndex}
                                                  onClick={() => {
                                                    if(col[0] === 'visiopt_conversions' && rowData['traffic_source'] !== 'Total'){
                                                      handleCustomerJourneyOpenModal('', rowData, rowData[col[0]], "traffic");
                                                    }
                                                    handleColumnDataClicked('', 'youtubeAds', col[0]);
                                                  }}
                                                  style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                                >
                                                  {rowData[col[0]]}
                                                </td>
                                              );
                                            } else {
                                              if (rowData['traffic_source'] === 'Total') {
                                                return <td key={colIndex}></td>;
                                              }
                                              return (
                                                <td key={colIndex}>
                                                  <Checkbox
                                                    checked={rowData[col[0]]}
                                                    onChange={() => handleTrafficRowChecked(rowIndex, 'youtubeAds')}
                                                  />
                                                </td>
                                              );
                                            }
                                          })}
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </Table>
                              ) : null
                            )}
                        </>
                      );
                    }else if(trafficName === 'microsoft'){

                      return (
                        <>
                          {/*Microsoft Button*/}
                          {showBacktoCampButton.length > 0 && showBacktoCampButton.map((data, index) => data.trafficName === trafficName ? (
                              <Button size="xs" compact mr={10} onClick={() => handleRemoveCampaignBackButton("microsoft")}>
                                  Back to microsoft campaign
                                </Button>
                            ) : null
                          )}
                          {showBacktoAdsetButton.length > 0 && showBacktoAdsetButton.map((data, index) => data.trafficName === trafficName ? (
                              <Button size="xs" compact mr={10} onClick={() => handleRemoveAdsetBackButton("microsoft")}>
                                  Back to microsoft Adset
                                </Button>
                            ) : null
                          )}
                          {/*Microsoft Table*/}
                          {showTrafficSourceTable &&
                            showTrafficSourceTable.map((traffic) => {
                              if (traffic.trafficName === "microsoft" && traffic.show === true) {
                                return (
                                  <>
                                    <Table striped highlightOnHover withBorder my={5} fontSize="xs">
                                      <thead>
                                        <tr>
                                          {campaignColumns[trafficName].map((col, index) => {
                                            if(col[0] !== 'checkbox') {
                                              const isColor = showColumnColor.find(d => d.tableName === 'microsoft' && d.columnName === col[0] && d.show === true);
                                              return(
                                                <th
                                                  key={index}
                                                  draggable
                                                  onDragStart={(e) => handleDragStart(e, index, 'microsoft')}
                                                  onDrop={(e) => handleDrop(e, index)}
                                                  onDragOver={(e) => e.preventDefault()}
                                                  onClick={(e) => handleTrafficSorting(col[0], 'microsoft')}
                                                  style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                                >
                                                <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                                  {col[1]}{(() => {
                                                    const tableSortConfig = trafficSortConfig.filter(config => config.table === 'microsoft');
                                                    const sort = tableSortConfig.find(config => config.key === col[0]);

                                                    if (sort) {
                                                      return sort.direction === 'ascending' ? <IconCaretUpFilled /> : <IconCaretDownFilled />;
                                                    }
                                                  })()}
                                                </span>
                                                </th>
                                              );
                                            }else{
                                              return(
                                                <th key={index}>
                                                  <Checkbox
                                                    checked={microsoftTrafficChecked}
                                                    onChange={() => handleTrafficSourceColumnChecked('microsoft')}
                                                  />
                                                </th>
                                              );
                                            }})}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {searchRows(campaignColumns[trafficName], 'microsoft', 'microsoft')}
                                        {microsoftData.map((rowData, rowIndex) => {

                                          return (
                                            <tr
                                              key={rowIndex}
                                              style={rowData['traffic_source'] === 'Total' ? { fontWeight: 'bold' } : {}}
                                            >
                                              {campaignColumns[trafficName].map((col, colIndex) => {
                                                if (col[0] !== 'checkbox') {
                                                  const isColor = showColumnColor.find(d => d.tableName === 'microsoft' && d.columnName === col[0] && d.show === true);
                                                  return (
                                                    <td
                                                      key={colIndex}
                                                      onClick={() => {
                                                      col[0] === 'visiopt_conversions' && rowData['traffic_source'] !== 'Total' ?
                                                          handleCustomerJourneyOpenModal('', rowData, rowData[col[0]], "traffic")
                                                        : handleTrafficDataChecked(col[1], rowIndex, trafficName, rowData['type'], rowData['cpc'], rowData['ctr'], rowData['cpm'])
                                                      handleColumnDataClicked('','microsoft', col[0]);
                                                      }}
                                                      style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                                    >
                                                      {rowData[col[0]]}
                                                    </td>
                                                  );
                                                } else {
                                                  if (rowData['traffic_source'] === 'Total') {
                                                    return <td key={colIndex}></td>;
                                                  }
                                                  return (
                                                    <td key={colIndex}>
                                                      <Checkbox
                                                        checked={rowData[col[0]]}
                                                        onChange={() => handleTrafficRowChecked(rowIndex, 'microsoft')}
                                                      />
                                                    </td>
                                                  );
                                                }
                                              })}
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </Table>
                                  </>
                                );
                              }
                              return null;
                            })}
                            {/*Microsoft Adset Table*/}
                            {showAdsetTable && showAdsetTable.map((adset) => 
                              adset.show === true && adset.trafficName === trafficName ? (
                                <Table striped highlightOnHover withBorder my={5} fontSize="xs">
                                  <thead>
                                    <tr>
                                      {campaignColumns['microsoftAdset'].map((col, index) => {
                                        if(col[0] !== 'checkbox') {
                                          const isColor = showColumnColor.find(d => d.tableName === 'microsoftAdset' && d.columnName === col[0] && d.show === true);
                                          return(
                                            <th
                                              key={index}
                                              draggable
                                              onDragStart={(e) => handleDragStart(e, index, 'microsoftAdset')}
                                              onDrop={(e) => handleDrop(e, index)}
                                              onDragOver={(e) => e.preventDefault()}
                                              onClick={(e) => handleTrafficSorting(col[0], 'microsoftAdset')}
                                              style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                            >
                                            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                              {col[1]}{(() => {
                                                const tableSortConfig = trafficSortConfig.filter(config => config.table === 'microsoftAdset');
                                                const sort = tableSortConfig.find(config => config.key === col[0]);

                                                if (sort) {
                                                  return sort.direction === 'ascending' ? <IconCaretUpFilled /> : <IconCaretDownFilled />;
                                                }
                                              })()}
                                            </span>
                                            </th>
                                          );
                                        }else{
                                          return(
                                            <th key={index}>
                                              <Checkbox
                                                checked={microsoftAdsetTrafficChecked}
                                                onChange={() => handleTrafficSourceColumnChecked('microsoftAdset')}
                                              />
                                            </th>
                                          );
                                        }})}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {searchRows(campaignColumns['microsoftAdset'], 'microsoft', 'microsoftAdset')}
                                    {microsoftAdsetData.map((rowData, rowIndex) => {

                                      return (
                                        <tr
                                          key={rowIndex}
                                          style={rowData['traffic_source'] === 'Total' ? { fontWeight: 'bold' } : {}}
                                        >
                                          {campaignColumns['microsoftAdset'].map((col, colIndex) => {
                                            if (col[0] !== 'checkbox') {
                                              const isColor = showColumnColor.find(d => d.tableName === 'microsoftAdset' && d.columnName === col[0] && d.show === true);
                                              return (
                                                <td
                                                  key={colIndex}
                                                  onClick={() => {
                                                    if(col[0] === 'visiopt_conversions' && rowData['traffic_source'] !== 'Total'){
                                                      handleCustomerJourneyOpenModal('', rowData, rowData[col[0]], "traffic");
                                                    }else {
                                                      handleTrafficAdsetDataClick(col[1], rowIndex, 'microsoftAdset', trafficName, rowData['traffic_camp_name'], rowData['type'], rowData['cpc'], rowData['ctr'], rowData['cpm']);
                                                    }
                                                    handleColumnDataClicked('', 'microsoftAdset', col[0]);
                                                  }}
                                                  style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                                >
                                                  {rowData[col[0]]}
                                                </td>
                                              );
                                            } else {
                                              if (rowData['traffic_source'] === 'Total') {
                                                return <td key={colIndex}></td>;
                                              }
                                              return (
                                                <td key={colIndex}>
                                                  <Checkbox
                                                    checked={rowData[col[0]]}
                                                    onChange={() => handleTrafficRowChecked(rowIndex, 'microsoftAdset')}
                                                  />
                                                </td>
                                              );
                                            }
                                          })}
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </Table>
                              ) : null
                            )}
                            {/*Microsoft Ads Table*/}
                            {showAdsTable && showAdsTable.map((ads) => 
                              ads.show === true && ads.trafficName === trafficName ? (
                                <Table striped highlightOnHover withBorder my={5} fontSize="xs">
                                  <thead>
                                    <tr>
                                      {campaignColumns['microsoftAds'].map((col, index) => {
                                        if(col[0] !== 'checkbox') {
                                          const isColor = showColumnColor.find(d => d.tableName === 'microsoftAds' && d.columnName === col[0] && d.show === true);
                                          return(
                                            <th
                                              key={index}
                                              draggable
                                              onDragStart={(e) => handleDragStart(e, index, 'microsoftAds')}
                                              onDrop={(e) => handleDrop(e, index)}
                                              onDragOver={(e) => e.preventDefault()}
                                              onClick={(e) => handleTrafficSorting(col[0], 'microsoftAds')}
                                              style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                            >
                                            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                              {col[1]}{(() => {
                                                const tableSortConfig = trafficSortConfig.filter(config => config.table === 'microsoftAds');
                                                const sort = tableSortConfig.find(config => config.key === col[0]);

                                                if (sort) {
                                                  return sort.direction === 'ascending' ? <IconCaretUpFilled /> : <IconCaretDownFilled />;
                                                }
                                              })()}
                                            </span>
                                            </th>
                                          );
                                        }else{
                                          return (
                                            <th key={index}>
                                              <Checkbox />
                                            </th>
                                          );
                                        }})}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {searchRows(campaignColumns['microsoftAds'], 'microsoft', 'microsoftAds')}
                                    {microsoftAdsData.map((rowData, rowIndex) => {

                                      return (
                                        <tr
                                          key={rowIndex}
                                          style={rowData['traffic_source'] === 'Total' ? { fontWeight: 'bold' } : {}}
                                        >
                                          {campaignColumns['microsoftAds'].map((col, colIndex) => {
                                            if (col[0] !== 'checkbox') {
                                              const isColor = showColumnColor.find(d => d.tableName === 'microsoftAds' && d.columnName === col[0] && d.show === true);
                                              return (
                                                <td
                                                  key={colIndex}
                                                  onClick={() => {
                                                    if(col[0] === 'visiopt_conversions' && rowData['traffic_source'] !== 'Total'){
                                                      handleCustomerJourneyOpenModal('', rowData, rowData[col[0]], "traffic");
                                                    }
                                                    handleColumnDataClicked('', 'microsoftAds', col[0]);
                                                  }}
                                                  style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                                >
                                                  {rowData[col[0]]}
                                                </td>
                                              );
                                            } else {
                                              if (rowData['traffic_source'] === 'Total') {
                                                return <td key={colIndex}></td>;
                                              }
                                              return (
                                                <td key={colIndex}>
                                                  <Checkbox
                                                    checked={rowData[col[0]]}
                                                    onChange={() => handleTrafficRowChecked(rowIndex, 'microsoftAds')}
                                                  />
                                                </td>
                                              );
                                            }
                                          })}
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </Table>
                              ) : null
                            )}
                        </>
                      );
                    }else if(trafficName === 'tiktok'){

                      return (
                        <>
                          {/*Tiktok Button*/}
                          {showBacktoCampButton.length > 0 && showBacktoCampButton.map((data, index) => data.trafficName === trafficName ? (
                              <Button size="xs" compact mr={10} onClick={() => handleRemoveCampaignBackButton("tiktok")}>
                                  Back to tiktok campaign
                                </Button>
                            ) : null
                          )}
                          {showBacktoAdsetButton.length > 0 && showBacktoAdsetButton.map((data, index) => data.trafficName === trafficName ? (
                              <Button size="xs" compact mr={10} onClick={() => handleRemoveAdsetBackButton("tiktok")}>
                                  Back to tiktok Adset
                                </Button>
                            ) : null
                          )}
                          {/*Tiktok Table*/}
                          {showTrafficSourceTable &&
                            showTrafficSourceTable.map((traffic) => {
                              if (traffic.trafficName === "tiktok" && traffic.show === true) {
                                return (
                                  <>
                                    <Table striped highlightOnHover withBorder my={5} fontSize="xs">
                                      <thead>
                                        <tr>
                                          {campaignColumns[trafficName].map((col, index) => {
                                            if(col[0] !== 'checkbox') {
                                              const isColor = showColumnColor.find(d => d.tableName === 'tiktok' && d.columnName === col[0] && d.show === true);
                                              return(
                                                <th
                                                  key={index}
                                                  draggable
                                                  onDragStart={(e) => handleDragStart(e, index, 'tiktok')}
                                                  onDrop={(e) => handleDrop(e, index)}
                                                  onDragOver={(e) => e.preventDefault()}
                                                  onClick={(e) => handleTrafficSorting(col[0], 'tiktok')}
                                                  style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                                >
                                                <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                                  {col[1]}{(() => {
                                                    const tableSortConfig = trafficSortConfig.filter(config => config.table === 'tiktok');
                                                    const sort = tableSortConfig.find(config => config.key === col[0]);

                                                    if (sort) {
                                                      return sort.direction === 'ascending' ? <IconCaretUpFilled /> : <IconCaretDownFilled />;
                                                    }
                                                  })()}
                                                </span>
                                                </th>
                                              );
                                            }else{
                                              return(
                                                <th key={index}>
                                                  <Checkbox
                                                    checked={tiktokTrafficChecked}
                                                    onChange={() => handleTrafficSourceColumnChecked('tiktok')}
                                                  />
                                                </th>
                                              );
                                            }})}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {searchRows(campaignColumns[trafficName], 'tiktok', 'tiktok')}
                                        {tiktokData.map((rowData, rowIndex) => {

                                          return (
                                            <tr
                                              key={rowIndex}
                                              style={rowData['traffic_source'] === 'Total' ? { fontWeight: 'bold' } : {}}
                                            >
                                              {campaignColumns[trafficName].map((col, colIndex) => {
                                                if (col[0] !== 'checkbox') {
                                                  const isColor = showColumnColor.find(d => d.tableName === 'tiktok' && d.columnName === col[0] && d.show === true);
                                                  return (
                                                    <td
                                                      key={colIndex}
                                                      onClick={() => {
                                                      col[0] === 'visiopt_conversions' && rowData['traffic_source'] !== 'Total' ?
                                                          handleCustomerJourneyOpenModal('', rowData, rowData[col[0]], "traffic")
                                                        : handleTrafficDataChecked(col[1], rowIndex, trafficName, rowData['type'], rowData['cpc'], rowData['ctr'], rowData['cpm'])
                                                      handleColumnDataClicked('', 'tiktok', col[0]);
                                                      }}
                                                      style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                                    >
                                                      {rowData[col[0]]}
                                                    </td>
                                                  );
                                                } else {
                                                  if (rowData['traffic_source'] === 'Total') {
                                                    return <td key={colIndex}></td>;
                                                  }
                                                  return (
                                                    <td key={colIndex}>
                                                      <Checkbox
                                                        checked={rowData[col[0]]}
                                                        onChange={() => handleTrafficRowChecked(rowIndex, 'tiktok')}
                                                      />
                                                    </td>
                                                  );
                                                }
                                              })}
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </Table>
                                  </>
                                );
                              }
                              return null;
                            })}
                            {/*Tiktok Adset Table*/}
                            {showAdsetTable && showAdsetTable.map((adset) => 
                              adset.show === true && adset.trafficName === trafficName ? (
                                <Table striped highlightOnHover withBorder my={5} fontSize="xs">
                                  <thead>
                                    <tr>
                                      {campaignColumns['tiktokAdset'].map((col, index) => {
                                        if(col[0] !== 'checkbox') {
                                          const isColor = showColumnColor.find(d => d.tableName === 'tiktokAdset' && d.columnName === col[0] && d.show === true);
                                          return(
                                            <th
                                              key={index}
                                              draggable
                                              onDragStart={(e) => handleDragStart(e, index, 'tiktokAdset')}
                                              onDrop={(e) => handleDrop(e, index)}
                                              onDragOver={(e) => e.preventDefault()}
                                              onClick={(e) => handleTrafficSorting(col[0], 'tiktokAdset')}
                                              style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                            >
                                            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                              {col[1]}{(() => {
                                                const tableSortConfig = trafficSortConfig.filter(config => config.table === 'tiktokAdset');
                                                const sort = tableSortConfig.find(config => config.key === col[0]);

                                                if (sort) {
                                                  return sort.direction === 'ascending' ? <IconCaretUpFilled /> : <IconCaretDownFilled />;
                                                }
                                              })()}
                                            </span>
                                            </th>
                                          );
                                        }else{
                                          return(
                                            <th key={index}>
                                              <Checkbox
                                                checked={tiktokAdsetTrafficChecked}
                                                onChange={() => handleTrafficSourceColumnChecked('tiktokAdset')}
                                              />
                                            </th>
                                          );
                                        }})}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {searchRows(campaignColumns['tiktokAdset'], 'tiktok', 'tiktokAdset')}
                                    {tiktokAdsetData.map((rowData, rowIndex) => {

                                      return (
                                        <tr
                                          key={rowIndex}
                                          style={rowData['traffic_source'] === 'Total' ? { fontWeight: 'bold' } : {}}
                                        >
                                          {campaignColumns['tiktokAdset'].map((col, colIndex) => {
                                            if (col[0] !== 'checkbox') {
                                              const isColor = showColumnColor.find(d => d.tableName === 'tiktokAdset' && d.columnName === col[0] && d.show === true);
                                              return (
                                                <td
                                                  key={colIndex}
                                                  onClick={() => {
                                                    if(col[0] === 'visiopt_conversions' && rowData['traffic_source'] !== 'Total'){
                                                      handleCustomerJourneyOpenModal('', rowData, rowData[col[0]], "traffic");
                                                    }else {
                                                      handleTrafficAdsetDataClick(col[1], rowIndex, 'tiktokAdset', trafficName, rowData['traffic_camp_name'], rowData['type'], rowData['cpc'], rowData['ctr'], rowData['cpm']);
                                                    }
                                                    handleColumnDataClicked('', 'tiktokAdset', col[0]);
                                                  }}
                                                  style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                                >
                                                  {rowData[col[0]]}
                                                </td>
                                              );
                                            } else {
                                              if (rowData['traffic_source'] === 'Total') {
                                                return <td key={colIndex}></td>;
                                              }
                                              return (
                                                <td key={colIndex}>
                                                  <Checkbox
                                                    checked={rowData[col[0]]}
                                                    onChange={() => handleTrafficRowChecked(rowIndex, 'tiktokAdset')}
                                                  />
                                                </td>
                                              );
                                            }
                                          })}
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </Table>
                              ) : null
                            )}
                            {/*Tiktok Ads Table*/}
                            {showAdsTable && showAdsTable.map((ads) => 
                              ads.show === true && ads.trafficName === trafficName ? (
                                <Table striped highlightOnHover withBorder my={5} fontSize="xs">
                                  <thead>
                                    <tr>
                                      {campaignColumns['tiktokAds'].map((col, index) => {
                                        if(col[0] !== 'checkbox') {
                                          const isColor = showColumnColor.find(d => d.tableName === 'tiktokAds' && d.columnName === col[0] && d.show === true);
                                          return(
                                            <th
                                              key={index}
                                              draggable
                                              onDragStart={(e) => handleDragStart(e, index, 'tiktokAds')}
                                              onDrop={(e) => handleDrop(e, index)}
                                              onDragOver={(e) => e.preventDefault()}
                                              onClick={(e) => handleTrafficSorting(col[0], 'tiktokAds')}
                                              style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                            >
                                            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                              {col[1]}{(() => {
                                                const tableSortConfig = trafficSortConfig.filter(config => config.table === 'tiktokAds');
                                                const sort = tableSortConfig.find(config => config.key === col[0]);

                                                if (sort) {
                                                  return sort.direction === 'ascending' ? <IconCaretUpFilled /> : <IconCaretDownFilled />;
                                                }
                                              })()}
                                            </span>
                                            </th>
                                          );
                                        }else{
                                          return(
                                            <th key={index}>
                                              <Checkbox />
                                            </th>
                                          );
                                        }})}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {searchRows(campaignColumns['tiktokAds'], 'tiktok', 'tiktokAds')}
                                    {tiktokAdsData.map((rowData, rowIndex) => {

                                      return (
                                        <tr
                                          key={rowIndex}
                                          style={rowData['traffic_source'] === 'Total' ? { fontWeight: 'bold' } : {}}
                                        >
                                          {campaignColumns['tiktokAds'].map((col, colIndex) => {
                                            if (col[0] !== 'checkbox') {
                                              const isColor = showColumnColor.find(d => d.tableName === 'tiktokAds' && d.columnName === col[0] && d.show === true);
                                              return (
                                                <td
                                                  key={colIndex}
                                                  onClick={() => {
                                                    if(col[0] === 'visiopt_conversions' && rowData['traffic_source'] !== 'Total'){
                                                      handleCustomerJourneyOpenModal('', rowData, rowData[col[0]], "traffic");
                                                    }
                                                    handleColumnDataClicked('', 'tiktokAds', col[0]);
                                                  }}
                                                  style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                                >
                                                  {rowData[col[0]]}
                                                </td>
                                              );
                                            } else {
                                              if (rowData['traffic_source'] === 'Total') {
                                                return <td key={colIndex}></td>;
                                              }
                                              return (
                                                <td key={colIndex}>
                                                  <Checkbox
                                                    checked={rowData[col[0]]}
                                                    onChange={() => handleTrafficRowChecked(rowIndex, 'tiktokAds')}
                                                  />
                                                </td>
                                              );
                                            }
                                          })}
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </Table>
                              ) : null
                            )}
                        </>
                      );
                    }else if(trafficName === 'applovin'){
                      return(
                        <>
                          {/*Applovin Button*/}
                          {showBacktoCampButton.length > 0 && showBacktoCampButton.map((data, index) => data.trafficName === trafficName ? (
                              <Button size="xs" compact mr={10} onClick={() => handleRemoveCampaignBackButton("applovin")}>
                                  Back to Applovin campaign
                                </Button>
                            ) : null
                          )}
                          {/*Applovin Table*/}
                          {showTrafficSourceTable && showTrafficSourceTable.map((traffic) => {
                            if (traffic.trafficName === "applovin" && traffic.show === true) {
                              return (
                                <>
                                  <Table striped highlightOnHover withBorder my={5} fontSize="xs">
                                    <thead>
                                      <tr>
                                        {campaignColumns[trafficName].map((col, index) => {
                                          if(col[0] !== 'checkbox') {
                                            const isColor = showColumnColor.find(d => d.tableName === 'applovin' && d.columnName === col[0] && d.show === true);
                                            return(
                                              <th
                                                key={index}
                                                draggable
                                                onDragStart={(e) => handleDragStart(e, index, 'applovin')}
                                                onDrop={(e) => handleDrop(e, index)}
                                                onDragOver={(e) => e.preventDefault()}
                                                onClick={(e) => handleTrafficSorting(col[0], 'applovin')}
                                                style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                              >
                                              <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                                {col[1]}{(() => {
                                                  const tableSortConfig = trafficSortConfig.filter(config => config.table === 'applovin');
                                                  const sort = tableSortConfig.find(config => config.key === col[0]);

                                                  if (sort) {
                                                    return sort.direction === 'ascending' ? <IconCaretUpFilled /> : <IconCaretDownFilled />;
                                                  }
                                                })()}
                                              </span>
                                              </th>
                                            );
                                          }else{
                                            return(
                                              <th key={index}>
                                                <Checkbox
                                                  checked={applovinTrafficChecked}
                                                  onChange={() => handleTrafficSourceColumnChecked('applovin')}
                                                />
                                              </th>
                                            );
                                          }})}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {searchRows(campaignColumns[trafficName], 'applovin', 'applovin')}
                                      {applovinData.map((rowData, rowIndex) => {

                                        return (
                                          <tr
                                            key={rowIndex}
                                            style={rowData['traffic_source'] === 'Total' ? { fontWeight: 'bold' } : {}}
                                          >
                                            {campaignColumns[trafficName].map((col, colIndex) => {
                                              if (col[0] !== 'checkbox') {
                                                const isColor = showColumnColor.find(d => d.tableName === 'applovin' && d.columnName === col[0] && d.show === true);
                                                return (
                                                  <td
                                                    key={colIndex}
                                                    onClick={() => {
                                                    col[0] === 'visiopt_conversions' && rowData['traffic_source'] !== 'Total' ?
                                                        handleCustomerJourneyOpenModal('', rowData, rowData[col[0]], "traffic")
                                                      : handleTrafficDataChecked(col[1], rowIndex, trafficName, rowData['type'], rowData['cpc'], rowData['ctr'], rowData['cpm'])
                                                    handleColumnDataClicked('','applovin', col[0]);
                                                    }}
                                                    style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                                  >
                                                    {rowData[col[0]]}
                                                  </td>
                                                );
                                              } else {
                                                if (rowData['traffic_source'] === 'Total') {
                                                  return <td key={colIndex}></td>;
                                                }
                                                return (
                                                  <td key={colIndex}>
                                                    <Checkbox
                                                      checked={rowData[col[0]]}
                                                      onChange={() => handleTrafficRowChecked(rowIndex, 'applovin')}
                                                    />
                                                  </td>
                                                );
                                              }
                                            })}
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                </>
                              );
                            }
                            return null;
                          })}
                          {/*Microsoft Ads Table*/}
                          {showAdsetTable && showAdsetTable.map((ads) => ads.show === true && ads.trafficName === trafficName ? (
                              <Table striped highlightOnHover withBorder my={5} fontSize="xs">
                                <thead>
                                  <tr>
                                    {campaignColumns['applovinAdset'].map((col, index) => {
                                      if(col[0] !== 'checkbox') {
                                        const isColor = showColumnColor.find(d => d.tableName === 'applovinAdset' && d.columnName === col[0] && d.show === true);
                                        return(
                                          <th
                                            key={index}
                                            draggable
                                            onDragStart={(e) => handleDragStart(e, index, 'applovinAdset')}
                                            onDrop={(e) => handleDrop(e, index)}
                                            onDragOver={(e) => e.preventDefault()}
                                            onClick={(e) => handleTrafficSorting(col[0], 'applovinAdset')}
                                            style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                          >
                                          <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                            {col[1]}{(() => {
                                              const tableSortConfig = trafficSortConfig.filter(config => config.table === 'applovinAdset');
                                              const sort = tableSortConfig.find(config => config.key === col[0]);

                                              if (sort) {
                                                return sort.direction === 'ascending' ? <IconCaretUpFilled /> : <IconCaretDownFilled />;
                                              }
                                            })()}
                                          </span>
                                          </th>
                                        );
                                      }else{
                                        return (
                                          <th key={index}>
                                            <Checkbox />
                                          </th>
                                        );
                                      }})}
                                  </tr>
                                </thead>
                                <tbody>
                                  {searchRows(campaignColumns['applovinAdset'], 'applovin', 'applovinAdset')}
                                  {applovinAdsetData.map((rowData, rowIndex) => {

                                    return (
                                      <tr
                                        key={rowIndex}
                                        style={rowData['traffic_source'] === 'Total' ? { fontWeight: 'bold' } : {}}
                                      >
                                        {campaignColumns['applovinAdset'].map((col, colIndex) => {
                                          if (col[0] !== 'checkbox') {
                                            const isColor = showColumnColor.find(d => d.tableName === 'applovinAdset' && d.columnName === col[0] && d.show === true);
                                            return (
                                              <td
                                                key={colIndex}
                                                onClick={() => {
                                                  if(col[0] === 'visiopt_conversions' && rowData['traffic_source'] !== 'Total'){
                                                    handleCustomerJourneyOpenModal('', rowData, rowData[col[0]], "traffic");
                                                  }
                                                  handleColumnDataClicked('', 'applovinAdset', col[0]);
                                                }}
                                                style={{ cursor:'pointer', backgroundColor: isColor ? '#E4E9ED' : 'inherit' }}
                                              >
                                                {rowData[col[0]]}
                                              </td>
                                            );
                                          } else {
                                            if (rowData['traffic_source'] === 'Total') {
                                              return <td key={colIndex}></td>;
                                            }
                                            return (
                                              <td key={colIndex}>
                                                <Checkbox
                                                  checked={rowData[col[0]]}
                                                  onChange={() => handleTrafficRowChecked(rowIndex, 'applovinAdset')}
                                                />
                                              </td>
                                            );
                                          }
                                        })}
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            ) : null
                          )}
                        </>
                      );
                    }
                  })}
                </div>
              )}
            </Card>
          </>
        )}
        <Space h="xl"/>
        <div>
          {showUpdateButton && <Button onClick={() => handleUpdateReport('update')}>Update Report</Button> }          
          <Button color="teal" ml={showUpdateButton ? 10 : 0} onClick={() => handleSaveReportModalOpenModal('save')}>
            Save Report
          </Button>
          <Button color="red" ml={10} onClick={handleCancelReport}>
           Cancel
          </Button>
        </div>
      </Card>
    </Container>
	</>
	);
}