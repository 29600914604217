import { Group, Text, Button, Box, Grid, ActionIcon, Flex, Container, Card, Switch, Skeleton } from '@mantine/core';
import { IconHelp, IconTrash, IconPencil, IconPlayerPlay, IconPlayerPause, IconCaretUpFilled, IconCaretDownFilled } from '@tabler/icons-react';
import { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { useDocumentTitle } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { HeaderMenu } from "../../components/HeaderMenu/HeaderMenu";
import { useNavigate } from "react-router-dom";
import InsightsApi from "../../api/InsightsApi";
import { SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";

export function ManageHeatmaps() {
  useDocumentTitle("Visiopt - Insights");
  const navigate = useNavigate();

  const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');
  const [heatMapList, setHeatMapList] = useState([]);
  const [sortedHeatmapList, setSortedHeatmapList] = useState([]);
  const heatmapIconRef = useRef(null);
  const highAccuracyIconRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({});
  const [sortKeyName, setSortKeyName] = useState('');
  const [sortingDirection, setSortingDirection] = useState('');
  const [showSkeleton, setShowSkeleton] = useState(false);
  const handleHeaderMenuComponentChange = (Value:any) => {
    setSelectedMemberWebsite(Value);
  };

  const handleRecordToggle = (pauseStatus:any, heatmapId:any) => {
    if(pauseStatus === '0') {
      modals.openConfirmModal({
        title: 'Please confirm your action',
        children: (
          <Text size="sm">
            Are you sure you want to pause this Heat Map?
          </Text>
        ),
        labels: { confirm: 'Confirm', cancel: 'Cancel' },
        onCancel: () => {console.log('Cancel')},
        onConfirm: () => handleUpdateHeatmapRecord(heatmapId)
      });
    }else{
      handleUpdateHeatmapRecord(heatmapId);
    }
  };

  const handleUpdateHeatmapRecord = (heatMapId:any) => {
    InsightsApi.resumeheatmap({
      heatmap_id: heatMapId
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          fetchHeatMapList();
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
  };

  const updateReport = (heat_map_id:any) => {
    navigate("/create-heatmap", { state: { heat_map_id: heat_map_id} });
  };

  const deleteHeatmapConfirm = (deleteHeatmapId:any) => modals.openConfirmModal({
    title: 'Please confirm your action',
    children: (
      <Text size="sm">
        Are you sure you want to delete this Heat Map?
      </Text>
    ),
    labels: { confirm: 'Confirm', cancel: 'Cancel' },
    onCancel: () => {console.log('Cancel')},
    onConfirm: () => handleDeleteHeatMap(deleteHeatmapId)
  });

  const handleDeleteHeatMap = (deleteHeatmapId:any) => {
    if(deleteHeatmapId !== '') {
      InsightsApi.deleteheatmap({
        heatmap_id: deleteHeatmapId
      })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            if (response.status === 401 || response.status === 400) {
              navigate("/logout");
            }
          }
        })
        .then(data => {
          if(data.status === 1){
            fetchHeatMapList();
          }
        })
        .catch(error => {
          console.error("API Error:", error);
        });
    }
  };

  const handleHeatMapClick = (heatmap_id:any,heatmapname:any,page_url:any) => {
    navigate('/heatmap-view', { state: { heatmap_id: heatmap_id, heatmapname: heatmapname, page_url:page_url, system_website_id: selectedMemberWebsite} });
  };

  const fetchHeatMapList = () => {
    setShowSkeleton(true);
    InsightsApi.getheatmaplist({
        system_website_id: selectedMemberWebsite
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
            setShowSkeleton(false);
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setShowSkeleton(false);
          setHeatMapList(data.data);
          setSortedHeatmapList(data.data);
        }
      })
      .catch(error => {
        setShowSkeleton(false);
        console.error("API Error:", error);
      });
  };

  const handleHighAccuracyChange = (heatMapId:any, highAccuracyValue:any) => {
    const dataObject = {
      heatmap_id: heatMapId,
      high_accuracy: highAccuracyValue !== '' ? '0' : '1',
      sysyem_website_id: selectedMemberWebsite
    };
    InsightsApi.updatehighaccuracy(dataObject)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          fetchHeatMapList();
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
  };

  useEffect(() => {
    if(selectedMemberWebsite !== '') {
      fetchHeatMapList();
    }
  }, [selectedMemberWebsite]);

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    let iconRef = id === 152 ? heatmapIconRef : highAccuracyIconRef;
    if (iconRef.current) {
      const { top, left, width } = iconRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      if(id === 329){
        setTooltipPosition({
          [id]: { top: top + scrollOffset + 22, left: left - width - 140 },
        });
      } else {
        setTooltipPosition({
          [id]: { top: top + scrollOffset, left: left + width + 10 },
        });
      }
    }
  };

  const handleSorting = (keyName: string) => {
    let newDirection = sortingDirection === 'desc' ? 'asc' : 'desc';
    setSortingDirection(newDirection);
    setSortKeyName(keyName);
    
    const sortedHeatMapList = [...heatMapList].sort((a, b) => {
      if (!keyName) return 0;

      let valueA = a[keyName];
      let valueB = b[keyName];
      if (keyName === 'create_at') {
        valueA = new Date(a[keyName]);
        valueB = new Date(b[keyName]);
      } else {
        valueA = a[keyName]?.toString().toLowerCase() || '';
        valueB = b[keyName]?.toString().toLowerCase() || '';
      }

      if (valueA < valueB) return newDirection === 'asc' ? -1 : 1;
      if (valueA > valueB) return newDirection === 'asc' ? 1 : -1;
      return 0;
    });
    setSortedHeatmapList(sortedHeatMapList);
  };

	return (
		<>
      <HeaderMenu HeaderMenuValue={selectedMemberWebsite} HeaderMenuOnChange={handleHeaderMenuComponentChange}/>
      {showHelpModal && Object.keys(tooltipPosition).map((key) => (
        <SelectTooltip
          isVisible={showHelpModal}
          setIsVisible={setShowHelpModal}
          id={helpTargetId}
          tooltipPosition={tooltipPosition[key]}
        />
      ))}
      <Container size="xl">
        <Card shadow="sm" padding="sm" radius="md" withBorder>
          <Group position="apart" mb="xs">
            <Text weight={500}>HEAT MAPS <IconHelp ref={heatmapIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(152)}/></Text>
          </Group>
          <Button type="button">
            <Link to="/create-heatmap" style={{color:"white",textDecoration: "none"}}>
              Create a New Heat Map
            </Link>
          </Button>
          <Box
            p={4}
            pl={20}
            mt={10}
            sx={(theme) => ({
              backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : '#f8f9fa',
              padding: "10px",
              borderRadius: theme.radius.md,
              fontWeight: "bold",
            })}
          >
          {sortedHeatmapList.some(item => Array.isArray(item) ? item.length > 0 : true) && (
            <Grid gutter="xs">
              <Grid.Col span={2}>
                <Text mt={3} weight={500} onClick={() => handleSorting('heatmap_name')}>
                  <span style={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }}>
                    {'Heat Map Name'}
                    {sortKeyName === 'heatmap_name' && (
                      sortingDirection === 'asc' ? <IconCaretUpFilled /> : <IconCaretDownFilled />
                    )}
                  </span>
                </Text>
              </Grid.Col>
              <Grid.Col span={2}>
                <Text mt={3} weight={500} onClick={() => handleSorting('funnel_name')}>                  
                  <span style={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }}>
                    {'Funnel Name'}
                    {sortKeyName === 'funnel_name' && (
                      sortingDirection === 'asc' ? <IconCaretUpFilled /> : <IconCaretDownFilled />
                    )}
                  </span>
                </Text>
              </Grid.Col>
              <Grid.Col span={3}>
                <Text mt={3} weight={500} onClick={() => handleSorting('page_url')}>
                  <span style={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }}>
                    {'Page URL'}
                    {sortKeyName === 'page_url' && (
                      sortingDirection === 'asc' ? <IconCaretUpFilled /> : <IconCaretDownFilled />
                    )}
                  </span>
                </Text>
              </Grid.Col>
              <Grid.Col span={2}>
                <Text mt={3} weight={500} onClick={() => handleSorting('create_at')}>
                  <span style={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }}>
                    {'Date Created'}
                    {sortKeyName === 'create_at' && (
                      sortingDirection === 'asc' ? <IconCaretUpFilled /> : <IconCaretDownFilled />
                    )}
                  </span>
                </Text>
              </Grid.Col>
              <Grid.Col span={2} style={{ display: 'flex', gap: '3px' }}>
                <Text mt={3} weight={500} onClick={() => handleSorting('high_accuracy')}>
                  <span style={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }}>
                    {'High Accuracy'}
                    {sortKeyName === 'high_accuracy' && (
                      sortingDirection === 'asc' ? <IconCaretUpFilled /> : <IconCaretDownFilled />
                    )}                  
                  </span>
                </Text>
                <IconHelp ref={highAccuracyIconRef} size="1.1rem" color="red" style={{marginTop: '6px', cursor: 'pointer'}} onClick={() => handleHelpClick(329)}/>
              </Grid.Col>
            </Grid>
          )}
          </Box>
          {showSkeleton ? (
            <Flex direction="column" gap={4}>  
              {Array.from({ length: 10 }).map((_, index) => (
                <Skeleton key={index} height={44} width={1246} />
              ))}
            </Flex>
          ) : (
          sortedHeatmapList && sortedHeatmapList.map((heatMap, heatmapIndex) => (
            <Box
              key={heatmapIndex}
              p={4}
              pl={20}
              mt={10}
              sx={(theme) => ({
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : '#f8f9fa',
                padding: "10px",
                borderRadius: theme.radius.md,
                border: "0.0625rem solid transparent",
                borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[1] : '#dee2e6'
              })}
            >
              <Grid gutter="xs">
                <Grid.Col span={2}>
                  <Text
                    mt={3}
                    onClick={() => handleHeatMapClick(heatMap.heatmap_id,heatMap.heatmap_name,heatMap.page_url)}
                    style={{ cursor: 'pointer' }}
                  >
                    {heatMap.heatmap_name}
                  </Text>
                </Grid.Col>
                <Grid.Col span={2}>
                  <Text mt={3} >
                    {heatMap.funnel_name}
                  </Text>
                </Grid.Col>
                <Grid.Col span={3}>
                  <Text mt={3} 
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      cursor: 'pointer'
                    }}
                    title={heatMap.page_url}
                  >
                    {heatMap.page_url}
                  </Text>
                </Grid.Col>
                <Grid.Col span={2}>
                  <Text mt={3} >
                    {heatMap.create_at}
                  </Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <Switch
                    checked={heatMap.high_accuracy !== ''}
                    size="md"
                    onLabel="ON"
                    offLabel="OFF"
                    onChange={() => handleHighAccuracyChange(heatMap.heatmap_id, heatMap.high_accuracy)}
                  />
                </Grid.Col>
                <Grid.Col span={2}>
                  <Flex
                    justify="flex-end"
                    align="center"
                    direction="row"
                    wrap="wrap"
                  >
                  <ActionIcon
                    variant="default"
                    size="lg"
                    mx={3}
                    onClick={() => handleRecordToggle(heatMap.pause_status, heatMap.heatmap_id)}
                    title="Pause/Resume"
                  >
                    {heatMap.pause_status === '1' ? (
                      <IconPlayerPlay size="1.1rem" />
                    ) : (
                      <IconPlayerPause size="1.1rem" />
                    )}
                  </ActionIcon>
                  <ActionIcon
                    variant="default"
                    size="lg"
                    mx={3}
                    onClick={() => updateReport(heatMap.heatmap_id)}
                    title="Edit"
                  >
                    <IconPencil size="1.1rem" />
                  </ActionIcon>
                  <ActionIcon
                    variant="default"
                    size="lg"
                    mx={3}
                    onClick={() => deleteHeatmapConfirm(heatMap.heatmap_id)}
                    title="Delete"
                  >
                    <IconTrash size="1.1rem" />
                  </ActionIcon>
                  </Flex>
                </Grid.Col>
              </Grid>
            </Box>
          ))
          )}
        </Card>
      </Container>
		</>
	);
}