import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Modal, Button, Group, Text, Skeleton, Textarea } from '@mantine/core';
import { IconHelp, IconCheck } from '@tabler/icons-react';
import TestsApi from "../../api/TestsApi";
import { notifications } from '@mantine/notifications';
import { modals } from '@mantine/modals';

export function SetWinner({ selectedMemberWebsite, pageId, taguchiTestId, varIdshow, pageUrlShow, testTypeShow, openWinnerModal, setWinnerModalOpen, setWinnerCloseModal, errorModelOpen, errorMessage, showErrorHeader, showMessageHeader, systemUsageLoader, setloading }:any) {
  const navigate = useNavigate();
  const [setWinnerData, setSetWinnerData] = useState([]);

  const handleOpenConfirmModal = () => {
    if (testTypeShow === 'classic_test') {
      modals.openConfirmModal({
        title: 'Set Winner',
        children: (
          <Text size="sm">
            The winner has been set and the test discarded.<br />
            Please change the links in your site to point to the winning page: {pageUrlShow}
          </Text>
        ),
        labels: { confirm: 'Set Winner', cancel: 'Cancel' },
        confirmProps: { color: 'red' },
        onCancel: () => console.log('Cancel'),
        onConfirm: () => {
          setWinnerCloseModal();
          handleSaveSetWinner(varIdshow);
        },
      });
    } else {
      Setwinner(varIdshow);
      setWinnerModalOpen(true);
    }
  };

  useEffect(() => {
    if (openWinnerModal) {
      handleOpenConfirmModal();
    }
  }, [openWinnerModal]);

  const Setwinner = async (varId) =>{
    setloading(true);
    TestsApi.setwinnerpopupget({
      page_id: pageId,
      test_id: varId
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate('/logout');
        }
      }
    })
    .then((data) => {
      if (data.status === 1) {
        setSetWinnerData(data.data.set_winner_data);
        setloading(false);
      }
    })
    .catch((error) => {
      console.error('API Error:', error);
      setloading(false);
    });
  };

  const handleSaveSetWinner = (varId) => {
    setWinnerCloseModal();
    systemUsageLoader(true);
    TestsApi.discardtest({
      system_website_id: selectedMemberWebsite,
      page_id:pageId,
      taguchi_test_id:taguchiTestId,
      type:"winner",
      test_win_id:varIdshow
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        systemUsageLoader(false);
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        navigate('/review-tests');
      } else {
        errorMessage(data.message);
        errorModelOpen(true);
        showErrorHeader(true);
        systemUsageLoader(false);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
      systemUsageLoader(false);
    });
  }

  return (
    <>
      <Modal.Root opened={openWinnerModal} onClose={setWinnerCloseModal} size="80%">
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>
              SET WINNER <IconHelp size="1.1rem" color="red" style={{ marginBottom: -3 }} />
            </Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <Text weight={500} fz={14}>CONGRATULATIONS, YOU HAVE A WINNER!</Text>
            <Text mt={12} fz={14}>
              To ensure only the winning versions of the variables will show for your visitors in the future you have two choices:
            </Text>
            <Text mt={12} fz={14}>
              <Text fz={14} >1. Do nothing, simply leave the Visiopt test script on your page, and we will make sure only the winning versions show to visitors</Text>
              <Text fz={14} mt={12}>2. You can manually update the winning versions of the variables on your page. For your convenience we display the code that must be changed on your page in the table below.
                <Text fz={14}>To use this option, please update your page and then stop the test.</Text>
                <Text fz={14}>(To update the source code of your page to the winning versions of the variables you have to search for the text/code of the control versions of each variable and replace it with the text/code of the corresponding winning versions.)</Text>
              </Text>
            </Text>
            <form>
              {/*<Skeleton visible={setloading}>*/}
                {setWinnerData.map((item,index) => (
                  <>
                  <Text fz={14} mt={15} weight={500}>Variable: {item.variable_title}</Text>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                    <div style={{ flex: 1, marginRight: 10 }}>
                      <Text fz={14}>Control Version: {item.control_title}</Text>
                      <Textarea
                        value={item.control_content}
                        minRows={5}
                        readOnly
                      />
                    </div>
                    <div style={{ flex: 1, marginLeft: 10 }}>
                      <Text fz={14} ml={10}>Winner Version: {item.ver_title}</Text>
                      <Textarea
                        value={item.ver_content}
                        minRows={5}
                        readOnly
                      />
                    </div>
                  </div>
                  </>
                ))}
              {/*</Skeleton>*/}
              <Group position="right">
                <Button type="button" size="sm" w={100} mt={10} mb={10} onClick={handleSaveSetWinner}>
                  Continue
                </Button>
              </Group>
            </form>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  );
}