import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Text, Select, Input, Button } from '@mantine/core';
import TestsApi from "../../api/TestsApi";

export function AddTest({
  FunnelRotationData,
  SelectedMemberWebsite,
  SelectedThumbnails,
  SelectedThumbnail,
  ClassicAbSplitTest,
  ErrorMessageChange,
  ShowErrorHeader,
  ErrorCloseModal,
  }:any) {

  const navigate = useNavigate();
  const [testName, setTestName] = useState('');
  const [selectedFunnelRotation, setSelectedFunnelRotation] = useState('');

  const handleFunnelRotationChange = (value) => {
    setSelectedFunnelRotation(value);
  };

    const handleSaveClassicRotationPage = (e) => {
      e.preventDefault();

      TestsApi.saveclassicabrotationpage({
        system_website_id: SelectedMemberWebsite,
        rotation_page: SelectedThumbnails,
        control_page_id: SelectedThumbnail,
        funnel_id: selectedFunnelRotation,
        test_name: testName,
      })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate('/logout');
          }
        }
      })
      .then((data) => {
        if (data.status === 1) {
          navigate("/reviewing-test", { state: { pageId: SelectedThumbnail, funnelId:selectedFunnelRotation, taguchiTestId:ClassicAbSplitTest} });
        } else {
          ErrorMessageChange(data.message);
          ShowErrorHeader(true);
          ErrorCloseModal(true);
        }
      })
      .catch((error) => {
        console.error('API Error:', error);
      });
    };

  return (
    <>
      <form onSubmit={handleSaveClassicRotationPage}>
        <div style={{ display: 'flex',marginTop:15}}>
          <Text mt={5} mr={5}>Select Funnel:</Text><div style={{color:"red",marginTop:5}}>*</div>
          <Select 
            w={200}
            ml={10}
            defaultValue={selectedFunnelRotation}
            value={selectedFunnelRotation}
            onChange={handleFunnelRotationChange}
            data={FunnelRotationData}
            searchable
            nothingFound="No options"
            maxDropdownHeight={300}
          />
          <Text mt={5} mr={5} ml={15}>Test Name:</Text><div style={{color:"red",marginTop:5}}>*</div>
          <Input w={200} ml={10} value={testName} onChange={(e) => setTestName(e.target.value)} />
          <Button type="submit" ml={10} style={{color:"white",textDecoration: "none"}}>Continue</Button>
        </div>
      </form>
    </>
  );
}