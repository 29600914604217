import React, { useState, useMemo } from 'react';
import { useDocumentTitle } from '@mantine/hooks';
import { HeaderMenu } from "../../components/HeaderMenu/HeaderMenu";
import { Modal, Container, Card, Group, Text, Select, Button, Grid, Radio, Box, MultiSelect } from '@mantine/core';
import { IconHelp } from '@tabler/icons-react';
import './ReviewingTest.css';

import { Chart } from "react-google-charts";
import { useEffect } from 'react';
import { visiUserData } from '../../GeneralFunction';
import { useNavigate,useLocation } from "react-router-dom";
import { IconX } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { VisiLoader, ErrorModal } from "../../components/GeneralComponent/GeneralComponent";
import { OptimizeReport } from "../../components/TestHistoryReport/OptimizeReport";
import { TestVariableData } from "../../components/TestHistoryReport/TestVariableData";
import { TestInfoModal } from "../../components/TestHistoryReport/TestInfoModal";
import { TestTotal } from "../../components/TestHistoryReport/TestTotal";
import TestsApi from "../../api/TestsApi";

export function TestHistoryReport() {
	const navigate = useNavigate();
  const location = useLocation();
  const { pageId, historyId } = location.state || {};
	useEffect(() => {
	  if(!visiUserData()){
	    navigate("/login");
	  }
	}, [navigate]);

	useDocumentTitle("Visiopt - Test History Report");

	const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');

	const handleHeaderMenuComponentChange = (Value:any) => {
	  setSelectedMemberWebsite(Value);
	};

	const [convRedioBtn, setConvRedioBtn] = useState('1');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorModelOpen, seterrorCloseModal] = useState(false);
  const [showErrorHeader, setShowErrorHeader] = useState(false);
  const [showMessageHeader, setShowMessageHeader] = useState(false);

  const [variationsBtn, setVariationsBtn] = useState('1');
  const [variablesBtn, setVariablesBtn] = useState('0');
  const [variablesOptimumResponse, setVariablesOptimumResponse] = useState('');
  const [variablesVariablesTab, setVariablesVariablesTab] = useState([]);
  const [SystemUsageLoader, setSystemUsageLoader] = useState(false);

  const [convRadioFunnelsGoals, setConvRadioFunnelsGoals] = useState<any[]>([]);
  const [convRadioPostbackOptions, setConvRadioPostbackOptions] = useState<any[]>([]);
  const [convRadioButtonOptions, setConvRadioButtonOptions] = useState<any[]>([]);
  const [funnelsGoals, setFunnelsGoals] = useState('');
  const [selectedButton, setSelectedButton] = useState('');
  const [postbackOptions, setPostbackOptions] = useState('');

  const [historyMasterId, setHistoryMasterId] = useState('');
  const [testNameShow, setTestNameShow] = useState('');
  const [testPageLinkShow, setTestPageLinkShow] = useState('');
  const [testPageTitleShow, setTestPageTitleShow] = useState('');
  const [totalUniquesShow, setTotalUniquesShow] = useState('');
  const [totalVisitsShow, setTotalVisitsShow] = useState('');
  const [totalConversionsShow, setTotalConversionsShow] = useState('');
  const [testVariationsData, setTestVariationsData] = useState([]);
  const [dataColumnChart, setDataColumnChart] = useState([]);
  const [dataColumnPercentageChart, setDataColumnPercentageChart] = useState([]);
  const [selectedValue, setSelectedValue] = useState('1');
  const [variablesTabShow, setVariablesTabShow] = useState('');
  const [conversionTypeShow, setConversionTypeShow] = useState('');
  const [conversionTypeValueSelected, setConversionTypeValueSelected] = useState('');
  const [convtopageNameShow, setConvtopageNameShow] = useState('');
  const [marksalestime, setMarksalestime] = useState(0);


/* VARIATIONS & VARIABLES*/
  const handleOptimize = (value:any) => {
    if(value === 'Variations'){
      setVariationsBtn('1');
      setVariablesBtn('0');
    } else if(value === 'Variables'){
      setVariationsBtn('0');
      setVariablesBtn('1');
      handleGetVariables();
    }
  };

  const handleGetVariables = () => {
    setSystemUsageLoader(true);
    TestsApi.gettesthistoryvariabletab({
      history_master_id: historyMasterId
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate('/logout');
        }
      }
    })
    .then((data) => {
      if (data.status === 1) {
        setVariablesOptimumResponse(data.data.optimum_response);
        setVariablesVariablesTab(data.data.variables_tab || []);
        setSystemUsageLoader(false);
      }
    })
    .catch((error) => {
      console.error('API Error:', error);
      setSystemUsageLoader(false);
    });
  };

/* END VARIATIONS & VARIABLES*/

/* CONVERSIONS TO*/
  const handleConvRadioChange = (value) => {
    setConvRedioBtn(value);
    setConversionTypeShow(value === '1' ? 'goal' : value === '2' ? 'button' : 'postback');
    setConversionTypeValueSelected('');
  };

  useEffect(() => {
    if(selectedMemberWebsite !== ''){
      if (!pageId) {
        notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: "Page id is a required field.",
        });
        navigate('/review-tests');
        return;
      }
      TestsApi.gettesthistoryconversionsdropdown({
        history_id:historyId
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if (data.status === 1) {
          setConvRadioFunnelsGoals(data.data.goal_options || []);
          setConvRadioPostbackOptions(data.data.postback_options || []);
          setConvRadioButtonOptions(data.data.button_options || []);
        } else {
          console.log("Error:","Error");
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
    }
  }, [selectedMemberWebsite,marksalestime, pageId, navigate, historyId]);

  const funnelGoal = useMemo(() => [
    { value: '', label: 'Select a Goal' },
    ...convRadioFunnelsGoals.map((FunnelsGoals) => ({
      value: FunnelsGoals.goal_id,
      label: FunnelsGoals.goal_name,
    })),
  ], [convRadioFunnelsGoals]);

  const postbackOption = useMemo(() => [
    { value: '', label: 'Select Postback' },
    ...convRadioPostbackOptions.map((PostbackOptions) => ({
      value: PostbackOptions.postback_id,
      label: PostbackOptions.postback_name,
    })),
  ], [convRadioPostbackOptions]);

  const buttonOptions = useMemo(() => [
    { value: '', label: 'Select a Button' },
    ...Object.values(convRadioButtonOptions).map((ButtonOptions) => ({
      value: ButtonOptions.button_id,
      label: ButtonOptions.button_name,
    })),
  ], [convRadioButtonOptions]);

  useEffect(() => {
    if(conversionTypeShow === 'goal'){
      setConvRedioBtn('1');
    }
    if(conversionTypeShow === 'button'){
      setConvRedioBtn('2');
    }
    if(conversionTypeShow === 'postback'){
      setConvRedioBtn('3');
    }

    if (convRedioBtn === '1') {
      const selectedOption = funnelGoal.find(option => option.value === conversionTypeValueSelected);
      if (selectedOption) {
        setFunnelsGoals(selectedOption.value);
      }
    } else if (convRedioBtn === '2') {
      const selectedOption = buttonOptions.find(option => option.value === conversionTypeValueSelected);
      if (selectedOption) {
        setSelectedButton(selectedOption.value);
      }
    } else if (convRedioBtn === '3') {
      const selectedOption = postbackOption.find(option => option.value === conversionTypeValueSelected);
      if (selectedOption) {
        setPostbackOptions(selectedOption.value);
      }
    }
  }, [conversionTypeValueSelected,marksalestime]);

  const handleSelectConvChange = (value) => {
    setConversionTypeValueSelected(value);
    if (convRedioBtn === '1') {
      setFunnelsGoals(value);
    } else if (convRedioBtn === '2') {
      setSelectedButton(value);
    } else if (convRedioBtn === '3') {
      setPostbackOptions(value);
    }
  };
/* END CONVERSIONS TO*/

/* TEST OPTIMIZE REPORT*/
  useEffect(() => {
    if(selectedMemberWebsite !== ''){
      setSystemUsageLoader(true);
      TestsApi.testhistoryoptimizereport({
        system_website_id:selectedMemberWebsite,
        test_history_id:historyId,
        test_goal_type_opt_his: conversionTypeShow,
        convtogoal:convRedioBtn === '1' ? funnelsGoals : '',
        convtobutton:convRedioBtn === '2' ? selectedButton : '',
        convtopostback:convRedioBtn === '3' ? postbackOptions : '',
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if (data.status === 1) {
          setSystemUsageLoader(false);
          setTestVariationsData(data.data.test_history_data);
          setDataColumnChart(data.data.conversion_chart_column);
          setDataColumnPercentageChart(data.data.conversion_percentage_chart_column);
          setHistoryMasterId(data.data.history_master_id);
          setTestNameShow(data.data.test_name);
          setTestPageLinkShow(data.data.test_page_link);
          setTestPageTitleShow(data.data.page_title);
          setTotalUniquesShow(data.data.total_uniques);
          setTotalVisitsShow(data.data.total_visits);
          setTotalConversionsShow(data.data.total_conversions);
          setConversionTypeShow(data.data.conversion_type);
          setConversionTypeValueSelected(data.data.conversion_type_value_selected);
          setConvtopageNameShow(data.data.convtopage_name);
          setVariablesTabShow(data.data.hide_variables_tab);
          setMarksalestime(data.data.updated_time);
        } else {
          setErrorMessage(data.message);
          seterrorCloseModal(true);
          setSystemUsageLoader(false);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
        setSystemUsageLoader(false);
      });
    }
  }, [selectedMemberWebsite,marksalestime,funnelsGoals,postbackOptions,selectedButton]);

  const handleConversionRadioChange = (value) => {
    setSelectedValue(value);
  };

  const dataColumnChartRows = [
    ['Variation', 'Value', { role: 'style' }],
    ...dataColumnChart,
  ];

  const optionsColumnChart = {
    legend: { position: 'none' },
    chartArea: { width: '90%', height: '80%' },
    vAxis: {
      title: 'Conversions',
      titleTextStyle: { fontFamily: 'Cursive', fontSize: 16 },
    },
  };

  const dataColumnPercentageChartRows = [
    ['Variation', 'Value', { role: 'style' }],
    ...dataColumnPercentageChart,
  ];

  const optionsColumnPercentageChart = {
    legend: { position: 'none' },
    chartArea: { width: '90%', height: '80%' },
    vAxis: {
      title: 'Conversions %',
      titleTextStyle: { fontFamily: 'Cursive', fontSize: 16 },
    },
  };


  const allOptions = [
    { value: 'Uniques', label: 'Uniques' },
    { value: 'Visits', label: 'Visits' },
    { value: 'Conv. # to page', label: 'Conv. # to page' },
    { value: 'Conv. % to page', label: 'Conv. % to page' },
    { value: 'Percentage Improvement', label: 'Percentage Improvement' },
    { value: 'Statistical Significance', label: 'Statistical Significance' },
    { value: 'Total Revenue', label: 'Total Revenue' },
    { value: 'AOV', label: 'AOV' },
  ];

  const defaultSelectedOptions = [
    'Uniques',
    'Visits',
    'Conv. # to page',
    'Conv. % to page',
    'Percentage Improvement',
    'Statistical Significance'
  ];

  const [selectedfilteredOptions, setSelectedfilteredOptions] = useState<string[]>(defaultSelectedOptions);

  const handleSelectFilteredChange = (values: string[]) => {
    setSelectedfilteredOptions(values);
  };

/* END TEST OPTIMIZE REPORT*/ 

  const handlerrorCloseModal = () => {
    seterrorCloseModal(false);
  };

	return (
	  <>
    <VisiLoader VisiLoaderVisible={SystemUsageLoader}/>
	  <HeaderMenu HeaderMenuValue={selectedMemberWebsite} HeaderMenuOnChange={handleHeaderMenuComponentChange}/>

	  <Container size="xxl">
      <div style={{ display: 'flex',marginBottom:"10px"}}>
        <Box w={130}>
          <Button rightIcon={<IconHelp size="1.1rem" color="red"/>} variant="default" bg={variationsBtn === '1' ? '#e7fcf5' : "" } type="submit" onClick={() => handleOptimize('Variations')}>
            Variations
          </Button>
        </Box>
        {variablesTabShow === 'show' &&(
          <Box w={130}>
            <Button rightIcon={<IconHelp size="1.1rem" color="red"/>} variant="default" bg={variablesBtn === '1' ? '#e7fcf5' : "" } type="submit" onClick={() => handleOptimize('Variables')}>
              Test Variable Results
            </Button>
          </Box>
        )}
      </div>
      <div style={{ display: variationsBtn === '1' ? "block" : "none" }}>
  	  	<Card shadow="sm" padding="sm" radius="md" withBorder>
  	  		<div style={{ display: 'flex',marginBottom: '5px' }}>
  		  		<Group position="apart" mb="xs">
  		        <Text weight={500}>TEST HISTORY - {testPageTitleShow} <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
  		      </Group>
  		      <div style={{ display: 'flex',marginLeft:"auto"}}>
  		     		<TestInfoModal
                PageId={pageId}
                HistoryId={historyId}
                SetErrorMessage={setErrorMessage}
                ErrorCloseModal={seterrorCloseModal}
                ShowErrorHeader={setShowErrorHeader}
                ShowMessageHeader={setShowMessageHeader}
              />  		     		  
  		     	</div>
  		    </div>
  		    <Group>
  		      <Text mb={6}><IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/> Conversions to:</Text>
  		      <Radio.Group
  		        name="GoalType"
  		        mb={10}
  		        value={convRedioBtn}
              onChange={handleConvRadioChange}
  		      >
  		        <Group mt="xs">
  		          <Radio value="1" label="Goals" />
  		          <Radio value="2" label="Buttons" />
  		          <Radio value="3" label="Postbacks" />
  		        </Group>
  		      </Radio.Group>
  		      <Select
              value={conversionTypeValueSelected}
              onChange={handleSelectConvChange}
              data={convRedioBtn === '1' ? funnelGoal : convRedioBtn === '2' ? buttonOptions : postbackOption}
            />
  		    </Group>
  		    <Group mt={10}>
    	      <MultiSelect
              data={allOptions}
              onChange={handleSelectFilteredChange} 
              value={selectedfilteredOptions}
              multiple
              placeholder="Select Metrics"
            />
  		    </Group>
  		    <Group>
            <OptimizeReport
              SelectedFilteredOptions={selectedfilteredOptions}
              TestVariationsData={testVariationsData}
            />
  		    </Group>
  			</Card>
  			<Card shadow="sm" padding="sm" radius="md" withBorder mt={10} mb={20}>
  				<Group position="apart" mb="xs">
  		      <Text weight={500}>CONVERSION TO PAGE/GOALS <IconHelp size="1.1rem" color="red" style={{marginBottom:-3,marginRight:15}}/>   {convtopageNameShow}</Text>
  		    </Group>
  		    <Radio.Group
  		      name="Conversions"
  		      mb={10}
  		      value={selectedValue}
            onChange={handleConversionRadioChange}
  		    >
  		      <Group mt="md">
  		        <Radio value="1" label="Conversions" />
  		        <Radio value="2" label="Conversion %" />
  		      </Group>
  		    </Radio.Group>
  		    <Grid mb={15}>
  			    <Grid.Col span={9} style={{ textAlign: 'left' }}>
              {dataColumnChartRows.length > 1 && dataColumnPercentageChartRows.length > 1 && (
    				   	<Chart
    					   	chartType="ColumnChart"
    					   	width="100%"
    					   	height="200px"
    					   	data={selectedValue === "1" ? dataColumnChartRows : dataColumnPercentageChartRows}
                  options={selectedValue === "1" ? optionsColumnChart : optionsColumnPercentageChart}

    					  />
              )}
  			    </Grid.Col>
  			    <Grid.Col span={3} style={{ textAlign: 'left' }} mt={20}>
  				    <TestTotal
                TestNameShow={testNameShow}
                TestPageLinkShow={testPageLinkShow}
                TestPageTitleShow={testPageTitleShow}
                TotalUniquesShow={totalUniquesShow}
                TotalVisitsShow={totalVisitsShow}
                TotalConversionsShow={totalConversionsShow}
              />
  			    </Grid.Col>
  			  </Grid>
  			</Card>
      </div>
      <div style={{ display: variablesBtn === '1' ? "block" : "none" }}>
        <TestVariableData
          VariablesData={variablesVariablesTab}
          VariableOptimumResponse={variablesOptimumResponse}
        />
      </div>
      {/*Error Model*/}
      <ErrorModal errorModelOpen={errorModelOpen} errorMessage={errorMessage} showErrorHeader={showErrorHeader} showMessageHeader={showMessageHeader} onClose={handlerrorCloseModal} />
	  </Container>
	  </>
	);
}