import { useEffect, useState, useRef } from 'react';
import FunnelApi from "../../api/FunnelApi";
import { useNavigate } from "react-router-dom";
import { Modal, Text, Input, Space, Button, Select, TextInput, Flex, Grid, Skeleton, Kbd, Image, Pagination, Group } from '@mantine/core';
import { IconHelp, IconSearch, IconCheck } from '@tabler/icons-react';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { modals } from '@mantine/modals';
import { SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";

export function AddFunnelPage({handleSelectPageOpenModalProp, sethandleSelectPageOpenModalProp, setSystemUsageLoader, selectedMemberWebsite, setErrorMessage, seterrorCloseModal, setShowErrorHeader, setShowDeleteErrorHeader, setshowDeletefunnelErrorHeader, setShowRemovefromfunnelErrorHeader, setLoading, marksalestime, resetSelection, setMarksalestime, loading, selectedThumbnails, setSelectedThumbnails, setVisiSmartCodeModalOpen, handleRemoveFromfunnel, handleCheckTrecking, handleGetTreckingCode, handleEditOpenModal, handleDeletePage, setSelectedPageId, setAfterAddNewPage}){

	const navigate = useNavigate();
	const searchInputRef = useRef(null);
	const pageIconRef = useRef(null);

	const [selectPageModelOpen, setSelectPageModelOpen] = useState(false);
	const [selectPageFunnel, setSelectPageFunnel] = useState([]);
	const [totalPages, setTotalPages] = useState(10);
	const [totalPaginationNo, setTotalPaginationNo] = useState(1);
	const [selectedAllPageValue, setSelectedAllPageValue] = useState('0');
	const [keyword, setKeywords] = useState('');
	const [activePageNo, setActivePageNo] = useState(1);
	const [selectedPageOption, setSelectedPageOption] = useState({});
	const [showHelpModal, setShowHelpModal] = useState(false);
	const [helpTargetId, setHelpTargetId] = useState(0);
	const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

	/*Use All Funnel Form Name*/
  	const form = useForm({
	    initialValues: {
	      url: ''
	    },
  	});

	useEffect(() => {
		if(handleSelectPageOpenModalProp){
			AddNewPageOpenModal();
		}
	}, [handleSelectPageOpenModalProp]);

	useEffect(() => {
	    const handleKeyDown = (event) => {
	      if (event.ctrlKey && event.key === 'k') {
	        event.preventDefault();
	        searchInputRef.current.focus();
	      }
	    };

	    window.addEventListener('keydown', handleKeyDown);

	    return () => {
	      window.removeEventListener('keydown', handleKeyDown);
	    };
  	}, []);

	const AddNewPageOpenModal = () => {
		setSystemUsageLoader(true);
	    FunnelApi.selectpagesfromfunnel({
	      system_website_id: selectedMemberWebsite,
	      checktrackstate: 1,
	      filter: selectedAllPageValue,
	      keyword: keyword,
	      page: activePageNo
	    })
	      .then(response => response.json())
	      .then(data => {
	        if (data.status === 1) {
	          setSystemUsageLoader(false);
	          setSelectPageModelOpen(true);
	        } else {
	          setSystemUsageLoader(false);
	          setErrorMessage(data.message);
	          seterrorCloseModal(true);
	          setShowErrorHeader(false);
	          setShowDeleteErrorHeader(false);
	          setshowDeletefunnelErrorHeader(false);
	          setShowRemovefromfunnelErrorHeader(false);
	          setSelectPageModelOpen(false);
              sethandleSelectPageOpenModalProp(false);
	        }
	      })
	      .catch(error => {
	        console.error("API Error:", error);
	        setSystemUsageLoader(false);
	      });
	}

	useEffect(() => {
	    if (selectPageModelOpen) {
	      setLoading(true);
	      seterrorCloseModal(false);
	      FunnelApi.selectpagesfromfunnel({
	        system_website_id: selectedMemberWebsite,
	        checktrackstate: 1,
	        filter: selectedAllPageValue,
	        keyword: keyword,
	        page: activePageNo
	      })
	      .then(response => {
	        if (response.ok) {
	          return response.json();
	        } else {
	          if (response.status === 401 || response.status === 400) {
	            setLoading(false);
	            navigate("/logout");
	          }
	        }
	      })
	      .then(data => {
	        if(data.status === 1){
	          if (data.data.data) {
	            setSelectPageFunnel(data.data.data);
	            if (data.data.active_page_no !== activePageNo) {
	              setActivePageNo(data.data.active_page_no);
	              setActivePageNo(1);
	            }
	            if (data.data.total_pages !== totalPages) {
	              setTotalPages(data.data.total_pages);
	            }
	            if (JSON.stringify(data.data.total_pagination_no) !== JSON.stringify(totalPaginationNo)) {
	              setTotalPaginationNo(data.data.total_pagination_no);
	            }
	          } else{
	            console.log("No data received");
	            setSelectPageFunnel([]);
	          }
	        }else{
	          setSelectedAllPageValue('0');
	          setSelectPageModelOpen(false);
	          sethandleSelectPageOpenModalProp(false);
	          setErrorMessage(data.message);
	          seterrorCloseModal(true);
	        }
	        setLoading(false);
	      })
	      .catch(error => {
	        console.error("API Error:", error);
	        setLoading(false);
	      });
	    }
  	}, [selectPageModelOpen, selectedAllPageValue, keyword, activePageNo, marksalestime, totalPages, totalPaginationNo]);

  	const handleSelectPageCloseModal = () => {
	    setSelectedAllPageValue('0');
	    setActivePageNo(1);
	    setSelectPageModelOpen(false);
	    sethandleSelectPageOpenModalProp(false);
	    resetSelection();
	    form.reset();
  	};

  	const handleSelectAllPagesValueChange = (selectedAllPageOption) => {
	    setSelectedAllPageValue(selectedAllPageOption);
	    setActivePageNo(1);
  	};

  	/*Add the Page*/
  	const handleAddthePageSubmit = async (formData:any) =>{
	    try {
	      const { url } = formData;
	      let no_of_pages_access;

	      const userDataString = atob(localStorage.getItem('visiUserData'));
	      const userData = JSON.parse(userDataString);
	      if (userData && userData && userData.no_of_add_access) {
	        no_of_pages_access = userData.no_of_add_access.no_of_pages_access;
	      }
	      const APIResponse = await FunnelApi.addpage({
	        system_website_id: selectedMemberWebsite,
	        url: url,
	        no_of_pages_access:no_of_pages_access
	      });
	      const APIResultJSON = await APIResponse.json();
	      if (APIResultJSON.status === 1) {
	        notifications.show({
	          color: 'green',
	          icon: <IconCheck size="1.1rem" />,
	          title: 'Success',
	          message: APIResultJSON.message,
	        });
	        setMarksalestime(APIResultJSON.data.updated_time);
	        form.reset();
	        handleSelectPageCloseModal();
	        handleGetTreckingCode(APIResultJSON.data.page_id);
	      	setVisiSmartCodeModalOpen(true);
	      	setAfterAddNewPage(1);
	      } else {
	        form.setErrors({ url: APIResultJSON.message });
	      }
	    } catch (error) {
	      console.log('error', error);
	    }
  	};

  	const [AllPagesoptions] = useState([
	    { value: '0', label: 'All pages'},
	    { value: '1', label: 'Tested Pages'},
	    { value: '2', label: 'Pages in Funnel'},
	    { value: '3', label: 'Affiliate Pages'},
	    { value: '4', label: 'Other Pages'},
  	]);

  	const handlePageChange = (newPage) => {
	    setActivePageNo(newPage);
  	};

  	const setKeyword = (e) => {
	    setKeywords(e.target.value);
	    setActivePageNo(1);
  	};

  	const handleThumbnailClick = (pageId) => {
	    setSelectedThumbnails((prevSelectedThumbnails) => {
	      if (prevSelectedThumbnails.includes(pageId)) {
	        return prevSelectedThumbnails.filter(id => id !== pageId);
	      } else {
	        return [...prevSelectedThumbnails, pageId];
	      }
	    });
  	};

  	const handleThumbnailDoubleClick = (pageId:any) =>{
	    setLoading(true);
	    FunnelApi.addtofunnel({
	      system_website_id: selectedMemberWebsite,
	      page_id:pageId
	    })
	    .then(response => {
	      if (response.ok) {
	        return response.json();
	      } else {
	        if (response.status === 401 || response.status === 400) {
	          setLoading(false);
	          navigate("/logout");
	        }
	      }
	    })
	    .then(data => {
	      if(data.status === 1){
	        setMarksalestime(data.data.updated_time);
	        handleSelectPageCloseModal();
	        setLoading(false);
	      } else {
	        modals.open({
	          title: 'Message',
	          children: (
	          <>
	            <Text size="sm">
	              {data.message}
	            </Text>
	            <Group position="right" mt="md">
	              <Button onClick={() => {
	                setVisiSmartCodeModalOpen(true);
	                modals.closeAll();
	              }}>OK</Button>
	            </Group>
	          </>
	          ),
	        });
	        setLoading(false);
	      }
	    })
	    .catch(error => {
	      console.error("API Error:", error);
	      setLoading(false);
	    });
  	};

  	const handleSelectPageAction = (value:any, page_id:any) => {
	    setSelectedPageOption(prevOptions => ({
	        ...prevOptions,
	        [page_id]: ''
	    }));
	    if(value === "addtofunnel"){
	      handleAddtoFunnel(page_id);
	    } else if(value === "remove"){
	      handleRemoveFromfunnel(page_id);
	    } else if(value === "checkTracking"){
	      handleCheckTrecking(page_id);
	    } else if(value === "trackingCode"){
	      setAfterAddNewPage(1);
	      handleSelectPageCloseModal();
	      handleGetTreckingCode(page_id);
	      setVisiSmartCodeModalOpen(true);
	    } else if(value === "edit_page"){
	      handleEditOpenModal(page_id);
	    } else if(value === "delete"){
	      handleDeletePage(page_id);
	    } else if(value === "refresh_screenshot"){
	      modals.openConfirmModal({
	        title: 'Refresh Screenshot',
	        children: (
	          <Text size="sm">
	            Your screenshot will be updated soon!!!
	          </Text>
	        ),
	        labels: { confirm: 'Ok', cancel: "Close" },
	        onCancel: () => console.log('Cancel'),
	        onConfirm: () => handleRefreshScreenshot(page_id),
	      });
	    }
  	};

  	const handleAddtoFunnel = (selectedThumbnail) =>{
	    FunnelApi.addtofunnel({
	      system_website_id: selectedMemberWebsite,
	      page_id:selectedThumbnail
	    })
	    .then(response => {
	      if (response.ok) {
	        return response.json();
	      } else {
	        if (response.status === 401 || response.status === 400) {
	          navigate("/logout");
	        }
	      }
	    })
	    .then(data => {
	      if (data.status === 1) {
	        setMarksalestime(data.data.updated_time);
	        handleSelectPageCloseModal();
	        setLoading(false);
	        resetSelection();
	      } else {
	        modals.open({
	          title: 'Message',
	          children: (
	          <>
	            <Text size="sm">
	              {data.message}
	            </Text>
	            <Group position="right" mt="md">
	              <Button onClick={() => {
	                setVisiSmartCodeModalOpen(true);
	                modals.closeAll();
	              }}>OK</Button>
	            </Group>
	            </>
	          ),
	        });
	        setLoading(false);
	        resetSelection();
	      }
	    })
	    .catch(error => {
	      console.error("API Error:", error);
	      setLoading(false);
	    });
  	};

  	/* Refresh Screenshot */
  	const handleRefreshScreenshot = async (selectedPageId) =>{
	    setSelectedPageId(selectedPageId);
	    try {
	      const APIResponse = await FunnelApi.refreshscreenshot({
	        system_website_id: selectedMemberWebsite,
	        page_id: selectedPageId
	      });
	      const APIResultJSON = await APIResponse.json();
	      if (APIResultJSON.status === 1) {
	        notifications.show({
	          color: 'green',
	          icon: <IconCheck size="1.1rem" />,
	          title: 'Success',
	          message: APIResultJSON.message,
	        });
	      } else {
	        setErrorMessage(APIResultJSON.message);
	        seterrorCloseModal(true);
	      }
	    } catch (error) {
	      console.log('error', error);
	    }
  	};

  	const [selectPageBoxOption] = useState([
	    { value: '', label: 'Choose Action', hidden:'hidden' },
	    { value: 'addtofunnel', label: 'Add to funnel' },
	    { value: 'remove', label: 'Remove from funnel' },
	    { value: 'checkTracking', label: 'Check Tracking' },
	    { value: 'trackingCode', label: 'VisiSmart Code' },
	    { value: 'edit_page', label: 'Edit Page' },
	    { value: 'delete', label: 'Delete Page' },
	    { value: 'refresh_screenshot', label: 'Refresh Screenshot' },
	    { value: 'mergePages', label: 'Merge Pages' },
  	]);

  	const handleMultipleAddtoFunnel = (selectedThumbnail) =>{
	    const requests = selectedThumbnails.map(pageId => 
	      FunnelApi.addtofunnel({
	        system_website_id: selectedMemberWebsite,
	        page_id: pageId
	      })
	    );
	    Promise.all(requests)
	    .then(responses => {
	        const jsonPromises = responses.map(response => {
	          if (response.ok) {
	            return response.json();
	          } else {
	            if (response.status === 401 || response.status === 400) {
	              navigate("/logout");
	              return null;
	            }
	          }
	        });
	        return Promise.all(jsonPromises);
	      })
	    .then(dataArray => {
	      dataArray.forEach(data => {
	        if (data.status === 1) {
	          setMarksalestime(data.data.updated_time);
	          handleSelectPageCloseModal();
	          setLoading(false);
	        } else {
	          modals.open({
	            title: 'Message',
	            children: (
	            <>
	              <Text size="sm">
	                {data.message}
	              </Text>
	              <Group position="right" mt="md">
	                <Button onClick={() => {
	                  setVisiSmartCodeModalOpen(true);
	                  modals.closeAll();
	                }}>OK</Button>
	              </Group>
	            </>
	            ),
	          });
	          setLoading(false);
	          resetSelection();
	        }
	      });
	    })
	    .catch(error => {
	      console.error("API Error:", error);
	      setLoading(false);
	    });
  	};

  	const handleHelpClick = (id:any) => {
	    setShowHelpModal(true);
	    setHelpTargetId(id);
	    if (pageIconRef.current) {
	      const { top, left, width } = pageIconRef.current.getBoundingClientRect();
	      const scrollOffset = window.scrollY;
	      setTooltipPosition({
	        top: top + scrollOffset,
	        left: left + width + 10, 
	      });
	    }
	};

	return (
		<>
		{showHelpModal && <SelectTooltip isVisible={showHelpModal} setIsVisible={setShowHelpModal} id={helpTargetId} tooltipPosition={tooltipPosition} />}
		{/*New Select Page*/}
	    <Modal.Root opened={selectPageModelOpen} onClose={handleSelectPageCloseModal} style={{ zIndex: 1 }} size="80%">
	      <Modal.Overlay />
	      <Modal.Content>
	        <Modal.Header>
	          <Modal.Title>ADD PAGES TO YOUR FUNNEL <IconHelp ref={pageIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(114)}/></Modal.Title>
	          <Modal.CloseButton />
	        </Modal.Header>
	        <Modal.Body>
	          <form onSubmit={form.onSubmit((formData) => { handleAddthePageSubmit(formData); })}>
	            <Text size="sm" ml={0} fz={14}>Note: Any page you add must have your VisiSmart Code. </Text>
	            <Text size="sm" mt={10} fz={14} fw={700}>Add pages to Visiopt</Text>
	            <div style={{ display: 'flex',marginBottom: '10px' }}>
	              <Input.Wrapper size="sm" ml={0} fz={14} mt={5}>Enter the url of the page you want to add to Visiopt:</Input.Wrapper>
	              <Space w="xs" />
	              <Input id="url" value={form.values.url} onChange={(event) => form.setFieldValue('url', event.currentTarget.value)} error={form.errors.url && 'Invalid URL'} w={400} ml={0}/>
	              <Space w="xs" />
	              <Button type="submit" size="sm" maw={150}>
	                Add the Page
	              </Button>
	            </div>
	            {form.errors.url && (
	              <span style={{ color: 'red', marginTop: '5px' }}>{form.errors.url}</span>
	            )}
	          </form>
	          <Text size="sm" mt={10} fz={14} fw={700}>Select pages for your funnel</Text>
	          <div style={{ display: 'flex',marginBottom: '10px' }}>
	            <Text size="sm" ml={0} fz={14} mt={8}>Pages you already added to Visiopt: </Text>
	            <Space w="xs" />
	            <Select
	              placeholder="All pages"
	              defaultValue="0"
	              data={AllPagesoptions}
	              onChange={(selectedAllPageOption) => handleSelectAllPagesValueChange(selectedAllPageOption)}
	              value={selectedAllPageValue}
	              maxDropdownHeight={300}
	              maw={152}
	              size="sm"
	              dropdownPosition="bottom"
	            />
	            <TextInput
	              ref={searchInputRef}
	              style={{marginLeft:"auto"}}
	              placeholder="Search"
	              icon={<IconSearch size="1rem" />}
	              rightSectionWidth={60}
	              rightSection={
	                <Flex align="center" justify="flex-end">
	                  <Kbd mr={5}>Ctrl+K</Kbd>
	                </Flex>
	              }
	              onChange={setKeyword}
	            />
	          </div>
	          <Grid ml={0} mt={20} mb={20} style={{height: 450,flex: "1 1 auto",overflow: "auto"}}>
	            {selectPageFunnel.map((item, index) => (
	              <div className="visi-page" key={index} style={{marginRight:10}}>
	                <Skeleton visible={loading}>
	                  <div className={`hover-pointer ${item.pageClass}`}
	                      style={{ backgroundColor: (item.selected || selectedThumbnails.includes(item.page_id)) ? '#38d9a9' : '' }} 
	                      onClick={item.selected ? undefined : () => handleThumbnailClick(item.page_id)}
	                    >
	                    <span title={item.page_url}>
	                      <span className="page-url">{item.page_title}</span>
	                    </span>
	                    <div className="thumbnail" style={{ backgroundImage: `url(${'https://static.visiopt.com/page-thumb/'+item.img_url})` }} title={item.page_url} onDoubleClick={item.selected ? undefined : () => handleThumbnailDoubleClick(item.page_id)}>
	                      {(item.marge_img !== '' && (
	                          <Image
	                          id="merge_img"
	                          src={item.marge_img}
	                          title="This Page is merged with other links"
	                          style={{}}
	                        />
	                        ))
	                      }
	                      {(item.track_check !== '' && (
	                        <Image
	                          id="track_img"
	                          src={item.track_check}
	                          title={item.track_check_title}
	                        />
	                        ))
	                      }
	                    </div>
	                    <span className="page-title">{item.custom_title}</span>
	                  </div>
	                </Skeleton>
	                <Select
	                  placeholder="Choose Action"
	                  onChange={(value) => handleSelectPageAction(value, item.page_id)} 
	                  value={selectedPageOption[item.page_id] || ''}
	                  data={item.selected ? selectPageBoxOption.filter(option => option.value !== 'addtofunnel') : selectPageBoxOption && (!item.selected) ? selectPageBoxOption.filter(option => option.value !== 'remove') : selectPageBoxOption}
	                  maxDropdownHeight={250}
	                  maw={152}
	                  size="xs"
	                  dropdownPosition="top"
	                />
	              </div>
	            ))}
	          </Grid>
	          {selectPageFunnel.some(item => Array.isArray(item) ? item.length > 0 : true) && (
	          <Pagination.Root total={totalPages} value={activePageNo} onChange={handlePageChange}>
	            <Group spacing={5} position="center">
	              <Pagination.First />
	              <Pagination.Previous />
	              <Pagination.Items />
	              <Pagination.Next />
	              <Pagination.Last />
	            </Group>
	          </Pagination.Root>
	          )}
	          <Group position="right">
	          <Button type="submit" size="sm" maw={400} 
	                  onClick={() => handleMultipleAddtoFunnel(selectedThumbnails)} 
	                  disabled={!selectedThumbnails.length} 
	                >
	            Add Selected to the Funnel
	          </Button>
	          </Group>
	        </Modal.Body>
	      </Modal.Content>
	    </Modal.Root>
		</>
	);
}