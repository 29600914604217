import { Button, Modal, Group, Input } from '@mantine/core';
import { useEffect, useState } from 'react';
import { IconHelp, IconCheck } from '@tabler/icons-react';
import { useForm } from '@mantine/form';
import FunnelApi from "../../api/FunnelApi";
import { notifications } from '@mantine/notifications';

export function AddUpdateFunnel({handleEditFunnelOpenModalProp, selectedMemberWebsite, data, SelectedFunnelValue, setUpdatedtime, handleSelectFunnelListComponentChange, setselectedFunnelTitle, handleChange, sethandleEditFunnelOpenModalProp}) {

	const [funnelTitle, setFunnelTitle] = useState('');
	const [newFunnelModalOpen, setNewFunnelModalOpen] = useState(false);
	const [funnelButton, setFunnelButton] = useState('');
	const [editFunnelModalOpen, setEditFunnelModalOpen] = useState(false);

	useEffect(() => {
		if(handleEditFunnelOpenModalProp){
			handleEditFunnelOpenModal(handleEditFunnelOpenModalProp);
		}
	}, [handleEditFunnelOpenModalProp]);

	/*Add New Funnel*/
  	const handleNewFunnelOpenModal = (value:any) => {
	    setFunnelTitle('NEW FUNNEL');
	    setFunnelButton('Create Funnel')
	    setNewFunnelModalOpen(true);
  	};

  	/*Rename Edit Funnel*/
  	const handleEditFunnelOpenModal = (value:any) => {
	    setFunnelTitle('RENAME FUNNEL');
	    setFunnelButton('Update Funnel');
	    setEditFunnelModalOpen(true);
  	};

  	const handleNewFunnelCloseModal = () => {
	    setNewFunnelModalOpen(false);
  	};

  	const form = useForm({
	    initialValues: {
	      funnel_name: '',
	      edit_funnel_name: ''
	    },
  	});

  	const handleNewFunnelSubmit = async (formData:any) => {
	    try {
	      const { funnel_name } = formData;
	      const APIResponse = await FunnelApi.addfunnel({
	        system_website_id: selectedMemberWebsite,
	        name: funnel_name,
	        account_funnel_limit: 1000
	      });
	      const APIResultJSON = await APIResponse.json();
	      if (APIResultJSON.status === 1) {
	        notifications.show({
	          color: 'green',
	          icon: <IconCheck size="1.1rem" />,
	          title: 'Success',
	          message: APIResultJSON.message,
	        });
	        handleNewFunnelCloseModal();
	        setUpdatedtime(APIResultJSON.data.selected_funnel_time);
	        handleSelectFunnelListComponentChange(APIResultJSON.data.selected_funnel_id);
	        form.reset();
	      } else {
	        form.setErrors({ funnel_name: APIResultJSON.message });
	      }
	    } catch (error) {
	      console.log('error', error);
	    }
  	}

  	const handleEditFunnelCloseModal = () => {
	    setEditFunnelModalOpen(false);
	    form.reset();
	    setselectedFunnelTitle('');
	    sethandleEditFunnelOpenModalProp(false);
  	};

  	const handleEditFunnelSubmit = async () => {
	    try {
	      const { edit_funnel_name } = data;
	      const APIResponse = await FunnelApi.addfunnel({
	        system_website_id: selectedMemberWebsite,
	        name: edit_funnel_name,
	        account_funnel_limit: 1000,
	        funnel_id: SelectedFunnelValue
	      });
	      const APIResultJSON = await APIResponse.json();
	      if (APIResultJSON.status === 1) {
	        notifications.show({
	          color: 'green',
	          icon: <IconCheck size="1.1rem" />,
	          title: 'Success',
	          message: APIResultJSON.message,
	        });
	        handleEditFunnelCloseModal();
	        setUpdatedtime(APIResultJSON.data.selected_funnel_time);
	        handleSelectFunnelListComponentChange(APIResultJSON.data.selected_funnel_id);
	      } else {
	        form.setErrors({ edit_funnel_name: APIResultJSON.message });
	      }
	    } catch (error) {
	      console.log('error', error);
	    }
  	};

	return (
		<>
		{/*New funnel*/}
	    <Modal.Root opened={newFunnelModalOpen} onClose={handleNewFunnelCloseModal}>
	      <Modal.Overlay />
	      <Modal.Content>
	        <Modal.Header>
	          <Modal.Title>{funnelTitle} <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
	          <Modal.CloseButton />
	        </Modal.Header>
	        <Modal.Body>
	          <form onSubmit={form.onSubmit((formData) => { handleNewFunnelSubmit(formData); })}>
	            <Group>
	              <Input.Wrapper label="Enter Funnel Name:" error={form.errors.funnel_name}>
	                <Input id="funnel_name" value={form.values.funnel_name} onChange={(event) => form.setFieldValue('funnel_name', event.currentTarget.value)} error={form.errors.funnel_name && 'Invalid funnel name'} w={242} ml={0}/>
	              </Input.Wrapper>
	              <Button type="submit" size="sm" w={150} mt={25}>
	                {funnelButton}
	              </Button>
	            </Group>
	          </form>
	        </Modal.Body>
	      </Modal.Content>
	    </Modal.Root>
	    {/*Rename funnel*/}
	    <Modal.Root opened={editFunnelModalOpen} onClose={handleEditFunnelCloseModal}>
	      <Modal.Overlay />
	      <Modal.Content>
	        <Modal.Header>
	          <Modal.Title>{funnelTitle} <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
	          <Modal.CloseButton />
	        </Modal.Header>
	        <Modal.Body>
	          <form onSubmit={form.onSubmit((formData) => { handleEditFunnelSubmit(); })}>
	            <Group>
	              <Input.Wrapper label="Enter Funnel Name:" error={form.errors.edit_funnel_name}>
	                <Input id="edit_funnel_name" name="edit_funnel_name" value={data.edit_funnel_name} hidden={SelectedFunnelValue ? true : false} onChange={handleChange} error={form.errors.edit_funnel_name && 'Invalid funnel name'} w={242} ml={0}/>
	              </Input.Wrapper>
	              <Button type="submit" size="sm" w={150} mt={25}>
	                {funnelButton}
	              </Button>
	            </Group>
	          </form>
	        </Modal.Body>
	      </Modal.Content>
	    </Modal.Root>
		<Button type="submit" size="sm" maw={150} onClick={() => handleNewFunnelOpenModal(0)}>
          	New Funnel
        </Button>
		</>
	);
}