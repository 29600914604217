import { Modal, Group, Input, Button, Table, Flex, ActionIcon, Text } from '@mantine/core';
import { IconHelp, IconX, IconCheck } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import FunnelApi from "../../api/FunnelApi";
import { useNavigate } from "react-router-dom";
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { modals } from '@mantine/modals';

export function BlockIP({handleBlockIPOpenModalProp, sethandleBlockIPOpenModalProp, selectedMemberWebsite}){

	const navigate = useNavigate();

	const [blockIPModalOpen, setBlockIPModalOpen] = useState(false);
	const [blockIPAddress, setBlockIPAddress] = useState([]);

	useEffect(() => {
		if(handleBlockIPOpenModalProp){
			BlockIPOpenModal();
		}
	}, [handleBlockIPOpenModalProp]);

	const BlockIPOpenModal = () => {
		setBlockIPModalOpen(true);
	    FunnelApi.getblockipaddress({
	      system_website_id: selectedMemberWebsite
	    })
	    .then(response => {
	      if (response.ok) {
	          return response.json();
	      } else {
	          if (response.status === 401 || response.status === 400) {
	              navigate("/logout");
	          }
	      }
	    })
	    .then(data => {
	      if (data) {
	        setBlockIPAddress(data.data);
	      }
	    })
	    .catch(error => {
	        console.error("API Error:", error);
	    });
  	};

	/*Use All Funnel Form Name*/
  	const form = useForm({
	    initialValues: {
	      blockipaddress: ''
	    },
  	});

	const handleBlockIPCloseModal = () => {
		sethandleBlockIPOpenModalProp(false);
	    setBlockIPModalOpen(false);
	    form.reset();
  	};

  	/*Add Block IP Address*/
  	const handleBlockIPAddressSubmit = async (formData:any) => {
	    try{
	      const { blockipaddress } = formData;
	      const APIResponse = await FunnelApi.addblockipaddress({
	        system_website_id: selectedMemberWebsite,
	        ip: blockipaddress
	      });
	      const APIResultJSON = await APIResponse.json();
	      if (APIResultJSON.status === 1) {
	        form.setFieldValue('blockipaddress', '');
	        notifications.show({
	          color: 'green',
	          icon: <IconCheck size="1.1rem" />,
	          title: 'Success',
	          message: APIResultJSON.message,
	        });
	        BlockIPOpenModal();
	      } else{
	        form.setErrors({ blockipaddress: APIResultJSON.message });
	      }
	    } catch (error) {
	      console.log('error', error);
	    }
  	}

  	/*Delete Block IP Address*/
  	const deleteBlockIPConfirm = (ipId) => {
	    modals.openConfirmModal({
	      title: 'Delete Block IP Address',
	      children: (
	        <Text size="sm">
	          This will allow traffic to be registered from this IP. Are you sure you want to continue?
	        </Text>
	      ),
	      labels: { confirm: 'Delete Block IP Address', cancel: "No don't delete it" },
	      confirmProps: { color: 'red' },
	      onCancel: () => console.log('Cancel'),
	      onConfirm: async () => {
	        try {
	          const APIResponse = await FunnelApi.deleteblockipaddress({
	            ip_id: ipId
	          });
	          const APIResultJSON = await APIResponse.json();
	          if (APIResultJSON.status === 1) {
	            notifications.show({
	              color: 'green',
	              icon: <IconCheck size="1.1rem" />,
	              title: 'Success',
	              message: APIResultJSON.message,
	            })
	            BlockIPOpenModal();
	          } else {
	            notifications.show({
	              color: 'red',
	              icon: <IconX size="1.1rem" />,
	              title: 'Error',
	              message: APIResultJSON.message,
	            })
	          }
	        } catch (error) {
	          console.log('Error deleting block IP address:', error);
	        }
	      },
	    });
  	};

	return (
		<>
		{/*Block IP Address*/}
	    <Modal.Root opened={blockIPModalOpen} onClose={handleBlockIPCloseModal}>
	      <Modal.Overlay />
	      <Modal.Content>
	        <Modal.Header>
	          <Modal.Title>BlOCK IP ADDRESSES <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
	          <Modal.CloseButton />
	        </Modal.Header>
	        <Modal.Body>
	          <form onSubmit={form.onSubmit((formData) => { handleBlockIPAddressSubmit(formData); })}>
	            <Group>
	              <Input.Wrapper label="Enter Block IP:" error={form.errors.blockipaddress}>
	                <Input id="blockipaddress" value={form.values.blockipaddress} onChange={(event) => form.setFieldValue('blockipaddress', event.currentTarget.value)} error={form.errors.blockipaddress && 'Block IP Address Required'} w={242} ml={0}/>
	              </Input.Wrapper>
	              <Button type="submit" size="sm" w={80} mt={25}>
	                Save
	              </Button>
	            </Group>
	            <Group mt={10}>
	              <Table striped highlightOnHover withBorder>
	                <tbody>
	                  {blockIPAddress.map((item, index) => (
	                  <tr key={index}>
	                    <td>{item.ip}</td>
	                    <td>
	                      <Flex
	                        justify="flex-end"
	                        align="center"
	                        direction="row"
	                        wrap="wrap"
	                      >
	                        <ActionIcon
	                          variant="default"
	                          size="lg"
	                          onClick={() => deleteBlockIPConfirm(item.ip_id)}
	                        >
	                          <IconX size="1.1rem" />
	                        </ActionIcon>
	                      </Flex>
	                    </td>
	                  </tr>
	                ))}
	                </tbody>
	              </Table>
	            </Group>
	          </form>
	        </Modal.Body>
	      </Modal.Content>
	    </Modal.Root>
		</>
	);
}