import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useDocumentTitle } from '@mantine/hooks';
import { HeaderMenu } from "../../components/HeaderMenu/HeaderMenu";
import { Skeleton, Container, Card, Group, Text, Select, Button, Space, Grid, Table, ActionIcon, Radio, Box, MultiSelect, Anchor, Menu } from '@mantine/core';
import { IconHelp, IconClockHour4, IconSettings, IconRefresh, IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import './ReviewingTest.css';

import { Chart } from "react-google-charts";
import { SelectTimePicker } from "../../components/GeneralComponent/GeneralComponent";
import PermissionWrapper from '../../PermissionWrapper';
import { VisiLoader, ErrorModal } from "../../components/GeneralComponent/GeneralComponent";
import TestsApi from "../../api/TestsApi";
import { PauseResume } from '../../components/ReviewingTest/PauseResume';
import { TestInfo } from '../../components/ReviewingTest/TestInfo';
import { DiscardTest } from '../../components/ReviewingTest/DiscardTest';
import { AudienceSize } from '../../components/ReviewingTest/AudienceSize';
import { VisiSmartCode } from '../../components/ReviewingTest/VisiSmartCode';
import { TestMode } from '../../components/ReviewingTest/TestMode';
import { TestNotes } from '../../components/ReviewingTest/TestNotes';
import { Logs } from '../../components/ReviewingTest/Logs';
import { GoogleAnalytics } from '../../components/ReviewingTest/GoogleAnalytics';
import { TrafficControl } from '../../components/ReviewingTest/TrafficControl';
import { Attribution } from '../../components/ReviewingTest/Attribution';
import { ResetStats } from '../../components/ReviewingTest/ResetStats';
import { BehavioralFilter } from '../../components/ReviewingTest/BehavioralFilter';
import { ShoppingCartProcessor } from '../../components/ReviewingTest/ShoppingCartProcessor';
import { SetWinner } from '../../components/ReviewingTest/SetWinner';
import { CustomTitle } from '../../components/ReviewingTest/CustomTitle';
import { PauseVariations } from '../../components/ReviewingTest/PauseVariations';
import { Variables } from '../../components/ReviewingTest/Variables';

export function ReviewingTest() {

  const navigate = useNavigate();
	useDocumentTitle("Visiopt - Reviewing Test");

	const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');

	const handleHeaderMenuComponentChange = (Value:any) => {
	  setSelectedMemberWebsite(Value);
	};

	const testId = '1';

  const location = useLocation();
  const { pageId, taguchiTestId, funnelId, testsId } = location.state || {};
  const [SystemUsageLoader, setSystemUsageLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  const [errorModelOpen, seterrorCloseModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorHeader, setShowErrorHeader] = useState(false);
  const [showMessageHeader, setShowMessageHeader] = useState(false);

  const [isAudienceSizeModalOpen, setIsAudienceSizeModalOpen] = useState(false);

  const [isTrafficControlModalOpen, setIsTrafficControlModalOpen] = useState(false);
  const [isTestNotesModalOpen, setIsTestNotesModalOpen] = useState(false);

  const [shoppingCartProcessorModalOpen, setShoppingCartProcessorModalOpen] = useState(false);
  const [isGoogleAnalyticsModalOpen, setIsGoogleAnalyticsModalOpen] = useState(false);

  const [isTestMode, setIsTestMode] = useState(false);
  
  const [isAttributionModalOpen, setIsAttributionModalOpen] = useState(false);

  const [isVisiSmartCodeModalOpen, setIsVisiSmartCodeModalOpen] = useState(false);

  const [convRedioBtn, setConvRedioBtn] = useState('1');
  const [convRadioFunnelsGoals, setConvRadioFunnelsGoals] = useState<any[]>([]);
  const [convRadioPostbackOptions, setConvRadioPostbackOptions] = useState<any[]>([]);
  const [convRadioButtonOptions, setConvRadioButtonOptions] = useState<any[]>([]);
  const [funnelsGoals, setFunnelsGoals] = useState('');
  const [selectedButton, setSelectedButton] = useState('');
  const [postbackOptions, setPostbackOptions] = useState('');

  const [isConversionLogsModalOpen, setIsConversionLogsModalOpen] = useState(false);

  const [selectedTimePicker, setSelectedTimePicker] = useState('');
	const [selectedTimePickerToDate, setSelectedTimePickerToDate] = useState('');
	const [selectedTimePickerFromDate, setSelectedTimePickerFromDate] = useState('');

  const [behavioralFilterModalOpen, setBehavioralFilterModalOpen] = useState(false);

  const [variationsBtn, setVariationsBtn] = useState('1');
  const [variablesBtn, setVariablesBtn] = useState('0');
  const [showVariablesTab, setShowVariablesTab] = useState(false);

  const [behavioralDimensionTrackingTags, setBehavioralDimensionTrackingTags] = useState({});
  const [behavioralDimensionNetworkParameters, setBehavioralDimensionNetworkParameters] = useState({});
  const [behavioralDimensionCustomTags, setBehavioralDimensionCustomTags] = useState({});
  const [behavioralDimensionSource, setBehavioralDimensionSource] = useState([]);
  const [displayFilterButton, setDisplayFilterButton] = useState(0);

  const [setWinnerModalOpen, setSetWinnerModalOpen] = useState(false);
  const [varIdshow, setVarId] = useState('');
  const [pageUrlShow, setPageUrlShow] = useState('');

  const [behavioralFilterArrCount, setBehavioralFilterArrCount] = useState(0);
  const [testModeShow, setTestModeShow] = useState(0);
  const [webhookSetShow, setWebhookSetShow] = useState(0);
  const [hidePauseShow, setHidePauseShow] = useState('');
  const [testNameShow, setTestNameShow] = useState('');
  const [pageIdShow, setPageIdShow] = useState('');
  const [tagichiTypeIdShow, setTagichiTypeIdShow] = useState('');
  const [testPageLinkShow, setTestPageLinkShow] = useState('');
  const [testPageTitleShow, setTestPageTitleShow] = useState('');
  const [totalUniquesShow, setTotalUniquesShow] = useState('');
  const [totalVisitsShow, setTotalVisitsShow] = useState('');
  const [totalConversionsShow, setTotalConversionsShow] = useState('');
  const [testVariationsData, setTestVariationsData] = useState([]);
  const [dataColumnChart, setDataColumnChart] = useState([]);
  const [dataColumnPercentageChart, setDataColumnPercentageChart] = useState([]);
  const [pageBottomMsgShow, setPageBottomMsgShow] = useState('');
  const [selectedValue, setSelectedValue] = useState('1');
  const [testTypeShow, setTestTypeShow] = useState('');
  const [variablesTabShow, setVariablesTabShow] = useState('');
  const [testsIdShow, setTestsIdShow] = useState('');
  const [testStartDate, setTestStartDate] = useState('');
  const [conversionTypeShow, setConversionTypeShow] = useState('');
  const [conversionTypeValueSelected, setConversionTypeValueSelected] = useState('');
  const [convtopageNameShow, setConvtopageNameShow] = useState('');
  const [marksalestime, setMarksalestime] = useState(0);
  const [testStatusShow, setTestStatusShow] = useState(0);

  const [isResetStatsModalOpen, setIsResetStatsModalOpen] = useState(false);
/* TIME PICKER AND DATE PICKER  */
  const handleselectedTimePickerComponentChange = (Value:any) => {
    setSelectedTimePicker(Value);
  }

  useEffect(() => {
    if (selectedTimePicker !== null) {
      TestOptimizeReport();
    }
  }, [selectedTimePicker]); 

  const handleselectedTimePickerToDateChange = (Value:any) => {
    setSelectedTimePickerToDate(Value);
  }

  const handleselectedTimePickerFromDateChange = (Value:any) => {
    setSelectedTimePickerFromDate(Value);
  }

  useEffect(() => {
    if (selectedTimePickerFromDate && selectedTimePickerToDate) {
      TestOptimizeReport();
    }
  }, [selectedTimePickerFromDate, selectedTimePickerToDate]); 
/* END TIME PICKER AND DATE PICKER  */

/* AUDIENCE SIZE */
  const handleAudienceSizeOpen = () => {
    setIsAudienceSizeModalOpen(true);
  };

  const handleAudienceSizeClose = () => {
    setIsAudienceSizeModalOpen(false);
  };
/* END AUDIENCE SIZE */

/* TRAFFIC CONTROL */
  const handleTrafficControlOpenModal = (value:any) => {
    setIsTrafficControlModalOpen(true);
  };
  
  const handleTrafficControlCloseModal = () => {
    setIsTrafficControlModalOpen(false);
  };
/* END TRAFFIC CONTROL */

/* TEST NOTES */
  const handleTestNotesOpenModal = (value:any) => {
    setIsTestNotesModalOpen(true);
  };

  const handleTestNotesCloseModal = () => {
    setIsTestNotesModalOpen(false);
  };
/* END TEST NOTES */

/* RESET STATS */
  const handleResetStatsModalOpen = (value:any) => {
    setIsResetStatsModalOpen(true);
  };

  const handleResetStatsCloseModal = (value:any) => {
    setIsResetStatsModalOpen(false);
  };
/* END RESET STATS */

/* CONNECT SHOPPING CART & PROCESSOR (OPTIONAL) */
  const handleShoppingCartProcessorOpenModal = (value:any) => {
    setShoppingCartProcessorModalOpen(true);
  };

  const handleShoppingCartProcessorCloseModal = () => {
    setShoppingCartProcessorModalOpen(false);
  };
/* END CONNECT SHOPPING CART & PROCESSOR (OPTIONAL) */ 

/* GOOGLE ANALYTICS */
  const handleGoogleAnalyticsOpenModal = (value:any) => {
    setIsGoogleAnalyticsModalOpen(true);
  };

  const handleGoogleAnalyticsCloseModal = () => {
    setIsGoogleAnalyticsModalOpen(false);
  };
/* END GOOGLE ANALYTICS */

/* TEST MODE*/
  const handleTestMode = () => {
    setIsTestMode(false);
    setTimeout(() => setIsTestMode(true), 0); 
  };
/* END TEST MODE*/

/* ATTRIBUTION MODEL*/
  const handleAttributionModelOpenModal = (value:any) => {
    setIsAttributionModalOpen(true);
  };

  const handleAttributionCloseModal = () => {
    setIsAttributionModalOpen(false);
  };
/* END ATTRIBUTION MODEL*/

/* VISISMART CODE*/
  const handleVisiSmartCodeOpen = () => {
    setIsVisiSmartCodeModalOpen(true);
  };

  const handleVisiSmartCodeCloseModal = () => {
    setIsVisiSmartCodeModalOpen(false);
  };
/* END VISISMART CODE*/

  const handleTestOptimizeReport = () => {
    TestOptimizeReport();
  };

  const handlenetworkhierarchical = () => {
    getnetworkhierarchicaldata();
  };

  const handlebehavioraldimensionfilter = () => {
    getbehavioraldimensionfilterdata();
  };

/* CONVERSIONS TO*/
  const handleConvRadioChange = (value) => {
    setConvRedioBtn(value);
    setConversionTypeShow(value === '1' ? 'goal' : value === '2' ? 'button' : 'postback');
    setConversionTypeValueSelected('');
  };

  useEffect(() => {
    if(selectedMemberWebsite !== ''){
  	  if (!pageId) {
  	    navigate('/review-tests');
  	    return;
  	  }
  	  TestsApi.getconversionsdropdown({
  	    system_website_id: selectedMemberWebsite,
  	    page_id:pageId,
  	    taguchi_id:taguchiTestId
  	  })
  	  .then(response => {
  	    if (response.ok) {
  	      return response.json();
  	    } else {
  	      if (response.status === 401 || response.status === 400) {
  	        navigate("/logout");
  	      }
  	    }
  	  })
  	  .then(data => {
  	    if (data.status === 1) {
          setConvRadioFunnelsGoals(data.data.funnels_goals || []);
          setConvRadioPostbackOptions(data.data.postback_options || []);
          setConvRadioButtonOptions(data.data.button_options || []);
  	    } else {
  	      console.log("Error:","Error");
  	    }
  	  })
  	  .catch(error => {
  	    console.error("API Error:", error);
  	  });
    }
  }, [selectedMemberWebsite]);

  const funnelGoal = useMemo(() => [
    { value: '', label: 'Select a Goal' },
    ...convRadioFunnelsGoals.map((FunnelsGoals) => ({
      value: FunnelsGoals.goal_id,
      label: FunnelsGoals.goal_name,
    })),
  ], [convRadioFunnelsGoals]);

  const postbackOption = useMemo(() => [
    { value: '', label: 'Select Postback' },
    ...convRadioPostbackOptions.map((PostbackOptions) => ({
      value: PostbackOptions.postback_id,
      label: PostbackOptions.postback_name,
    })),
  ], [convRadioPostbackOptions]);

  const buttonOptions = useMemo(() => [
    { value: '', label: 'Select a Button' },
    ...Object.values(convRadioButtonOptions).map((ButtonOptions) => ({
      value: ButtonOptions.button_id,
      label: ButtonOptions.button_name,
    })),
  ], [convRadioButtonOptions]);

  const SelectedconversionTypeValue = () => {
    if(conversionTypeShow === 'goal'){
      setConvRedioBtn('1');
    }
    if(conversionTypeShow === 'button'){
      setConvRedioBtn('2');
    }
    if(conversionTypeShow === 'postback'){
      setConvRedioBtn('3');
    }

    if (convRedioBtn === '1') {
      const selectedOption = funnelGoal.find(option => option.value === conversionTypeValueSelected);
      if (selectedOption) {
        setFunnelsGoals(selectedOption.value);
      }
    } else if (convRedioBtn === '2') {
      const selectedOption = buttonOptions.find(option => option.value === conversionTypeValueSelected);
      if (selectedOption) {
        setSelectedButton(selectedOption.value);
      }
    } else if (convRedioBtn === '3') {
      const selectedOption = postbackOption.find(option => option.value === conversionTypeValueSelected);
      if (selectedOption) {
        setPostbackOptions(selectedOption.value);
      }
    };
  };

  useEffect(() => {
    SelectedconversionTypeValue();
  }, [conversionTypeValueSelected]);

  const handleSelectConvChange = (value) => {
    setConversionTypeValueSelected(value);
    if (convRedioBtn === '1') {
      setFunnelsGoals(value);
    } else if (convRedioBtn === '2') {
      setSelectedButton(value);
    } else if (convRedioBtn === '3') {
      setPostbackOptions(value);
    }
  };
/* END CONVERSIONS TO*/

/* CONVERSION LOG*/
  const handleConversionLogsOpenModal = (value:any) => {
  	setIsConversionLogsModalOpen(true);
  };

  const handleConversionLogsCloseModal = () => {
    setIsConversionLogsModalOpen(false);
  };
/* END CONVERSION LOG*/

/* BEHAVIORAL DIMENSION*/
  const handleBehavioralFilterOpenModal = (value:any) => {
    setBehavioralFilterModalOpen(true);
  }

  const handleBehavioralFilterCloseModal = () => {
    setBehavioralFilterModalOpen(false);
  };
/* END BEHAVIORAL DIMENSION*/

/* VARIATIONS & VARIABLES*/
  const handleOptimize = (value:any) => {
    if(value === 'Variations'){
      setVariationsBtn('1');
      setVariablesBtn('0');
    } else if(value === 'Variables'){
      setVariationsBtn('0');
      setVariablesBtn('1');
      setShowVariablesTab(false);
      setTimeout(() => setShowVariablesTab(true), 0); 
    }
  };
/* END VARIATIONS & VARIABLES*/

/* BEHAVIORAL DIMENSION FILTER*/
    const getbehavioraldimensionfilterdata = () => {
        if(selectedMemberWebsite !== ''){
          setLoading(true);
          TestsApi.getbehavioraldimensionfilterdata({
          system_website_id: selectedMemberWebsite,
          page_id:pageId,
          taguchi_id:taguchiTestId
        })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            if (response.status === 401 || response.status === 400) {
              navigate("/logout");
            }
          }
        })
        .then(data => {
          if (data.status === 1) {
            setLoading(false);
            setBehavioralDimensionTrackingTags(data.data.tracking_tags);
            setBehavioralDimensionCustomTags(data.data.custom_tags);
            setBehavioralDimensionNetworkParameters(data.data.network_parameters);
            setBehavioralDimensionSource(data.data.source);
            setDisplayFilterButton(data.data.display_filter_button);
          } else {
            setLoading(false);
            console.error("Error:", "Error");
          }
        })
        .catch(error => {
          setLoading(false);
          console.error("API Error:", error);
        });
      }
    };

    useEffect(() => {
      getbehavioraldimensionfilterdata();
  }, [selectedMemberWebsite]);
/* END BEHAVIORAL DIMENSION FILTER*/

/* NETWORK HIERARCHICAL*/
  const [selectedTrackingTagsValue, setSelectedTrackingTagsValue] = useState([]);
  const [selectedCustomTagsValue, setSelectedCustomTagsValue] = useState([]);
  const [selectedSourceValue, setSelectedSourceValue] = useState([]);
  const [selectedNetworkParameters, setSelectedNetworkParameters] = useState({});
  const [selectedNetworkParam, setSelectedNetworkParam] = useState({ads_network: {}});

  const handleSelectNetworkParameters = (values, network, parameterName, array_id) => {
    setSelectedNetworkParameters(prevState => ({
      ...prevState,
      [network]: {
        ...prevState[network],
        [parameterName]: values,
      },
    }));

    setSelectedNetworkParam(prevState => {
        return {
            ...prevState,
            ads_network: {
                ...prevState.ads_network,
                [array_id]: values
            }
        };
    });
  };

  const getnetworkhierarchicaldata = () => {
    if(selectedMemberWebsite !== ''){
        setSystemUsageLoader(true);
        const adsData = {};
        Object.keys(selectedNetworkParameters).forEach(network => {
          Object.keys(selectedNetworkParameters[network]).forEach((parameter, index) => {
            adsData[`ads_${index + 1}`] = selectedNetworkParameters[network][parameter];
          });
        });
        TestsApi.getnetworkhierarchicaldata({
        page_id:pageId,
        taguchi_id:taguchiTestId,
        hierarchical_type:"tests",
        ...adsData
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if (data.status === 1) {
          setBehavioralDimensionNetworkParameters(data.data.network_parameters);
        } else {
          setSystemUsageLoader(false);
          console.error("Error:", "Error");
        }
      })
      .catch(error => {
        setSystemUsageLoader(false);
        console.error("API Error:", error);
      });
      setSystemUsageLoader(false);
    }
  };

  useEffect(() => {
    getnetworkhierarchicaldata();
  }, [selectedNetworkParameters]);
/* END NETWORK HIERARCHICAL*/

/* SET WINNER*/
  const handleSetWinner = async (varId, PageUrlShow: any) => {
    setVarId(varId);
    setPageUrlShow(PageUrlShow);
    setSetWinnerModalOpen(true);
  };

  const handleSetWinnerCloseModal = () => {
    setSetWinnerModalOpen(false);
  };
/* END SET WINNER*/

/* FILTER */
  const handleSelectTrackingTags = (parameterName, value, array_id) => {
    setSelectedTrackingTagsValue(prevState => ({
        ...prevState,
        [`${array_id}`]: value
    }));
  };

  const handleSelectCustomTags = (parameterName, value, array_id) => {
    setSelectedCustomTagsValue(prevState => ({
        ...prevState,
        [`${array_id}`]: value
    }));
  };

  const handleSelectSource = (value) => {
    setSelectedSourceValue(value);
  };

  const behavioralDimensionFilter = () => {
    if(selectedMemberWebsite !== ''){
      const formattedAdsTrackingNetwork = Object.keys(selectedTrackingTagsValue).reduce((acc, key) => {
          acc[key] = selectedTrackingTagsValue[key];
          return acc;
      }, {});

      const formattedAdsCustomTags = Object.keys(selectedCustomTagsValue).reduce((acc, key) => {
          acc[key] = selectedCustomTagsValue[key];
          return acc;
      }, {});

      TestsApi.testoptimizereport({
        system_website_id:selectedMemberWebsite,
        page_id:pageId,
        time: selectedTimePicker,
        test_goal_type: conversionTypeShow,
        convtogoal:convRedioBtn === '1' ? funnelsGoals : '',
        convtobutton:convRedioBtn === '2' ? selectedButton : '',
        convtopostback:convRedioBtn === '3' ? postbackOptions : '',
        ads_network: selectedNetworkParam.ads_network,
        ads_tracking_network:formattedAdsTrackingNetwork,
        ads_custom_network:formattedAdsCustomTags,
        source:selectedSourceValue,
        from:selectedTimePickerFromDate,
        to:selectedTimePickerToDate
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if (data.status === 1) {
          setTestVariationsData(data.data.test_data);
          setDataColumnChart(data.data.conversion_chart_column);
          setDataColumnPercentageChart(data.data.conversion_percentage_chart_column);
          setBehavioralFilterArrCount(data.data.behavioral_filter_arr_count);
          setTestModeShow(data.data.testmode);
          setWebhookSetShow(data.data.webhook_set);
          setHidePauseShow(data.data.hide_pause);                      
          setTestNameShow(data.data.test_name);
          setPageIdShow(data.data.page_id);
          setTagichiTypeIdShow(data.data.tagichi_type_id);
          setTestPageLinkShow(data.data.test_page_link);
          setTestPageTitleShow(data.data.test_page_title);
          setTotalUniquesShow(data.data.total_uniques);
          setTotalVisitsShow(data.data.total_visits);
          setTotalConversionsShow(data.data.total_conversions);
          setPageBottomMsgShow(data.data.page_bottom_msg);
          setConversionTypeShow(data.data.conversion_type);
          setConversionTypeValueSelected(data.data.conversion_type_value_selected);
          setConvtopageNameShow(data.data.convtopage_name);
          setTestTypeShow(data.data.test_type);
          setVariablesTabShow(data.data.hide_variables_tab);
          setTestsIdShow(data.data.tests_id);
          setTestStartDate(data.data.test_start_date);
          setMarksalestime(data.data.updated_time);
        } else {
          setErrorMessage(data.message);
          seterrorCloseModal(true);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
    }
  };
/* END FILTER */

/* TEST OPTIMIZE REPORT*/
  const TestOptimizeReport = () => {
    if(selectedMemberWebsite !== ''){
      setLoading(true);
      const formattedAdsTrackingNetwork = Object.keys(selectedTrackingTagsValue).reduce((acc, key) => {
        acc[key] = selectedTrackingTagsValue[key];
        return acc;
      }, {});

      const formattedAdsCustomTags = Object.keys(selectedCustomTagsValue).reduce((acc, key) => {
          acc[key] = selectedCustomTagsValue[key];
          return acc;
      }, {});
      TestsApi.testoptimizereport({
        system_website_id:selectedMemberWebsite,
        page_id:pageId,
        time: selectedTimePicker,
        test_goal_type: conversionTypeShow,
        convtogoal:convRedioBtn === '1' ? funnelsGoals : '',
        convtobutton:convRedioBtn === '2' ? selectedButton : '',
        convtopostback:convRedioBtn === '3' ? postbackOptions : '',
        ads_network: selectedNetworkParam.ads_network,
        ads_tracking_network:formattedAdsTrackingNetwork,
        ads_custom_network:formattedAdsCustomTags,
        source:selectedSourceValue,
        from:selectedTimePickerFromDate,
        to:selectedTimePickerToDate
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if (data.status === 1) {
          setLoading(false);
          setTestVariationsData(data.data.test_data);
          setDataColumnChart(data.data.conversion_chart_column);
          setDataColumnPercentageChart(data.data.conversion_percentage_chart_column);
          setBehavioralFilterArrCount(data.data.behavioral_filter_arr_count);
          setTestModeShow(data.data.testmode);
          setWebhookSetShow(data.data.webhook_set);
          setHidePauseShow(data.data.hide_pause);
          setTestNameShow(data.data.test_name);
          setPageIdShow(data.data.page_id);
          setTagichiTypeIdShow(data.data.tagichi_type_id);
          setTestPageLinkShow(data.data.test_page_link);
          setTestPageTitleShow(data.data.test_page_title);
          setTotalUniquesShow(data.data.total_uniques);
          setTotalVisitsShow(data.data.total_visits);
          setTotalConversionsShow(data.data.total_conversions);
          setPageBottomMsgShow(data.data.page_bottom_msg);
          setConversionTypeShow(data.data.conversion_type);
          setConversionTypeValueSelected(data.data.conversion_type_value_selected);
          setConvtopageNameShow(data.data.convtopage_name);
          setTestTypeShow(data.data.test_type);
          setVariablesTabShow(data.data.hide_variables_tab);
          setTestsIdShow(data.data.tests_id);
          setTestStartDate(data.data.test_start_date);
          setMarksalestime(data.data.updated_time);

          setTestStatusShow(data.data.testpausestatus);
        } else {
          setErrorMessage(data.message);
          seterrorCloseModal(true);
          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
        console.error("API Error:", error);
      });
    }
  };
  
  useEffect(() => {
    if(funnelsGoals || postbackOptions || selectedButton){
      behavioralDimensionFilter();
    }
  }, [selectedMemberWebsite,funnelsGoals,postbackOptions,selectedButton]);

  const handleConversionRadioChange = (value) => {
    setSelectedValue(value);
    behavioralDimensionFilter();
  };

  const dataColumnChartRows = [
    ['Variation', 'Value', { role: 'style' }],
    ...dataColumnChart,
  ];

  const optionsColumnChart = {
    legend: { position: 'none' },
    chartArea: { width: '90%', height: '80%' },
    vAxis: {
      title: 'Conversions',
      titleTextStyle: { fontFamily: 'Cursive', fontSize: 16 },
    },
  };

  const dataColumnPercentageChartRows = [
    ['Variation', 'Value', { role: 'style' }],
    ...dataColumnPercentageChart,
  ];

  const optionsColumnPercentageChart = {
    legend: { position: 'none' },
    chartArea: { width: '90%', height: '80%' },
    vAxis: {
      title: 'Conversions %',
      titleTextStyle: { fontFamily: 'Cursive', fontSize: 16 },
    },
  };

  const getBackgroundColor = (highlight) => {
    if (highlight === 'red') {
      return '#ffd9d9';
    }
    if (highlight === 'yellow') {
      return '#fff7c6';
    }
    return '';
  };

  const allOptions = [
    { value: 'Uniques', label: 'Uniques' },
    { value: 'Visits', label: 'Visits' },
    { value: 'Conv. # to page', label: 'Conv. # to page' },
    { value: 'Conv. % to page', label: 'Conv. % to page' },
    { value: 'Percentage Improvement', label: 'Percentage Improvement' },
    { value: 'Statistical Significance', label: 'Statistical Significance' },
    { value: 'Avg. Time Spent', label: 'Avg. Time Spent' },
    { value: 'Total Revenue', label: 'Total Revenue' },
    { value: 'AOV', label: 'AOV' },
    { value: 'Avg. Revenue per unique', label: 'Avg. Revenue per unique' },
  ];

  const defaultSelectedOptions = [
    'Uniques',
    'Visits',
    'Conv. # to page',
    'Conv. % to page',
    'Percentage Improvement',
    'Statistical Significance',
    'Total Revenue',
    'AOV',
    'Avg. Revenue per unique'
  ];
  const [selectedfilteredOptions, setSelectedfilteredOptions] = useState<string[]>(defaultSelectedOptions);
  
  const filteredOptions = webhookSetShow === 1
    ? allOptions
  : allOptions.filter(option => 
      option.value !== 'Total Revenue' && 
      option.value !== 'AOV' && 
      option.value !== 'Avg. Revenue per unique'
    );

  const handleSelectFilteredChange = (values: string[]) => {
    setSelectedfilteredOptions(values);
  };

  const testVariationsRows = testVariationsData.map((element,index) => (
      <tr key={element.test_number} 
        style={{ backgroundColor: getBackgroundColor(element.column_heighlite) }}
      >
      <td>{element.test_number}</td>
      <td style={{ width: '200px'}}>
        <div style={{ display: 'flex'}}>
          <CustomTitle 
            varId={element.variable} 
            verId={element.version} 
            currentVariations={element.name} 
            testnumber={element.test_number}
            previewadtest={element.preview_adtest}
            editvariationhide={element.edit_variation_hide}
            pageId={pageId}
            taguchiTestId={taguchiTestId}
            testsIdShow={testsIdShow}
            setMarksalestime={setMarksalestime}
            TestOptimizeReport={handleTestOptimizeReport}
          />
        </div>
      </td>
      {selectedfilteredOptions.includes('Uniques') && <td>{element.unique}</td>}
      {selectedfilteredOptions.includes('Visits') && <td>{element.visit}</td>}
      {selectedfilteredOptions.includes('Conv. # to page') && <td>{element.pageconvnum}</td>}
      {selectedfilteredOptions.includes('Conv. % to page') && <td>{element.pageconv} %</td>}
      {selectedfilteredOptions.includes('Percentage Improvement') && 
        <td>
        {element.pi_per === 'Not Enough Data' ? (
          <Text c="red" fw={500}>{element.pi_per}</Text>
        ) : (
          <div style={{ display: 'flex' }}>
            <Text fw={500} w={40}>{Math.round(element.pi_per)}%</Text>
            <div className="pi_graph pi_cf">
              <div className={`pi_ext ${element.pi_class}`}>
                <div style={{ width: `${Math.min(Math.round(element.pi_per_value), 100)}%` }} className={`pi_bar ${element.pi_class}`}></div>
              </div>
            </div>
          </div>
        )}
        </td>
      }
      <td><Text c="teal" fw={700} style={{cursor: 'pointer'}} onClick={() => handleSetWinner(element.test_number,element.page_url)}>Set Winner</Text></td>
      {selectedfilteredOptions.includes('Statistical Significance') &&
        <>
          {(element.ss_class !== '' || element.ss_per !== 0) ? (
          <td><div style={{ display: 'flex'}}><Text fw={500} style={{width:"5.5rem"}}>{element.ss_per}%</Text>{element.ss_per !== ''?<progress value={element.ss_per} style={{width:"208%",marginTop:"3px"}} className={element.ss_class} max="100"></progress>:''}</div></td>
          ) : (
            <td></td>
          )}
        </>
      }
      {webhookSetShow !== 0 && (
      <>
      {selectedfilteredOptions.includes('Total Revenue') && <td>{element.revenue}</td>}
      {selectedfilteredOptions.includes('AOV') && <td>{element.aov}</td>}
      {selectedfilteredOptions.includes('Avg. Revenue per unique') && <td>{element.avg_revenue_per_unique}</td>}
      </>
      )}
      {selectedfilteredOptions.includes('Avg. Time Spent') && <td>{element.time_spent}</td>}
      {hidePauseShow !== 'hide' && (
      <>
      <td>
        <PauseVariations 
          selectedMemberWebsite={selectedMemberWebsite}
          pageId={pageId}
          taguchiTestId={taguchiTestId}
          testsIdShow={testsIdShow}
          pausedvariation={element.paused_variation}
          varId={element.version}
          currentVariations={element.name}
          setMarksalestime={setMarksalestime}
          TestOptimizeReport={handleTestOptimizeReport}
          errorModelOpen={seterrorCloseModal}
          errorMessage={setErrorMessage}
          showErrorHeader={setShowErrorHeader}
          showMessageHeader={setShowMessageHeader}
          systemUsageLoader={setSystemUsageLoader}
        />
      </td>
       </>
      )}
    </tr>
  ));
/* END TEST OPTIMIZE REPORT*/

  const behavioralDimensionClearFilter = () => {
    setLoading(true);
    setBehavioralDimensionTrackingTags({});
    setBehavioralDimensionCustomTags({});
    setBehavioralDimensionNetworkParameters({});
    setBehavioralDimensionSource([]);
    setSelectedTrackingTagsValue([]);
    setSelectedCustomTagsValue([]);
    setSelectedSourceValue([]);
    setSelectedNetworkParameters({});
    setSelectedNetworkParam({ads_network: {}});

    TestsApi.testoptimizereport({
      system_website_id:selectedMemberWebsite,
      page_id:pageId,
      time: selectedTimePicker,
      test_goal_type: conversionTypeShow,
      convtogoal:convRedioBtn === '1' ? funnelsGoals : '',
      convtobutton:convRedioBtn === '2' ? selectedButton : '',
      convtopostback:convRedioBtn === '3' ? postbackOptions : '',
      from:selectedTimePickerFromDate,
      to:selectedTimePickerToDate
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        setLoading(false);
        setTestVariationsData(data.data.test_data);
        setDataColumnChart(data.data.conversion_chart_column);
        setDataColumnPercentageChart(data.data.conversion_percentage_chart_column);
        setBehavioralFilterArrCount(data.data.behavioral_filter_arr_count);
        setTestModeShow(data.data.testmode);
        setWebhookSetShow(data.data.webhook_set);
        setHidePauseShow(data.data.hide_pause);
        setTestNameShow(data.data.test_name);
        setPageIdShow(data.data.page_id);
        setTagichiTypeIdShow(data.data.tagichi_type_id);
        setTestPageLinkShow(data.data.test_page_link);
        setTestPageTitleShow(data.data.test_page_title);
        setTotalUniquesShow(data.data.total_uniques);
        setTotalVisitsShow(data.data.total_visits);
        setTotalConversionsShow(data.data.total_conversions);
        setPageBottomMsgShow(data.data.page_bottom_msg);
        setConversionTypeShow(data.data.conversion_type);
        setConversionTypeValueSelected(data.data.conversion_type_value_selected);
        setConvtopageNameShow(data.data.convtopage_name);
        setTestTypeShow(data.data.test_type);
        setVariablesTabShow(data.data.hide_variables_tab);
        setTestsIdShow(data.data.tests_id);
        setTestStartDate(data.data.test_start_date);
        setMarksalestime(data.data.updated_time);
      } else {
        setErrorMessage(data.message);
        seterrorCloseModal(true);
        setLoading(false);
      }
    })
    .catch(error => {
      setLoading(false);
      console.error("API Error:", error);
    });
  };

  const handleRefreshStats = () => {
    behavioralDimensionClearFilter();
  };

  const handlerrorCloseModal = () => {
    seterrorCloseModal(false);
  };

  const handleEditTest = (pageId:any,tagichiTypeId:any) => {
    if(testTypeShow === "modern_test"){
      const url = `/test-editor-visual-modern/${pageId}/${tagichiTypeId}`;
      window.open(url, '_blank');
    }else{
      const url = `/test-editor-visual/${pageId}/${tagichiTypeId}`;
      window.open(url, '_blank');
    }
  };

  const [selectedGraph, setSelectedGraph] = useState('0');

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5; // Number of legend items per page

  const dataLineChart = [
    ['Day', 'Control', 'Variation 1', 'Variation 2', 'Variation 3', 'Variation 4', 'Variation 5', 'Variation 6', 'Variation 7', 'Variation 8', 'Variation 9', 'Variation 10'],
    [1, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110],
    [2, 15, 22, 32, 52, 62, 72, 82, 92, 102, 112, 122],
    [3, 20, 30, 50, 65, 70, 85, 90, 100, 110, 120, 133],
    [4, 35, 42, 60, 70, 80, 90, 107, 110, 123, 130, 144],
    [5, 48, 60, 70, 80, 95, 100, 110, 120, 130, 149, 155],
    [6, 55, 70, 80, 90, 100, 115, 120, 135, 146, 150, 166],
    [7, 60, 80, 90, 109, 110, 120, 130, 140, 150, 160, 177],
    [8, 78, 90, 100, 110, 120, 130, 140, 150, 169, 170, 188],
  ];

  // Colors for each line (25 variations)
  const colors = ['#f39c12', '#e74c3c', '#c0392b', '#743190', '#E5D82A', '#D23D6A', '#354B8C', '#8F4B37', '#429770', '#D91C49', '#FD5825'];
  const optionsLineChart = {
    legend: { position: 'none' },
    chartArea: { height: 'auto', width: '90%' },
    vAxis: {
      title: 'Cumulative Conversions',
      titleTextStyle: { fontFamily: 'Cursive', fontSize: 16 },
      minValue: 0,
    },
    hAxis: {
      title: 'Days',
      titleTextStyle: { fontFamily: 'Cursive', fontSize: 16 },
    },
    colors: colors, // We keep all colors visible in the chart itself
  }; 

  // Pagination for legend items (slicing colors and labels)
  const paginatedLabels = dataLineChart[0].slice(1).slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
  const paginatedColors = colors.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  // Calculate total pages for legend
  const charttotalPages = Math.ceil(colors.length / itemsPerPage);

	return (
	  <>
    <VisiLoader VisiLoaderVisible={SystemUsageLoader}/>
	  <HeaderMenu HeaderMenuValue={selectedMemberWebsite} HeaderMenuOnChange={handleHeaderMenuComponentChange}/>
	  {/*Audience Size Model*/}
    <AudienceSize
      selectedMemberWebsite={selectedMemberWebsite}
      pageId={pageId}
      isAudienceSizeModalOpen={isAudienceSizeModalOpen}
      onAudienceSizeClose={handleAudienceSizeClose}
      TestOptimizeReport={handleTestOptimizeReport}
      setMarksalestime={setMarksalestime}
      errorModelOpen={seterrorCloseModal}
      errorMessage={setErrorMessage}
      showErrorHeader={setShowErrorHeader}
      showMessageHeader={setShowMessageHeader}
    />
    {/*Connect Shopping Cart & Processor Model*/}
    <ShoppingCartProcessor
      selectedMemberWebsite={selectedMemberWebsite}
      pageId={pageId}
      funnelId={funnelId}
      testsId={testsId}
      isShoppingCartProcessorModalOpen={shoppingCartProcessorModalOpen}
      onShoppingCartProcessorClose={handleShoppingCartProcessorCloseModal}
      TestOptimizeReport={handleTestOptimizeReport}
      setMarksalestime={setMarksalestime}
      errorModelOpen={seterrorCloseModal}
      errorMessage={setErrorMessage}
      showErrorHeader={setShowErrorHeader}
      showMessageHeader={setShowMessageHeader}
    />
    {/*Behavioral Filters Model*/}
    <BehavioralFilter
      selectedMemberWebsite={selectedMemberWebsite}
      pageId={pageId}
      isBehavioralFilterModalOpen={behavioralFilterModalOpen}
      onBehavioralFilterClose={handleBehavioralFilterCloseModal}
      TestOptimizeReport={handleTestOptimizeReport}
      setMarksalestime={setMarksalestime}
      getnetworkhierarchicaldata={handlenetworkhierarchical}
      getbehavioraldimensionfilterdata={handlebehavioraldimensionfilter}
      errorModelOpen={seterrorCloseModal}
      errorMessage={setErrorMessage}
      showErrorHeader={setShowErrorHeader}
      showMessageHeader={setShowMessageHeader}s
    />
    {/*Attribution Model*/}
    <Attribution
      selectedMemberWebsite={selectedMemberWebsite}
      pageId={pageId}
      isAttributionModalOpen={isAttributionModalOpen}
      onAttributionClose={handleAttributionCloseModal}
      TestOptimizeReport={handleTestOptimizeReport}
      setMarksalestime={setMarksalestime}
      errorModelOpen={seterrorCloseModal}
      errorMessage={setErrorMessage}
      showErrorHeader={setShowErrorHeader}
      showMessageHeader={setShowMessageHeader}
    />
    {/*Traffic Control Model*/}
    <TrafficControl
      selectedMemberWebsite={selectedMemberWebsite}
      pageId={pageId}
      isTrafficControlModalOpen={isTrafficControlModalOpen}
      onTrafficControlClose={handleTrafficControlCloseModal}
      errorModelOpen={seterrorCloseModal}
      errorMessage={setErrorMessage}
      showErrorHeader={setShowErrorHeader}
      showMessageHeader={setShowMessageHeader}
    />
    {/*Google Analytics Integration Model*/}
    <GoogleAnalytics
      selectedMemberWebsite={selectedMemberWebsite}
      pageId={pageId}
      taguchiTestId={taguchiTestId}
      isGoogleAnalyticsModalOpen={isGoogleAnalyticsModalOpen}
      onGoogleAnalyticsClose={handleGoogleAnalyticsCloseModal}
      errorModelOpen={seterrorCloseModal}
      errorMessage={setErrorMessage}
      showErrorHeader={setShowErrorHeader}
      showMessageHeader={setShowMessageHeader}
    />
    {/*Conversion Logs Model*/}
    <Logs
      selectedMemberWebsite={selectedMemberWebsite}
      pageId={pageId}
      taguchiTestId={taguchiTestId}
      funnelId={funnelId}
      testStartDate={testStartDate}
      isConversionLogsModalOpen={isConversionLogsModalOpen}
      onConversionLogsClose={handleConversionLogsCloseModal}
      TestOptimizeReport={handleTestOptimizeReport}
      errorModelOpen={seterrorCloseModal}
      errorMessage={setErrorMessage}
      showErrorHeader={setShowErrorHeader}
      showMessageHeader={setShowMessageHeader}
    />
    {/*Test Notes Model*/}
    <TestNotes
      selectedMemberWebsite={selectedMemberWebsite}
      pageId={pageId}
      taguchiTestId={taguchiTestId}
      isTestNotesModalOpen={isTestNotesModalOpen}
      onTestNotesClose={handleTestNotesCloseModal}
      errorModelOpen={seterrorCloseModal}
      errorMessage={setErrorMessage}
      showErrorHeader={setShowErrorHeader}
      showMessageHeader={setShowMessageHeader}
    />
    {/*VisiSmart Code Model*/}
    <VisiSmartCode
      selectedMemberWebsite={selectedMemberWebsite}
      pageId={pageId}
      isVisiSmartCodeModalOpen={isVisiSmartCodeModalOpen}
      onVisiSmartCodeClose={handleVisiSmartCodeCloseModal}
      errorModelOpen={seterrorCloseModal}
      errorMessage={setErrorMessage}
      showErrorHeader={setShowErrorHeader}
      showMessageHeader={setShowMessageHeader}
    />
    {/*Test Mode*/}
    <TestMode
      selectedMemberWebsite={selectedMemberWebsite}
      pageId={pageId}
      taguchiTestId={taguchiTestId}
      testModeShow={testModeShow}
      isTestMode={isTestMode}
      TestOptimizeReport={handleTestOptimizeReport}
      setMarksalestime={setMarksalestime}
      errorModelOpen={seterrorCloseModal}
      errorMessage={setErrorMessage}
      showErrorHeader={setShowErrorHeader}
      showMessageHeader={setShowMessageHeader}
      systemUsageLoader={setSystemUsageLoader}
    />
    {/*Set Winner Model*/}
    <SetWinner
      selectedMemberWebsite={selectedMemberWebsite}
      pageId={pageId}
      taguchiTestId={taguchiTestId}
      varIdshow={varIdshow}
      pageUrlShow={pageUrlShow}
      testTypeShow={testTypeShow}
      openWinnerModal={setWinnerModalOpen}
      setWinnerModalOpen={setSetWinnerModalOpen}
      setWinnerCloseModal={handleSetWinnerCloseModal}
      errorModelOpen={seterrorCloseModal}
      errorMessage={setErrorMessage}
      showErrorHeader={setShowErrorHeader}
      showMessageHeader={setShowMessageHeader}
      systemUsageLoader={setSystemUsageLoader}
      setloading={setLoading}
    />
	  <Container size="xxl">
      <Skeleton visible={loading}>
	  	<div style={{ display: 'flex',marginBottom:"10px"}}>
		    <Box w={130}>
  		    <Button rightIcon={<IconHelp size="1.1rem" color="red"/>} variant="default" bg={variationsBtn === '1' ? '#e7fcf5' : "" } type="submit" onClick={() => handleOptimize('Variations')}>
            Variations
          </Button>
        </Box>
        {variablesTabShow === 'show' &&( 
          <Box w={130}>
    		    <Button rightIcon={<IconHelp size="1.1rem" color="red"/>} variant="default" bg={variablesBtn === '1' ? '#e7fcf5' : "" } type="submit" onClick={() => handleOptimize('Variables')}>
              Variables
            </Button>
          </Box>
        )}
	  	</div>
      <div style={{ display: variationsBtn === '1' ? "block" : "none" }}>
  	  	<Card shadow="sm" padding="sm" radius="md" withBorder>
  	  		<div style={{ display: 'flex',marginBottom: '10px' }}>
  		  		<Group position="apart" mb="xs">
  		        <Text weight={500}>TEST - {testPageTitleShow} <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
  		      </Group>
  		      <div style={{ display: 'flex',marginLeft:"auto"}}>
  			     	<ActionIcon
  	            variant="white"
  	            size="lg"
  	          >
  	            <IconClockHour4 size="1.1rem" />
  	          </ActionIcon>
  	          <SelectTimePicker SelectTimePickerValue={selectedTimePicker} SelectTimePickerOnChange={handleselectedTimePickerComponentChange} SelectTimePickerFromDateOnChange={handleselectedTimePickerFromDateChange} SelectTimePickerToDateOnChange={handleselectedTimePickerToDateChange} SelectTestStartDate={testStartDate}/>
  		     		<ActionIcon
  		     		  variant="default"
  		     		  size="lg"
  		     		 	sx={(theme) => ({
  	               backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#fff",
  	             })}
  		     		  title="Refresh Stats"
  		     		  ml={5}
  		     		  onClick={handleRefreshStats}
  		     		>
  		     		  <IconRefresh size="1.1rem" />
  		     		</ActionIcon>
              <PauseResume 
                selectedMemberWebsite={selectedMemberWebsite}
                pageId={pageId} 
                taguchiTestId={taguchiTestId} 
                TestStatusShow={testStatusShow}
                errorModelOpen={seterrorCloseModal}
                errorMessage={setErrorMessage}
                showErrorHeader={setShowErrorHeader}
                showMessageHeader={setShowMessageHeader}
                systemUsageLoader={setSystemUsageLoader}
              />
              <TestInfo 
                pageId={pageId}
                errorModelOpen={seterrorCloseModal}
                errorMessage={setErrorMessage}
                showErrorHeader={setShowErrorHeader}
                showMessageHeader={setShowMessageHeader}
                systemUsageLoader={setSystemUsageLoader}
              />
              <DiscardTest 
                testId={testId} 
                pageId={pageId} 
                taguchiTestId={taguchiTestId} 
                selectedMemberWebsite={selectedMemberWebsite} 
                errorModelOpen={seterrorCloseModal}
                errorMessage={setErrorMessage}
                showErrorHeader={setShowErrorHeader}
                showMessageHeader={setShowMessageHeader}
                systemUsageLoader={setSystemUsageLoader}
              />
  		     		<Menu width={260} position="bottom-end" withinPortal>
  		     		  <Menu.Target>
  		     		    <ActionIcon
      		     		  variant="default"
      		     		  size="lg"
      		     		  sx={(theme) => ({
      	               backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#fff",
      	             })}
      		     		  title="Settings"
      		     		  ml={5}
      		     		>
      		     		  <IconSettings size="1.1rem" />
      		     		</ActionIcon>
  		     		  </Menu.Target>

  		     		  <Menu.Dropdown>
  		     		    <Menu.Item px={10} py={6} onClick={handleAudienceSizeOpen}>Audience Size</Menu.Item>
  		     		    <Menu.Item px={10} py={6} onClick={handleShoppingCartProcessorOpenModal}>Connect Shopping Cart & Processor</Menu.Item>
                  <PermissionWrapper permission="behavioral_dimensions_access">
                    <Menu.Item px={10} py={6} onClick={handleBehavioralFilterOpenModal}>Behavioral Filters</Menu.Item>
                  </PermissionWrapper>
                  {testTypeShow !== "classic_test" && (
  		     		     <Menu.Item px={10} py={6} onClick={() => handleEditTest(pageIdShow,tagichiTypeIdShow)}>Edit Test</Menu.Item>
                  )}
  		     		    <Menu.Item px={10} py={6} onClick={handleResetStatsModalOpen}>Reset Stats</Menu.Item>
  		     		    <Menu.Item px={10} py={6} onClick={handleAttributionModelOpenModal}>Attribution Model</Menu.Item>
  		     		    <Menu.Item px={10} py={6} onClick={handleTrafficControlOpenModal}>Traffic Control</Menu.Item>
  		     		    <Menu.Item px={10} py={6} onClick={handleGoogleAnalyticsOpenModal}>Google Analytics Integration</Menu.Item>
  		     		    <Menu.Item px={10} py={6} onClick={handleConversionLogsOpenModal}>Logs</Menu.Item>
  		     		    <Menu.Item px={10} py={6} onClick={handleTestNotesOpenModal}>Test Notes</Menu.Item>
                  <Menu.Item px={10} py={6} onClick={handleTestMode}>Test Mode</Menu.Item>
  		     		    <Menu.Item px={10} py={6} onClick={handleVisiSmartCodeOpen}>VisiSmart Code</Menu.Item>
  		     		  </Menu.Dropdown>
  		     		</Menu> 
  		     	</div>
  		    </div>
  		    <Group>
  		      <Text mb={6}><IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/> Conversions to:</Text>
  		      <Radio.Group
  		        name="GoalType"
  		        mb={10}
  		        value={convRedioBtn}
              onChange={handleConvRadioChange}
  		      >
  		        <Group mt="xs">
  		          <Radio value="1" label="Goals" />
  		          <Radio value="2" label="Buttons" />
  		          <Radio value="3" label="Postbacks" />
  		        </Group>
  		      </Radio.Group>
  		      <Select
              value={conversionTypeValueSelected}
              onChange={handleSelectConvChange}
  			      data={convRedioBtn === '1' ? funnelGoal : convRedioBtn === '2' ? buttonOptions : postbackOption}
  			    />
            {testModeShow !== 0 && (
              <Text weight={500} mt={4} c="red">Test Mode Active</Text>
            )}
  		    </Group>
  		    <Group mt={10}>
            <MultiSelect
              data={filteredOptions}
              onChange={handleSelectFilteredChange} 
              value={selectedfilteredOptions}
              multiple
              placeholder="Select Metrics"
            />
    		    <Box w={200}>
              {behavioralFilterArrCount !== 0 && (
  	  		    <Button fullWidth variant="default" bg="#e7fcf5" onClick={handleBehavioralFilterOpenModal}>
  	            Behavioral Filters Applied
  	          </Button>
              )}
  	        </Box>
  		    </Group>
            <div className="ads_network">

            {Object.keys(behavioralDimensionNetworkParameters).length > 0 && (
              <>
              <Text weight={500} mt={15}>Network Parameters</Text>
              {Object.keys(behavioralDimensionNetworkParameters).map(network => (
                <div key={network} style={{ marginBottom: '15px' }}>
                  <div style={{ display: 'flex', marginBottom: '10px' }}>
                    <Text weight={500} mt={4} tt="capitalize">{network}:</Text>
                    <div style={{ display: 'flex', marginLeft: '10px' }}>
                      {Object.keys(behavioralDimensionNetworkParameters[network]).map(parameterName => {
                        const parameter = behavioralDimensionNetworkParameters[network][parameterName];
                        return (
                          <div key={`${network}-${parameterName}`} style={{ display: 'flex', marginRight: '15px' }}>
                            <Text color="red" mx={5} mt={4}>{parameterName}</Text>
                            <input type="hidden" name={`${network}-${parameterName}-id`} value={parameter.array_id} />
                            <MultiSelect
                              data={parameter.dropdown_data.map(item => ({
                                value: item.key,
                                label: item.value
                              }))}
                              onChange={(values) => handleSelectNetworkParameters(values, network, parameterName, parameter.array_id)}
                              name={`${network}-${parameterName}`}
                              nothingFound="No matches found"
                              searchable
                              radius="sm"
                              size="sm"
                              mr={10}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ))}
              </>
            )}
            {Object.keys(behavioralDimensionTrackingTags).length > 0 && (
              <>
              <Text weight={500} mt={15}>Tracking tags</Text>
              <div className="ads_network" style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                {Object.keys(behavioralDimensionTrackingTags).map(parameterName => {
                  const parameter = behavioralDimensionTrackingTags[parameterName];
                  return (
                    <div key={`${parameterName}`} style={{ display: 'flex', marginRight: '15px' }}>
                      <Text color="red" mx={5} mt={4}>{parameterName}</Text>
                      <input type="hidden" name={`${parameterName}-id`} value={parameter.array_id} />
                      <MultiSelect
                        data={parameter.dropdown_data.map(item => ({
                          value: item.key,
                          label: item.value
                        }))}
                        onChange={(value) => handleSelectTrackingTags(parameterName, value, parameter.array_id)}
                        name={parameterName}
                        nothingFound="No matches found"
                        searchable
                        radius="sm"
                        size="sm"
                        mr={10}
                      />
                    </div>
                  );
                })}
              </div>
             </>
            )}            
            {Object.keys(behavioralDimensionCustomTags).length > 0 && (
              <>
              <Text weight={500} mt={15}>Custom tags</Text>
              <div className="ads_network" style={{ display: 'flex', marginTop: '10px' }}>
                {Object.keys(behavioralDimensionCustomTags).map(parameterName => {
                  const parameter = behavioralDimensionCustomTags[parameterName];
                  return (
                    <div key={`${parameterName}`} style={{ display: 'flex', marginRight: '15px' }}>
                      <Text color="red" mx={5} mt={4}>{parameterName}</Text>
                      <input type="hidden" name={`${parameterName}-id`} value={parameter.array_id} />
                      <MultiSelect
                        data={parameter.dropdown_data.map(item => ({
                          value: item.key,
                          label: item.value
                        }))}
                        onChange={(value) => handleSelectCustomTags(parameterName, value, parameter.array_id)}
                        name={parameterName}
                        nothingFound="No matches found"
                        searchable
                        radius="sm"
                        size="sm"
                        mr={10}
                      />
                    </div>
                  );
                })}
              </div>
            </>
            )}
            {Object.keys(behavioralDimensionSource).length > 0 && (
              <>
              <Text weight={500} mt={15}>Source</Text>
              <div className="ads_network" style={{ display: 'flex', marginTop: '10px' }}>
                <Text color="red" mx={5} mt={4}>source</Text>
                <MultiSelect
                  data={behavioralDimensionSource.map(item => ({
                    value: item.key,
                    label: item.value
                  }))}
                  onChange={handleSelectSource}
                  nothingFound="No matches found"
                  searchable
                  radius="sm"
                  size="sm"
                  mr={10}
                />
              </div>
              </>
            )}
            {displayFilterButton === 1 &&(
              <Button style={{ marginTop: '15px' }} onClick={() => behavioralDimensionFilter()}>
                Filter
              </Button>
            )}
          </div>
  		    <Group>
            {testVariationsData.length > 1 ? (
  			  	<Table striped highlightOnHover withBorder my={15} fontSize="xs">
             <thead>
               <tr>
                 <th>ID</th>
                 <th>Variations</th>
                  {selectedfilteredOptions.includes('Uniques') && <th>Uniques</th>}
                  {selectedfilteredOptions.includes('Visits') && <th>Visits</th>}
                  {selectedfilteredOptions.includes('Conv. # to page') && <th>Conv. # to page</th>}
                  {selectedfilteredOptions.includes('Conv. % to page') && <th>Conv. % to page</th>}
                  {selectedfilteredOptions.includes('Percentage Improvement') &&<th>Percentage Improvement</th>}
                 <th>Choose Winner</th>
                 {selectedfilteredOptions.includes('Statistical Significance') &&<th>Statistical Significance</th>}
                 {webhookSetShow !== 0 && (
                 <>
                  {selectedfilteredOptions.includes('Total Revenue') && <th>Total Revenue</th>}
                  {selectedfilteredOptions.includes('AOV') && <th>AOV</th>}
                  {selectedfilteredOptions.includes('Avg. Revenue per unique') && <th>Avg. Revenue per unique</th>}
                 </>
                 )}
                 {selectedfilteredOptions.includes('Avg. Time Spent') && <th>Avg. Time Spent</th>}
                 {hidePauseShow !== 'hide' && (
                 <>
                  <th>Pause Variation</th>
                 </>
                 )}
               </tr>
             </thead>
             <tbody>{testVariationsRows}</tbody>
  	  	    </Table>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "30px", marginBottom: "30px", width: '100%' }}>
                <Text fz={14}>There is no data yet. Please check back in later.</Text>
              </div>
            )}
  		    </Group>
  			</Card>
  			<Card shadow="sm" padding="sm" radius="md" withBorder mt={10} mb={20}>
  				<Group position="apart" mb="xs">
  		      <Text weight={500}>CONVERSION TO PAGE/GOALS <IconHelp size="1.1rem" color="red" style={{marginBottom:-3,marginRight:15}}/>   {convtopageNameShow}</Text>
  		    </Group>
          <Group>
            <Text>Select Graph :</Text>
            <Select
              mb={5}
              mt={5}
              dropdownPosition="bottom"
              defaultValue="0"
              maxDropdownHeight={100}
              searchable
              data={[
                { value: '0', label: 'Main Conversions' },
                { value: '1', label: 'Cumulative Conversions' },
              ]}
              onChange={(value) => setSelectedGraph(value)}
            />
          </Group>
  		    <Grid mb={15}>
  			    <Grid.Col span={9} style={{ textAlign: 'left' }}>
            {selectedGraph === '1' ? (
            <div>
              <Chart
                chartType="LineChart"
                width="100%"
                height="400px"
                data={dataLineChart}
                options={optionsLineChart}
              />
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '0px' }}>
                  {paginatedLabels.map((label, index) => (
                    <div key={label} style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                      <div
                        style={{
                          width: '15px',
                          height: '15px',
                          backgroundColor: paginatedColors[index],
                          marginRight: '5px',
                        }}
                      >
                      </div>
                      <span>{label}</span>
                    </div>
                  ))}
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
                    <span onClick={() => setCurrentPage(Math.max(currentPage - 1, 0))} style={{ cursor: currentPage === 0 ? 'none' : 'pointer', opacity: currentPage === 0 ? 0.5 : 1 }}>
                      <IconChevronLeft stroke={2} size="1.125rem"/>
                    </span>
                    <span style={{ margin: '-3px 5px' }}>{currentPage + 1}/{charttotalPages}</span>
                    <span onClick={() => setCurrentPage(Math.min(currentPage + 1, charttotalPages - 1))} style={{ cursor: currentPage === charttotalPages - 1 ? 'none' : 'pointer', opacity: currentPage === charttotalPages - 1 ? 0.5 : 1 }}>
                      <IconChevronRight stroke={2} size="1.125rem"/>
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <>
              <Radio.Group
                name="Conversions"
                mb={10}
                value={selectedValue}
                onChange={handleConversionRadioChange}
              >
                <Group mt="md">
                  <Radio value="1" label="Conversions" />
                  <Radio value="2" label="Conversion %" />
                </Group>
              </Radio.Group>
              {dataColumnChartRows.length > 1 && dataColumnPercentageChartRows.length > 1 && (
                <Chart
                  chartType="ColumnChart"
                  width="100%"
                  height="400px"
                  data={selectedValue === "1" ? dataColumnChartRows : dataColumnPercentageChartRows}
                  options={selectedValue === "1" ? optionsColumnChart : optionsColumnPercentageChart}
                />
              )}
              </>
            )}
  			    </Grid.Col>
  			    <Grid.Col span={3} style={{ textAlign: 'left' }} mt={20}>
  				    <Box
  				      sx={(theme) => ({
  				        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#f8f9fa",
  				        padding: "10px",
  				        borderRadius: "4px",
  				        width:"auto"
  				      })}
  				    >
  				      {/*<div style={{ display: 'flex'}}>
  				      	<Text size="sm" fz={14} fw={500} mr={5}>Test Start on:</Text>
  				      	<Text size="sm" fz={14}>12-14-2023</Text>
  				      </div>*/}
                <div style={{ display: 'flex'}}>
                  <Text size="sm" fz={14} fw={500} mr={5} style={{ display: 'inline-block',width:"90px"}}>Test Totals</Text>
                  <div style={{ display: 'inline-block'}}>
                    <Text size="sm" fz={14} mr={5}>({testNameShow} - <Anchor href={testPageLinkShow} target="_blank" fw={500} mr={5} mt={-2}>{testPageTitleShow} </Anchor>)</Text>
                  </div>
                </div>
  				      <Space h="xs" />
  			        <div style={{ display: 'flex'}}>
  			        	<Text size="sm" fz={14} fw={500} mr={5}>Uniques:</Text>
  			        	<Text size="sm" fz={14}>{totalUniquesShow}</Text>
  			        </div>
  							<div style={{ display: 'flex'}}>
  								<Text size="sm" fz={14} fw={500} mr={5}>Visits:</Text>
  								<Text size="sm" fz={14}>{totalVisitsShow}</Text>
  							</div>
  							<div style={{ display: 'flex'}}>
  								<Text size="sm" fz={14} fw={500} mr={5}>Conversions:</Text>
  								<Text size="sm" fz={14}>{totalConversionsShow}</Text>
  							</div>
  				    </Box>
  			    </Grid.Col>
  			  </Grid>
  			</Card>
        {pageBottomMsgShow !== '' && (
        <Card shadow="sm" padding="sm" radius="md" withBorder mt={10} mb={20}>
          <Grid mb={15}>
            <Text mt={15} fz={14} ml={10}>
              {pageBottomMsgShow}
            </Text>
          </Grid>
        </Card>
        )}
      </div>
      <div style={{ display: variablesBtn === '1' ? "block" : "none" }}>
        <Variables 
          pageId={pageId}
          showVariablesTab={showVariablesTab}
          selectedTimePicker={selectedTimePicker}
          selectedTimePickerFromDate={selectedTimePickerFromDate}
          selectedTimePickerToDate={selectedTimePickerToDate}
          systemUsageLoader={setSystemUsageLoader}
        />
      </div>
      {/*ResetStats Modal*/}
      <ResetStats
        selectedMemberWebsite={selectedMemberWebsite}
        pageIdShow={pageIdShow}
        taguchiTestId={taguchiTestId}
        isResetStatsModalOpen={isResetStatsModalOpen}
        onResetStatsClose={handleResetStatsCloseModal}
        TestOptimizeReport={handleTestOptimizeReport}
        setMarksalestime={setMarksalestime}
        errorModelOpen={seterrorCloseModal}
        errorMessage={setErrorMessage}
        showErrorHeader={setShowErrorHeader}
        showMessageHeader={setShowMessageHeader}
      />
      {/*Error Model*/}
      <ErrorModal errorModelOpen={errorModelOpen} errorMessage={errorMessage} showErrorHeader={showErrorHeader} showMessageHeader={showMessageHeader} onClose={handlerrorCloseModal} />
      </Skeleton>
	  </Container>
	  </>
	);
}