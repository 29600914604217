import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Modal, Select, Button, Group, Text, Radio, Divider, Table, Pagination } from '@mantine/core';
import { IconHelp } from '@tabler/icons-react';
import TestsApi from "../../api/TestsApi";
import { SelectTimePicker } from "../GeneralComponent/GeneralComponent";

export function Logs({ selectedMemberWebsite, pageId, funnelId, testStartDate, isConversionLogsModalOpen, onConversionLogsClose, TestOptimizeReport, errorModelOpen, errorMessage, showErrorHeader, showMessageHeader }:any) {
  const navigate = useNavigate();
  const [conversionLogsConversionType, setConversionLogsConversionType] = useState([]);
  const [conversionLogsGoalList, setConversionLogsGoalList] = useState([]);
  const [conversionLogsPostbackList, setConversionLogsPostbackList] = useState([]);
  const [conversionType, setConversionType] = useState('');
  const [conversionTypeName, setConversionTypeName] = useState('');
  const [goalList, setGoalList] = useState('');
  const [goalListName, setGoalListName] = useState('');
  const [postbackList, setPostbackList] = useState('');
  const [, setPostbackListName] = useState('');
  const [convLogRedioBtn, setConvLogRedioBtn] = useState('goal');
  const [filterConversionLog, setFilterConversionLog] = useState('no');
  const [undefinedConversionTableField, setUndefinedConversionTableField] = useState<string[]>([]);
  const [undefinedConversionTableData, setUndefinedConversionTableData] = useState<any[]>([]);
  const [conversionTypeStatus, setConversionTypeStatus] = useState('');
  const [definedConversionTableField, setDefinedConversionTableField] = useState<string[]>([]);
  const [definedConversionTableData, setDefinedConversionTableData] = useState<any[]>([]);
  const [resetConversionTableField, setResetConversionTableField] = useState<string[]>([]);
  const [resetConversionTableData, setResetConversionTableData] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(15);
  const [activePageNo, setActivePageNo] = useState(1);
  const [totalPaginationNo, setTotalPaginationNo] = useState(1);

  const [selectedTimePicker, setSelectedTimePicker] = useState('');
  const [selectedTimePickerToDate, setSelectedTimePickerToDate] = useState('');
  const [selectedTimePickerFromDate, setSelectedTimePickerFromDate] = useState('');

  useEffect(() => {
    if (isConversionLogsModalOpen) {
      if (!pageId) {
        navigate('/review-tests');
        return;
      }
      TestsApi.getconversionlogdetails({
        system_website_id: selectedMemberWebsite,
        page_id:pageId,
        funnel_id:funnelId
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if (data.status === 1) {
          setConversionLogsConversionType(data.data.conversion_type);
          setConversionLogsGoalList(data.data.goal_list);
          setConversionLogsPostbackList(data.data.postback_list);
        } else {
          errorMessage(data.message);
          errorModelOpen(true);
          showErrorHeader(true);
          onConversionLogsClose();
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
    }
  }, [isConversionLogsModalOpen, pageId]);

  const conversionTypeData = [
    { value: '', label: 'Select Conversion Type' },
    ...conversionLogsConversionType.map((ConversionTypeData) => ({
      value: ConversionTypeData.id,
      label: ConversionTypeData.name,
    })),
  ];

  const goalListData = [
    { value: '', label: 'Select Goal List' },
    ...conversionLogsGoalList.map((GoalListData) => ({
      value: GoalListData.page_id,
      label: GoalListData.page_title,
    })),
  ];

  const postbackListData = [
    { value: '', label: 'Select Postback List' },
    ...conversionLogsPostbackList.map((PostbackListData) => ({
      value: PostbackListData.postback_id,
      label: PostbackListData.postback_name,
    })),
  ];

  const handleConversionType = (value:any) => {
    const selectedOption = conversionTypeData.find((option) => option.value === value);
    setConversionType(value);
    setConversionTypeName(selectedOption ? selectedOption.label : '');
  };

  const handleSelectListChange = (value) => {
    if (convLogRedioBtn === 'goal') {
      const selectedOption = goalListData.find((option) => option.value === value);
      setGoalList(value);
      setGoalListName(selectedOption ? selectedOption.label : '');
    } else if (convLogRedioBtn === 'postback') {
      const selectedOption = postbackListData.find((option) => option.value === value);
      setPostbackList(value);
      setPostbackListName(selectedOption ? selectedOption.label : '');
    }
  };

  const handleRadioChange = (value) => {
    setConvLogRedioBtn(value);
  };

  const handlePageChange = (newPage) => {
    setActivePageNo(newPage);
  };

  const handleConversionDataLog = (value:any) => {
    TestsApi.getconversionlogfilter({
      system_website_id: selectedMemberWebsite,
      funnel_first_page_id:pageId,
      conv_type:conversionType,
      type:"tests",
      conversion_log_time:selectedTimePicker,
      goal_name:goalListName,
      goal_type:convLogRedioBtn,
      conv_goal:goalList,
      page:activePageNo,
      conv_postback:postbackList,
      conversion_log_from:selectedTimePickerFromDate,
      conversion_log_to:selectedTimePickerToDate
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        setUndefinedConversionTableField(data.data.conversion_field || []);
        setUndefinedConversionTableData(data.data.conversion_data || []);
        setDefinedConversionTableField(data.data.conversion_field || []);
        setDefinedConversionTableData(data.data.conversion_data || []);
        setResetConversionTableField(data.data.conversion_field || []);
        setResetConversionTableData(data.data.conversion_data || []);
        setConversionTypeStatus(data.data.conversion_type_status);
        setFilterConversionLog('yes');
        if (data.data.active_page_no !== activePageNo) {
          setActivePageNo(data.data.active_page_no);
          setActivePageNo(1);
        }
        if (data.data.total_pages !== totalPages) {
          setTotalPages(data.data.total_pages);
        }
        if (JSON.stringify(data.data.total_pagination_no) !== JSON.stringify(totalPaginationNo)) {
          setTotalPaginationNo(data.data.total_pagination_no);
        }
      } else {
        errorMessage(data.message);
        errorModelOpen(true);
        showErrorHeader(true);
        onConversionLogsClose();
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  const handleConversionLogsCloseModal = () => {
    onConversionLogsClose();
    setConversionType('');
    setGoalList('');
    setPostbackList('');
    setConversionLogsGoalList([]);
    setConversionLogsPostbackList([]);
    setUndefinedConversionTableField([]);
    setUndefinedConversionTableData([]);
    setDefinedConversionTableField([]);
    setDefinedConversionTableData([]);
    setResetConversionTableField([]);
    setResetConversionTableData([]);
    setSelectedTimePicker('');
    setSelectedTimePickerToDate('');
    setSelectedTimePickerFromDate('');
  };

  /* TIME PICKER AND DATE PICKER  */
    const handleselectedTimePickerComponentChange = (Value:any) => {
      setSelectedTimePicker(Value);
    }

    useEffect(() => {
      if (selectedTimePicker !== null) {
        TestOptimizeReport();
      }
    }, [selectedTimePicker]); 

    const handleselectedTimePickerToDateChange = (Value:any) => {
      setSelectedTimePickerToDate(Value);
    }

    const handleselectedTimePickerFromDateChange = (Value:any) => {
      setSelectedTimePickerFromDate(Value);
    }

    useEffect(() => {
      if (selectedTimePickerFromDate && selectedTimePickerToDate) {
        TestOptimizeReport();
      }
    }, [selectedTimePickerFromDate, selectedTimePickerToDate]); 
  /* END TIME PICKER AND DATE PICKER  */

  return (
    <>
      <Modal.Root opened={isConversionLogsModalOpen} onClose={handleConversionLogsCloseModal} size="70%">
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>CONVERSION LOG <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body h={350}>
            <Group mt={15} >
              <SelectTimePicker SelectTimePickerValue={selectedTimePicker} SelectTimePickerOnChange={handleselectedTimePickerComponentChange} SelectTimePickerFromDateOnChange={handleselectedTimePickerFromDateChange} SelectTimePickerToDateOnChange={handleselectedTimePickerToDateChange} SelectTestStartDate={testStartDate}/>
              <Text>Conversion Type:</Text>
              <Select
                dropdownPosition="bottom"
                // maxDropdownHeight={100}
                value={conversionType}
                searchable
                data={conversionTypeData}
                onChange={handleConversionType}
              />
              <Text style={{ display: conversionTypeName === "Reset Conversion" ? "none" : "block" }}>Conversion to:</Text>
              <Radio.Group
                name="ConGoalType"
                mb={10}
                value={convLogRedioBtn}
                onChange={(value) => handleRadioChange(value)}
                style={{ display: conversionTypeName === "Reset Conversion" ? "none" : "block" }}
              >
                <Group mt="xs">
                  <Radio value="goal" label="Goals" />
                  <Radio value="postback" label="Postbacks" />
                </Group>
              </Radio.Group>
              <Select
                dropdownPosition="bottom"
                // maxDropdownHeight={100}
                value={convLogRedioBtn === 'goal' ? goalList : postbackList}
                onChange={handleSelectListChange}
                searchable
                data={convLogRedioBtn === 'goal' ? goalListData : postbackListData}
                style={{ display: conversionTypeName === "Reset Conversion" ? "none" : "block" }}
              />
              <Button type="submit" size="sm" w={80} onClick={handleConversionDataLog}>
                Filter
              </Button>
            </Group>
            <Divider my="sm" />
            <Group style={{ display: conversionTypeName === "Undefined Conversion" && filterConversionLog === 'yes' ? "block" : "none" }}>
              <Table striped highlightOnHover withBorder my={15} fontSize="xs">
                <thead>
                <tr>
                  {undefinedConversionTableField.map((element, index) => (
                    <th key={index}>{element}</th>
                  ))}
                </tr>
                </thead>
                <tbody>
                  {
                    undefinedConversionTableData.length > 0 ? (
                      <>
                        {conversionTypeStatus === "pixel_goal_conversion" && (
                          undefinedConversionTableData.map((element, index) => (
                            <tr key={index}>
                              <td>{element.goal_name}</td>
                              <td>{element.total_undefined_conv}</td>
                            </tr>
                          ))
                        )}
                        {conversionTypeStatus === "undefined_goal_webhook_conversion" && (
                          undefinedConversionTableData.map((element, index) => (
                            <tr key={index}>
                              <td>{element.date}</td>
                              <td>{element.email}</td>
                              <td>{element.transaction_id}</td>
                              <td>{element.sales_amount}</td>
                              <td>{element.reason}</td>
                            </tr>
                          ))
                        )}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={undefinedConversionTableField.length} style={{ textAlign: 'center' }}>No Data Found</td>
                      </tr>
                    )
                  }
                </tbody>
              </Table>
            </Group>
            <Group style={{ display: conversionTypeName === "Defined Conversion" && filterConversionLog === 'yes' ? "block" : "none" }}>
              <Table striped highlightOnHover withBorder my={15} fontSize="xs">
                <thead>
                  <tr>
                    {definedConversionTableField.map((element, index) => (
                      <th key={index}>{element}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {
                    definedConversionTableData.length > 0 ? (
                      <>
                        {conversionTypeStatus === "pixel_goal_conversion" && (
                          definedConversionTableData.map((element, index) => (
                            <tr key={index}>
                              <td>{element.goal_name}</td>
                              <td>{element.total_undefined_conv}</td>
                            </tr>
                          ))
                        )}
                        {conversionTypeStatus === "defined_goal_webhook_conversion" && (
                          definedConversionTableData.map((element, index) => (
                            <tr key={index}>
                              <td>{element.date}</td>
                              <td>{element.email}</td>
                              <td>{element.transaction_id}</td>
                              <td>{element.sales_amount}</td>
                            </tr>
                          ))
                        )}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={definedConversionTableField.length} style={{ textAlign: 'center' }}>No Data Found</td>
                      </tr>
                    )
                  }
                </tbody>
              </Table>
            </Group>
            <Group style={{ display: conversionTypeName === "Reset Conversion" && filterConversionLog === 'yes' ? "block" : "none" }}>
              <Table striped highlightOnHover withBorder my={15} fontSize="xs">
                <thead>
                  <tr>
                    {resetConversionTableField.map((element, index) => (
                      <th key={index}>{element}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {
                    resetConversionTableData.length > 0 ? (
                      <>
                        {conversionTypeStatus === "reset_conversion" && (
                          resetConversionTableData.map((element, index) => (
                            <tr key={index}>
                              <td>{element.action}</td>
                              <td>{element.date}</td>
                              <td>{element.email}</td>
                            </tr>
                          ))
                        )}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={resetConversionTableField.length} style={{ textAlign: 'center' }}>No Data Found</td>
                      </tr>
                    )
                  }
                </tbody>
              </Table>
            </Group>
            {filterConversionLog === 'yes' && totalPages ?
            <Pagination.Root total={totalPages} value={activePageNo} onChange={handlePageChange}>
              <Group spacing={5} position="center">
                <Pagination.First />
                <Pagination.Previous />
                <Pagination.Items />
                <Pagination.Next />
                <Pagination.Last />
              </Group>
            </Pagination.Root> : ''}
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  );
}