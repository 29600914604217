// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recording-type {
	background-color: #87cefa;
	margin-left: 30px;
	border-radius: 5px;
  	align-items: center;
  	padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Insights/RecordingSetting.css"],"names":[],"mappings":"AAAA;CACC,yBAAyB;CACzB,iBAAiB;CACjB,kBAAkB;GAChB,mBAAmB;GACnB,aAAa;AAChB","sourcesContent":[".recording-type {\n\tbackground-color: #87cefa;\n\tmargin-left: 30px;\n\tborder-radius: 5px;\n  \talign-items: center;\n  \tpadding: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
