import { Group, Text, Button, Box, Grid, ActionIcon, Flex, Modal, Select, Input, Checkbox, Radio, Space, Anchor, Table } from '@mantine/core';
import { IconWorld, IconHelp, IconTrash, IconPencil, IconX, IconCheck } from '@tabler/icons-react';
import { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from "react-router-dom";
import ReactDOM from 'react-dom';
import { modals } from '@mantine/modals';
import { VisiLoader, SelectTooltip } from "../../GeneralComponent/GeneralComponent";
import { notifications } from '@mantine/notifications';
import PermissionWrapper from '../../../PermissionWrapper';
import SettingsApi from "../../../api/SettingsApi";

export function ManageYourDomains({ SelectMemberWebsiteListValue, SelectMemberWebsiteListOnChange }:any) {

  const navigate = useNavigate();

  const [addDomainOpened, setAddDomain] = useState(false);

  const [addSubdomainOpened, setAddSubdomain] = useState(false);

  const [PublishDomainChecked, PublishDomainSetChecked] = useState(false);

  const [GetDomainListLoader, setGetDomainListLoader] = useState(false);

  const [DeleteDomainLoader, setDeleteDomainLoader] = useState(false);

  const [AddDomainLoader, setAddDomainLoader] = useState(false);

  const [DomainList, setDomainList] = useState([]);

  const [WebsiteName, setWebsiteName] = useState('');
  const [WebsiteAddress, setWebsiteAddress] = useState('');
  const [FrontendFramework, setFrontendFramework] = useState('0');
  const [Protocol, setProtocol] = useState('ftp');
  const [Host, setHost] = useState('');
  const [Port, setPort] = useState('');
  const [Username, setUsername] = useState('');
  const [Password, setPassword] = useState('');
  const [DefaultRootDirectory, setDefaultRootDirectory] = useState('/');

  const manageDomainIconRef = useRef(null);
  const addDomainIconRef = useRef(null);
  const addSubdomainIconRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({});

  const handlePublishDomainChange = (event:any) => {
    PublishDomainSetChecked(event.currentTarget.checked);
  };

  const [publishToValue, publishToSetValue] = useState('1');

  const handleAddDomainSubmit = (event:any) => {
    setAddDomainLoader(true);
    event.preventDefault();
    let site_type = '0';
    if(PublishDomainChecked === true){
      if(publishToValue === '2'){
        site_type = '2';
      }else if(publishToValue === '3'){
        site_type = '3';
      }
    }
    SettingsApi.addeditdomain({
      site_type : site_type,
      site_name : WebsiteName,
      website_address : WebsiteAddress,
      front_end_framework : FrontendFramework,
      ftp_host : Host,
      ftp_port : Port,
      ftp_proto : Protocol,
      ftp_user : Username,
      ftp_pass : Password,
      ftp_default_root_dir : DefaultRootDirectory,
      website_id: updateDomainId ? updateDomainId : ''
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setAddDomainLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        if(data.data && data.data.site_id){
          if(updateDomainId){
            setDomainList(
              DomainList.map(item => {
                if (item.website_id === updateDomainId) {
                  return { ...item, website_name: WebsiteName };
                }
                return item;
              })
            );
          }else{
            setDomainList([...DomainList, {website_id : data.data.site_id, website_name : WebsiteName}]);
          }
        }
        setAddDomainLoader(false);
        closeDomainModal();
        notifications.show({
          color: 'teal',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
      }else{
        setAddDomainLoader(false);
        notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: data.message,
        })
      }
    })
    .catch(error => {
      console.log(error);
      setAddDomainLoader(false);
    });
  };

  const [subdomainName, setSubdomainName] = useState('');
  const handleAddSubdomainSubmit = (event:any) => {
    event.preventDefault();
    SettingsApi.adddeletedomainsubdomain({
      website_id : websiteId,
      action_perform : "save",
      domain_name : subdomainName
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1 && data.data && data.data.website_sub_domain_id && data.data.website_sub_domain_id !== ""){
        const AddSubdomainData = { bg_color: '', domain_name: subdomainName, domain_subdomain_id: data.data.website_sub_domain_id };
        setSubdomainTableData([...subdomainTableData, AddSubdomainData]);
        notifications.show({
          color: 'teal',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
      } else {
        notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: data.message,
        })
      }
      setSubdomainName('');
    })
    .catch(error => {
      console.log(error);
    });
  };

  const [updateDomainId, setUpdateDomainId] = useState('');

  const updateDomain = (id:any) => {
    setUpdateDomainId(id);
    setAddDomain(true);
    SettingsApi.getsingledomain({
      website_id: id
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate('/logout');
        }
      }
    })
    .then((data) => {
      if (data.status === 1) {
        setProtocol(data.data.ftp_proto);
        setPort(data.data.ftp_port);
        setHost(data.data.ftp_host);
        setUsername(data.data.ftp_user);
        setPassword(data.data.ftp_pass);
        setWebsiteName(data.data.site_name);
        setWebsiteAddress(data.data.site);
        setDefaultRootDirectory(data.data.ftp_default_root_dir);
      }
    })
    .catch((error) => {
      console.error('API Error:', error);
    });
  }

  const closeDomainModal = () => {
    setAddDomain(false);
    setUpdateDomainId('');
    setProtocol('');
    setPort('');
    setHost('');
    setUsername('');
    setPassword('');
    setWebsiteName('');
    setWebsiteAddress('');
    setDefaultRootDirectory('');
  }

  const [websiteId, setWebsiteId] = useState('');

  const closeSubdomainModal = () => {
    setAddSubdomain(false);
    setSubdomainName('');
  }

  const AddSubdomain = (id:any) => {
    setWebsiteId(id);
    setAddSubdomain(true);
    SettingsApi.getdomainsubdomainlist({
      website_id: id
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate('/logout');
        }
      }
    })
    .then((data) => {
      if (data.status === 1) {
        setSubdomainTableData(data.data);
      }
    })
    .catch((error) => {
      console.error('API Error:', error);
    });
  }

  const [subdomainTableData, setSubdomainTableData] = useState([]);

  const deleteDomainConfirm = (deleteDomainId:any) => modals.openConfirmModal({
    title: 'Please confirm your action',
    children: (
      <Text size="sm">
        Are you sure you want to uninstall VisiOpt from this website?
      </Text>
    ),
    labels: { confirm: 'Confirm', cancel: 'Cancel' },
    onCancel: () => {},
    onConfirm: () => {
      setDeleteDomainLoader(true);
      SettingsApi.uninstalldomain({
        website_id : deleteDomainId,
        selected_site_id: SelectMemberWebsiteListValue
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            setDeleteDomainLoader(false);
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setDomainList(DomainList.filter(item => item.website_id !== deleteDomainId));
          setDeleteDomainLoader(false);
          notifications.show({
            color: 'teal',
            icon: <IconCheck size="1.1rem" />,
            title: 'Success',
            message: data.message,
          });
        }else{
          setDeleteDomainLoader(false);
          notifications.show({
            color: 'red',
            icon: <IconX size="1.1rem" />,
            title: 'Error',
            message: data.message,
          })
        }
      })
      .catch(error => {
        console.log(error);
        setDeleteDomainLoader(false);
      });
    }
  });

  const deleteSubdomainConfirm = (deleteSubdomainId:any, deleteSubDomainName:any) => modals.openConfirmModal({
    title: 'Please confirm your action',
    children: (
      <Text size="sm">
        Are you sure you want to uninstall VisiOpt from this website?
      </Text>
    ),
    labels: { confirm: 'Confirm', cancel: 'Cancel' },
    onCancel: () => console.log('Cancel'),
    onConfirm: () => handleSubdomainDelete(deleteSubdomainId,deleteSubDomainName),
  });

  const handleSubdomainDelete = (id:any,name:any) => {
    SettingsApi.adddeletedomainsubdomain({
      domain_subdomain_id : id,
      action_perform : "delete",
      domain_name : name
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setSubdomainTableData(subdomainTableData.filter(item => item.domain_subdomain_id !== id));
        notifications.show({
          color: 'teal',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
      } else {
        notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: data.message,
        })
      }
    })
    .catch(error => {
      console.log(error);
    });
  };



  useEffect(() => {
    setGetDomainListLoader(true);
    SettingsApi.getdomainlist()
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setGetDomainListLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1 && data.data && data.data.length > 0){
        setDomainList(data.data);
        setGetDomainListLoader(false);
      }
      setGetDomainListLoader(false);
    })
    .catch(error => {
      console.log(error);
      setGetDomainListLoader(false);
    });
  }, []);

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    const iconRefMapping = {
      172: manageDomainIconRef,
      126: addDomainIconRef,
      160: addSubdomainIconRef
    };
    const iconRef = iconRefMapping[id];
    if (iconRef.current) {
      const rect = iconRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        [id]: {top: rect.top + scrollOffset, left: rect.left + rect.width + 10 },
      });
    }
  };

	return (
		<>
      <VisiLoader VisiLoaderVisible={GetDomainListLoader} />
      <VisiLoader VisiLoaderVisible={DeleteDomainLoader} />
      {showHelpModal &&
        ReactDOM.createPortal(
          <div>
            {Object.keys(tooltipPosition).map((key) => (
              <SelectTooltip
                isVisible={showHelpModal}
                setIsVisible={setShowHelpModal}
                id={helpTargetId}
                tooltipPosition={tooltipPosition[key]} 
              />
            ))}
          </div>,
        document.body
      )}
      <Modal.Root size={1100} opened={addDomainOpened} onClose={closeDomainModal}>
        <Modal.Overlay />
        <Modal.Content>
          <VisiLoader VisiLoaderVisible={AddDomainLoader} />
          <Modal.Header>
            <Modal.Title><Text weight={500}>ADD A DOMAIN <IconHelp ref={addDomainIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(126)}/></Text></Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleAddDomainSubmit}>
              <Group mb={10}>
                <Text weight={500}>{publishToValue === '3' ? 'Subdomain Name:' : 'Website Name:'}</Text>
                <Input name="website_name" value={WebsiteName} onChange={(e) => setWebsiteName(e.target.value)} />
                <Text weight={500}>{publishToValue === '3' ? 'Subdomain Address:' : 'Website Address:'}</Text>
                {updateDomainId && updateDomainId > '0' ?
                  <Input name="website_address" value={WebsiteAddress} onChange={(e) => setWebsiteAddress(e.target.value)} />
                :
                  <Input name="website_address" value={WebsiteAddress} onChange={(e) => setWebsiteAddress(e.target.value)} />
                }
              </Group>
              <PermissionWrapper permission="add_domain_spa_frameworks_access">
                <Group mb={10}>
                  <Text weight={500}>Is your website using Frontend Framework? <br /><Text weight={400} fz="xs">(VueJs/ReactJs/AngularJs) Or Go High Level Platform</Text></Text>
                  {updateDomainId && updateDomainId > '0' ?
                    <Select
                      data={[
                          { value: '1', label: 'Yes' },
                          { value: '0', label: 'No' }
                        ]}
                      value="0"
                      name="frontend_framework"                      
                      disabled
                    />
                  :
                    <Select
                      data={[
                          { value: '1', label: 'Yes' },
                          { value: '0', label: 'No' }
                        ]}
                      value={FrontendFramework}
                      onChange={(e) => setFrontendFramework(e)}
                      name="frontend_framework"
                    />
                  }
                </Group>
              </PermissionWrapper>
              <Group mb={10}>
                <Text weight={500}>I want to Publish to This Domain: </Text>
                <Checkbox onChange={handlePublishDomainChange} checked={PublishDomainChecked}/>
              </Group>
              {PublishDomainChecked ?
                <Group mb={10}>
                  <Text weight={500}>Choose Where You'd Like To Publish To: </Text>
                  <Radio.Group value={publishToValue} onChange={publishToSetValue}>
                    <Group>
                      <Radio value="1" label="Your Own Server" />
                      <Radio value="2" label="Visiopt Server Using a Custom Domain" />
                      <Radio value="3" label="Visiopt Server Using a Visiopt Subdomain" />
                    </Group>
                  </Radio.Group>
                </Group>
              : ""}
              {PublishDomainChecked && publishToValue && publishToValue === "1" ?
                <Group mb={10}>
                  <Box
                    mt={10}
                    sx={(theme) => ({
                      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#f8f9fa",
                      padding: "10px",
                      borderRadius: "4px"
                    })}
                  >
                  <Text weight={500}>ADDING FTP CREDENTIALS</Text>
                  <Space h="xs" />
                  <Text>Publishing directly to your own site is easy. Simply add your FTP details in the form provided.</Text>
                  <Space h="xs" />
                  <Text>Once you add your FTP details your site will be available for tracking and testing and it will also be available to publish to from Visiopt's Landing Page Module.</Text>
                  <Space h="xs" />
                  <Text><Text weight={500} component="span">Note: </Text>FTP details can be added at any time from the Website Edit Option.</Text>
                  <Space h="xs" />
                  <Text>If FTP doesn't connect be sure to check Protocol. FTP or SFTP as well as port.</Text>
                  <Space h="xs" />
                  <Text c="red" weight={500}>Some servers will not publish properly unless SFTP is used.</Text>
                </Box>
                <Group>
                  <Text weight={500}>Protocol : </Text>
                    <Select
                      data={[
                          { value: 'ftp', label: 'FTP' },
                          { value: 'sftp', label: 'SFTP' }
                        ]}
                      value={Protocol}
                      onChange={(e) => setProtocol(e)}
                      name="protocol"
                    />
                    <Text weight={500}>Host: </Text>
                    <Input name="host" value={Host} onChange={(e) => setHost(e.target.value)} />
                    <Text weight={500}>Port: </Text>
                    <Input name="port" value={Port} onChange={(e) => setPort(e.target.value)} />
                </Group>
                <Group>
                  <Text weight={500}>Username: </Text>
                    <Input name="username" value={Username} onChange={(e) => setUsername(e.target.value)} />
                    <Text weight={500}>Password: </Text>
                    <Input name="password" value={Password} onChange={(e) => setPassword(e.target.value)} />
                    <Text weight={500}>Default Root Directory: </Text>
                    <Input name="default_root_directory" value={DefaultRootDirectory} onChange={(e) => setDefaultRootDirectory(e.target.value)} />
                </Group>
                </Group>
              : ""}
              {PublishDomainChecked && publishToValue && publishToValue === "2" ?
                <Group mb={10}>
                  <Box
                    mt={10}
                    sx={(theme) => ({
                      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#f8f9fa",
                      padding: "10px",
                      borderRadius: "4px"
                    })}
                  >
                  <Text weight={500}>SIMPLE INSTRUCTIONS TO ADD YOUR DOMAIN</Text>
                  <Space h="xs" />
                  <Text>You will need to add a CNAME in your DNS records. This is quick and easy to do.</Text>
                  <Space h="xs" />
                  <Text>1. Choose the domain or subdomain you wish to use. You can use an entirely new domain or add a subdomain like... lp.mydomain.com or go.mydomain.com</Text>
                  <Space h="xs" />
                  <Text>2. Go to your DNS records.</Text>
                  <Space h="xs" />
                  <Text>3. Add the CNAME lp.visioptdev.com</Text>
                  <Space h="xs" />
                  <Text>4. Enter details as follows:</Text>
                  <Space h="xs" />
                  <Text><Text weight={500} component="span">TTL: </Text>3600</Text>
                  <Space h="xs" />
                  <Text><Text weight={500} component="span">Type: </Text>CNAME</Text>
                  <Space h="xs" />
                  <Text><Text weight={500} component="span">Record / Data/ Host: </Text>lp.visioptdev.com</Text>
                  <Space h="xs" />
                  <Text>5. You must wait until propagation before using this domain for publishing.</Text>
                  <Space h="xs" />
                  <Text>Check DNS Propogation here: <Anchor href="https://dnschecker.org/#CNAME/" target="_blank">https://dnschecker.org/#CNAME/</Anchor></Text>
                </Box>
                </Group>
              : ""}
              {PublishDomainChecked && publishToValue && publishToValue === "3" ?
                <Group mb={10}>
                  <Box
                    mt={10}
                    sx={(theme) => ({
                      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#f8f9fa",
                      padding: "10px",
                      borderRadius: "4px"
                    })}
                  >
                  <Text weight={500}>NOTES</Text>
                  <Space h="xs" />
                  <Text>Choose a unique subdomain not already in use. Please note we do recommend that you choose the Publish To Visiopt Servers Using a Custom Domain or Subdomain option for more privacy and better results.</Text>
                </Box>
                </Group>
              : ""}
              <Group>
                {updateDomainId && updateDomainId > '0' ?
                  <Button type="submit" >Update Domain</Button>
                :
                  <Button type="submit" >Add Domain</Button>
                }
                <Button color="red" type="button" onClick={closeDomainModal}>Cancel</Button>
              </Group>
            </form>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
      <Modal.Root size="lg" opened={addSubdomainOpened} onClose={closeSubdomainModal}>
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title><Text weight={500}>CONNECT A DOMAIN OR A SUBDOMAIN TO THE SITE <IconHelp ref={addSubdomainIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(160)}/></Text></Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleAddSubdomainSubmit}>
              <Group mb={10}>
                <Text weight={500}>Enter Domain/Subdomain Name: </Text>
                <Input name="domain_subdomain_name" w={250} value={subdomainName} onChange={(e) => setSubdomainName(e.target.value)}/>
                <Button type="submit" >Add</Button>
                <Table striped highlightOnHover withBorder>
                  <tbody>
                    {subdomainTableData.map((item) => (
                      <tr key={item.domain_subdomain_id}>
                        <td>{item.domain_name}</td>
                        <td>
                          <Flex
                            justify="flex-end"
                            align="center"
                            direction="row"
                            wrap="wrap"
                          >
                            <ActionIcon
                              variant="default"
                              size="lg"
                              title="Delete"
                              onClick={() => deleteSubdomainConfirm(item.domain_subdomain_id,item.domain_name)}
                            >
                              <IconX size="1.1rem" />
                          </ActionIcon>
                          </Flex>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Group>
            </form>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
			<Group position="apart" mt="md" mb="xs">
        <Text weight={500}>MANAGE YOUR DOMAINS <IconHelp ref={manageDomainIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(172)}/></Text>
      </Group>
      <Button onClick={() => setAddDomain(true)}>
        Add a New Domain
      </Button>
      {DomainList.map((Domain, DomainIndex) =>
        <Box
          key={DomainIndex}
          p={4}
          pl={20}
          mt={10}
          sx={(theme) => ({
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : '#f8f9fa',
            padding: "10px",
            borderRadius: theme.radius.md,
            border: "0.0625rem solid transparent",
            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[1] : '#dee2e6'
          })}
        >
          <Grid gutter="xs">
            <Grid.Col span={6}>
              <Link to={Domain.website_url} target="_blank" style={{ textDecoration: 'none', color: 'black' }}>
                {Domain.website_name}
              </Link>
            </Grid.Col>
            <Grid.Col span={6}>
              <Flex
                justify="flex-end"
                align="center"
                direction="row"
                wrap="wrap"
              >
              <Text mr={5}>{Domain.version}</Text>
              <ActionIcon
                variant="default"
                size="lg"
                mx={3}
                title="Edit"
                onClick={() => updateDomain(Domain.website_id)}
              >
                <IconPencil size="1.1rem" />
              </ActionIcon>
              <ActionIcon
                variant="default"
                size="lg"
                mx={3}
                title="Add Subdomain"
                onClick={() => AddSubdomain(Domain.website_id)}
              >
                <IconWorld size="1.1rem" />
              </ActionIcon>
              <ActionIcon
                variant="default"
                size="lg"
                mx={3}
                title="Delete"
                onClick={() => deleteDomainConfirm(Domain.website_id)}
              >
                <IconTrash size="1.1rem" />
              </ActionIcon>
              </Flex>
            </Grid.Col>
          </Grid>
        </Box>
      )}
		</>
	);
}