// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DndList_item__jiSXr {
  display: inline-block;
  align-items: center;
  border-radius: var(--mantine-radius-md);
  border: rem(1px) solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-5));
  padding: var(--mantine-spacing-sm) var(--mantine-spacing-xl);
  /*background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-5));*/
  margin-bottom: var(--mantine-spacing-sm);
}

.DndList_itemDragging__Bpb09 {
  box-shadow: var(--mantine-shadow-sm);
}

.DndList_symbol__CpC-m {
  font-size: rem(30px);
  font-weight: 700;
  width: rem(60px);
}`, "",{"version":3,"sources":["webpack://./src/components/Funnel/DndList.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,uCAAuC;EACvC,2FAA2F;EAC3F,4DAA4D;EAC5D,yFAAyF;EACzF,wCAAwC;AAC1C;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".item {\n  display: inline-block;\n  align-items: center;\n  border-radius: var(--mantine-radius-md);\n  border: rem(1px) solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-5));\n  padding: var(--mantine-spacing-sm) var(--mantine-spacing-xl);\n  /*background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-5));*/\n  margin-bottom: var(--mantine-spacing-sm);\n}\n\n.itemDragging {\n  box-shadow: var(--mantine-shadow-sm);\n}\n\n.symbol {\n  font-size: rem(30px);\n  font-weight: 700;\n  width: rem(60px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `DndList_item__jiSXr`,
	"itemDragging": `DndList_itemDragging__Bpb09`,
	"symbol": `DndList_symbol__CpC-m`
};
export default ___CSS_LOADER_EXPORT___;
