import React, { useState } from 'react';
import { Modal, Text, Anchor, ActionIcon } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconX, IconHelp, IconInfoCircle } from '@tabler/icons-react';
import { useNavigate } from "react-router-dom";
import TestsApi from "../../api/TestsApi";

export function TestInfoModal({
    PageId,
    HistoryId,
    SetErrorMessage,
    ErrorCloseModal,
    ShowErrorHeader,
    ShowMessageHeader
  }:any) {

  const navigate = useNavigate();
  const [testInfoModalOpen, setTestInfoModalOpen] = useState(false);
  const [testInfoTestName, setTestInfoTestName] = useState('');
  const [testInfoPageLink, setTestInfoPageLink] = useState('');
  const [testInfoPageTitle, setTestInfoPageTitle] = useState('');
  const [testInfoTestTime, setTestInfoTestTime] = useState('');
  const [testInfoTestEndTime, setTestInfoTestEndTime] = useState('');
  const [testInfoTestType, setTestInfoTestType] = useState('');

  const handleTestInfoOpenModal = (value:any) => {
    if (!PageId) {
      notifications.show({
        color: 'red',
        icon: <IconX size="1.1rem" />,
        title: 'Error',
        message: "Page id is a required field.",
      });
      navigate('/review-tests');
      return;
    }
    TestsApi.gettestinfo({
      page_id:PageId,
      type:"TestHistory",
      test_id:HistoryId
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        setTestInfoTestName(data.data.test_name);
        setTestInfoPageLink(data.data.page_link);
        setTestInfoPageTitle(data.data.page_title);
        setTestInfoTestTime(data.data.test_time);
        setTestInfoTestEndTime(data.data.test_ended);
        setTestInfoTestType(data.data.test_type);
        setTestInfoModalOpen(true);
      } else {
        SetErrorMessage(data.message);
        ErrorCloseModal(true);
        ShowErrorHeader(true);
        ShowMessageHeader(false);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  const handleTestInfoCloseModal = () => {
    setTestInfoModalOpen(false);
  };

  return (
    <>
      <Modal.Root opened={testInfoModalOpen} onClose={handleTestInfoCloseModal}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title><Text weight={500}>TEST INFORMATION <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text></Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <form>
            <div style={{ display: 'flex'}}>
              <Text size="sm" fz={14} fw={500} mr={5}>Test Name:</Text>
              <Text size="sm" fz={14}> {testInfoTestName}</Text>
            </div>
            <div style={{ display: 'flex'}}>
              <Text size="sm" fz={14} fw={500} mr={5}>Page Name:</Text>
              <Anchor href={testInfoPageLink} target="_blank" fw={500} mr={5} mt={-2}>{testInfoPageTitle} </Anchor>
            </div>
            <div style={{ display: 'flex'}}>
              <Text size="sm" fz={14} fw={500} mr={5}>Test Type:</Text>
              <Text size="sm" fz={14} mr={5}>{testInfoTestType}</Text>
            </div>
            <div style={{ display: 'flex'}}>
              <Text size="sm" fz={14} fw={500} mr={5}>Date the test started:</Text>
              <Text size="sm" fz={14}>{testInfoTestTime}</Text>
            </div>
             <div style={{ display: 'flex'}}>
              <Text size="sm" fz={14} fw={500} mr={5}>Date the test is finished:</Text>
              <Text size="sm" fz={14}>{testInfoTestEndTime}</Text>
            </div>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>

    <ActionIcon
      variant="default"
      size="lg"
      sx={(theme) => ({
         backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#fff",
       })}
      title="Test Info"
      ml={5}
      onClick={() => handleTestInfoOpenModal(1)}
    >
      <IconInfoCircle size="1.1rem" />
    </ActionIcon>
    </>
  );
}