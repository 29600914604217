import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Modal, Select, Button, Group, Text, Input, Radio, Table, ActionIcon, CopyButton } from '@mantine/core';
import { IconHelp, IconCheck, IconPencil, IconTrash  } from '@tabler/icons-react';
import TestsApi from "../../api/TestsApi";
import { notifications } from '@mantine/notifications';
import { modals } from '@mantine/modals';

export function ShoppingCartProcessor({ selectedMemberWebsite, pageId, funnelId, testsId, isShoppingCartProcessorModalOpen, onShoppingCartProcessorClose, setMarksalestime, TestOptimizeReport, errorModelOpen, errorMessage, showErrorHeader, showMessageHeader }:any) {
  const navigate = useNavigate();
  const [shoppingCartProcessorList, setShoppingCartProcessorList] = useState(true);
  const [convRedioTypeBtn, setConvRedioTypeBtn] = useState('1');
  const [testShoppingCartProcessorWebhookData, settestShoppingCartProcessorWebhookData] = useState([]);
  const [testShoppingCartProcessorShoppingData, settestShoppingCartProcessorShoppingData] = useState([]);
  const [testShoppingCartProcessorFunnelsGoals, settestShoppingCartProcessorFunnelsGoals] = useState([]);
  const [testShoppingCartProcessorPaymentProcessor, settestShoppingCartProcessorPaymentProcessor] = useState([]);
  const [selectedWebhookURL, setSelectedWebhookURL] = useState('');
  const [selectedSecretKey, setSelectedSecretKey] = useState('');
  const [selectedWebhookId, setSelectedWebhookId] = useState('');
  const [selectedPaymentType, setSelectedPaymentType] = useState('');
  const [selectedShoppingCart, setSelectedShoppingCart] = useState('');
  const [selectedShoppingCartName, setSelectedShoppingCartName] = useState('');
  const [selectedPaymentProcessor, setSelectedPaymentProcessor] = useState('');
  const [selectedPaymentProcessorName, setSelectedPaymentProcessorName] = useState('');
  const [selectedPage, setSelectedPage] = useState('');
  const [selectedProcessor, setSelectedProcessor] = useState('');
  const [selectChange, setSelectChange] = useState('');

  useEffect(() => {
    if (isShoppingCartProcessorModalOpen) {
      if (!pageId) {
        navigate('/review-tests');
        return;
      }
      TestsApi.getconnectshoppingcartandprocessor({
        system_website_id: selectedMemberWebsite,
        page_id:pageId,
        funnel_id:funnelId
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if (data.status === 1) {
          settestShoppingCartProcessorWebhookData(data.data.webhook_list);
          settestShoppingCartProcessorShoppingData(data.data.shopping_cart);
          settestShoppingCartProcessorFunnelsGoals(data.data.funnels_goals);
          settestShoppingCartProcessorPaymentProcessor(data.data.payment_processor);
          setShoppingCartProcessorList(true);
        } else {
          errorMessage(data.message);
          errorModelOpen(true);
          showErrorHeader(true);
          onShoppingCartProcessorClose();
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
    }
  }, [isShoppingCartProcessorModalOpen, pageId]);

  const testShoppingCartProcessorRows = testShoppingCartProcessorWebhookData.map((element) => (
    <tr key={element.webhook_id} style={{ cursor: 'pointer' }}>
      <td>{element.processor}</td>
      <td>{element.page_name}</td>
      <td>{element.webhook_url}</td>
      <td>
        <ActionIcon
          variant="default"
          size="md"
          title="Edit"
          onClick={() => EditWebhook(element.webhook_id)}
        >
          <IconPencil size="1.1rem" />
        </ActionIcon>
      </td>
      <td>
        <ActionIcon
          variant="default"
          size="md"
          title="Delete"
          onClick={() => deleteWebhookConfirm(element.webhook_id)}
        >
          <IconTrash size="1.1rem" />
        </ActionIcon>
      </td>
    </tr>
  ));

  const shoppingCartData = [
    { value: '', label: 'Select Shopping Cart' },
    ...testShoppingCartProcessorShoppingData.map((ShoppingData) => ({
      value: ShoppingData.shopping_cart_id,
      label: ShoppingData.shopping_cart_name,
    })),
  ];

  const paymentProcessorData = [
    { value: '', label: 'Select Payment Processor' },
    ...testShoppingCartProcessorPaymentProcessor.map((PaymentProcessor) => ({
      value: PaymentProcessor.payment_processor_id,
      label: PaymentProcessor.payment_processor_name,
    })),
  ];

  const pageData = [
    { value: '', label: 'Select a Page' },
    ...testShoppingCartProcessorFunnelsGoals.map((FunnelsGoals) => ({
      value: FunnelsGoals.page_id,
      label: FunnelsGoals.page_name,
    })),
  ];

  const selectData = convRedioTypeBtn === '1' ? shoppingCartData : paymentProcessorData;
  const labelText = convRedioTypeBtn === '1' ? 'Select a Shopping Cart' : 'Select Payment Processor';

  const handleShoppingCartProcessorCloseModal = () => {
    onShoppingCartProcessorClose();
    setSelectedWebhookURL('');
    setSelectedSecretKey('');
    setSelectedPage('');
    setConvRedioTypeBtn('1');
    setSelectedPaymentProcessor('');
    setSelectedShoppingCart('');
    setSelectedWebhookId('');
    setShoppingCartProcessorList(true);
  };

  const AddWebhook  = () => {
    setShoppingCartProcessorList(false);
  };

  const handleSelectChange = (value) => {
    setSelectChange(value);
    const selectedOption = convRedioTypeBtn === '1' ? shoppingCartData.find(option => option.value === value) : paymentProcessorData.find(option => option.value === value);
    const label = selectedOption ? selectedOption.label : '';
    setSelectedProcessor(label);
    if (convRedioTypeBtn === '1') {
      setSelectedShoppingCart(value);
      setSelectedShoppingCartName(label);
      setSelectedPage('');
      setSelectedWebhookURL('');
      setSelectedSecretKey('');
    } else if (convRedioTypeBtn === '2') {
      setSelectedPaymentProcessor(value);
      setSelectedPaymentProcessorName(label);
      setSelectedPage('');
      setSelectedWebhookURL('');
      setSelectedSecretKey('');
    }
  };

  const handleConvRedioTypeBtn = (value) => {
    setConvRedioTypeBtn(value);
    setSelectedPage('');
    setSelectedWebhookURL('');
    setSelectedSecretKey('');
  };

  const handleSelectPageChange = (value) => {
    const selectedsOption = selectData.find(option => option.value === selectChange);
    setSelectedPage(value);
    GetWebhookUrl(value,selectedProcessor);
  };

  const GetWebhookUrl = (selectedPage,processor) => {
    TestsApi.getwebhookurl({
      system_website_id: selectedMemberWebsite,
      page_id:selectedPage,
      processor:selectedProcessor
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        setSelectedWebhookURL(data.data.webhook_url);
      } else {
        errorMessage(data.message);
        errorModelOpen(true);
        showErrorHeader(true);
        onShoppingCartProcessorClose();
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  }

  useEffect(() => {
    if (convRedioTypeBtn === '1') {
      setSelectedPaymentType('shopping_cart');
    } else if (convRedioTypeBtn === '2') {
      setSelectedPaymentType('payment_processor');
    }
  }, [convRedioTypeBtn]);

  const isShoppingCart = convRedioTypeBtn === '1';
  const isPaymentProcessor = convRedioTypeBtn === '2';

  const handleAddPaymentProcessorCart = (e) => {
    e.preventDefault();
    TestsApi.addpaymentprocessorcart({
      system_website_id: selectedMemberWebsite,
      page_id:selectedPage,
      tests_id:testsId,
      webhook_id:selectedWebhookId,
      payment_type:selectedPaymentType,
      shopping_cart: isShoppingCart ? selectedShoppingCart : '',
      shopping_cart_text: isShoppingCart ? selectedShoppingCartName : '',
      shopping_webhook_url: isShoppingCart ? selectedWebhookURL : '',
      payment_processor: isPaymentProcessor ? selectedPaymentProcessor : '',
      payment_processor_text: isPaymentProcessor ? selectedPaymentProcessorName : '',
      payment_webhook_url: isPaymentProcessor ? selectedWebhookURL : '',
      secret_key: selectedSecretKey ? selectedSecretKey : '',
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        setMarksalestime(data.data.updated_time);
        handleShoppingCartProcessorCloseModal();
      } else {
        errorMessage(data.message);
        errorModelOpen(true);
        showErrorHeader(true);
        onShoppingCartProcessorClose();
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  }

  const EditWebhook = (webhookId) => {
    setSelectedWebhookId(webhookId);
    TestsApi.getsinglewebhookprocessordetail({
      webhook_id:webhookId
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        if (data.data.payment_type === 'payment_processor') {
          setSelectedPaymentProcessor(data.data.cart_type);
        } else if (data.data.payment_type === 'shopping_cart') {
          setSelectedShoppingCart(data.data.cart_type);
        }
        setSelectedProcessor(data.data.processor);
        setSelectedPage(data.data.page_id);
        setSelectedWebhookURL(data.data.webhook_url);
        setSelectedSecretKey(data.data.secret_key);
        setConvRedioTypeBtn(data.data.payment_type === 'payment_processor' ? '2' : '1');
        setShoppingCartProcessorList(false);
      } else {
        errorMessage(data.message);
        errorModelOpen(true);
        showErrorHeader(true);
        onShoppingCartProcessorClose();
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  const deleteWebhookConfirm = async (webhookId:any) => {
    try {
      const APIResponse = await TestsApi.validatedeletepaymentprocessorcart({
        tests_id: testsId,
        webhook_id : webhookId
      });
       const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.status === 1) {
        modals.openConfirmModal({
        title: 'Delete Webhook',
        children: (
          <Text size="sm">
            {APIResultJSON.data.msg}
          </Text>
        ),
        labels: { confirm: 'Delete the Webhook', cancel: "No don't delete it" },
        confirmProps: { color: 'red' },
        onCancel: () => console.log('Cancel'),
        onConfirm: () => {
          handleDeleteWebhook(webhookId);
        },
      });
      }else{
        errorMessage(APIResultJSON.message);
        errorModelOpen(true);
        showErrorHeader(true);
        onShoppingCartProcessorClose();
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  }

  const handleDeleteWebhook = async (webhookId:any) =>{
    try {
      const APIResponse = await TestsApi.deletepaymentprocessorcart({
        webhook_id : webhookId
      });
       const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.status === 1) {
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: APIResultJSON.message,
        });
        handleShoppingCartProcessorCloseModal();
      }else{
        errorMessage(APIResultJSON.message);
        errorModelOpen(true);
        showErrorHeader(true);
        onShoppingCartProcessorClose();
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  return (
    <>
      <Modal.Root opened={isShoppingCartProcessorModalOpen} onClose={onShoppingCartProcessorClose} size="80%">
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>CONNECT SHOPPING CART & PROCESSOR (OPTIONAL) <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
          {shoppingCartProcessorList === false ?
            <form onSubmit={handleAddPaymentProcessorCart}>
              <Text fw="500">
                You can connect your payment processor and shopping cart. This step is optional but will enable more accurate tracking.
              </Text>
              <div style={{ display: 'flex' }}>
                <Text mt={13} mr={10} fw="500" fz={14}>
                  Select a Processor:
                </Text>
                <Radio.Group name="ShoppingType" mb={10} value={convRedioTypeBtn} onChange={(value) => handleConvRedioTypeBtn(value)}>
                  <Group mt="md">
                    <Radio value="1" label="Shopping Cart" />
                    <Radio value="2" label="Payment Processor" />
                  </Group>
                </Radio.Group>
              </div>
              <Group spacing="xs" mb={5}>
                <IconHelp size="1.1rem" color="red" />
                <Text fz={14} fw="500">
                  {labelText} :
                </Text>
                <Select
                  w={250}
                  value={convRedioTypeBtn === '1' ? selectedShoppingCart : selectedPaymentProcessor}
                  data={convRedioTypeBtn === '1' ? shoppingCartData : paymentProcessorData}
                  dropdownPosition="bottom"
                  onChange={(value) => handleSelectChange(value)}
                  withinPortal
                />
                <Text fz={14} ml={5} fw="500">
                  Select Page :
                </Text>
                <Select
                  w={250}
                  value={selectedPage}
                  data={pageData}
                  dropdownPosition="bottom"
                  onChange={(value) => handleSelectPageChange(value)}
                  disabled={convRedioTypeBtn === '1' ? !selectedShoppingCart : !selectedPaymentProcessor}
                  withinPortal
                />
              </Group>
              <Group spacing="xs" mb={5}>
                <Text fz={14} mb={10} fw="500">
                  Webhook URL :
                </Text>
                <Input
                  w={602}
                  value={selectedWebhookURL}
                  onChange={(e) => setSelectedWebhookURL(e.target.value)}
                  disabled={!selectedPage}
                />
                <CopyButton timeout={2000} value={selectedWebhookURL}>
                  {({ copied, copy }) => (
                    <Button color={copied ? 'teal' : 'blue'} onClick={copy}>
                      {copied ? 'Copied Webhook URL' : 'Copy Webhook URL'}
                    </Button>
                  )}
                </CopyButton>
              </Group>
              {selectedProcessor === "ClickBank" &&(
                <Group spacing="xs" mb={5}>
                  <Text fz={14} mb={10} ml={120} fw="500">
                    Secret Key :
                  </Text>
                  <Input
                    w={602}
                    value={selectedSecretKey}
                    onChange={(e) => setSelectedSecretKey(e.target.value)}
                  />
                </Group>
              )}
              <Text fw="500" c="red" fz={14}>
                Please note: The revenue generating page is usually a thank you or confirmation page. The final conversion page in your funnel.
              </Text>
              <Text fw="500" c="red" fz={14}>
                It is important to note that even if your buyer does not make it all the way to the end of your funnel, Visiopt will still collect the total order amount inclusive of any upsells or downsells.
              </Text>
              <Group position="left">
                <Button type="submit" size="sm" w={140} mt={10}>
                  Save Webhook
                </Button>
              </Group>
            </form>
            :
            <Group>
              <Button type="submit" size="sm" w={165} onClick={() => AddWebhook()}>
                Add New Webhook
              </Button>
              <Table striped highlightOnHover withBorder fontSize="xs">
               <thead>
                 <tr>
                   <th>Processor</th>
                   <th>Webhook Page</th>
                   <th>Webhook Url</th>
                   <th>Edit</th>
                   <th>Delete</th>
                 </tr>
               </thead>
               <tbody>{testShoppingCartProcessorRows}</tbody>
              </Table>
            </Group>
          }
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  );
}