import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { useDocumentTitle } from '@mantine/hooks';
import { IconHelp } from '@tabler/icons-react';
import { HeaderMenu } from "../../components/HeaderMenu/HeaderMenu";
import { Container, Skeleton, Card, Group, Text, Button, Grid, Pagination, Accordion, Flex } from '@mantine/core';
import classes from '../DashBoard/DashBoard.module.css';
import './ReviewingTest.css';
import { SelectTooltip, ErrorModal } from "../../components/GeneralComponent/GeneralComponent";
import { DiscardDraft } from "../../components/CreateTest/DiscardDraft";
import { GridPage } from "../../components/CreateTest/GridPage";
import { PageList } from "../../components/CreateTest/PageList";
import { RotationPageList } from "../../components/CreateTest/RotationPageList";
import { GridRotationPage } from "../../components/CreateTest/GridRotationPage";
import { AddTest } from "../../components/CreateTest/AddTest";
import { TestTypes } from "../../components/CreateTest/TestTypes";
import { PageOptions } from "../../components/CreateTest/PageOptions";
import TestsApi from "../../api/TestsApi";

export function CreateTest() {
	const navigate = useNavigate();
	useDocumentTitle("Visiopt - Create Test");
	const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');
	const [selectTestType, setSelectTestType] = useState('');
	const [accordionItemDefaultValue, setAccordionItemDefaultValue] = useState("step-1");
	const [step2, setStep2] = useState("none");
	const [step3, setStep3] = useState("none");
	const [step4, setStep4] = useState("none");

	const testIconRef = useRef(null);
	const testTypeIconRef = useRef(null);
	const step1IconRef = useRef(null);
	const step4IconRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({});

	const handleHeaderMenuComponentChange = (Value:any) => {
	  setSelectedMemberWebsite(Value);
	};

	const ContinueTestEditor = (pageId:any) => {
		if(selectTestType === ''){
			setErrorMessage("Please select a test!");
			seterrorCloseModal(true);
		} else {
			navigate(`/test-editor-visual/${selectedThumbnail}/${selectTestType}`);
		}
	};
  const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [errorModelOpen, seterrorCloseModal] = useState(false);
	const [showErrorHeader, setShowErrorHeader] = useState(false);
	const [showMessageHeader, setShowMessageHeader] = useState(false);
	const [selectedAllCreateTestPageValue, setSelectedAllCreateTestPageValue] = useState('0');
	const [activePageNo, setActivePageNo] = useState(1);
	const [keyword, setKeyword] = useState('');
	const [selectPageCreateTests, setSelectPageCreateTests] = useState([]);
	const [modernabSplitTest, setModernabSplitTest] = useState('');
	const [singleVariableTest, setSingleVariableTest] = useState('');
	const [classicabSplitTest, setClassicabSplitTest] = useState('');
	const [totalPages, setTotalPages] = useState(10);
	const [totalPaginationNo, setTotalPaginationNo] = useState(1);
	const [selectedThumbnail, setSelectedThumbnail] = useState(null);
	const [selectedDraftThumbnail, setSelectedDraftThumbnail] = useState(null);
	const [isThumbnailSelected, setIsThumbnailSelected] = useState(false);
	const [isDraftThumbnailSelected, setIsDraftThumbnailSelected] = useState(false);
	const [selectedDraftPageId, setSelectedDraftPageId] = useState('');
	const [selectedDraftTaguchiTestId, setSelectedDraftTaguchiTestId] = useState('');
  const [viewSelect, setViewSelect] = useState('grid');
  const [showNumber, setShowNumber] = useState(25);
  const [selectedThumbnails, setSelectedThumbnails] = useState([]);

  const [funnelRotationOptions, setFunnelRotationOptions] = useState([]);

  const [selectSmartRelationData, setSelectSmartRelationData] = useState([]);
  const [marksalestime, setMarksalestime] = useState(0);


	useEffect(() => {
		if(selectedMemberWebsite !== ''){
      setLoading(true);
	    seterrorCloseModal(false);
	    TestsApi.getcreatetestpagelist({
	      system_website_id: selectedMemberWebsite,
	      filter: selectedAllCreateTestPageValue,
	      layout: viewSelect,
	      number: showNumber,
	      page: activePageNo,
	      keyword: keyword
	    })
	    .then(response => {
	      if (response.ok) {
	        return response.json();
	      } else {
	        if (response.status === 401 || response.status === 400) {	          
	          navigate("/logout");
	        }
	      }
	    })
	    .then(data => {
	      if(data.status === 1){
          setLoading(false);
	        if (data.data.test_pages_list) {
	          setSelectPageCreateTests(data.data.test_pages_list);
	          setModernabSplitTest(data.data.modern_ab_split_test);
	          setSingleVariableTest(data.data.single_variable_test);
	          setClassicabSplitTest(data.data.classic_ab_split_test);
	          if (data.data.active_page_no !== activePageNo) {
	            setActivePageNo(data.data.active_page_no);
	            setActivePageNo(1);
	          }
	          if (data.data.total_pages !== totalPages) {
	            setTotalPages(data.data.total_pages);
	          }
	          if (JSON.stringify(data.data.total_pagination_no) !== JSON.stringify(totalPaginationNo)) {
	            setTotalPaginationNo(data.data.total_pagination_no);
	          }
	        } else{
	          console.log("No data received");
	          setSelectPageCreateTests([]);
	        }
	      }else{
	        setSelectedAllCreateTestPageValue('0');
	        setErrorMessage(data.message);
	        seterrorCloseModal(true);
          setLoading(false);
	      }	      
	    })
	    .catch(error => {
        setLoading(false);
	      console.error("API Error:", error);	      
	    });
	  }
  }, [selectedAllCreateTestPageValue, activePageNo, selectedMemberWebsite, viewSelect, showNumber, keyword, marksalestime]);





  const handlePageChange = (newPage) => {
    setActivePageNo(newPage);
  };

  const handleAddtoFunnel = async () => {
  	try {
  		if(selectedDraftThumbnail !== null){
  			const APIResponse = await TestsApi.checkpageinfunnelvalidation({
	        system_website_id : selectedMemberWebsite,
	        page_id: selectedDraftThumbnail
	      });
	      const APIResultJSON = await APIResponse.json();
	      if (APIResultJSON.status === 1) {
	      	if(singleVariableTest === selectedDraftTaguchiTestId){
	      		navigate(`/test-editor-visual/${selectedDraftPageId}/${selectedDraftTaguchiTestId}`);
	      	} else if(modernabSplitTest === selectedDraftTaguchiTestId){
	      		navigate(`/test-editor-visual-modern/${selectedDraftPageId}/${selectedDraftTaguchiTestId}`);
	      	} else {
	      		navigate(`/test-editor-visual/${selectedDraftPageId}/${selectedDraftTaguchiTestId}`);
	      	}
	      }else{
	        setErrorMessage(APIResultJSON.message);
	        seterrorCloseModal(true);
	        setShowErrorHeader(true);
	        setShowMessageHeader(false);
	      }
  		} 

  		if (selectedThumbnail !== null) {
  			const APIResponse = await TestsApi.checkpageinfunnelvalidation({
	        system_website_id : selectedMemberWebsite,
	        page_id: selectedThumbnail
	      });
	      const APIResultJSON = await APIResponse.json();
	      if (APIResultJSON.status === 1) {
	      	setStep2("block");
					setAccordionItemDefaultValue("step-2");
	      }else{
	        setErrorMessage(APIResultJSON.message);
	        seterrorCloseModal(true);
	        setShowErrorHeader(true);
	        setShowMessageHeader(false);
	      }
  		}
    } catch (error) {
      console.error("API Error:", error);
    }
  }


	const funnelRotationData = [
    { value: '', label: 'Select Funnel' },
    ...funnelRotationOptions.map((FunnelRotation) => ({
      value: FunnelRotation.funnel_id,
      label: FunnelRotation.name,
    })),
  ];


	const SelectSmartRelationalTest = (value:any) => {
		setSelectTestType(value);
	};

	const handlerrorCloseModal = () => {
	  seterrorCloseModal(false);
	};

	const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    const iconRefMapping = {
    	13: testTypeIconRef,
    	24: step4IconRef,
    	120: testIconRef,
    	14: step1IconRef
    };
    const iconRef =  iconRefMapping[id];
    if (iconRef.current) {
      const { top, left, width } = iconRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        [id]: {top: top + scrollOffset, left: left + width + 10},
      });
    }
  };

	return (
	  <>
	  {showHelpModal && Object.keys(tooltipPosition).map((key) => (
      <SelectTooltip
        isVisible={showHelpModal}
        setIsVisible={setShowHelpModal}
        id={helpTargetId}
        tooltipPosition={tooltipPosition[key]}
      />
    ))}
	  {/*Error Model*/}
	  <ErrorModal errorModelOpen={errorModelOpen} errorMessage={errorMessage} showErrorHeader={showErrorHeader} showMessageHeader={showMessageHeader} onClose={handlerrorCloseModal} />
	  <HeaderMenu HeaderMenuValue={selectedMemberWebsite} HeaderMenuOnChange={handleHeaderMenuComponentChange}/>
  	<Container size="xl">
  		<Card shadow="sm" padding="sm" radius="md" withBorder>
	  	  <Group position="apart" mb="xs">
	  	    <Text weight={500}>Create Test </Text>
	  	  </Group>
	  	  <Container size="xl" px={0}>
	  	    <Accordion chevronPosition="right"  value={accordionItemDefaultValue} onChange={setAccordionItemDefaultValue} variant="separated">
	  	      <Accordion.Item className={classes.item} value="step-1">
	  	        <Accordion.Control><Text component="span" color="red" weight={500}>Step 1</Text><Text component="span" weight={500}> - Select a Page <IconHelp ref={testIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(120)}/></Text></Accordion.Control>
	  	        <Accordion.Panel>
	  	        	<PageOptions
	  	        		ViewSelect={viewSelect}
									ViewSelectChange={setViewSelect}
									ShowNumberChange={setShowNumber}
									ActivePageNo={setActivePageNo}
									SelectedTestPageValue={setSelectedAllCreateTestPageValue}
									KeywordChange={setKeyword}
	  	        	/>
          	    <div style={{flex: "1 1 auto",overflowX: "hidden",marginTop:10}}>
          	    	<div style={{ display: viewSelect === "grid" ? "block" : "none"}}>
                    {loading ? (
										  <Flex justify="start" gap="sm" mt={10} wrap="wrap">
										    {Array.from({ length: 21 }).map((_, index) => (
										      <Skeleton key={index} height={185} width={163} />
										    ))}
										  </Flex>
										) : (
										  <>
        					  <Grid ml={0} mt={10} mb={10} >
                      <GridPage
                        SelectedPageCreateTests={selectPageCreateTests}
                        SelectedThumbnail={selectedThumbnail}
                        SelectedThumbnailChange={setSelectedThumbnail}
                        SelectedDraftThumbnail={selectedDraftThumbnail}
                        SelectedDraftThumbnailChange={setSelectedDraftThumbnail}
                        DraftThumbnailIsSelected={isDraftThumbnailSelected}
                        DraftThumbnailIsSelectedChange={setIsDraftThumbnailSelected}
                        ThumbnailIsSelected={isThumbnailSelected}
                        ThumbnailIsSelectedChange={setIsThumbnailSelected}
                        SelectedDraftPageIdChange={setSelectedDraftPageId}
                        SelectedDraftTaguchiIdChange={setSelectedDraftTaguchiTestId}
                      />
                    </Grid>
										  </>
										)}
                    {selectPageCreateTests.some(item => Array.isArray(item) ? item.length > 0 : true) && (
        					  <Pagination.Root total={totalPages} value={activePageNo} onChange={handlePageChange} >
        					    <Group spacing={5} position="center">
        					      <Pagination.First />
        					      <Pagination.Previous />
        					      <Pagination.Items />
        					      <Pagination.Next />
        					      <Pagination.Last />
        					    </Group>
        					  </Pagination.Root>
                    )}
        				  </div>
        				  <div style={{ display: viewSelect === "list" ? "block" : "none"}}>
                    {selectPageCreateTests.some(item => Array.isArray(item) ? item.length > 0 : true) && (
                    <>
                    <Skeleton visible={loading}>
        				  	<PageList
                        SelectedPageCreateTests={selectPageCreateTests}
                        SelectedThumbnail={selectedThumbnail}
                        SelectedThumbnailChange={setSelectedThumbnail}
                        SelectedDraftThumbnail={selectedDraftThumbnail}
                        SelectedDraftThumbnailChange={setSelectedDraftThumbnail}
                        DraftThumbnailIsSelected={isDraftThumbnailSelected}
                        DraftThumbnailIsSelectedChange={setIsDraftThumbnailSelected}
                        ThumbnailIsSelected={isThumbnailSelected}
                        ThumbnailIsSelectedChange={setIsThumbnailSelected}
                        SelectedDraftPageIdChange={setSelectedDraftPageId}
                        SelectedDraftTaguchiIdChange={setSelectedDraftTaguchiTestId}
                      />
        		  	    <Pagination.Root total={totalPages} value={activePageNo} onChange={handlePageChange} >
        		  	      <Group spacing={5} position="center">
        		  	        <Pagination.First />
        		  	        <Pagination.Previous />
        		  	        <Pagination.Items />
        		  	        <Pagination.Next />
        		  	        <Pagination.Last />
        		  	      </Group>
        		  	    </Pagination.Root>
                    </Skeleton>
                    </>
                    )}
        				  </div>
        			  </div>
        			  <div className="legend-holder">
        				  <Grid>
        		        <Grid.Col span={6}>
        		        	<Button type="button" onClick={() => handleAddtoFunnel()}>Continue</Button>
	        		        {selectedDraftThumbnail && (
                        <DiscardDraft
                          SelectedMemberWebsite={selectedMemberWebsite}
                          SelectedDraftThumbnail={selectedDraftThumbnail}
                          SelectedDraftThumbnailChange={setSelectedDraftThumbnail}
                          SelectedDraftPageId={selectedDraftPageId}
                          SalesMarkTimeChange={setMarksalestime}
                          ErrorMessageChange={setErrorMessage}
                          CloseErrorModal={seterrorCloseModal}
                          ErrorHeaderChange={setShowErrorHeader}
                        />
								    	)}
        		        </Grid.Col>  
	        		      <Grid.Col span={3} offset={3} mt={6}> 
	        		        	<Flex gap="xs" align="center" mt={6}>
												  <span className="legend selected" /><Text size="sm" fz={12}>Selected</Text>
												  <span className="legend no-test" /><Text size="sm" fz={12}>No Test</Text>
												  <span className="legend draft" /><Text size="sm" fz={12}>Draft Test</Text>
												</Flex>
	        		      </Grid.Col>
        		      </Grid>
        			  </div>
	  	        </Accordion.Panel>
	  	      </Accordion.Item>
	  	      <Accordion.Item className={classes.item} value="step-2" style={{display:step2}}>
	  	        <Accordion.Control><Text component="span" color="red" weight={500}>Step 2</Text><Text component="span" weight={500}> - Select Test Type <IconHelp ref={testTypeIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(13)}/></Text></Accordion.Control>
	  	        <Accordion.Panel>
	  	        	<TestTypes
	  	        		SelectedMemberWebsite={selectedMemberWebsite}
									SelectedThumbnail={selectedThumbnail}
									ModernABSplitTest={modernabSplitTest}
									SingleVariableTest={singleVariableTest}
									SetStep3={setStep3} SetStep4={setStep4}
									SetLoading={setLoading}
									ErrorMessageChange={setErrorMessage}
									ShowErrorHeader={setShowErrorHeader}
									SetCloseModal={seterrorCloseModal}
									AccordionItemDefaultValueChange={setAccordionItemDefaultValue}
									FunnelRotationOptions={setFunnelRotationOptions}
									SmartRelationsData={setSelectSmartRelationData}
	  	        	/>
	  	        </Accordion.Panel>
	  	      </Accordion.Item>
	  	      <Accordion.Item className={classes.item} value="step-3" style={{display:step3}}>
	  	        <Accordion.Control><Text component="span" color="red" weight={500}>Step 3</Text><Text component="span" weight={500}> - Select Rotataion Page <IconHelp ref={step1IconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(14)}/></Text></Accordion.Control>
 	  	        <Accordion.Panel>
 	  	        	<PageOptions
	  	        		ViewSelect={viewSelect}
									ViewSelectChange={setViewSelect}
									ShowNumberChange={setShowNumber}
									ActivePageNo={setActivePageNo}
									SelectedTestPageValue={setSelectedAllCreateTestPageValue}
									KeywordChange={setKeyword}
	  	        	/>
           	    <div style={{flex: "1 1 auto",overflowX: "hidden",marginTop:10}}>
           	    	<div style={{ display: viewSelect === "grid" ? "block" : "none"}}>
         					  <Grid ml={0} mt={10} mb={10} >
                      <Skeleton visible={loading}>
                        <GridRotationPage
                          SelectedPageCreateTests={selectPageCreateTests}
                          SelectedThumbnail={selectedThumbnails}
                          SelectedThumbnailChange={setSelectedThumbnails}
                        />         					    
                      </Skeleton>
         				  	</Grid>
                    {selectPageCreateTests.some(item => Array.isArray(item) ? item.length > 0 : true) && (
         					  <Pagination.Root total={totalPages} value={activePageNo} onChange={handlePageChange} >
         					    <Group spacing={5} position="center">
         					      <Pagination.First />
         					      <Pagination.Previous />
         					      <Pagination.Items />
         					      <Pagination.Next />
         					      <Pagination.Last />
         					    </Group>
         					  </Pagination.Root>
                    )}
         				  </div>
         				  <div style={{ display: viewSelect === "list" ? "block" : "none"}}>
                    {selectPageCreateTests.some(item => Array.isArray(item) ? item.length > 0 : true) && (
                    <>
                    <Skeleton visible={loading}>
                      <RotationPageList
                        SelectedPageCreateTests={selectPageCreateTests}
                        SelectedThumbnail={selectedThumbnails}
                        SelectedThumbnailChange={setSelectedThumbnails}
                      />
         		  	    <Pagination.Root total={totalPages} value={activePageNo} onChange={handlePageChange} >
         		  	      <Group spacing={5} position="center">
         		  	        <Pagination.First />
         		  	        <Pagination.Previous />
         		  	        <Pagination.Items />
         		  	        <Pagination.Next />
         		  	        <Pagination.Last />
         		  	      </Group>
         		  	    </Pagination.Root>
                    </Skeleton>
                    </>
                    )}
         				  </div>
         			  </div>
         			  	<AddTest
         			  		SelectedMemberWebsite={selectedMemberWebsite}
										FunnelRotationData={funnelRotationData}
										SelectedThumbnails={selectedThumbnails}
										SelectedThumbnail={selectedThumbnail}
										ClassicAbSplitTest={classicabSplitTest}
										ErrorMessageChange={setErrorMessage}
										ShowErrorHeader={setShowErrorHeader}
										ErrorCloseModal={seterrorCloseModal}
         			  	/>
 	  	        </Accordion.Panel>
	  	      </Accordion.Item>
	  	      <Accordion.Item className={classes.item} value="step-4" style={{display:step4}}>
	  	        <Accordion.Control><Text component="span" color="red" weight={500}>Step 3</Text><Text component="span" weight={500}> - Select Smart Relational Test <IconHelp ref={step4IconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(24)}/></Text></Accordion.Control>
  	        	<Accordion.Panel>
  	        		{loading ? (
  	        			<Skeleton visible={loading} height={750}>
  	        			</Skeleton>
  	        		) : (
  	        			<Group>
	  	        			{selectSmartRelationData.map((item) => (
							        <Card
							          key={item.taguchi_type_id}
							          withBorder
							          h={130}
							          py={6}
							          w={232}
							          className={item.taguchi_type_id === selectTestType ? "test_type_clicked" : ""}
							          onClick={() => SelectSmartRelationalTest(item.taguchi_type_id)}
	                      style={{cursor: 'pointer'}}
							        >
							          <Text weight={700}>{item.title}</Text>
							          <Text my={8} fz={12} weight={700}>{item.variables} VARIABLES</Text>
							          {item.numberofcount.map((count, index) => (
							            <div key={index} style={{ display: "flex" }}>
							              <Text fz={12} weight={700} mr={5}>{count.numberVars}</Text>
							              <Text fz={12} mr={5}>variables with</Text>
							              <Text fz={12} weight={700} mr={5}>{count.numberVers}</Text>
							              <Text fz={12}>versions</Text>
							            </div>
							          ))}
							        </Card>
							      ))}
	              	</Group>
  	        		)}
              	<Group>
              		<Button type="button" mt={10} onClick={() => ContinueTestEditor(selectedThumbnail)}>Continue</Button>
              	</Group>
  	        	</Accordion.Panel>
  	      	</Accordion.Item>
  	    	</Accordion>
	  	  </Container>
	  	</Card>
	  </Container>
	  </>
	);
}