import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Card, Text, Group, Grid, Select, Box, LoadingOverlay } from '@mantine/core';
import { IconHelp } from "@tabler/icons-react";
import { Chart } from "react-google-charts";
import DashBoardApi from "../../api/DashBoardApi";
import { BoxPageThumb, ErrorModal } from "../../components/GeneralComponent/GeneralComponent";

export function ActiveCampaigns({ selectedMemberWebsite, selectedActiveTestMemberWebsite, activeTests }:any) {
  const navigate = useNavigate();
  const [ActiveCampaignsLoader, setActiveCampaignsLoader] = useState(false);
  const [activeCampaignList, setActiveCampaignList] = useState([]);
  const [reportChartData, setReportChartData] = useState([]);
  const [chartType, setChartType] = useState('1');
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const [selectedBoxPageThumb, setSelectedBoxPageThumb] = useState('');
  const [errorModelOpen, setErorrModelOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorHeader, setShowErrorHeader] = useState(false);

  const handleSelectedCampaign = (value:any, page:any) => {
    setSelectedCampaign(value);
    setSelectedBoxPageThumb(page);
  };

  const handleReportChartType = (value:any) => {
    setChartType(value);
  };

  const handleErrorModalClose = () => {
    setErorrModelOpen(false);
  };

  useEffect(() => {
    if(selectedMemberWebsite !== ''){
      DashBoardApi.getdashboardactivecampaignlist({
        system_website_id : selectedMemberWebsite
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setActiveCampaignList(data.data);
          const firstSelectedCampaign = data.data.length > 0 ? data.data[0].campaign_id : '';
          const firstSelectedBoxThumbPage = data.data.length > 0 ? data.data[0].campaign_page_id : '';
          setSelectedCampaign(firstSelectedCampaign);
          setSelectedBoxPageThumb(firstSelectedBoxThumbPage);
        }else{
          setErorrModelOpen(true);
          setErrorMessage(data.message);
          setShowErrorHeader(true);
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
  }, [selectedMemberWebsite]);

  useEffect(() => {
    if(chartType !== '' && selectedCampaign !== ''){
      setActiveCampaignsLoader(true);
      DashBoardApi.getdashboardchartdata({
        campaign_id : selectedCampaign,
        chart_type : chartType
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
            setActiveCampaignsLoader(false);
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setReportChartData(data.data);
          setActiveCampaignsLoader(false);
        }else{
          setActiveCampaignsLoader(false);
          setErorrModelOpen(true);
          setErrorMessage(data.message);
          setShowErrorHeader(true);
        }
      })
      .catch(error => {
        console.log(error);
        setActiveCampaignsLoader(false);
      });
    }
  }, [chartType, selectedCampaign]);

  return (
    <>
      {/*START ERORR MODAL*/}
      {errorModelOpen && (
        <ErrorModal
          errorModelOpen={errorModelOpen}
          errorMessage={errorMessage}
          showErrorHeader={showErrorHeader}
          onClose={handleErrorModalClose}
        />
      )}
      {/*END ERROR MODAL*/}
      {selectedActiveTestMemberWebsite && activeCampaignList.length > 0 && (
        <Box pos="relative">
          <LoadingOverlay zIndex={9999999999} visible={ActiveCampaignsLoader} overlayBlur={2} loaderProps={{variant:"bars", color:"gray"}} />
          <Card shadow="sm" padding="lg" radius="md" withBorder mb={10}>
            <Card.Section>
            </Card.Section>
            <Group position="apart" mt="md" mb="xs">
              <Text weight={500}>ACTIVE CAMPAIGNS <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
            </Group>
            <Group mb={15}>
              <Text>Select Campaign: </Text>
              <Select
                data={[
                  { value: '', label: 'Select Campaign', disabled: true },
                  ...activeCampaignList.map((campaign) => ({
                    value: campaign.campaign_id,
                    label: campaign.campaign_name,
                  })),
                ]}
                value={selectedCampaign}
                onChange={(selectedValue) => {
                  const selectedCampaignData = activeCampaignList.find(
                    (campaign) => campaign.campaign_id === selectedValue
                  );
                  handleSelectedCampaign(selectedValue, selectedCampaignData?.campaign_page_id);
                }}
                w={240}
                mr={20}                
              />              
            </Group>
            <Group mb={15}>
              <Text>Report Type: </Text>
              <Select
                data={[
                    { value: '1', label: 'ROAS all time VS Selected date range' },
                    { value: '2', label: 'Total Cost VS Total revenue over the selected time period' }
                  ]}
                value={chartType}
                onChange={handleReportChartType}
                w={240}
                ml={30}
              />
            </Group>
            <Grid justify="space-between">
              <Grid.Col span={2} style={{ textAlign: 'left' }}>
                <BoxPageThumb BoxPageThumbSelectedMemberWebsite={selectedMemberWebsite} BoxPageThumbSelectedActiveTestPage={selectedBoxPageThumb} />
              </Grid.Col>
              <Grid.Col span={10} style={{ textAlign: 'right' }}>
                {chartType === '1' && (
                  <Chart
                    chartType="LineChart"
                    data={reportChartData}
                    options={{
                      titleTextStyle: {
                        color: '#4D4D4D',
                        fontSize: 18,
                      },
                      legend: {
                        position: 'top',
                        alignment: 'center',
                        textStyle: { color: 'black', fontSize: 16,
                        layout: 'vertical', },
                      },
                      series: {
                        0: { lineDashStyle: [0], labelInLegend: 'Total ROAS' },
                        1: { lineDashStyle: [0], labelInLegend: 'Selected Date Range' },
                      },
                      hAxis: { title: "Date", format: 'yyyy-MM-dd' },
                      vAxis: { title: "ROAS" },
                    }}
                    width="100%"
                    height="300px"
                  />
                )}
                {chartType === '2' && (
                  <Chart
                    chartType="LineChart"
                    data={reportChartData}
                    options={{
                      titleTextStyle: {
                        color: '#4D4D4D',
                        fontSize: 18,
                      },
                      legend: {
                        position: 'top',
                        alignment: 'center',
                        textStyle: { color: 'black', fontSize: 16,
                        layout: 'vertical', },
                      },
                      colors: ['red', 'green'],
                      series: {
                        0: { lineDashStyle: [0], labelInLegend: 'Total Cost' },
                        1: { lineDashStyle: [0], labelInLegend: 'Total Revenue' },
                      },
                      hAxis: { title: "Date", format: 'yyyy-MM-dd' },
                      vAxis: { title: "Amount($)" },
                    }}
                    width="100%"
                    height="300px"
                  />
                )}
              </Grid.Col>
            </Grid>
          </Card>
        </Box>
        )}
    </>
  );
}