import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import ReactDOM from 'react-dom';
import { Modal, Button, Input, Group, Text, Select } from '@mantine/core';
import { IconHelp, IconCheck } from '@tabler/icons-react';
import CampaignReport from "../../api/CampaignReport";
import { notifications } from '@mantine/notifications';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import { TextEditorToolbar } from '../GeneralComponent/TextEditorToolbar';
import { RichTextEditor, Link } from '@mantine/tiptap';
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import { SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";

export function CampaignNotes({ campaignId, isCampaignNotesModalOpen, onCampaignNotesClose, setMarksalestime, errorModelOpen, errorMessage, showErrorHeader, showMessageHeader }:any) {
  const navigate = useNavigate();
  const [noteTitle, setNoteTitle] = useState('');
  const [noteContent, setNoteContent] = useState('');
  const [campaignDropDisplay, setCampaignDropDisplay] = useState('');
  const [campaignDropList, setCampaignDropList] = useState([]);
  const [dropListvalue, setDropListvalue] = useState('');

  const notesiconRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const campaignIdString = campaignId[0];

  const dropListData = [
    { value: '', label: 'Select Campaign', disabled: true },
    ...campaignDropList.map((dropListData) => ({
      value: dropListData.campaign_id,
      label: dropListData.campaign_name
    })),
  ];

  const handleDropList = (value:any) => {
    setDropListvalue(value);
    setNoteTitle('');
    setNoteContent('');
    CampaignReport.getcampaignnotedetails({
      campaign_id:value,
      type:"get_note"
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        setNoteTitle(data.data.campaign_note_title);
        setNoteContent(data.data.campaign_note);
      } else {
        errorMessage(data.message);
        errorModelOpen(true);
        showErrorHeader(true);
        onCampaignNotesClose();
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  useEffect(() => {
    const selectedCampaign = campaignDropList.find((dropListData) => dropListData.selected === "selected");
    if (selectedCampaign) {
      setDropListvalue(selectedCampaign.campaign_id);
    }
  }, [campaignDropList]);

  useEffect(() => {
    if (isCampaignNotesModalOpen) {
      if (!campaignId) {
        navigate('/review-tests');
        return;
      } else {
        CampaignReport.getcampaignnotedetails({
          campaign_id:campaignId
        })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            if (response.status === 401 || response.status === 400) {
              navigate("/logout");
            }
          }
        })
        .then(data => {
          if (data.status === 1) {
            setNoteTitle(data.data.campaign_note_title);
            setNoteContent(data.data.campaign_note);
            setCampaignDropDisplay(data.data.campaign_drop_display);
            setCampaignDropList(data.data.campaign_drop_list);
          } else {
            errorMessage(data.message);
            errorModelOpen(true);
            showErrorHeader(true);
            onCampaignNotesClose();
          }
        })
        .catch(error => {
          console.error("API Error:", error);
        });
      }
    }
  }, [isCampaignNotesModalOpen, campaignId]);

  const testnotesEditor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      Color,
      TextStyle,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
    ],
    content: '',
    onUpdate: ({ editor }) => {
      setNoteContent(editor.getHTML());
    },
  });

  const saveSelection = (editor) => {
    const { from, to } = editor.state.selection;
    return { from, to };
  };

  const restoreSelection = (editor, selection) => {
    editor.commands.setTextSelection(selection);
  };

  useEffect(() => {
    if (testnotesEditor) {
      if (noteContent === '') {
        testnotesEditor.commands.clearContent();
      } else {
        const selection = saveSelection(testnotesEditor);
        testnotesEditor.commands.setContent(noteContent, true);
        restoreSelection(testnotesEditor, selection);
      }
    }
  }, [noteContent, testnotesEditor]);

  const handleSaveTestNotes = (e) => {
  e.preventDefault();
  const campaignId = dropListvalue ? dropListvalue : campaignIdString;
  CampaignReport.savecampaignnote({
      campaign_id: campaignId,
      campaign_note_title:noteTitle,
      campaign_note:noteContent
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        onCampaignNotesClose();
      } else {
        errorMessage(data.message);
        errorModelOpen(true);
        showErrorHeader(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  }

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    if (notesiconRef.current) {
      const { top, left, width } = notesiconRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        top: top + scrollOffset,
        left: left + width + 10, 
      });
    }
  };

  return (
    <>
      {showHelpModal &&
        ReactDOM.createPortal(
          <div style={{ zIndex: 9999 }}>
            <SelectTooltip
              isVisible={showHelpModal}
              setIsVisible={setShowHelpModal}
              id={helpTargetId}
              tooltipPosition={tooltipPosition}
            />
          </div>,
        document.body
      )}
      <Modal.Root opened={isCampaignNotesModalOpen} onClose={onCampaignNotesClose} size="80%">
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>CAMPAIGN NOTES <IconHelp ref={notesiconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(320)}/></Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body h={campaignDropDisplay !== "hide" ? 520 : 480}>
            <form onSubmit={handleSaveTestNotes}>
              <Group mt={5} >
                {campaignDropDisplay !== "hide" && (
                  <>
                  <Text>Select Campaign:</Text>
                  <Select
                    dropdownPosition="bottom"
                    value={dropListvalue}
                    searchable
                    data={dropListData}
                    onChange={handleDropList}
                    styles={{ input: { cursor: 'pointer' } }}
                  />
                  </>
                )}
              </Group>
              <Input.Wrapper label="Note Title:" mb={10}>
                <Input value={noteTitle} onChange={(e) => setNoteTitle(e.target.value)} />
              </Input.Wrapper>
              <RichTextEditor editor={testnotesEditor}>
                <TextEditorToolbar />
                <div style={{height: '300px', overflowY: 'auto'}} onClick={() => testnotesEditor?.chain().focus().run()}>
                  <RichTextEditor.Content />
                </div>
              </RichTextEditor>
              <Group position="right">
                <Button type="submit" size="sm" w={80} mt={10} mb={10}>
                  Save
                </Button>
              </Group>
            </form>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  );
}