export function ServerAPI(API, Method, Data = {}){

  const noTokenAPIs = ['login','get_member_list','forgot_password','reset_password'];

  var APIHeaders = new Headers();
  APIHeaders.append("Content-Type", "application/json");
  if(!noTokenAPIs.includes(API)){
    APIHeaders.append("Authorization", "Bearer " + visiUserToken());
  }

  var raw = JSON.stringify(Data);

  var requestOptions = {
    method: Method,
    headers: APIHeaders,
    body: raw,
    redirect: 'follow'
  };

  if(Method === 'GET'){
    delete requestOptions.body
  }

  return requestOptions;
  
}

export function visiMemberData(){
  if(localStorage.getItem('visiMemberData') !== null){
    let visiMemberData = atob(localStorage.getItem('visiMemberData'));
    if(visiMemberData && visiMemberData !== ''){
      return JSON.parse(visiMemberData);
    }else{
      return false;
    }
  }else{
    return false;
  }
}

export function visiUserData(){
  if(localStorage.getItem('visiUserData') !== null){
    let visiUserData = atob(localStorage.getItem('visiUserData'));
    if(visiUserData && visiUserData !== ''){
      return JSON.parse(visiUserData);
    }else{
      return false;
    }
  }else{
    return false;
  }
  
}

export function visiUserPermission(){

  if(localStorage.getItem('visiUserData') !== null){
    let visiUserData = atob(localStorage.getItem('visiUserData'));
    if(visiUserData && visiUserData !== ''){
      visiUserData = JSON.parse(visiUserData);
      if(visiUserData.access_permission && visiUserData.access_permission !== ''){
        return visiUserData.access_permission;
      }else{
        return false;
      }
    }else{
      return false;
    }
  }else{
    return false;
  }
  
}

export function visiUserToken(){

  if(localStorage.getItem('visiUserData') !== null){
    let visiUserData = atob(localStorage.getItem('visiUserData'));
    if(visiUserData && visiUserData !== ''){
      return JSON.parse(visiUserData).visi_login_token;
    }else{
      return false;
    }
  }else{
    return false;
  }
}

export function visiUserId(){
  if(localStorage.getItem('visiUserData') !== null){
    let visiUserData = atob(localStorage.getItem('visiUserData'));
    if(visiUserData && visiUserData !== ''){
      return JSON.parse(visiUserData).member_id;
    }else{
      return false;
    }
  }else{
    return false;
  }
  
}

export function visiUserSession(){

  if(visiUserId()){
    let visiUserSession = localStorage.getItem('visiUser_'+visiUserId());
    if(visiUserSession && visiUserSession !== ''){
      return JSON.parse(visiUserSession);
    }else{
      return false;
    }
  }else{
    return false;
  }
  
}

export function setVisiUserSession(key, value){
  if(visiUserId()){
    let visiUserSession = localStorage.getItem('visiUser_'+visiUserId());
    const existingData = JSON.parse(visiUserSession) || {};
    if(key === 'page'){
      existingData.page = value;
    }
    if(key === 'website'){
      existingData.website = value;
    }
    if(key === 'funnel'){
      existingData.funnel = value;
    }
    if(key === 'time'){
      existingData.time = value;
    }
    if(key === 'start_date'){
      existingData.start_date = value;
    }
    if(key === 'end_date'){
      existingData.end_date = value;
    }
    localStorage.setItem("visiUser_"+visiUserId(), JSON.stringify(existingData));
  }else{
    return false;
  }
  
}