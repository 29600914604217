import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Modal, Select, Button, Input, Group } from '@mantine/core';
import { IconHelp, IconCheck } from '@tabler/icons-react';
import TestsApi from "../../api/TestsApi";
import { notifications } from '@mantine/notifications';

export function AudienceSize({ selectedMemberWebsite, pageId, isAudienceSizeModalOpen, onAudienceSizeClose, setMarksalestime, TestOptimizeReport, errorModelOpen, errorMessage, showErrorHeader, showMessageHeader }:any) {
  const navigate = useNavigate();
  const [audienceSizeOptions, setAudienceSizeOptions] = useState([]);
  const [selectedAudienceSize, setSelectedAudienceSize] = useState('');
  const [audienceSizeInput, setAudienceSizeInput] = useState('');

  useEffect(() => {
    if (isAudienceSizeModalOpen) {
      TestsApi.getaudiancesize({
        page_id: pageId,
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            if (response.status === 401 || response.status === 400) {
              navigate("/logout");
            }
          }
        })
        .then((data) => {
          if (data.status === 1) {
            setAudienceSizeOptions(data.data);
            const defaultSelectedOption = data.data.find((option) => option.selected === 'true');
            if (defaultSelectedOption) {
              setSelectedAudienceSize(defaultSelectedOption.option_value);
              if (defaultSelectedOption.option_name === 'Please Select') {
                setAudienceSizeInput(defaultSelectedOption.option_value);
              }
            } else {
              setSelectedAudienceSize('');
            }
          } else {
            errorMessage(data.message);
            errorModelOpen(true);
            showErrorHeader(true);
            onAudienceSizeClose();
          }
        })
        .catch((error) => {
          console.error('API Error:', error);
        });
    }
  }, [isAudienceSizeModalOpen, pageId]);

  const handleAudienceSizeChange = (value) => {
    const selectedOption = audienceSizeOptions.find(option => option.option_value === value);
    if (selectedOption.option_name === "Please Select") {
      setSelectedAudienceSize(value);
      setAudienceSizeInput(value);
    } else {
      setSelectedAudienceSize(selectedOption.option_value);
      setAudienceSizeInput('');
    }
  };

  const handleSaveAudienceSize = (e) => {
    e.preventDefault();
    const audienceSizeToSave = audienceSizeOptions.find(option => option.option_value === selectedAudienceSize)?.option_name === "Please Select"
    ? audienceSizeInput
    : selectedAudienceSize;
    TestsApi.saveaudiancesizenum({
      system_website_id: selectedMemberWebsite,
      page_id:pageId,
      winner_num_un:audienceSizeToSave
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        setMarksalestime(data.data.updated_time);
        onAudienceSizeClose();
        TestOptimizeReport();
      } else {
        errorMessage(data.message);
        errorModelOpen(true);
        onAudienceSizeClose();
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  }

  return (
    <>
       <Modal.Root opened={isAudienceSizeModalOpen} onClose={onAudienceSizeClose}>
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>AUDIENCE SIZE <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body h={250}>
            <form onSubmit={handleSaveAudienceSize}>
              <Select
                label="Audience Size"
                value={selectedAudienceSize}
                onChange={handleAudienceSizeChange}
                data={audienceSizeOptions.map(option => ({
                  value: option.option_value,
                  label: option.option_name
                }))}
                searchable
                nothingFound="No options"
                maxDropdownHeight={180}
                size="sm"
                dropdownPosition="bottom"
                mb={10}
              />
              {audienceSizeOptions.find(option => option.option_value === selectedAudienceSize)?.option_name === "Please Select" ? (
                <Input.Wrapper label="Audience Size Value:">
                  <Input
                    id="audience_value"
                    w="auto"
                    ml={0}
                    value={audienceSizeInput}
                    onChange={e => setAudienceSizeInput(e.target.value)}
                    placeholder="Enter a number"
                  />
                </Input.Wrapper>
              ) : (
                <Input.Wrapper label="Audience Size Value:">
                  <Input
                    id="audience_value"
                    w="auto"
                    ml={0}
                    value={selectedAudienceSize}
                    readOnly
                  />
                </Input.Wrapper>
              )}
              <Group position="right">
                <Button type="submit" size="sm" w={80} mt={25}>
                  Save
                </Button>
              </Group>
            </form>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  );
}