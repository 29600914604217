import { ServerAPI } from '../GeneralFunction';

const CampaignAPI = {
	deletecampaign: (data) => {
		let url = "delete_campaign";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getcampaignlist: (data) => {
		let url = "get_campaign_list";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	checkexistingcampaignpage: (data) => {
		let url = "check_existing_campaign_page";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getconnectshoppingcartandprocessor: (data) => {
		let url = "get_connect_shopping_cart_and_processor";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	checkexistingcampaign: (data) => {
		let url = "check_existing_campaign";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getcampaignpagebyfunnelid: (data) => {
		let url = "get_campaign_page_by_funnel_id";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	addcampaignstepfirst: (data) => {
		let url = "add_campaign_step_first";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getcampaigndetail: (data) => {
		let url = "get_campaign_detail";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getgoalsdropdown: (data) => {
		let url = "get_goals_dropdown";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getconversiondata: (data) => {
		let url = "get_conversion_data";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getfbpixeldetails: (data) => {
		let url = "get_fb_pixel_details";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getgoogleconvadcustomer: (data) => {
		let url = "get_google_conv_ad_customer";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getgoogleconvadsubcustomer: (data) => {
		let url = "get_google_conv_ad_subcustomer";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getgoogleconversiondata: (data) => {
		let url = "get_google_conversion_data";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getgoogleconversionpagedetail: (data) => {
		let url = "get_google_conversion_page_detail";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	taboolaconvaduserdata: (data) => {
		let url = "taboola_conv_ad_user_data";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	gettaboolaconversionpagedetail: (data) => {
		let url = "get_taboola_conversion_page_detail";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	gettaboolaconversiondata: (data) => {
		let url = "get_taboola_conversion_data";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	outbrainconvaduserdata: (data) => {
		let url = "outbrain_conv_ad_user_data";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getoutbrainconversionpagedetail: (data) => {
		let url = "get_outbrain_conversion_page_detail";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getoutbrainconversiondata: (data) => {
		let url = "get_outbrain_conversion_data";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getmicrosoftconvadsaccount: (data) => {
		let url = "get_microsoft_conv_ads_account";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getmicrosoftconvgoalname: (data) => {
		let url = "get_microsoft_conv_goal_name";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getmicrosoftconversiondata: (data) => {
		let url = "get_microsoft_conversion_data";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	savecampaigndetail: (data) => {
		let url = "save_campaign_detail";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	saveconversiondata: (data) => {
		let url = "save_conversion_data";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	validatedeletecampaignpaymentprocessorcart: (data) => {
		let url = "validate_delete_campaign_payment_processor_cart";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getcampaigntypeaduserdata: (data) => {
		let url = "get_campaign_type_ad_user_data";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	deleteadsintegrations: (data) => {
		let url = "delete_ads_integrations";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	addcampaignpaymentprocessorcart: (data) => {
		let url = "add_campaign_payment_processor_cart";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	savegoogleadaccountcredentials: (data) => {
		let url = "save_google_ad_account_credentials";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getcampaigngoalresult: (data) => {
		let url = "get_campaign_goal_result";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	gettrackbutton: (data) => {
		let url = "get_track_button";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	savetrackbutton: (data) => {
		let url = "save_track_button";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	fbadsintegration: (data) => {
		let url = "fb_ads_integration";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	generatetiktokaccesstoken: (data) => {
		let url = "generate_tiktok_access_token";
		const requestOptions = ServerAPI(url, 'POST',data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	gettiktokconversiondata: (data) => {
		let url = "get_tiktok_conversion_data";
		const requestOptions = ServerAPI(url, 'POST',data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	gettiktokadvertiseraccount: (data) => {
		let url = "get_tiktok_advertiser_account";
		const requestOptions = ServerAPI(url, 'POST',data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	gettiktokpixel: (data) => {
		let url = "get_tiktok_pixel";
		const requestOptions = ServerAPI(url, 'POST',data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	}
};

export default CampaignAPI;