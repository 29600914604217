import React, { useState,useEffect, useRef } from 'react';
import Iframe from 'react-iframe';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useDocumentTitle } from '@mantine/hooks';
import { Group, Text, Modal, Select, Button, Divider, TextInput, ActionIcon } from '@mantine/core';
import { VisiLoader, SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";
import { visiUserSession, visiUserId, visiUserToken } from '../../GeneralFunction';
import { IconX, IconCheck, IconHelp, IconCircleMinus, IconPlus } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import TestsApi from "../../api/TestsApi";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const loadResource = (tag, attributes) => {
  return new Promise((resolve, reject) => {
    const element = document.createElement(tag);
    Object.keys(attributes).forEach(key => element.setAttribute(key, attributes[key]));
    element.onload = resolve;
    element.onerror = reject;
    document.head.appendChild(element);
  });
};

export const TestEditorVisual = () => {

	useEffect(() => {
    window.setTestEditorLoader = (val) => {
      setTestEditorLoader(val);
    };
    return () => {
      delete window.setTestEditorLoader;
    };
  }, []);

	useEffect(() => {
    const script1 = document.createElement("script");
    script1.src = "https://cdnjs.cloudflare.com/ajax/libs/codemirror/5.63.1/codemirror.min.js";
    script1.onload = () => {
      const script2 = document.createElement("script");
      script2.src = "https://cdnjs.cloudflare.com/ajax/libs/codemirror/5.63.1/mode/javascript/javascript.min.js";
      document.body.appendChild(script2);
    };
    document.body.appendChild(script1);
  }, []);

	const navigate = useNavigate();

  const testEditorIconRef = useRef(null);
  const globalCssIconRef = useRef(null);
  const variablesIconRef = useRef(null);
  const buttonsIconRef = useRef(null);
  const googleFontIconRef = useRef(null);
  const assetsUrlIconRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [showAssetsurl, setShowAssetsurl] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({});

	let { pageid } = useParams();
	let { taguchi_type_id } = useParams();
	let query = useQuery();
	const location = useLocation();

	useDocumentTitle("Visiopt - Test Editor Visual");
	
	const devicePreviewIframeSrc = `${process.env.REACT_APP_BACKEND_API_PATH}setDevicePreviewLocalStorage.php`;
	
	const [iframeContent, setIframeContent] = useState('');
	const [loadResourceLoaded, setLoadResourceLoaded] = useState(0);
	const [pageURL, setpageURL] = useState("");
  const loadResources = async () => {
    try {
      setTestEditorLoader(true);
    	const frontendPublicUrl = process.env.REACT_APP_FRONTEND_PUBLIC_URL;
    	console.log(frontendPublicUrl);
    	await loadResource('script', { src: frontendPublicUrl+'js/yui-all.js' });	    	
    	await loadResource('script', { src: frontendPublicUrl+'js/flobn-all.js' });
    	await loadResource('script', { src: frontendPublicUrl+'js/ZeroClipboard.js' });
    	await loadResource('script', { src: frontendPublicUrl+'js/jquery.min.js' });
    	await loadResource('script', { src: frontendPublicUrl+'js/jquery.magnific-popup.min.1.0.1.js' });
    	await loadResource('script', { src: frontendPublicUrl+'js/moment.min.js' });
    	await loadResource('script', { src: frontendPublicUrl+'js/jquery-sortable.js' });

      // Load CSS
      await loadResource('link', { rel: 'stylesheet', href: frontendPublicUrl+'js/color/css/colorpicker.css' });
      await loadResource('script', { src: frontendPublicUrl+'js/color/js/colorpicker.js' });
      await loadResource('link', { rel: 'stylesheet', href: frontendPublicUrl+'jodit/build/jodit.css' });
      await loadResource('link', { rel: 'stylesheet', href: frontendPublicUrl+'jodit/plugins/emoji/emoji.css' });
      await loadResource('link', { rel: 'stylesheet', href: frontendPublicUrl+'jodit/plugins/button-generator/button-generator.css' });
      await loadResource('link', { rel: 'stylesheet', href: frontendPublicUrl+'jodit/plugins/color-picker/color-picker.css' });

      // Load JS
     
      await loadResource('script', { src: frontendPublicUrl+'jodit/build/jodit.js' });
      await loadResource('script', { src: frontendPublicUrl+'jodit/plugins/emoji/emoji.js' });
      await loadResource('script', { src: frontendPublicUrl+'jodit/plugins/button-generator/button-generator.js' });
      await loadResource('script', { src: frontendPublicUrl+'jodit/plugins/color-picker/color-picker.js' });
      setLoadResourceLoaded(1);
      handleButtonClickTest();
    } catch (error) {
      console.error('Error loading resource:', error);
    }
  };
	useEffect(() => {
	  if(pageURL !== ""){
	  	loadResources();
	  }
  	
	}, [pageURL]);


  const handleButtonClickTest = async () => {

    let params = {
      page_url : pageURL,
      taguchi_type_id : TaguchiTypeIdDecoded,
      page_id : PageIdDecoded,
      front_end_framework : FrontEndFrameWork,
      website_url : WebsiteURL,
      page : Page,
      test_assets_url : assetsURL,
      member_id : memberId,
      token : token,
      system_website_id : websiteId,
    };
    let paramsJSON = JSON.stringify(params);
    let paramsJSONEncoded = btoa(paramsJSON);
    
	try {
	  const response = await axios.get(process.env.REACT_APP_BACKEND_API_PATH+'testsetup-data.php?testParams='+paramsJSONEncoded);
	  setIframeContent(response.data);
	  const script = document.createElement('script');
	  script.id = 'yui__dyn_0';
	  script.type = 'text/javascript';
	  script.src = process.env.REACT_APP_FRONTEND_BASE_URL+'public/js/visual-ui.js';
	  script.async = true;
	  document.head.appendChild(script);
	} catch (error) {
		setTestEditorLoader(false);
	  console.error('Error:', error);
	}
  };

  const handleIframeLoad = () => {
	  setTestEditorLoader(false);
	};

  const [assetsURL, setAssetsURL] = useState('');
  const [addAssetsURLLoader, setAddAssetsURLLoader] = useState(false);
  const [testEditorLoader, setTestEditorLoader] = useState(false);
  const [cacheClearButton, setcacheClearButton] = useState('0');

  const addAssetsURL = async () => {
  	let assets_url = btoa(assetsURL);
  	setAddAssetsURLLoader(true);
    TestsApi.addassetsurlforstyleimagenotloaded({
    	assets_url : assets_url,
    	page_id : pageid
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setAddAssetsURLLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
      	const visi_addbaseurl_element = document.getElementById('visi_addbaseurl');
      	visi_addbaseurl_element.style.visibility = 'hidden';
      	visi_addbaseurl_element.style.display = 'none';
      	const visi_overlay_element = document.getElementById('visi_overlay');
      	visi_overlay_element.style.visibility = 'hidden';
      	visi_overlay_element.style.display = 'none';
        setAddAssetsURLLoader(false);
        setShowAssetsurl(true);
        notifications.show({
          color: 'teal',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
      }else{
        setAddAssetsURLLoader(false);
        notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: data.message,
        })
      }
    })
    .catch(error => {
      console.log(error);
      setAddAssetsURLLoader(false);
    });
  }

  const cacheClear = async () => {
  	let page_url = document.getElementById('page_url').value;
  	setTestEditorLoader(true);
    TestsApi.testeditorprerendercacheclear({
    	page_url : page_url
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setTestEditorLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
    	console.log(data);
      if(data.status === 1 && data.data && data.data.msg && data.data.msg === 'Cache clear queued'){
        setTestEditorLoader(false);
        handleButtonClickTest();
      }else{
        setTestEditorLoader(false);
      }
    })
    .catch(error => {
      console.log(error);
      setTestEditorLoader(false);
    });
  }
 	const [testEditorData, setTestEditorData] = useState([]);
 	const [testButtonsData, setTestButtonsData] = useState([]);
 	const [funnelDropdown, setFunnelDropdown] = useState([]);
 	const [testName, setTestName] = useState('');
 	const [selectedFunnelName, setSelectedFunnelName] = useState('');
 	const [activeTest, setActiveTest] = useState("0");

 	const [taguchiTypeId, setTaguchiTypeId] = useState('');
 	const [maxVariables, setMaxVariables] = useState('');
 	const [isEdit, setIsEdit] = useState('');
 	const [varLimits, setVarLimits] = useState('');
 	const [initvars, setInitvars] = useState('');
 	const [googleFonts, setGoogleFonts] = useState('');
 	const [testCssShow, setTestCssShow] = useState('');
 	const [cacheClearShow, setCacheClearShow] = useState('');

 	let websiteId = visiUserSession().website ? visiUserSession().website : '';
 	let memberId = visiUserId() ? visiUserId() : '';
 	const token = visiUserToken() ? visiUserToken() : '';

 	const [activeTestId, setActiveTestId] = useState("0");
 	const [selectedBehavioralFilter, setSelectedBehavioralFilter] = useState([]);
  const [TaguchiTypeIdDecoded, setTaguchiTypeIdDecoded] = useState('0');
  const [PageIdDecoded, setPageIdDecoded] = useState('0');
  const [FrontEndFrameWork, setFrontEndFrameWork] = useState('0');
  const [WebsiteURL, setWebsiteURL] = useState('');
  const [Page, setPage] = useState('');

 	useEffect(() => {
      TestsApi.gettesteditordata({
      system_website_id: websiteId,
      page_id:pageid,
      taguchi_type_id:taguchi_type_id
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
      	setTestEditorData(data.data.test_editor_data);
      	setTestButtonsData(data.data.tests_buttons_data);
      	setFunnelDropdown(data.data.funnel_dropdown);
      	setTestName(data.data.test_name);
      	setActiveTest(data.data.is_active_test);
      	setActiveTestId(data.data.active_test_id);
      	setSelectedFunnelName(data.data.selected_funnel_name);
      	setTestCssShow(data.data.test_css);
      	setTaguchiTypeId(data.data.taguchi_type_id);
      	setMaxVariables(data.data.max_variables);
      	setIsEdit(data.data.is_edit);
      	setVarLimits(data.data.var_limits);
      	setInitvars(data.data.initvars);
      	setGoogleFonts(data.data.google_fonts);
      	setCacheClearShow(data.data.cache_clear);
      	setSelectedBehavioralFilter(data.data.behavioral_filter);
        setcacheClearButton(data.data.cache_clear);
        setAssetsURL(data.data.assets_directory_link);
        setpageURL(data.data.page_url);
				let deviceFilter = [];
		    let trafficSourceKey = [];
		    let trafficSourceValue = [];
		    data.data.behavioral_filter.forEach((set) => {
		      deviceFilter.push(set.device_filter);
		      trafficSourceKey.push(set.traffic_source_key);
		      trafficSourceValue.push(set.source_value);
		    });
		    setDeviceFilterString(deviceFilter.toString());
		    setTrafficSourceKey(trafficSourceKey.toString());
		    setTrafficSourceValue(trafficSourceValue.toString());
        setTaguchiTypeIdDecoded(data.data.taguchi_type_id);
        setPageIdDecoded(data.data.page_id);
        setFrontEndFrameWork(data.data.front_end_framework);
        setWebsiteURL(data.data.website_url);
        setPage(data.data.valid_page_id);
      } else {
        console.error("Error:", "Error");
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
 	}, []);

 	useEffect(() => {
    if(showAssetsurl === true){
      loadResources();
      setShowAssetsurl(false);
    }
  }, [showAssetsurl]);
  
 	const handleSaveTestSetup = (formValues) => {
 		const isValid = window.visi_formValidation('save_test');

		if (isValid) {
      const transformedVValues = (formValues) => {
			  const result = {};
			  Object.keys(formValues).forEach((key) => {
			    const match = key.match(/^v\[(\d+)\](?:\[(\d+)\])?\[(\w+)\]$/);
			    
			    if (match) {
			      const [, variation, subIndex, property] = match;
			      const index = parseInt(variation, 10);
			      
			      if (!result[index]) {
			        result[index] = {};
			      }
			      
			      if (subIndex !== undefined) {
			        if (!result[index][subIndex]) {
			          result[index][subIndex] = {};
			        }
			        result[index][subIndex][property] = formValues[key];
			      } else {
			        result[index][property] = formValues[key];
			      }
			    }
			  });
			  
			  return result;
			};

      const transformedVRValues = (formValues) => {
			  const result = {};

			  Object.keys(formValues).forEach((key) => {
			    const match = key.match(/^vr\[(\d+)\]\[(\d+)\]\[(\d+)\]\[(\w+)\]$/);
			    
			    if (match) {
			      const [, variation, subIndex1, subIndex2, property] = match;
			      const index = parseInt(variation, 10);
			      
			      if (!result[index]) {
			        result[index] = {};
			      }
			      
			      if (!result[index][subIndex1]) {
			        result[index][subIndex1] = {};
			      }
			      
			      if (!result[index][subIndex1][subIndex2]) {
			        result[index][subIndex1][subIndex2] = {};
			      }

			      result[index][subIndex1][subIndex2][property] = formValues[key];
			    }
			  });

			  return result;
			};

			const transformedBValues = (formValues) => {
			  const result = {};

			  Object.keys(formValues).forEach((key) => {
			    const match = key.match(/^b\[(\d+)\]\[(\w+)\]$/);
			    
			    if (match) {
			      const [, index, property] = match;
			      
			      if (!result[index]) {
			        result[index] = {};
			      }
			      
			      result[index][property] = formValues[key];
			    }
			  });

			  return result;
			};

	    const transformedV = transformedVValues(formValues);
			const transformedVR = transformedVRValues(formValues);
			const transformedB = transformedBValues(formValues);
			setTestEditorLoader(true);
	  	TestsApi.savetestsetup({
	  		system_website_id:websiteId,
	  		taguchi_type_id:taguchi_type_id,
	  		page_id:formValues.page_id,
	  		visi_tests_action:"save_test",
	  		visi_test_funnel:formValues.visi_test_funnel,
	  		is_edit:formValues.is_edit,
	  		testName:formValues.testName,
	  		v:transformedV,
	  		vr:transformedVR,
	  		b:transformedB,
	  		deleted_buttons:formValues.deleted_buttons,
	  		google_font:formValues.google_font,
	  		testcss:formValues.testcss,
	  		visi_device_filter:deviceFilterString,
	  		visi_traffic_source_key:trafficSourceKey,
	  		visi_traffic_source_value:trafficSourceValue,
	    })
	    .then(response => {
	      if (response.ok) {
	        return response.json();
	      } else {
	        if (response.status === 401 || response.status === 400) {
	          navigate("/logout");
	        }
	      }
	    })
	    .then(data => {
	      if(data.status === 1){
	      	setTestEditorLoader(false);
	      	notifications.show({
	          color: 'teal',
	          icon: <IconCheck size="1.1rem" />,
	          title: 'Success',
	          message: data.message,
	        });
	        navigate("/reviewing-test", { state: { pageId: pageid, taguchiTestId:taguchi_type_id, funnelId:selectedFunnelName, testsId:activeTestId } });
	      } else {
	        notifications.show({
	          color: 'red',
	          icon: <IconX size="1.1rem" />,
	          title: 'Error',
	          message: data.message,
	        });
	      }
	    })
	    .catch(error => {
	      console.error("API Error:", error);
	    });
	  }
  }

  const handleSaveDraftTestSetup = (formValues) => {
 		const isValid = window.visi_saveDraftFormValidation('save_draft');

		if (isValid) {
      const transformedVValues = (formValues) => {
			  const result = {};
			  Object.keys(formValues).forEach((key) => {
			    const match = key.match(/^v\[(\d+)\](?:\[(\d+)\])?\[(\w+)\]$/);
			    
			    if (match) {
			      const [, variation, subIndex, property] = match;
			      const index = parseInt(variation, 10);
			      
			      if (!result[index]) {
			        result[index] = {};
			      }
			      
			      if (subIndex !== undefined) {
			        if (!result[index][subIndex]) {
			          result[index][subIndex] = {};
			        }
			        result[index][subIndex][property] = formValues[key];
			      } else {
			        result[index][property] = formValues[key];
			      }
			    }
			  });
			  
			  return result;
			};

      const transformedVRValues = (formValues) => {
			  const result = {};

			  Object.keys(formValues).forEach((key) => {
			    const match = key.match(/^vr\[(\d+)\]\[(\d+)\]\[(\d+)\]\[(\w+)\]$/);
			    
			    if (match) {
			      const [, variation, subIndex1, subIndex2, property] = match;
			      const index = parseInt(variation, 10);
			      
			      if (!result[index]) {
			        result[index] = {};
			      }
			      
			      if (!result[index][subIndex1]) {
			        result[index][subIndex1] = {};
			      }
			      
			      if (!result[index][subIndex1][subIndex2]) {
			        result[index][subIndex1][subIndex2] = {};
			      }

			      result[index][subIndex1][subIndex2][property] = formValues[key];
			    }
			  });

			  return result;
			};

			const transformedBValues = (formValues) => {
			  const result = {};

			  Object.keys(formValues).forEach((key) => {
			    const match = key.match(/^b\[(\d+)\]\[(\w+)\]$/);
			    
			    if (match) {
			      const [, index, property] = match;
			      
			      if (!result[index]) {
			        result[index] = {};
			      }
			      
			      result[index][property] = formValues[key];
			    }
			  });

			  return result;
			};

	    const transformedV = transformedVValues(formValues);
			const transformedVR = transformedVRValues(formValues);
			const transformedB = transformedBValues(formValues);
			setTestEditorLoader(true);
	  	TestsApi.savetestsetup({
	  		system_website_id:websiteId,
	  		taguchi_type_id:taguchi_type_id,
	  		page_id:formValues.page_id,
	  		visi_tests_action:"save_draft",
	  		visi_test_funnel:formValues.visi_test_funnel,
	  		is_edit:formValues.is_edit,
	  		testName:formValues.testName,
	  		v:transformedV,
	  		vr:transformedVR,
	  		b:transformedB,
	  		deleted_buttons:formValues.deleted_buttons,
	  		google_font:formValues.google_font,
	  		testcss:formValues.testcss,
	  		visi_device_filter:deviceFilterString,
	  		visi_traffic_source_key:trafficSourceKey,
	  		visi_traffic_source_value:trafficSourceValue,
	    })
	    .then(response => {
	      if (response.ok) {
	        return response.json();
	      } else {
	        if (response.status === 401 || response.status === 400) {
	          navigate("/logout");
	        }
	      }
	    })
	    .then(data => {
	      if(data.status === 1){
	      	setTestEditorLoader(false);
	      	notifications.show({
	          color: 'teal',
	          icon: <IconCheck size="1.1rem" />,
	          title: 'Success',
	          message: data.message,
	        });
	        navigate("/create-test");
	      } else {
	        notifications.show({
	          color: 'red',
	          icon: <IconX size="1.1rem" />,
	          title: 'Error',
	          message: data.message,
	        });
	      }
	    })
	    .catch(error => {
	    	setTestEditorLoader(false);
	      console.error("API Error:", error);
	    });
	  }
  }

  const saveTestCss = async () => {
  	setTestCssShow(testCssShow);
  }

  const handleCancel = () => {
  	navigate("/review-tests");
  }

 	const FunnelDropdownData = [
    ...Object.entries(funnelDropdown).map(([key, value]) => ({
      value: key,
      label: value,
    })),
  ];

 	const handleResetBehavioralFilter = async () => {
  	setTestEditorLoader(true);
    TestsApi.testeditorresetfilterbehavioraldimensions({
    	active_test_id : activeTestId
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setTestEditorLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
      	handleBehavioralFilterCloseModal();
        setTestEditorLoader(false);
        setSelectedBehavioralFilter([]);
        setDeviceFilterString('');
        setTrafficSourceKey('');
        setTrafficSourceValue('');
        notifications.show({
          color: 'teal',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
      }else{
        setTestEditorLoader(false);
        notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: data.message,
        });
      }
    })
    .catch(error => {
      console.log(error);
      setTestEditorLoader(false);
    });
  }

  const [behavioralFilterModalOpen, setBehavioralFilterModalOpen] = useState(false);
  const [dropdownSets, setDropdownSets] = useState([]);
  const [showFirstDropdown, setShowFirstDropdown] = useState(false);
  const [showSourceValueInput, setShowSourceValueInput] = useState({});
  const [showSecondDropdown, setShowSecondDropdown] = useState({});
  const [showThirdDropdown, setShowThirdDropdown] = useState({});
  const [behavioralFilterFirstDropdown, setBehavioralFilterFirstDropdown] = useState([]);
  const [behavioralFilterThirdDropdown, setBehavioralFilterThirdDropdown] = useState({});
  const [behavioralFilterSecondDropdown, setBehavioralFilterSecondDropdown] = useState({});
  const [firstDropdown, setFirstDropdown] = useState('');
  const [, setThirdDropdown] = useState('');
  const [, setSecondDropdown] = useState('');
  const [, setSourceValueInput] = useState('');
  const [deviceFilterString, setDeviceFilterString] = useState('');
  const [trafficSourceKey, setTrafficSourceKey] = useState('');
  const [trafficSourceValue, setTrafficSourceValue] = useState('');

  /* BEHAVIORAL DIMENSION*/
  const handleBehavioralFilterOpenModal = (value:any) => {
    setBehavioralFilterModalOpen(true);
    const dropdownValues1 = selectedBehavioralFilter.map((filter, index) => ({
      id: index,
      firstDropdown: filter.device_filter || '',
      secondDropdown: filter.traffic_source_key || '',
      thirdDropdown: filter.ad_network_type || '',
      sourceValueInput: filter.source_value || '',
    }));
    setDropdownSets(dropdownValues1);

    if(dropdownValues1){
      TestsApi.behavioraldimensionsaddfilterfirstdropdown({
    	isTestEditorFilter : true
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if (data.status === 1) {
          setBehavioralFilterFirstDropdown(data.data.first_dropdown);
          setShowFirstDropdown(true);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
    }

    const dropdownValues = selectedBehavioralFilter.forEach((filter, index) => {
      if(filter.device_filter === "WGxCTW9LYlErd0RIWTdrc3hvL2d3Zz09" || filter.device_filter === "VDMzenM4ZGxuUDVSYTJpTDRnUUk3UT09" || filter.device_filter === "SjliZHA4M1lKLzVaZUNNbGlwcXd5Zz09"){
        TestsApi.behavioraldimensionsaddfilterseconddropdown({
          device_filter:filter.device_filter,
          page_id:pageid,
          ad_network_type:filter.ad_network_type,
        })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            if (response.status === 401 || response.status === 400) {
              navigate("/logout");
            }
          }
        })
        .then(data => {
          if (data.status === 1) {
            setBehavioralFilterSecondDropdown(prev => ({ ...prev, [index]: data.data.second_dropdown }));
            setShowSecondDropdown(prev => ({ ...prev, [index]: true }));
          }
        })
        .catch(error => {
          console.error("API Error:", error);
        });

        if(filter.device_filter === 'VDMzenM4ZGxuUDVSYTJpTDRnUUk3UT09'){
          TestsApi.behavioraldimensionsaddfilterthirddropdown({
            device_filter:filter.device_filter
          })
          .then(response => {
            if (response.ok) {
              return response.json();
            } else {
              if (response.status === 401 || response.status === 400) {
                navigate("/logout");
              }
            }
          })
          .then(data => {
            if (data.status === 1) {
              setBehavioralFilterThirdDropdown(prev => ({ ...prev, [index]: data.data.third_dropdown }));
              setThirdDropdown(filter.traffic_source_key);
              setShowThirdDropdown(prev => ({ ...prev, [index]: true }));
              setShowSecondDropdown(prev => ({ ...prev, [index]: true }));
            }
          })
          .catch(error => {
            console.error("API Error:", error);
          });
        }
      }
      if(filter.device_filter === "WGxCTW9LYlErd0RIWTdrc3hvL2d3Zz09" || filter.device_filter === "VDMzenM4ZGxuUDVSYTJpTDRnUUk3UT09" || filter.device_filter === "SjliZHA4M1lKLzVaZUNNbGlwcXd5Zz09"  || filter.device_type === "Source"){
      	setShowSourceValueInput(prev => ({ ...prev, [index]: true }));
      }
    });
  };

  const handleBehavioralFilterCloseModal = () => {
    setBehavioralFilterModalOpen(false);
    setDropdownSets([]);
    setShowFirstDropdown(false);
    setShowSourceValueInput(false);
    setShowSecondDropdown(false);
    setShowThirdDropdown(false); 
  };

  const handleSaveBehavioralFilter = (e) => {
    e.preventDefault();
    let deviceFilter = [];
    let trafficSourceKey = [];
    let trafficSourceValue = [];
    let newDropdownSets = [];
    dropdownSets.forEach((set) => {
      deviceFilter.push(set.firstDropdown);
      trafficSourceKey.push(set.secondDropdown);
      trafficSourceValue.push(set.sourceValueInput);
      newDropdownSets.push({
        device_filter : set.firstDropdown,
        ad_network_type : set.thirdDropdown,
        traffic_source_key : set.secondDropdown,
        device_type : "",
        source_value : set.sourceValueInput
      });
    });
    setSelectedBehavioralFilter(newDropdownSets);
    setDeviceFilterString(deviceFilter.toString());
    setTrafficSourceKey(trafficSourceKey.toString());
    setTrafficSourceValue(trafficSourceValue.toString());
    handleBehavioralFilterCloseModal();
  };

  const handleFirstDropdown = (value:any,setId) => {
    setFirstDropdown(value);
    setDropdownSets((prevSets) =>
      prevSets.map((set) =>
        set.id === setId ? { ...set, firstDropdown: value } : set
      )
    );

    if(value === 'WGxCTW9LYlErd0RIWTdrc3hvL2d3Zz09' || value === 'SjliZHA4M1lKLzVaZUNNbGlwcXd5Zz09' || value === 'VDMzenM4ZGxuUDVSYTJpTDRnUUk3UT09' || value === 'VHBXdmd3MHZhSUsxN1BkREM1MCthdz09'){
    	setShowSourceValueInput(prev => ({ ...prev, [setId]: true }));
    }else{
    	setShowSourceValueInput(prev => ({ ...prev, [setId]: false }));
    }

    if(value === 'VDMzenM4ZGxuUDVSYTJpTDRnUUk3UT09'){
      TestsApi.behavioraldimensionsaddfilterthirddropdown({
        device_filter:value
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if (data.status === 1) {
          const thirdDropdownData = data.data.third_dropdown;
          setBehavioralFilterThirdDropdown(prev => ({ ...prev, [setId]: thirdDropdownData }));
          setDropdownSets(prevSets => prevSets.map(set => {
            if (set.id === setId) {
              return { ...set, thirdDropdown: thirdDropdownData.length > 0 ? thirdDropdownData[0].key : null };
            }
            return set;
          }));
          setShowThirdDropdown(prev => ({ ...prev, [setId]: true }));
          setShowSecondDropdown(prev => ({ ...prev, [setId]: true }));
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
    }else{
      setShowThirdDropdown(prev => ({ ...prev, [setId]: false }));
      setShowSecondDropdown(prev => ({ ...prev, [setId]: false }));
    }
    const thirdDropdown = 'facebook';
    TestsApi.behavioraldimensionsaddfilterseconddropdown({
      device_filter:value,
      page_id:pageid,
      ad_network_type:thirdDropdown,
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        const secondDropdownData = data.data.second_dropdown;
        setBehavioralFilterSecondDropdown(prev => ({ ...prev, [setId]: secondDropdownData }));

        setDropdownSets(prevSets => prevSets.map(set => {
          if (set.id === setId) {
            return { ...set, secondDropdown: secondDropdownData.length > 0 ? secondDropdownData[0].key : null };
          }
          return set;
        }));
        setShowSecondDropdown(prev => ({ ...prev, [setId]: true }));
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  const handleThirdDropdown = (value:any,setId) => {
    setThirdDropdown(value);
    setDropdownSets((prevSets) =>
      prevSets.map((set) =>
        set.id === setId ? { ...set, thirdDropdown: value } : set
      )
    );
    TestsApi.behavioraldimensionsaddfilterseconddropdown({
      device_filter: firstDropdown,
      page_id: pageid,
      ad_network_type:value,
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate('/logout');
        }
      }
    })
    .then((data) => {
      if (data.status === 1) {
        const secondDropdownData = data.data.second_dropdown;
        setBehavioralFilterSecondDropdown(prev => ({ ...prev, [setId]: secondDropdownData }));

        setDropdownSets(prevSets => prevSets.map(set => {
          if (set.id === setId) {
            return { ...set, secondDropdown: secondDropdownData.length > 0 ? secondDropdownData[0].key : null };
          }
          return set;
        }));
      }
    })
    .catch((error) => {
      console.error('API Error:', error);
    });
  }

  const handleSecondDropdown = (value:any,setId) => {
    setSecondDropdown(value);
    setDropdownSets((prevSets) =>
      prevSets.map((set) =>
        set.id === setId ? { ...set, secondDropdown: value } : set
      )
    );
  }

  const handleSourceValueInput = (value:any,setId) => {
    setSourceValueInput(value);
    setDropdownSets((prevSets) =>
      prevSets.map((set) =>
        set.id === setId ? { ...set, sourceValueInput: value } : set
      )
    );
  }

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    const iconRefMapping = {
      18: variablesIconRef,
      157: googleFontIconRef,
      294: testEditorIconRef,
      156: globalCssIconRef,
      286: assetsUrlIconRef,
      336: buttonsIconRef
    };
    const iconref = iconRefMapping[id];    
    if (iconref.current) {
      console.log(iconref.current.getBoundingClientRect());
      const { top, left, width, height } = iconref.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      let calculatedTop, calculatedLeft;
      if (id !== 18 && id !== 336) {
        calculatedTop = top + scrollOffset;
        calculatedLeft = left + width + 10;
      } else {
        calculatedTop = top + height + scrollOffset;
        calculatedLeft = left - 250;
      }

      setTooltipPosition({
        [id]: { top: calculatedTop, left: calculatedLeft },
      });
    }
  };

  const handleRemoveFilter = (setId) => {
    const updatedDropdownSets = dropdownSets.filter(set => set.id !== setId);
    setDropdownSets(updatedDropdownSets);
  };

  const handleAddFilter = () => {
    const newId = dropdownSets.length > 0 ? dropdownSets[dropdownSets.length - 1].id + 1 : 0;
    const newSet = { id: newId, firstDropdown: '', secondDropdown: '', thirdDropdown: '' };
    setDropdownSets([...dropdownSets, newSet]);

    setShowSecondDropdown(prev => ({ ...prev, [newId]: false }));
    setShowThirdDropdown(prev => ({ ...prev, [newId]: false }));

    TestsApi.behavioraldimensionsaddfilterfirstdropdown({
    	isTestEditorFilter : true
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        const firstDropdownData = data.data.first_dropdown;
        setBehavioralFilterFirstDropdown(firstDropdownData);
        setDropdownSets(prevSets => prevSets.map(set => {
          if (set.id === newId) {
            return { ...set, firstDropdown: firstDropdownData.length > 0 ? firstDropdownData[0].key : null };
          }
          return set;
        }));
        setShowFirstDropdown(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  return (
	<>
	<VisiLoader VisiLoaderVisible={testEditorLoader} />
	{showHelpModal && Object.keys(tooltipPosition).map((key) => (
    <SelectTooltip
      isVisible={showHelpModal}
      setIsVisible={setShowHelpModal}
      id={helpTargetId}
      tooltipPosition={tooltipPosition[key]}
      type='test_editor'
    />
  ))}
	{/*Behavioral Filters Model*/}
  <Modal.Root opened={behavioralFilterModalOpen} onClose={handleBehavioralFilterCloseModal} size="80%">
    <Modal.Overlay />
    <Modal.Content>
      <Modal.Header>
        <Modal.Title>TARGET TEST BY DIMENSIONS <IconHelp ref={testEditorIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(294)}/></Modal.Title>
        <Modal.CloseButton />
      </Modal.Header>
      <Modal.Body>
      	<Group>
        <div>
          {dropdownSets.map((set) => (
            <div key={set.id} style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
              {showFirstDropdown && (
              <Select
                data={behavioralFilterFirstDropdown.map((item) => ({ value: item.key, label: item.value }))}
                value={set.firstDropdown}
                onChange={(value) => handleFirstDropdown(value, set.id)}
                searchable
                placeholder="Select value"
                nothingFound="No options"
                size="sm"
                dropdownPosition="bottom"
                mb={10}
                style={{marginRight:15}}
                withinPortal
              />
              )}
              {showThirdDropdown[set.id] && (
              <Select
                data={(behavioralFilterThirdDropdown[set.id] || []).map((item) => ({ value: item.key, label: item.value }))}
                value={set.thirdDropdown}
                onChange={(value) => handleThirdDropdown(value, set.id)}
                searchable
                nothingFound="No options"
                size="sm"
                dropdownPosition="bottom"
                mb={10}
                style={{marginRight:15}}
                withinPortal
              />
              )}
              {showSecondDropdown[set.id] && (
              <Select
                data={(behavioralFilterSecondDropdown[set.id] || []).map((item) => ({ value: item.key, label: item.value }))}
                value={set.secondDropdown}
                onChange={(value) => handleSecondDropdown(value, set.id)}
                searchable
                nothingFound="No options"
                size="sm"
                dropdownPosition="bottom"
                mb={10}
                style={{marginRight:15}}
                withinPortal
              />
              )}
              {showSourceValueInput[set.id] && (
              	<TextInput placeholder="Source Value (eg. newsletter)" mb={10} style={{ marginRight: 15 }} value={set.sourceValueInput} onChange={(value) => handleSourceValueInput(value.target.value, set.id)}/>
              )}
              {showFirstDropdown && (
              	<ActionIcon title="Remove">
	            		<IconCircleMinus size="1.1rem" color="red" style={{marginBottom:8}} onClick={() => handleRemoveFilter(set.id)}/>
	            	</ActionIcon>
              )}
            </div>
          ))}
        </div>
        </Group>
        <Button onClick={handleAddFilter}>
          <IconPlus size="1.1rem" color="white"/><Text ml={5}>Add Filter</Text>
        </Button>
        <Divider my="sm" />  
        <Group position="right">
        	<Button type="button" size="sm" color="orange" onClick={handleResetBehavioralFilter}>
        	  Reset Filter
        	</Button>
          <Button onClick={handleSaveBehavioralFilter} size="sm" w={80}>
            Save
          </Button>
        </Group>
      </Modal.Body>
    </Modal.Content>
  </Modal.Root>
	<div className="test-wrapper flexed">
	  <Helmet>
	  <link rel="stylesheet" type="text/css" href="/css/normalize.css"/>
	  <link rel="stylesheet" type="text/css" href="/css/magnific-popup.css"/>
	  <link rel="stylesheet" type="text/css" href="/css/visiopt-visual.css"/>
	  {loadResourceLoaded === 1 ?
	  <script type="text/javascript">
		{`
			YAHOO.flobn.register('var-limit',{`+varLimits+`});
			YAHOO.flobn.register('initvars','`+initvars+`');
			YAHOO.flobn.register('taguchi_type_id','`+taguchiTypeId+`');

			var jQcustom = jQuery.noConflict();

			var window_height = jQcustom(window).height() - 230;
			jQcustom('.variable-holder .variable-tree').css("height", window_height+"px");
			jQcustom('.visi_button-holder .visi_button-tree').css("height", window_height+"px");

			jQcustom( "iframe" ).on('load',function() {
				if(document.getElementById("vis").contentDocument.title == '403 Forbidden'){
					document.title = 'Visiopt - Tests';
				} else {
					document.title = document.getElementById("vis").contentDocument.title;
				}  
			});
			function testsettings(){
				jQcustom('.test-settings_checkbox').toggle();
			}
			function testmobile(){
				jQcustom('#test-pc').removeClass('test-pc-active');
				jQcustom('#test-pc').addClass('test-pc');
				jQcustom('#test-mobile').removeClass('test-mobile');
				jQcustom('#test-mobile').addClass('test-mobile-active');
				jQcustom('#vis').addClass('active_visi_mobile');
			}
			function testpc(){
				jQcustom('#test-pc').removeClass('test-pc');
				jQcustom('#test-pc').addClass('test-pc-active');
				jQcustom('#test-mobile').removeClass('test-mobile-active');
				jQcustom('#test-mobile').addClass('test-mobile');
				jQcustom('#vis').removeClass('active_visi_mobile');
			}
			function variable_tab_toggle(e){
				jQcustom("#variable_content").parent().removeClass("active");
				jQcustom("#variable_scripts").parent().removeClass("active");
				jQcustom("#variable_css").parent().removeClass("active");
				jQcustom(e).parent().addClass("active");
				if(jQcustom(e).attr('id') == 'variable_content')
				{
					jQcustom('#version_js_maindiv').hide();
					jQcustom('#version_content_maindiv').show();
					jQcustom('#version_css_maindiv').hide();
				}
				else if(jQcustom(e).attr('id') == 'variable_scripts')
				{
					if(jQcustom(e).attr('codemirror_sts') != 'loaded')
					{
							window.setTestEditorLoader(true);
					    var s = document.createElement("link");
					    s.rel = "stylesheet";
					    s.id = "codemirror_css";
					    s.href = "/css/codemirror/codemirror.min.css";
					    jQcustom("head").append(s);

					    var codemirror_theme = document.createElement("link");
					    codemirror_theme.rel = "stylesheet";
					    codemirror_theme.id = "codemirror_theme_css";
					    codemirror_theme.href = "/css/codemirror/theme-yonce.css";
					    jQcustom("head").append(codemirror_theme);

					    setTimeout(function(){
					        window['jseditor'] = CodeMirror.fromTextArea(document.getElementById("version_js"), {
					            mode: "javascript", 
					            lineNumbers: true, 
					            theme: "yonce", 
					            tabSize: 4,
					            lineSeparator: "|visi_new_line|" ,
					        });
					        window.setTestEditorLoader(false);
					    }, 1000);
					    jQcustom(e).attr("codemirror_sts","loaded");					    
					}
					jQcustom('#version_js_maindiv').show();
					jQcustom('#version_content_maindiv').hide();
					jQcustom('#version_css_maindiv').hide();
				}
				else if(jQcustom(e).attr('id') == 'variable_css')
				{
					jQcustom('#version_js_maindiv').hide();
					jQcustom('#version_content_maindiv').hide();
					jQcustom('#version_css_maindiv').show();
				}
			}
			var DOM = YAHOO.util.Dom,
				Signal = YAHOO.util.Event,
				Con = YAHOO.util.Connect,
				util = YAHOO.flobn.util;
				
			jQcustom('.visi-tab_section').click(function(){
				var tab_id = jQcustom(this).attr('id');
				jQcustom('.visi-tab_section').removeClass('active_tab');
				jQcustom(this).addClass('active_tab');
				if(tab_id == 'variable_section'){
					jQcustom('#button_section').removeClass('active_tab');
					jQcustom('.variable-holder').show();
					jQcustom('.visi_button-holder').hide();
					jQcustom('.addVersion').show();
				} else {
					jQcustom('#variable_section').removeClass('active_tab');
					jQcustom('.visi_button-holder').show();
					jQcustom('.variable-holder').hide();
					jQcustom('.addVersion').hide();
					
				}
			});

			jQcustom('.visi_btn_type').change(function() {
				var visi_btn_type = jQcustom(this).attr('id');
				if(visi_btn_type == 'new'){
					jQcustom('#btn_label').show();
					jQcustom('#btn_id').hide();
				} else{
					jQcustom('#btn_label').hide();
					jQcustom('#btn_id').show();
					jQcustom('#visi_defineBtn #btn_id').html('<option value="">Select Existing Button</option>');
					var btn_arr=[];
					jQcustom('.visi_button-holder .visi_button-tree li').each(function() {
						var value = jQcustom( this ).attr( "id" );
						var name = jQcustom( this ).find( "#name_"+value ).val();
						var parent_id = jQcustom( this ).find( "#button_"+value ).val();
						if(parent_id == null || parent_id == '0'){
							parent_id = 1;
						}
						btn_arr[value] = name;
						jQcustom('#visi_defineBtn #btn_id').append('<option value="'+parent_id+'">'+name+'</option>');
					});
					var btn_options = {};
					jQcustom("#visi_defineBtn #btn_id > option").each(function () {
						if(btn_options[this.text]) {
							jQcustom(this).remove();
						} else {
							btn_options[this.text] = this.value;
						}
					});
				}
			});

			function visi_saveDraftFormValidation(e){
				var testname = jQcustom('#testName').val();
        var visi_test_funnel = jQcustom('#visi_test_funnel').val();
        if(testname.trim() == "")
        {
          alert('Please Enter Test Name.');
          return false;
        }
        if(testname.length > 200)
        {
          alert('Test name must be less than 200 characters');
          return false;
        }
        if(visi_test_funnel.trim() == ''){
          alert('Visi Test Funnel is Required field');
          return false;
        }
				return true;
			}

			function visi_formValidation(e)
			{
				var limits = YAHOO.flobn.get('var-limit');
				var taguchi_type_id = YAHOO.flobn.get('taguchi_type_id');
				var is_edit = jQcustom("#is_edit").val();
				var limits_ary = JSON.parse(JSON.stringify(limits));
				var variation_sum = 0;
				var var_count = 0;
				jQcustom.each(limits_ary, function (i,v)
				{
				  variation_sum += v;
				  var_count ++;
				});
				var total_variable_count = var_count;
				var variable_count = jQcustom(".tree.variable-tree .variation_li").size();
				var variation_count = jQcustom(".tree.variable-tree li ul li").size();
				var version_rearrange_count = jQcustom(".tree.variable-tree li ul li ul li").size();
				var variation_count = variation_count - version_rearrange_count;
				var testname = jQcustom('#testName').val();
				var visi_test_funnel = jQcustom('#visi_test_funnel').val();
				if(testname.trim() == "")
				{
					alert('Please Enter Test Name.');
					return false;
				}
				if(testname.length > 200)
				{
					alert('Test name must be less than 200 characters');
					return false;
				}
				if(visi_test_funnel.trim() == ''){
					alert('Please select the funnel for the page you are testing. A funnel must be set up before saving a test.');
					return false;
				}
				if(taguchi_type_id == 18 )
				{
					var a = [];
					jQcustom('.tree.variable-tree .variation_li').each(function() {
						var array_len = jQcustom(this).find('ul').find('li').length;
						if (array_len == 0) {
							var a1 = jQcustom(this).find("textarea").text();
							a.push(a1);
						}
					});
					if(a.length != 0){
						alert('You can not save this test. In the following variations '+a+' you have not selected any variables. Please select variables and then save');
						return false;
					}
					if(variation_count < 2){
						
						alert('You have Not added the Minimum number of variables for this type of test.');
						return false;
					}
					if(variation_count < 2)
					{
						alert('You have Not added the Minimum number of version for this variable.');
						return false;
					}
				}
				else if(taguchi_type_id == 19)
				{
					if(total_variable_count != variable_count){
						
						alert('You have Not added the Minimum number of variables for this type of test.');
						return false;
					}
					if(variation_count < 2)
					{
						alert('You have Not added the Minimum number of version for this variable.');
						return false;
					}
				}
				else {
					if(total_variable_count != variable_count){
						
						alert('You have Not added the Minimum number of variables for this type of test.');
						return false;
					}
					if(variation_count != variation_sum)
					{
						alert('You have Not added the Minimum number of version for this variable.');
						return false;
					}
				}
				return true;  
			}

			jQcustom('#test_preview').click(function(){

				let taguchi_type_id = jQcustom("#taguchi_type_id_dp").val();
				let page_id = jQcustom("#page_id_dp").val();
				let test_global_css = jQcustom("#testcss").val();
				let page_url = jQcustom("#page_url").val();
				let front_end_framework = jQcustom("#front_end_framework_dp").val();
				let test_assets_url = jQcustom("#test_assets_url_dp").val();
				let valid_page = jQcustom("#valid_page_dp").val();

				let params = {
		      taguchi_type_id : taguchi_type_id,
		      page_id : page_id,
		      test_css : test_global_css,
		      page_url : page_url,
		      front_end_framework : front_end_framework,
		      test_assets_url : test_assets_url,
		      valid_page : valid_page
		    };

		    let paramsJSON = JSON.stringify(params);
    		let paramsJSONEncoded = btoa(paramsJSON);

				var pathname = '`+process.env.REACT_APP_BACKEND_API_PATH+`new_test_setup-preview.php?testParams='+paramsJSONEncoded;
				preview_var = [];
				google_font_var = [];
				css_var = [];

				if(taguchi_type_id == '19') {
					var test_css = jQcustom('#testcss').val();
					var google_font = jQcustom('#google_font').val();
					google_font_var.push(encodeURIComponent('https://fonts.googleapis.com/css?family='+google_font));
					css_var.push(encodeURIComponent(test_css));
					jQcustom('.device-preview').each(function(){
						preview_var.push(new Array());
						count = 0;
						var selected_variable = jQcustom('.device-preview').parent().parent().attr('id');
						count2 = 0;
						jQcustom('#'+selected_variable).find('textarea').each(function(){
							if(count2 < 1){
								name = jQcustom(this).attr('name');
								if(name.indexOf("selector") != -1){
									preview_var[count].push(encodeURIComponent(this.value));
								}
							}
							count2++;
						});
						
						count2 = 0;
						jQcustom(this).find('textarea').each(function() {
							if(count2 < 4){
								name = jQcustom(this).attr('name');
								if(name.indexOf("selector_js") != -1){
									var textarea_value = encodeURIComponent(this.value);
									if(name.includes("selector_js") == true){
										textarea_value = btoa(this.value);
									}
									preview_var[count].push(textarea_value);
								}
								if(name.indexOf("name") != -1){
									preview_var[count].push(encodeURIComponent(this.value));
								}
								if(name.indexOf("content") != -1){
									preview_var[count].push(encodeURIComponent(this.value));
								}
								if(name.indexOf("selector_css") != -1){
									preview_var[count].push(encodeURIComponent(this.value));
								}
							}
							count2++;
						});
						
						count = 1;
						jQcustom(this).find('ul li').each(function(){
							preview_var.push(new Array());
							jQcustom(this).find('textarea').each(function() {
								name = jQcustom(this).attr('name');
								if(name.indexOf("selector") != -1){
									var textarea_value = encodeURIComponent(this.value);
									if(name.includes("selector_js") == true){
										textarea_value = btoa(this.value);
										preview_var[count].push(textarea_value);
									} else if(name.includes("selector_css") == true){
										textarea_value = encodeURIComponent(this.value);
										preview_var[count].push(textarea_value);
									} else if(name.includes("selector") == true){
										textarea_value = encodeURIComponent(this.value);
										preview_var[count].push(textarea_value);
									}
								}
								if(name.indexOf("name") != -1){
									preview_var[count].push(encodeURIComponent(this.value));
								}
								if(name.indexOf("content") != -1){
									preview_var[count].push(encodeURIComponent(this.value));
								}
							});
							count++;
						});
						
					});
				}
				else {
					var test_css = jQcustom('#testcss').val();
					var google_font = jQcustom('#google_font').val();
					google_font_var.push(encodeURIComponent('https://fonts.googleapis.com/css?family='+google_font));
					css_var.push(encodeURIComponent(test_css));
					count = 0;
					jQcustom('.device-preview').each(function(){
						preview_var.push(new Array());
						var selected_variable = jQcustom(this).parent().parent().attr('id');
						count2 = 0;
						jQcustom('#'+selected_variable).find('textarea').each(function(){
							if(count2 < 1){
								name = jQcustom(this).attr('name');
								if(name.indexOf("selector") != -1){
									preview_var[count].push(encodeURIComponent(this.value));
								}
							}
							count2++;
						});
						
						count2 = 0;
						jQcustom(this).find('textarea').each(function() {
							if(count2 < 4){
								name = jQcustom(this).attr('name');
								if(name.indexOf("selector_js") != -1){
									var textarea_value = encodeURIComponent(this.value);
									if(name.includes("selector_js") == true){
										textarea_value = btoa(this.value);
									}
									preview_var[count].push(textarea_value);
								}
								if(name.indexOf("name") != -1){
									preview_var[count].push(encodeURIComponent(this.value));
								}
								if(name.indexOf("content") != -1){
									preview_var[count].push(encodeURIComponent(this.value));
								}
								if(name.indexOf("selector_css") != -1){
									preview_var[count].push(encodeURIComponent(this.value));
								}
							}
							count2++;
						});
						
						count3 = count+1;
						jQcustom(this).find('ul').find('li').each(function(){
							preview_var.push(new Array());
							jQcustom(this).find('textarea').each(function() {
								name = jQcustom(this).attr('name');
								if(name.indexOf("selector") != -1){
									var textarea_value = encodeURIComponent(this.value);
									if(name.includes("selector_js") == true){
										textarea_value = btoa(this.value);
										preview_var[count3].push(textarea_value);
									} else if(name.includes("selector_css") == true){
										textarea_value = encodeURIComponent(this.value);
										preview_var[count3].push(textarea_value);
									} else if(name.includes("selector") == true){
										textarea_value = encodeURIComponent(this.value);
										preview_var[count3].push(textarea_value);
									}
								}
								if(name.indexOf("name") != -1){
									preview_var[count3].push(encodeURIComponent(this.value));
								}
								if(name.indexOf("content") != -1){
									preview_var[count3].push(encodeURIComponent(this.value));
								}
							});
							count3++;
						});
						
						count++;
						if(count3 > 1){
							count = count3;
						}
					});
				}
				var d = new Date();
				d.setTime(d.getTime() + (1*24*60*60*1000));
				var expires = "expires="; 
				localStorage.setItem('preview_var', JSON.stringify(preview_var));
				document.cookie = "google_font_var="+JSON.stringify(google_font_var)+"; expires="+expires+"; path=/;";
				document.cookie = "css_var="+JSON.stringify(css_var)+"; expires="+expires+"; path=/;";
				let devicePreviewIframe = document.getElementById("devicePreviewIframe");
				let devicePreviewIframeWind = devicePreviewIframe.contentWindow;
				devicePreviewIframeWind.postMessage(JSON.stringify({preview_var:preview_var,google_font_var:google_font_var,css_var:css_var}), "`+process.env.REACT_APP_FRONTEND_BASE_URL+`");
				preview_window = window.open(pathname,"previewWindow", "menubar=0,resizeable=1,scrollbars=1,toolbar=0,status=0");
			});
			
			function open_visi_css()
			{
				var pageSize = util.getPageSize();
				document.getElementById('css_text').value = document.getElementById('testcss').value;
				DOM.setStyle('visi_overlay','height',pageSize.pageHeight+'px');
				DOM.setStyle('visi_overlay','width',pageSize.pageWidth+'px');
				DOM.setStyle('visi_overlay','visibility','visible');
				DOM.setStyle('visi_overlay','display','block');
				DOM.setStyle('visi_css','visibility','visible');
				DOM.setStyle('visi_css','display','block');
				document.getElementById('css_text').focus();
				
			}

			function open_visi_google_font()
			{
				get_google_font();
				var pageSize = util.getPageSize();
				document.getElementById('css_text').value = document.getElementById('testcss').value;
				DOM.setStyle('visi_overlay','height',pageSize.pageHeight+'px');
				DOM.setStyle('visi_overlay','width',pageSize.pageWidth+'px');
				DOM.setStyle('visi_overlay','visibility','visible');
				DOM.setStyle('visi_overlay','display','block');
				DOM.setStyle('visi_css2','visibility','visible');
				DOM.setStyle('visi_css2','display','block');
			}

			Signal.on('visi_css','click',defineCssHandler,this,true);
			Signal.on('visi_css2','click',defineCssHandler2,this,true);
			
			function defineCssHandler(e){
				YAHOO.flobn.register('tooltip', new YAHOO.flobn.widgets.tooltip());
				YAHOO.flobn.get('tooltip').init('.help', 99999999);
				var srcTarget = Signal.getTarget(e);
				switch(srcTarget.getAttribute('rel')){
					case 'savecss':
						var css_text = document.getElementById('css_text').value;
						var google_font_1 = document.getElementById('google_font').value;
						document.getElementById('testcss').value = css_text;
						style = document.getElementById('test_added_css');
						style.innerHTML = css_text;
						jQcustom("#test_added_font").attr("href", 'https://fonts.googleapis.com/css?family='+google_font_1);
					case 'closeWindow':
						DOM.setStyle('visi_css','visibility','hidden');
						DOM.setStyle('visi_css','display','none');
						DOM.setStyle('visi_overlay','visibility','hidden');
						DOM.setStyle('visi_overlay','display','none');                  
						break;
				}   
			}
			function defineCssHandler2(e){
				YAHOO.flobn.register('tooltip', new YAHOO.flobn.widgets.tooltip());
				YAHOO.flobn.get('tooltip').init('.help', 99999999);
				var srcTarget = Signal.getTarget(e);
				switch(srcTarget.getAttribute('rel')){
					case 'savecss2':
						var google_font_1 = document.getElementById('google_font').value;
						jQcustom("#test_added_font").attr("href", 'https://fonts.googleapis.com/css?family='+google_font_1);
					case 'closeWindow':
						DOM.setStyle('visi_css2','visibility','hidden');
						DOM.setStyle('visi_css2','display','none');
						DOM.setStyle('visi_overlay','visibility','hidden');
						DOM.setStyle('visi_overlay','display','none');                  
						break;
				}   
			}
			
			var preview_list = 30;
			var c_list = DOM.get('custom-gfont-list');
			var selected_font_array_list = [];
			var c_selected_list = DOM.get('selected_font_list');
			function onSelectFont(e){
				var target = _target(e);
				exist = jQcustom('#selected_font_list div').filter(function() {
					return jQcustom(this).text() == target.nextSibling.innerHTML;
				});
				if (!exist.length)
					c_selected_list.innerHTML += '<div class="remove_btn_span" style="border-bottom: 1px solid #a1a1a1;font-family: '+target.nextSibling.innerHTML+';text-decoration: none !important;line-height: normal !important;font-weight: normal !important;text-align: left !important;font-size: 100% !important;position: relative;padding-bottom: 8px;padding: 4px;">'+target.nextSibling.innerHTML+'<a href="#" class="remove_btn visi_icon-remove" style="cursor: pointer;text-decoration: none !important;line-height: normal !important;font-weight: normal !important;text-align: left !important;font-size: 100% !important;position: absolute;right: 15px;background: transparent url(/img/test-editor/test-icon-sprite.png) no-repeat scroll -16px 0 !important;display: inline-block !important;vertical-align: middle !important;width: 16px !important;height: 16px !important;margin: 5px 2px !important;float: left;"></a><br></div>';
				c_list.setAttribute('data-font-family', target.nextSibling.innerHTML);
				
				selected_font_array_list.push(target.nextSibling.innerHTML);
				c_list.setAttribute('data-gfont-variants', target.nextSibling.getAttribute('data-font-variants'));
				jQuery.unique(selected_font_array_list);
				
				var x = selected_font_array_list.join('|');
				jQcustom("#google_font").val(x);
				
			} 

			function get_google_font(){
				var fonts = {};
				var onGetFonts = {
					'success' : function(t){
						if (c_list){
							c_list.addEventListener('change', onSelectFont);
							c_list.addEventListener('scroll', onScrollList);
							fonts = YAHOO.lang.JSON.parse(t.responseText);
						}
					},
					'fail': function(t){
					}
				};
				var postData = "key=AIzaSyBhZeVX7Uk3Tp52cxVNIQ1opXbkvvGKd98";
				var request = Con.asyncRequest('GET', 'https://www.googleapis.com/webfonts/v1/webfonts?'+postData, onGetFonts);
			
				var a = jQcustom("#google_font").val();
				var selected_font_array = a.split('|');
				var postData = "key=AIzaSyBhZeVX7Uk3Tp52cxVNIQ1opXbkvvGKd98";
				jQcustom.getJSON("https://www.googleapis.com/webfonts/v1/webfonts?"+postData, function(fonts){

					if (fonts.items && fonts.items.length)
					{
						var list_end = false;
						var count = 30;
						var c_letter = jQcustom('[name=gfont_display]:checked')[0].value;
						var font_sels = {
							'serif'         : DOM.get('gfont_serif').checked,
							'sans-serif'    : DOM.get('gfont_sans_serif').checked,
							'handwriting'   : DOM.get('gfont_script').checked,
							'monospace'     : DOM.get('gfont_mono').checked,
							'display'       : DOM.get('gfont_display').checked
						};
						var i = 0, c_counter = 0;
						for (i = 0; !list_end && i<fonts.items.length && c_counter<=count; i++){
							var c_font = fonts.items[i];
							if (!font_sels[c_font.category]) continue;
							if (c_letter != "0" && c_font.family[0] != c_letter) continue;
							addPreviewFont(c_font.family);
							var t = Util.createDOM('div');
							var font_variants_array = c_font.variants;
							var font_variants = font_variants_array.join();
							t.innerHTML =
								'<input type="radio" name="gfont-family-sel" style="text-decoration: none !important;line-height: normal !important;font-weight: normal !important;text-align: left !important;font-size: 100% !important;display: none;"/><label style="border-bottom: 1px solid #a1a1a1;text-decoration: none !important;line-height: normal !important;font-weight: normal !important;text-align: left !important;font-size: 100% !important;display: block;padding: 4px;background: white;height: auto;color: black;width:352px;" data-index="'+i+'" data-font-variants="'+font_variants+'">'+c_font.family+'</label>';
							t.firstChild.id = 'gfont-rad-'+i;
							t.firstChild.nextSibling.style.fontFamily = "'"+c_font.family+"'";
							t.firstChild.nextSibling.setAttribute('for', t.firstChild.id);
							c_list.appendChild(t.firstChild);
							c_list.appendChild(t.firstChild);
							c_counter++;
						}
						last_index = i;
					}   


					for (var i = 0; i < fonts.items.length; i++) {
					var font_selected = '';      
				}
				});
				DOM.get('gfont_letter').addEventListener('change', onRefreshDefault);   
				DOM.get('gfont_faces').addEventListener('change', onRefreshDefault);
			} 

			function onRefreshDefault(e){
				refreshFonts(0, preview_list, true);
			}
			
			function onScrollList(e){
				if (c_list.scrollTop+c_list.getBoundingClientRect().height >= c_list.scrollHeight ){
					refreshFonts(last_index, preview_list);
				}
				e.stopPropagation();
			}
			function get_available_fonts(){
					var Fonts = [];
					var c_rules = Current_Styles.cssRules || Current_Styles.rules;
					for (var i = 0; i < c_rules.length; i++){
						var c_rule = c_rules[i];
						if (c_rule.type==5){
							Fonts.push( c_rule.style['font-family'] );                  
						}
					}
					return Fonts;
			}
			function refreshFonts(start,count,reset)
			{
				if (reset) {
					c_list.innerHTML = "";
					c_list.removeAttribute('data-font-family');
					c_list.removeAttribute('data-gfont-variants');
					last_index = 0;                 
				}
				var font_sels = {
					'serif'         : DOM.get('gfont_serif').checked,
					'sans-serif'    : DOM.get('gfont_sans_serif').checked,
					'handwriting'   : DOM.get('gfont_script').checked,
					'monospace'     : DOM.get('gfont_mono').checked,
					'display'       : DOM.get('gfont_display').checked
				};
				var list_end = false;
											var count = 30;
				var c_letter = jQcustom('[name=gfont_display]:checked')[0].value;
				if (fonts.items && fonts.items.length){
							var i = start, c_counter = 0;
							for (i = start; !list_end && i<fonts.items.length && c_counter<=count; i++){
								var c_font = fonts.items[i];
								if (!font_sels[c_font.category]) continue;
								if (c_letter != "0" && c_font.family[0] != c_letter) continue;
								addPreviewFont(c_font.family);
								var t = Util.createDOM('div');
								var font_variants_array = c_font.variants;
								var font_variants = font_variants_array.join();
								t.innerHTML =
									'<input type="radio" name="gfont-family-sel" style="text-decoration: none !important;line-height: normal !important;font-weight: normal !important;text-align: left !important;font-size: 100% !important;display: none;"/><label style="border-bottom: 1px solid #a1a1a1;text-decoration: none !important;line-height: normal !important;font-weight: normal !important;text-align: left !important;font-size: 100% !important;display: block;padding: 4px;background: white;height: auto;color: black;width:352px;" data-index="'+i+'" data-font-variants="'+font_variants+'">'+c_font.family+'</label>';
								t.firstChild.id = 'gfont-rad-'+i;
								t.firstChild.nextSibling.style.fontFamily = "'"+c_font.family+"'";
								t.firstChild.nextSibling.setAttribute('for', t.firstChild.id);
								c_list.appendChild(t.firstChild);
								c_list.appendChild(t.firstChild);
								c_counter++;
							}
							last_index = i;
						}
			}
			function _target(event){
				if (event.target.nodeName.toLowerCase() == "#text")
					return event.target.parentNode;
				return event.target;
			}
			function addPreviewFont(font){
				Util = YAHOO.flobn.util;
				var c_link = DOM.get('preview-font-link');
				if (!c_link){
					c_link = Util.createDOM('link');
					c_link.id = 'preview-font-link';
					c_link.rel = 'stylesheet';
					c_link.type = 'text/css';
					c_link.href = "https://fonts.googleapis.com/css?family=default|";
					c_list.parentNode.appendChild(c_link);
				}
				else 
					c_link.href += '|';
					c_link.href+=font.replace(/ /g,'+');
			}
			jQcustom(document).ready(function(){
				var gfont = jQcustom("#google_font").val();
				var c_selected_list = DOM.get('selected_font_list');
				if(gfont != ''){
					selected_font_array_list = gfont.split('|');
					var gfont_arr = gfont.split('|');
					var i;
					for (i = 0; i < gfont_arr.length; ++i) {
						c_selected_list.innerHTML += '<div class="remove_btn_span" style="border-bottom: 1px solid #a1a1a1;font-family: '+gfont_arr[i]+';text-decoration: none !important;line-height: normal !important;font-weight: normal !important;text-align: left !important;font-size: 100% !important;position: relative;padding-bottom: 8px;padding: 4px;">'+gfont_arr[i]+'<a href="#" class="remove_btn visi_icon-remove" style="cursor: pointer;text-decoration: none !important;line-height: normal !important;font-weight: normal !important;text-align: left !important;font-size: 100% !important;position: absolute;right: 15px;background: transparent url(/img/test-editor/test-icon-sprite.png) no-repeat scroll -16px 0 !important;display: inline-block !important;vertical-align: middle !important;width: 16px !important;height: 16px !important;margin: 5px 2px !important;float: left;"></a><br></div>';
					}   
				}
			});
			var fonts;
			jQcustom.getJSON('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyBhZeVX7Uk3Tp52cxVNIQ1opXbkvvGKd98',function(Font){
				fonts = Font;   
			});   
			jQcustom(document).on('click', '.remove_btn', function(e) {

				var removeItem = jQcustom(this).closest('.remove_btn_span').text();
				selected_font_array_list = jQcustom.grep(selected_font_array_list, function(value) {
					return value != removeItem;
				});
			   jQcustom(this).closest('.remove_btn_span').remove();
			   var x = selected_font_array_list.join('|');
			   jQcustom("#google_font").val(x);
			   e.preventDefault();
			   return false;
			});

			function open_visi_addbaseurl()
			{
				var pageSize = util.getPageSize();
				DOM.setStyle('visi_overlay','height',pageSize.pageHeight+'px');
				DOM.setStyle('visi_overlay','width',pageSize.pageWidth+'px');
				DOM.setStyle('visi_overlay','visibility','visible');
				DOM.setStyle('visi_overlay','display','block');
				DOM.setStyle('visi_addbaseurl','visibility','visible');
				DOM.setStyle('visi_addbaseurl','display','block');
				
				jQcustom("#visi_baseurl").focus();
			}
			
			Signal.on('visi_addbaseurl','click',addbaseurlHandler,this,true);
			function addbaseurlHandler(e){
				YAHOO.flobn.register('tooltip', new YAHOO.flobn.widgets.tooltip());
				YAHOO.flobn.get('tooltip').init('.help', 99999999);
				var srcTarget = Signal.getTarget(e);
				switch(srcTarget.getAttribute('rel')){
					/*case 'save':
						var get_baseurl = jQcustom("#visi_baseurl").val();
						var encode_url = window.btoa(get_baseurl.trim());  
						var pathname = jQcustom("#new_site_ssl").val();
						var page_id = jQcustom("#page_id").val();
							postData = 'act=visual-add_assetsurl&assets_url='+encode_url+'&page_id='+page_id;
							callback={
								success:function(o){
									var json = YAHOO.lang.JSON.parse(o.responseText);
									if (json.failure) {
										alert(json.error);
									}
									else {  
										location.reload(true);
									}
								},
								failure:function(){
									alert('An error ocured!');
								}       
							};
							var reguest = Con.asyncRequest('POST',pathname+'index_ajax.php',callback,postData);*/
					case 'closeWindow':
						DOM.setStyle('visi_addbaseurl','visibility','hidden');
						DOM.setStyle('visi_addbaseurl','display','none');
						DOM.setStyle('visi_overlay','visibility','hidden');
						DOM.setStyle('visi_overlay','display','none');                  
						break;
				}   
			}

			Jodit.defaultOptions.controls.Hide = {
				iconURL: '/img/test-editor/hide-icon.png',
				exec: function (editor) {
					var selectedContent = '';
					if(editor.selection.current().tagName == 'IMG'){
						selectedContent = editor.selection.current();
					} else if((editor.value.indexOf('<img') != -1) && (editor.selection.sel.focusNode.parentNode.tagName == 'HTML' || editor.selection.sel.focusNode.parentNode.tagName == 'BODY')){
						selectedContent = editor.editor.querySelector("img");
					} else if((editor.value.indexOf('<iframe') != -1) && (editor.selection.sel.focusNode.parentNode.tagName == 'HTML' || editor.selection.sel.focusNode.parentNode.tagName == 'BODY')){
						selectedContent = editor.editor.querySelector("iframe");
					} else {
						selectedContent = editor.selection.sel.focusNode.parentNode;
					}
					selectedContent.style.display = "none";
				}
			};

			Jodit.defaultOptions.controls.Show = {
				iconURL: '/img/test-editor/show-icon.png',
				exec: function (editor) {
					var selectContent = editor.value;
					editor.value = selectContent.replace(/display: none;/g, '');
				}
			};


			Jodit.defaultOptions.controls.Background_Color = {
				iconURL: "/img/test-editor/icon.jpg",
				tooltip: "Select Background Color",
				popup: function (editor) {
					var editor_content = editor.value;
					var tmp2 = document.createElement('div');
						tmp2.innerHTML = editor_content;
					document.getElementById('visiChangeBg').style.display = 'block';
					document.getElementById('visiChangeBg').style.visibility = 'visible';
					document.getElementById('visi_defineVersion').style.display = 'none';
					document.getElementById('visi_defineVersion').style.visibility = 'hidden';
					var CSSprop_background_color = tmp2.firstChild.style.backgroundColor;
					var CSSprop_background_image = tmp2.firstChild.style.backgroundImage;
					var CSSprop_background_repeat = tmp2.firstChild.style.backgroundRepeat;
					var CSSprop_background_position = tmp2.firstChild.style.backgroundPosition;
					var CSSprop_background_attachment = tmp2.firstChild.style.backgroundAttachment;
					
					if(CSSprop_background_color == ''){
						CSSprop_background_color = 'transparent';
					}
					if(CSSprop_background_image == ''){
						CSSprop_background_image = 'none';
					}
					if(CSSprop_background_repeat == ''){
						CSSprop_background_repeat = 'repeat';
					}
					if(CSSprop_background_position == ''){
						CSSprop_background_position = '0% 0%';
					}
					if(CSSprop_background_attachment == ''){
						CSSprop_background_attachment = 'scroll';
					}
					document.getElementById('background_color').value = CSSprop_background_color;
					document.getElementById('background_color').style.backgroundColor = CSSprop_background_color;
					if(CSSprop_background_image != '' && CSSprop_background_image.indexOf("url(") >=0){
						CSSprop_background_image = CSSprop_background_image.replace("url('", "");
						CSSprop_background_image = CSSprop_background_image.replace('url("', "");
						CSSprop_background_image = CSSprop_background_image.replace("')", "");
						CSSprop_background_image = CSSprop_background_image.replace('")', "");
						document.getElementById('background_image').value = CSSprop_background_image;
					}else{
						document.getElementById('background_image').value = 'none';
					}
					if(CSSprop_background_repeat != ''){                         
						jQcustom('#background_repeat').val(CSSprop_background_repeat);
					}
					document.getElementById('background_position').value = CSSprop_background_position;
					jQcustom('#background_attachment').val(CSSprop_background_attachment);
					jQcustom("#background_color").ColorPicker({
						onBeforeShow: function(){
							jQcustom("#background_color").ColorPickerSetColor(document.getElementById('background_color').value);
							document.getElementById('background_color').style.backgroundColor = document.getElementById('background_color').value;
						},
						onChange: function (hsb, hex, rgb, alpha) {
							var color = '#'+hex;
							document.getElementById('background_color').value = color;
							document.getElementById('background_color').style.backgroundColor = color;
						},
						onClose: function(){
							jQcustom("#background_color").ColorPickerSetColor('');
							document.getElementById('background_color').style.backgroundColor = 'none';
						}
					});
								
				}
			};
	  `}
	  </script>
	  : ''}
	  </Helmet>
	  <link rel="stylesheet" id="test_added_font" type="text/css" />
	  <style type="text/css" id="test_added_css"></style>
	  <input type="hidden" name ="new_site_ssl" id="new_site_ssl" defaultValue="https://visioptdev.com/client/" />
	  <form action="index.php" method="post" id="test_editor_form" className="flexed">
		<input type="hidden" name="taguchi_type_id" id="taguchi_type_id" defaultValue={taguchi_type_id}/>
		<input type="hidden" name="page_id" defaultValue={pageid} id="page_id"/>
		<input type="hidden" defaultValue={pageURL} id="page_url"/>
		<input type="hidden" name="is_edit" defaultValue={isEdit} id="is_edit"/>

		<input type="hidden" defaultValue={PageIdDecoded} id="page_id_dp"/>
		<input type="hidden" defaultValue={FrontEndFrameWork} id="front_end_framework_dp"/>
		<input type="hidden" defaultValue={assetsURL} id="test_assets_url_dp"/>
		<input type="hidden" defaultValue={TaguchiTypeIdDecoded} id="taguchi_type_id_dp"/>
		<input type="hidden" defaultValue={Page} id="valid_page_dp"/>
		<input type="hidden" defaultValue={process.env.REACT_APP_BACKEND_API_PATH} id="react_app_backend_api_path"/>
		<input type="hidden" defaultValue={websiteId} id="website_id"/>
		<input type="hidden" defaultValue={memberId} id="member_id"/>

		<iframe src={devicePreviewIframeSrc} id="devicePreviewIframe" style={{display: "none" }}></iframe>
		<div className="test-top">
		  <div className="selection-block" id="live-selection" style={{height: "100vh",borderRadius: "10px",border: "1px solid #e7e7e7"}}>
			<div className="selection-site" style={{borderRadius: "10px" }}>
			  <iframe width="100%" height="100%" title="My Iframe" border="0" id="vis" onLoad={handleIframeLoad} srcDoc={iframeContent}/>
			</div>
		  </div>
		  <div id="visi-variables-sidebar" style={{height: "100vh"}} className="visi-variables-sidebar visi-right-click-pop">
			<div className="variable-block" id="variables">
			  <div className="visi_section-title">
				  <h2 id="variable_section" className="visi-tab_section tab_section active_tab">
					Variables (Max. {maxVariables})
					<span className="help small-red-help" rel={18} ref={variablesIconRef} onClick={() => handleHelpClick(18)}/>
				  </h2>
				  <h2  id="button_section" className="visi-tab_section tab_section">
					Buttons
					<span className="help small-red-help" rel={336} ref={buttonsIconRef} onClick={() => handleHelpClick(336)}/>
				  </h2>
			  </div>
			  <div className="variable-holder">
				<ul className="tree variable-tree">
					{testEditorData.map((item, index) => (
						<li key={index} className="variation_li" id={`v-${item.variable_data[0].variable}`}>
							<span>{item.variable_title}<small>{item.edit_var_limits}</small></span>
							<span className="actions">
								<a href="#" rel="addVersion" className="add icon-add"> </a>
								<a href="#" rel="removeVar" className="remove icon-remove"> </a>
								<a href="#" rel="toggle" className="toggle unactive"> </a>
							</span>

							<textarea name={`v[${item.variable_data[0].variable}][selector]`} id={`v_${item.variable_data[0].variable}`} className="var_selector" defaultValue= {item.variable_data[0].selector}/>
							<textarea name={`v[${item.variable_data[0].variable}][name]`} id={`v_name_${item.variable_data[0].variable}`} className="var_selector" defaultValue= {item.variable_title}/>
							<ul>
								{item.variable_data.map((variable, varIndex) => (
									<li key={varIndex} className={variable.version_control_class} id={`${variable.variable}-${variable.version}`}>
										<span>{variable.version_name}</span>
										<span className="actions">
										  {variable.version_control_class !== "" ? (
										    <a href="#" rel="preview" className="preview icon-preview"> </a>
										  ) : (
										    <>
										      <a href="#" rel="addRearrangeVersion" className="visi_child_version visi_icon-plus" style={{display: "inline-block"}}> </a>
										      <a href="#" rel="editVersion" className="add icon-edit"> </a>
										      <a href="#" rel="preview" className="preview icon-preview"> </a>
										      <a href="#" rel="removeVersion" className="remove icon-remove"> </a>
										    </>
										  )}
										</span>
										<textarea name={`v[${variable.variable}][${variable.version}][name]`} id={`v_name_${variable.variable}_${variable.version}`} defaultValue= {variable.version_name}/>
										<textarea name={`v[${variable.variable}][${variable.version}][selector_js]`} id={`v_selector_js_${variable.variable}_${variable.version}`} style={{display: "none"}} defaultValue={variable.selector_js}/>
										<textarea name={`v[${variable.variable}][${variable.version}][selector_css]`} id={`v_selector_css_${variable.variable}_${variable.version}`} style={{display: "none"}} defaultValue={variable.selector_css}/>
										<textarea name={`v[${variable.variable}][${variable.version}][content]`} id={`v_${variable.variable}_${variable.version}`} defaultValue={variable.version_content}/>
										<ul className="version_rearrange">
											{variable.rearrange_data.map((rearrange, reIndex) => (
												<li key={reIndex} className={`re-${rearrange.variable}${rearrange.rearrange_count}`}>
													<span className="">{rearrange.rearrange_name}</span>
													<span className="actions">
														<a href="#" rel="editRearrangeVersion" className="add icon-edit"> </a>
													</span>
													<textarea name={`vr[${rearrange.variable}][${rearrange.version}][${rearrange.rearrange_count}][selector]`} id={`vr_selector_${rearrange.variable}_${rearrange.version}`} className="rearrange_selector" style={{display: "none"}} defaultValue={rearrange.rearrange_selector}/>
													<textarea name={`vr[${rearrange.variable}][${rearrange.version}][${rearrange.rearrange_count}][original]`} id={`vr_original_${rearrange.variable}_${rearrange.version}`} className="rearrange_original" style={{display: "none"}} defaultValue={rearrange.rearrange_original_content}/>
													<textarea name={`vr[${rearrange.variable}][${rearrange.version}][${rearrange.rearrange_count}][name]`} id={`vr_name_${rearrange.variable}_${rearrange.version}`} style={{display: "none"}} defaultValue= {rearrange.rearrange_name}/>
													<textarea name={`vr[${rearrange.variable}][${rearrange.version}][${rearrange.rearrange_count}][selector_js]`} id={`vr_selector_js_${rearrange.variable}_${rearrange.version}`} style={{display: "none"}} defaultValue={rearrange.rearrange_selector_js}/>
													<textarea name={`vr[${rearrange.variable}][${rearrange.version}][${rearrange.rearrange_count}][selector_css]`} id={`vr_selector_css_${rearrange.variable}_${rearrange.version}`} style={{display: "none"}} defaultValue={rearrange.rearrange_selector_css}/>
													<textarea name={`vr[${rearrange.variable}][${rearrange.version}][${rearrange.rearrange_count}][content]`} id={`vr_${rearrange.variable}_${rearrange.version}`} style={{display: "none"}} defaultValue={rearrange.rearrange_content}/>
												</li>
											))}
										</ul>
									</li>
								))}
							</ul>
						</li>
					))}
				</ul>
			  </div>
			  <div className="visi_button-holder" style={{display: "none"}}>
				<input type="hidden" name="deleted_buttons" id="deleted_buttons"/>
				<ul className="visi_tree visi_button-tree">
					{testButtonsData.map((item, index) => (
						<li className="visi_selected" id={item.tests_buttons_count}>
							<span className="li_span_btn">{item.same_as}{item.button_name}</span>
							<span className="visi_actions" style={{float: "right"}}>
								<a href="#" rel="removebutton" className="visi_remove visi_icon-remove"> </a>
							</span>
							<input name={`b[${item.tests_buttons_count}][parent_id]`} id={`parent_${item.tests_buttons_count}`} type="hidden" value={item.button_parent_id}/>
							<input name={`b[${item.tests_buttons_count}][button_id]`} id={`button_${item.tests_buttons_count}`} type="hidden" value={item.button_id}/>
							<textarea name={`b[${item.tests_buttons_count}][selector]`} id={`selector_${item.tests_buttons_count}`} style={{display: "none"}} defaultValue={item.button_selector}/>
							<textarea name={`b[${item.tests_buttons_count}][name]`} id={`name_${item.tests_buttons_count}`} style={{display: "none" }} defaultValue={item.button_name}/>
						</li>
					))}
				</ul>
			  </div>
			  <textarea style={{display: "none"}} name="testcss" id="testcss" value={testCssShow}/>
			  <input type="hidden" name="google_font" id="google_font" value={googleFonts}/>
			  <div className="preview_checkbox">
				<div rel="devicechange" className="test-pc-active" id="test-pc" title="Desktop" onClick={window['testpc']}/>
				<div rel="devicechange" className="test-mobile" id="test-mobile" title="Mobile" onClick={window['testmobile']}/>
				<div className="test-settings" id="test-settings" title="Settings" onClick={window['testsettings']}/>
				<div className="test-settings_checkbox" style={{display: "none"}}>
				  <ul className="test-settings-sub-menu">
					<li>
					  <a id="test_preview">Device Preview</a>
					</li>
					<li>
					  <a onClick={window['open_visi_google_font']}>Google Fonts</a>
					</li>
					<li>
					  <a rel="addCss" onClick={window['open_visi_css']}>Add Global CSS</a>
					</li>
					<li>
					  <a rel="style-image-load" onClick={window['open_visi_addbaseurl']}>Style/Image Not Loaded</a>
					</li>
					<li>
					  <a rel="targetDimensions" onClick={() => handleBehavioralFilterOpenModal(1)}>Target Test BY Dimensions</a>
					</li>
					{cacheClearButton === 1 && 
						<li>
						  <a onClick={cacheClear}>Clear Cache</a>
						</li>
					}
				  </ul>
				</div>
			  </div>
			  <div className="visi_float-right">
				<label className="vert-middle funnel-label-name">Funnel:</label>
				<select name="visi_test_funnel" id="visi_test_funnel" className="visi-select vert-middle funnel-dd-name" value={selectedFunnelName} onChange={e => setSelectedFunnelName(e.target.value)}>
					{FunnelDropdownData.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
				</select>
			  </div>
			  <div className="visi_float-right">
				<label className="vert-middle test-label-name">Test Name:</label>
				<input className="visi-select vert-middle test-dd-name" id="testName" type="text" maxLength={200} name="testName" value={testName}
          onChange={e => setTestName(e.target.value)} placeholder="" style={{cursor: "text"}}/>
			  </div>
			  <div className="visi_float-right vert-middle">
				<a className="visi_small-btn visi_red-btn test_cancle" onClick={handleCancel}>Cancel</a>
				{activeTest !== "1" && (
					<button type="button" 
						className="visi_small-btn" 
						id="visi_save_draft" 
						onClick={() => {
							const formData = new FormData(document.getElementById('test_editor_form'));
	    				const formValues = Object.fromEntries(formData.entries());
			        handleSaveDraftTestSetup(formValues);
						}}
					>
						Save Draft
					</button>
				)}
				<button type="button" 
					className="visi_small-btn" 
					id="visi_continue" 
					onClick={() => {
			        const formData = new FormData(document.getElementById('test_editor_form'));
      				const formValues = Object.fromEntries(formData.entries());
			        handleSaveTestSetup(formValues);
			    }}
			  >
					Save Test
				</button>
			  </div>
			</div>
		  </div>
		</div>
	  </form>
	  <div id="visi-right-click" className="visi-right-click visi-right-click-pop">
		<a href="#" className="visi-r-close visi-right-click-pop" rel="close"></a>
		<ul className="visi-right-click-pop">
		  <li className="visi-right-click-pop">
			<a href="#" className="visi-r-add visi-right-click-pop" rel="define">Select Variable</a>
		  </li>
		  <li className="visi-right-click-pop">
			<a href="#" className="visi-r-add visi-right-click-pop" rel="defineButton">Track Button</a>
		  </li>
		  <center className="visi-variables-selector-popup"></center>
		  <li className="visi-r-navigation visi-right-click-pop">Navigation:</li>
		  <li className="visi-right-click-pop">
			<a href="#" className="visi-r-parent visi-right-click-pop">Select Parent</a>
		  </li>
		</ul>
	  </div>
	  <div id="overlay" style={{ visibility: "hidden", display: "none" }} />
		<div id="visi_overlay" style={{backgroundImage:  "url(/img/test-editor/overlay.png)",position: "fixed",height: "100%",width: "100%",left: "0px",top: "0px",zIndex: "9999999",backgroundColor: "transparent",outline: "0",visibility: "visible",display: "none"}}>
		  <div className="visi_container" id="visi_defineVar" style={{display:"none",left: "35% !important"}}>
			<div className="visi_section-title">
			  <h3>DEFINE VARIABLE</h3>
			  <span className="help small-red-help" rel={153}>&nbsp;</span>
			  <button type="button" className="close icon-close" rel="closeWindow"></button>
			</div>        
			<div className="visi_content-holder visi-right-click-pop">
			  <fieldset>
				<div className="visi_line">
				  <label htmlFor="var" className="req">Please enter a descriptive name for the variable:</label>
				  <br/>
				  <input type="text" name="var" id="var" className="visi-select" style={{marginLeft:"0px",cursor: "text"}}/> 
				</div>
				<div className="buttons">
				  <button className="visi_small-btn visi_blue-btn visi_float-right" rel="save" type="button" id="var_save">Save</button>
				</div>
			  </fieldset>
			</div>
		  </div>
		  <div className="container" id="visiChangeBg" style={{display: "none",width: "500px",left: "50%",marginLeft: "-250px"}}>
			<div className="visi_section-title">
			  <h3>CHANGE BACKGROUND</h3>
			  <span className="help small-red-help" rel={155}>&nbsp;</span>
			  <button type="button" className="close icon-close" rel="closeWindow"></button>
			</div>
			<div className="content-holder">
			  <fieldset>
				<div className="visi_line">
				  <label htmlFor="ver" className="req">background-color:</label>
				  <input type="text" name="background_color" id="background_color" className="visi-select" defaultValue="transparent" style={{ backgroundColor: "none" }} placeholder="transparent"/>
				</div>
				<div className="visi_line">
				  <label htmlFor="ver" className="req">background-image:</label>
				  <input type="text" name="background_image" id="background_image" className="visi-select" defaultValue="none" placeholder="none"/>
				</div>
				<div className="visi_line">
				  <label htmlFor="ver" className="req">background-repeat:</label>
				  <select name="background_repeat" id="background_repeat" className="visi-select">
					<option value="repeat" selected="">repeat</option>
					<option value="repeat-x">repeat-x</option>
					<option value="repeat-y">repeat-y</option>
					<option value="no-repeat">no-repeat</option>
					<option value="space">space</option>
					<option value="round">round</option>
					<option value="initial">initial</option>
				  </select>
				</div>
				<div className="visi_line">
				  <label htmlFor="ver" className="req"> background-position:</label>
				  <input type="text" name="background_position" id="background_position" className="visi-select" defaultValue="0% 0%" placeholder="0% 0%"/>
				</div>
				<div className="visi_line">
				  <label htmlFor="ver" className="req">background-attachment:</label>
				  <select name="background_attachment" id="background_attachment" className="visi-select">
					<option value="scroll" selected="">scroll</option>
					<option value="fixed">fixed</option>
					<option value="local">local</option>
					<option value="initial">initial</option>
					<option value="inherit">inherit</option>
				  </select>
				</div>
				<div className="buttons">
				  <button className="small-btn blue-btn float-right" rel="save" type="button">Save</button>
				</div>
			  </fieldset>
			</div>
		  </div>
		  <div className="visi_container" id="visi_defineVersion" style={{display:"none"}}>
			<div className="visi_section-title">
			  <h3>DEFINE VERSION</h3>
			  <span className="help small-red-help" rel={158}>&nbsp;</span>
			  <button type="button" className="close icon-close" rel="closeWindow"></button>
			</div>
			<div className="sub-menu-holder sub-menu-holder-custom">
			  <ul className="sub-menu-tab windowtabs windowtabs-custom variable_tab_menu">
				<li className="active">
				  <a href="#" id="variable_content" onClick={(e) => window.variable_tab_toggle(e.target)}>Variable Content</a>
				</li>
				<li>
				  <a href="#" id="variable_scripts" onClick={(e) => window.variable_tab_toggle(e.target)}>Variable Scripts</a>
				</li>
				<li>
				  <a href="#" id="variable_css" onClick={(e) => window.variable_tab_toggle(e.target)}>Variable CSS</a>
				</li>
			  </ul>
			</div>
			<div className="visi_content-holder visi-right-click-pop">
			  <fieldset>
				<div className="visi_line">
				  <label htmlFor="ver" className="req">Version Name:</label>
				  <input type="text" name="ver" id="ver" className="visi-select" style={{cursor: "text"}}/>
				</div>
				<div className="visi_line" id="version_content_maindiv">
				  <textarea name="version_text" id="version_text" className="visi-select"/>
				</div>
				<div id="version_js_maindiv" style={{ display: "none" }}>
				  <div className="visi_line" id="version_js_div">
					<div style={{ color: "#e85656", marginBottom: 10 }}>
					  <b>NOTE:</b> Use only scripts that work after page load. Remove
					  &lt;script&gt; tags when adding a script! <br /> To see scripts
					  working you must use the device preview tool. You will not see
					  scripts working in the editor or test preview options.
					</div>
					<label htmlFor="ver" className="req">Version js:</label>
					<textarea name="version_js" id="version_js" className="visi-select"/>
				  </div>
				</div>
				<div id="version_css_maindiv" style={{ display: "none" }}>
				  <div className="visi_line" id="version_css_div">
					<div style={{ color: "#e85656", marginBottom: 10 }}>
					  <b>NOTE:</b> Use only CSS that work after page load. Remove
					  &lt;style&gt; tags when adding a CSS! <br /> To see CSS working
					  you must use the device preview tool. You will not see CSS working
					  in the editor or test preview options.
					</div>
					<label htmlFor="ver" className="req">Variable CSS:</label>
					<textarea name="version_css" id="version_css" className="visi-select" style={{cursor: "text"}}/>
				  </div>
				</div>
			  </fieldset>
			  <fieldset>
				<div className="buttons">
				  <button className="visi_small-btn visi_blue-btn visi_float-right" rel="save" type="button">Save</button>
				</div>
			  </fieldset>
			</div>
		  </div>
		  <div className="visi_container test_add_track_button_modal" id="visi_defineBtn" style={{display:"none"}}>
			<div className="visi_section-title">
			  <h3>DEFINE BUTTON</h3>
			  <span className="help small-red-help" rel={154}>&nbsp;</span>
			  <button type="button" className="close icon-close" rel="closeWindow"></button>
			</div>
			<div className="visi_content-holder visi-right-click-pop"  style={{marginTop:"14px"}}>
			  <fieldset>
				<div className="visi_line">
				  <input type="radio" name="visi_btn_type" id="new" defaultValue="new" defaultChecked="checked" className="visi_btn_type"/>
				  <label htmlFor="new" className="req" style={{fontSize:"14px"}}>New Button</label>
				  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				  <input type="radio" name="visi_btn_type" id="existing" defaultValue="existing" className="visi_btn_type"/>
				  <label htmlFor="existing" className="req" style={{fontSize:"14px"}}>Existing Button</label>
				  <br/>
				  <br/>
				  <input type="text" name="btn_label" id="btn_label" className="visi-select"/>
				  <select name="btn_id" id="btn_id" className="visi-select" style={{display:"none"}}>
					<option defaultValue="">Select Existing Button</option>
				  </select>
				</div>
				<div className="buttons">
				  <button className="visi_small-btn visi_blue-btn visi_float-right" rel="save" type="button" id="btn_save">Save</button>
				</div>
			  </fieldset>
			</div>
		  </div>
		  <div className="visi_container" id="visi_css" style={{display:"none"}}>
			<div className="visi_section-title">
			  <h3>Global CSS</h3>
			  <span className="help small-red-help" rel={156} ref={globalCssIconRef} onClick={() => handleHelpClick(156)}>&nbsp;</span>
			  <button type="button" className="close icon-close" rel="closeWindow"></button>
			</div>
			<div className="visi_content-holder visi-right-click-pop">
			  <fieldset>
				<label htmlFor="ver" className="req">CSS:</label>
				<div className="visi_line">
				  <textarea name="css_text" id="css_text" className="visi-select" value={testCssShow} onChange={(e) => setTestCssShow(e.target.value)} style={{cursor: "text"}}/>
				</div>
				<div className="buttons">
				  <button className="visi_small-btn visi_blue-btn visi_float-right" rel="savecss" id="save_css" onClick={saveTestCss} type="button">Save</button>
				</div>
			  </fieldset>
			</div>
		  </div>
		  <div className="visi_container" id="visi_css2" style={{display:"none"}}>
			<div className="visi_section-title">
			  <h3>TEST GOOGLE FONTS</h3>
			  <span className="help small-red-help" rel={157} ref={googleFontIconRef} onClick={() => handleHelpClick(157)}>&nbsp;</span>
			  <button type="button" className="close icon-close" rel="closeWindow"></button>
			</div>
			<div className="visi_content-holder visi-right-click-pop">
			  <fieldset>
				<div id="gfont_faces">
				  <span>Select Google Font:</span>
				  <input type="checkbox" id="gfont_serif" defaultChecked="checked"/>
				  <label htmlFor="gfont_serif"> Serif </label>
				  <input type="checkbox" id="gfont_sans_serif" defaultChecked="checked"/> <label> Sans Serif </label>
				  <input type="checkbox" id="gfont_script" defaultChecked="checked"/> <label htmlFor="gfont_script"> Script </label>
				  <input type="checkbox" id="gfont_mono" defaultChecked="checked"/> <label htmlFor="gfont_mono"> Monospace </label>
				  <input type="checkbox" id="gfont_display" defaultChecked="checked"/> <label htmlFor="gfont_display"> Display </label>
				  <br/>
				  <br/>
				</div>
				<div id="gfont_letter" className="button-group">
				<input type="radio" defaultValue={0} name="gfont_display" id="gfont_all" defaultChecked="checked"/> 
				<label htmlFor="gfont_all" className="radio gfont_all_label">ALL</label>
				<input type="radio" defaultValue="A" name="gfont_display" id="gfont_a"/>
				<label htmlFor="gfont_a" className="radio">A</label>
				<input type="radio" defaultValue="B" name="gfont_display" id="gfont_b"/>
				<label htmlFor="gfont_b" className="radio">B</label>
				<input type="radio" defaultValue="C" name="gfont_display" id="gfont_c"/>
				<label htmlFor="gfont_c" className="radio">C</label>
				<input type="radio" defaultValue="D" name="gfont_display" id="gfont_d"/>
				<label htmlFor="gfont_d" className="radio">D</label>
				<input type="radio" defaultValue="E" name="gfont_display" id="gfont_e"/>
				<label htmlFor="gfont_e" className="radio">E</label>
				<input type="radio" defaultValue="F" name="gfont_display" id="gfont_f"/>
				<label htmlFor="gfont_f" className="radio">F</label>
				<input type="radio" defaultValue="G" name="gfont_display" id="gfont_g"/>
				<label htmlFor="gfont_g" className="radio">G</label>
				<input type="radio" defaultValue="H" name="gfont_display" id="gfont_h"/>
				<label htmlFor="gfont_h" className="radio">H</label>
				<input type="radio" defaultValue="I" name="gfont_display" id="gfont_i"/>
				<label htmlFor="gfont_i" className="radio">I</label>
				<input type="radio" defaultValue="J" name="gfont_display" id="gfont_j"/>
				<label htmlFor="gfont_j" className="radio">J</label>
				<input type="radio" defaultValue="K" name="gfont_display" id="gfont_k"/>
				<label htmlFor="gfont_k" className="radio">K</label>
				<input type="radio" defaultValue="L" name="gfont_display" id="gfont_l"/>
				<label htmlFor="gfont_l" className="radio">L</label>
				<input type="radio" defaultValue="M" name="gfont_display" id="gfont_m"/>
				<label htmlFor="gfont_m" className="radio">M</label>
				<input type="radio" defaultValue="N" name="gfont_display" id="gfont_n"/>
				<label htmlFor="gfont_n" className="radio">N</label>
				<input type="radio" defaultValue="O" name="gfont_display" id="gfont_o"/>
				<label htmlFor="gfont_o" className="radio">O</label>
				<input type="radio" defaultValue="P" name="gfont_display" id="gfont_p"/>
				<label htmlFor="gfont_p" className="radio">P</label>
				<input type="radio" defaultValue="Q" name="gfont_display" id="gfont_q"/>
				<label htmlFor="gfont_q" className="radio">Q</label>
				<input type="radio" defaultValue="R" name="gfont_display" id="gfont_r"/>
				<label htmlFor="gfont_r" className="radio">R</label>
				<input type="radio" defaultValue="S" name="gfont_display" id="gfont_s"/>
				<label htmlFor="gfont_s" className="radio">S</label>
				<input type="radio" defaultValue="T" name="gfont_display" id="gfont_t"/>
				<label htmlFor="gfont_t" className="radio">T</label>
				<input type="radio" defaultValue="U" name="gfont_display" id="gfont_u"/>
				<label htmlFor="gfont_u" className="radio">U</label>
				<input type="radio" defaultValue="V" name="gfont_display" id="gfont_v"/>
				<label htmlFor="gfont_v" className="radio">V</label>
				<input type="radio" defaultValue="W" name="gfont_display" id="gfont_w"/>
				<label htmlFor="gfont_w" className="radio">W</label>
				<input type="radio" defaultValue="X" name="gfont_display" id="gfont_x"/>
				<label htmlFor="gfont_x" className="radio">X</label>
				<input type="radio" defaultValue="Y" name="gfont_display" id="gfont_y"/>
				<label htmlFor="gfont_y" className="radio">Y</label>
				<input type="radio" defaultValue="Z" name="gfont_display" id="gfont_z"/>
				<label htmlFor="gfont_z" className="radio">Z</label>
				</div>
				<div>
				  <div style={{width: "50%",float: "left",paddingTop: "15px"}}>
					<span>Available Font to Select</span>
				  </div>
				  <div style={{width: "50%",float: "right",paddingTop: "15px"}}>
					<span>Selected Font</span>
				  </div>
				</div>
				<div>
				  <div style={{width: "50%",float: "left",marginTop: 15}}>
					<div id="custom-gfont-list" className="gfont-items"/>
				  </div>
				  <div id="selected_font">
					<div id="selected_font_list"/>
				  </div>
				</div>
				<div className="visi_line" style={{width: "100%", float: "left"}}/>
				<div className="buttons">
				  <button className="visi_small-btn visi_blue-btn visi_float-right" rel="savecss2" id="save_css" type="button" >Save</button>
				</div>
			  </fieldset>
			</div>
		  </div>
		  <VisiLoader VisiLoaderVisible={addAssetsURLLoader} />
		  <div className="visi_container" id="visi_addbaseurl" style={{display:"none"}}>
			<div className="visi_section-title">
			  <h3>DEFINE ASSETS URL</h3>
			  <span className="help small-red-help" rel={286} ref={assetsUrlIconRef} onClick={() => handleHelpClick(286)}>&nbsp;</span>
			  <button type="button" className="close icon-close" rel="closeWindow"></button>
			</div>
			<div className="visi_content-holder visi-right-click-pop">
			  <fieldset>
				<div className="visi_line">
				  <span>
					<p style={{marginTop: "1.0rem",marginBottom: "1rem",fontSize:"12px"}}>Note:</p>
					<p style={{marginTop: "1.0rem",marginBottom: "1rem",fontSize:"12px"}}>
					  If you do not see styles showing in the editor than it means that
					  your website assets are located somewhere other than your core
					  domain. Assets include images, css files, etc.
					</p>
					<p style={{marginTop: "1.0rem",marginBottom: "1rem",fontSize:"12px"}}>
					  For example:
					</p>
					<p style={{marginTop: "1.0rem",marginBottom: "1rem",fontSize:"12px"}}>
					  Mysite.com would usually have all assets located at my site.com
					  but if your developer moved them to a file like
					  mysite.com/subfolder/assets thanwe have to direct Visiopt to that
					  folder.
					</p>
					<p style={{marginTop: "1.0rem",marginBottom: "1rem",fontSize:"12px"}}>
					  Enter the location of your site assets below. Check with your web
					  developer first.
					</p>
				  </span>
				  <label htmlFor="visi_baseurl" className="req " style={{marginTop: "1.0rem",marginBottom: "1rem",fontSize:"12px"}}>Please enter Assets Directory Link:</label>
				  <input type="text" name="visi_baseurl" id="visi_baseurl" className="visi-select" value={assetsURL} onChange={(event) => setAssetsURL(event.currentTarget.value)} style={{cursor: "text"}}/>
				</div>
				<div className="buttons">
				  <button onClick={addAssetsURL} className="visi_small-btn visi_blue-btn visi_float-right" rel="save" type="button" id="var_save">Save</button>
				</div>
			  </fieldset>
			</div>
		  </div>
		</div>
	  </div>
	</>
  );
}