import {
  TextInput,
  PasswordInput,
  Checkbox,
  Anchor,
  Paper,
  Text,
  Container,
  Group,
  Button,
  Center
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDocumentTitle } from '@mantine/hooks';
import { useState, useEffect } from 'react';
import { visiUserSession } from '../../GeneralFunction';
import bigLogo from '../../img/big-logo.png';
import { notifications } from '@mantine/notifications';
import {
  IconX
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../AuthContext';
import { visiUserPermission } from '../../GeneralFunction';
import LoginLogoutApi from "../../api/LoginLogoutApi";
export const LogIn = () => {

  useDocumentTitle("Visiopt - Login");
  const navigate = useNavigate();
  const { login, userRolePermissions } = useAuth();

  const [checked, setChecked] = useState(false);

  const handleStaySignedInChange = (event:any) => {
    setChecked(event.currentTarget.checked);
    form.setFieldValue('remember_me',(event.currentTarget.checked)?1:0);
  };

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
      remember_me: 0
    },

    validate: {
      email: (val) => (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val) ? null : 'Invalid email'),
      password: (val) => (val === '' ? 'Invalid Password' : null),
    },
  });

  const handleLoginSubmit = async (formData:any) => {
    try {
      const APIResponse = await LoginLogoutApi.login(formData)
      .then(APIResponse => APIResponse.text())
      .then(APIResult => {
        const APIResultJSON = JSON.parse(APIResult);
        if(APIResultJSON.status === 1){
          console.log();
          if(APIResultJSON.data.next_page === 'dashboard'){
            login();
            localStorage.setItem("visiUserData", btoa(JSON.stringify(APIResultJSON.data)));
            localStorage.removeItem('visiMemberData');
            let userPermissions = [];
            if(visiUserPermission()){
              for(const role in visiUserPermission()){
                userPermissions = visiUserPermission()[role];
              }
            }
            userRolePermissions(userPermissions);
            if(localStorage.getItem('visiUserData') !== null){
              var session = atob(localStorage.getItem('visiUserData'));

              const data = {
               'userSession': session,
               'action': 'set'
              };

              const form = document.createElement('form');
              form.method = 'POST';
              form.action = process.env.REACT_APP_OLD_VISIOPT_BASE_URL+'php-proxy.php'; 
              form.target = 'myIframe';
              for (const key in data) {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                input.value = data[key];
                form.appendChild(input);
              }
              const iframe = document.createElement('iframe');
              iframe.name = 'myIframe'; 
              iframe.style.display = 'none';
              document.body.appendChild(iframe);
              document.body.appendChild(form);
              form.submit();
            }

            if(visiUserSession() && visiUserSession().page){
              navigate(visiUserSession().page);
            }else{
              navigate("/dashboard");
            }
          }else if(APIResultJSON.data.next_page === 'select_member'){
            localStorage.setItem("visiMemberData", btoa(JSON.stringify(APIResultJSON.data)));
            navigate("/select-member");
          }
        }else{
          notifications.show({
            color: 'red',
            icon: <IconX size="1.1rem" />,
            title: 'Error',
            message: APIResultJSON.message,
          })
        }
      })
      .catch(error => console.log('error', error));
    } catch (error) {
      console.log('error', error);
    }
  }

  return (
    <Container size={800} my={40}>
      <Paper p={30} mt={30} radius="8px" style={{ border : '1px solid #dee2e6' }}>
        <Paper w="60%" mx="auto">
          <Center>
            <img src={bigLogo} alt="Company Logo"/>
          </Center>
          <Text ta="center" mb={10}>Welcome. Please Log in</Text>
          <form onSubmit={form.onSubmit((formData) => { handleLoginSubmit(formData); })}>
            <TextInput placeholder="Email" value={form.values.email} onChange={(event) => form.setFieldValue('email', event.currentTarget.value)} error={form.errors.email && 'Invalid email'}/>
            <PasswordInput placeholder="Password" mt="md" value={form.values.password} onChange={(event) => form.setFieldValue('password', event.currentTarget.value)} error={form.errors.password && 'Password Required'}/>
            <Group mt="lg">
              <Checkbox label="Stay signed in for 2 weeks" value={checked?1:0} checked={checked} onChange={handleStaySignedInChange}/>
            </Group>
            <Button fullWidth mt="xl" type="submit">
              Log in
            </Button>
          </form>
          <Center>
            <Anchor href="/forgot-password" size="sm" c="#8B8B8B" my={10}>
              Forgot your password?
            </Anchor>
          </Center>
        </Paper>
      </Paper>
      <p style={{ textAlign : 'center' }}>By Using This Site You Understand & Agree To Our <b><a href="https://visiopt.com/terms-of-use.html" target="_blank" rel="noreferrer" style={{color:"black",textDecoration:"none"}}>Terms Of Service </a></b>& <b><a href="https://visiopt.com/privacy-policy.html" target="_blank" rel="noreferrer" style={{color:"black",textDecoration:"none"}}>Privacy Policy</a></b>.</p>
    </Container>
  );
}