import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ActionIcon, Anchor, Text, Modal,  } from '@mantine/core';
import { IconHelp, IconInfoCircle, IconCheck, IconTrash } from '@tabler/icons-react';
import TestsApi from "../../api/TestsApi";
import { notifications } from '@mantine/notifications';
import { modals } from '@mantine/modals';

export function DiscardTest({ pageId, taguchiTestId, testId, selectedMemberWebsite, errorModelOpen, errorMessage, showErrorHeader, showMessageHeader, systemUsageLoader }:any) {
  const navigate = useNavigate();
  const discardTestConfirm = (testId:any) => modals.openConfirmModal({
    title: 'Discard the Test',
    children: (
      <Text size="sm">
        This will discard your current test so you will loose all data. Do you wish to continue? Then you should remove the webhook from the shopping cart or payment processor after delete campaign.
      </Text>
    ),
    labels: { confirm: 'Discard the Test', cancel: "No don't discard it" },
    confirmProps: { color: 'red' },
    onCancel: () => console.log('Cancel'),
    onConfirm: () => { 
      handleDiscardTest(pageId);
    },
  });

  const handleDiscardTest = (pageId:any) => {
    systemUsageLoader(true);
    TestsApi.discardtest({
      system_website_id: selectedMemberWebsite,
      page_id:pageId,
      taguchi_test_id:taguchiTestId
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        systemUsageLoader(false);
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        navigate('/review-tests');
      } else {
        errorMessage(data.message);
        errorModelOpen(true);
        showErrorHeader(true);
        systemUsageLoader(false);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
      systemUsageLoader(false);
    });
  }

  return (
    <>
      <ActionIcon 
        variant="default"
        size="lg"
        sx={(theme) => ({
           backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#fff",
         })}
        title="Discart Test"
        ml={5}
        onClick={() => discardTestConfirm(testId)}
      >
        <IconTrash size="1.1rem" />
      </ActionIcon>
    </>
  );
}