import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Grid, Card, Text, Image, Space, Button } from '@mantine/core';
import TestsApi from "../../api/TestsApi";

export function TestTypes({
    SelectedMemberWebsite,
    SelectedThumbnail,
    ModernABSplitTest,
    SingleVariableTest,
    SetStep3, SetStep4,
    SetLoading,
    ErrorMessageChange,
    ShowErrorHeader,
    SetCloseModal,
    AccordionItemDefaultValueChange,
    FunnelRotationOptions,
    SmartRelationsData
  }:any) {

    const navigate = useNavigate();
    const ContinueToRotataionPage = (pageId:any) => {
      SetStep3("block");
      AccordionItemDefaultValueChange("step-3");

      TestsApi.getfunnelinrotation({
        system_website_id: SelectedMemberWebsite,
        page_id:pageId
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if (data.status === 1) {
          FunnelRotationOptions(data.data.funnel_list);
        } else {
          ErrorMessageChange(data.message);
          ShowErrorHeader(true);
          SetCloseModal(true);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
    };

      const ContinueToSmartRelational = (value:any) => {
        SetStep4("block");
        AccordionItemDefaultValueChange("step-4");
        SetLoading(true);
        TestsApi.getsmartrelationallist()
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            if (response.status === 401 || response.status === 400) {
              navigate("/logout");
            }
          }
        })
        .then(data => {
          if (data.status === 1) {
            SmartRelationsData(data.data.smart_relational);
            SetLoading(false);
          } else {
            ErrorMessageChange(data.message);
            ShowErrorHeader(true);
            SetCloseModal(true);
            SetLoading(false);
          }
        })
        .catch(error => {
          SetLoading(false);
          console.error("API Error:", error);
        });
      };

  return (
    <>
      <Grid>
        <Grid.Col span={3}>
          <Card withBorder h={480}>
            <Text weight={500} fz={18} mb={20} ta="center">Classic A/B Split Test</Text>
            <Image src="./img/tests/test-ab.png"/>
            <Text mt={10}>Select this type of experiment if you want to test entirely different versions of a page.</Text>
            <Space h="md" />
            <Text>Make sure you have the rotation page ready and uploaded on your FTP server.</Text>
            <Space h="md" />
            <div style={{textAlign : "center"}}><Button type="button" mb={10} mt={65} onClick={() => ContinueToRotataionPage(SelectedThumbnail)}>Continue</Button></div>
          </Card>
        </Grid.Col>
        <Grid.Col span={3}>
          <Card withBorder h={480}>
            <Text weight={500} fz={18} mb={20} ta="center">Modern A/B Split Test</Text>
            <Image src="./img/tests/test-ab-modern.png"/>
            <Text mt={10}>Select this type of experiment if you'd like to perform an A/B split test while ensuring that the same URL is displayed at all times. This rotates all items chosen on the original URL.</Text>
            <Space h="md" />
            <Text>This is helpful when running tests on some ad networks to preserve quality scores while testing.</Text>
            <Space h="md" />
            <div style={{textAlign : "center"}}><Button type="button" mb={10} mt={0}><Link to={`/test-editor-visual-modern/${SelectedThumbnail}/${ModernABSplitTest}`} style={{color:"white",textDecoration: "none"}}>Continue</Link></Button></div>
          </Card>
        </Grid.Col>
        <Grid.Col span={3}>
          <Card withBorder h={480}>
            <Text weight={500} fz={18} mb={20} ta="center">Single-Variable Test</Text>
            <Image src="./img/tests/test-single-variable.png"/>
            <Text mt={10}>Select this type of experiment for testing a single element (Ex: the headline, or background color, or a picture) on your webpage.</Text>
            <Space h="md" />
            <Text>There are maximum 10 versions which can be rotated.</Text>
            <Space h="md" />
            <div style={{textAlign : "center"}}><Button type="button" mb={10} mt={42}><Link to={`/test-editor-visual/${SelectedThumbnail}/${SingleVariableTest}`} style={{color:"white",textDecoration: "none"}}>Continue</Link></Button></div>
          </Card>
        </Grid.Col>
        <Grid.Col span={3}>
          <Card withBorder h={480}>
            <Text weight={500} fz={18} mb={20} ta="center">Smart Relational Testing™</Text>
            <Image src="./img/tests/test-multi-variable.png"/>
            <Text mt={10}>Select this type of experiment if you want to test several elements (Ex: the headline and background color and a picture) on your webpage.</Text>
            <Space h="md" />
            <div style={{textAlign : "center"}}><Button type="button" mb={10} mt={102} onClick={() => ContinueToSmartRelational(SelectedThumbnail)}>Continue</Button></div>
          </Card>
        </Grid.Col>
      </Grid>
    </>
  );
}