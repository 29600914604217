import { Group, Text, Input, Button, Anchor, TextInput, Select, Grid, Table, Textarea, CopyButton } from '@mantine/core';
import { IconHelp, IconX, IconCheck } from '@tabler/icons-react';
import { useState, useEffect } from 'react';
import { modals } from '@mantine/modals';
import '../EmailIntegration/EmailIntegration.css';
import { VisiLoader } from "../../GeneralComponent/GeneralComponent";
import SettingsApi from "../../../api/SettingsApi";
import { useForm } from '@mantine/form';
import { useNavigate } from "react-router-dom";
import { notifications } from '@mantine/notifications';

export function PaymentProcessingAndCarts() {
	const navigate = useNavigate();
	const [activePPC, setActivePPC] = useState("payment-processing-carts");
	const [activeId, setActiveId] = useState(null);
	const [activePPCClass, setActivePPCClass] = useState([]);
	const [customJSONText, setcustomJSONText] = useState('{ "transaction_id": "1111111", "sales_amount": "52.50", "transaction_type": "paid", "email": "test@gmail.com", "first_name": "test1", "last_name": "test2", "zipcode": "45321", "country": "US", "state": "LA", "city": "Gonzales", "_kx_vt": "1714736736", "_kx_sid": "1723236503d8d5b3c605c0ab69b26ed3adcfe3d8a4", "_n_sid_random": "1.1.10.1714736735", "ip_address": "12:15:15:18" }');
	const [authorizeNetStatus, setAuthorizeNetStatus] = useState('connect');
	const [chargebeeStatus, setChargebeeStatus] = useState('connect');
	const [easypaydirectStatus, setEasypaydirectStatus] = useState('connect');
	const [hitpayStatus, setHitpayStatus] = useState('connect');
	const [housecallproStatus, setHousecallproStatus] = useState('connect');
	const [merchantxStatus, setMerchantxStatus] = useState('connect');
	const [paypalclassicIPNStatus, setPaypalclassicIPNStatus] = useState('connect');
	const [recurlyStatus, setRecurlyStatus] = useState('connect');
	const [stripeStatus, setStripeStatus] = useState('connect');
	const [goCardLessStatus, setGoCardLessStatus] = useState('connect');
	const [buygoodsStatus, setBuygoodsStatus] = useState('connect');
	const [clickBankStatus, setClickBankStatus] = useState('connect');
	const [clickfunnelsStatus, setClickfunnelsStatus] = useState('connect');
	const [digistore24Status, setDigistore24Status] = useState('connect');
	const [gohighlevelStatus, setGohighlevelStatus] = useState('connect');
	const [kajabiStatus, setKajabiStatus] = useState('connect');
	const [katraStatus, setKatraStatus] = useState('connect');
	const [konnektiveStatus, setKonnektiveStatus] = useState('connect');
	const [ontraportStatus, setOntraportStatus] = useState('connect');
	const [paykickstartesStatus, setPaykickstartesStatus] = useState('connect');
	const [samCartStatus, setSamCartStatus] = useState('connect');
	const [shopifyStatus, setShopifyStatus] = useState('connect');
	const [ultracartStatus, setUltracartStatus] = useState('connect');
	const [thriveCartStatus, setThriveCartStatus] = useState('connect');
	const [woocommerceStatus, setWoocommerceStatus] = useState('connect');
	const [customStatus, setCustomStatus] = useState('connect');
	const [customSampleJSCode, setCustomSampleJSCode] = useState(`
		<script type='text/javascript'>
	    function getVisiCookie3(name) {
	        var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
	        return v ? v[2] : null;
	    }
	    var vtss_kx = setInterval(function(){
        if(typeof _kx_called != 'undefined' && _kx_called == true){
            var v_kx_vt = getVisiCookie3('_kx_vt');
            /*Here will get the variation no in JS variable v_kx_vt */
            console.log(v_kx_vt);
            var v_kx_sid = getVisiCookie3('_kx_sid');
            /*Here will get the variation no in JS variable v_kx_sid */
            console.log(v_kx_sid);
            var v_n_sid_random = getVisiCookie3('_n_sid_random');
            /*Here will get the variation no in JS variable v_n_sid_random */
            console.log(v_n_sid_random);
            clearInterval(vtss_kx);
        }
	    }, 50);
		</script>
	`);

	const handleAction = (id:any) => {
		if(id === "cancel"){
			setActiveId(id);
			setActivePPC("payment-processing-carts");
		} else if(id === "chargebee" || id === "easypaydirect" || id === "hitpay" || id === "housecallpro" || id === "merchantx" || id === "paypal_classic_ipn" || id === "recurly"){
			setPaymentProcessingCartLoader(true);
			let paymentProcessorStatus = "";
			if(id === "chargebee"){
				paymentProcessorStatus = chargebeeStatus;
			}else if(id === "easypaydirect"){
				paymentProcessorStatus = easypaydirectStatus;
			}else if(id === "hitpay"){
				paymentProcessorStatus = hitpayStatus;
			}else if(id === "housecallpro"){
				paymentProcessorStatus = housecallproStatus;
			}else if(id === "merchantx"){
				paymentProcessorStatus = merchantxStatus;
			}else if(id === "paypal_classic_ipn"){
				paymentProcessorStatus = paypalclassicIPNStatus;
			}else if(id === "recurly"){
				paymentProcessorStatus = recurlyStatus;
			}
			SettingsApi.connectdisconnectpaymentprocessor({
	      payment_integration_type : id,
	      payment_action : 'save_details',
	      type : "payment_processor",
	      status : 	paymentProcessorStatus
	    })
	    .then(response => {
	      if (response.ok) {
	        return response.json();
	      } else {
	        if (response.status === 401 || response.status === 400) {
	          setPaymentProcessingCartLoader(false);
	          navigate("/logout");
	        }
	      }
	    })
	    .then(data => {
	      if(data.status === 1){
	        setPaymentProcessingCartLoader(false);
	        if(paymentProcessorStatus === 'connect'){
	        	if(!activePPCClass.includes(id)){
	        		setActivePPCClass(oldArray => [...oldArray, id]);
	        		if(id === "chargebee"){
								setChargebeeStatus('disconnect');
							}else if(id === "easypaydirect"){
								setEasypaydirectStatus('disconnect');
							}else if(id === "hitpay"){
								setHitpayStatus('disconnect');
							}else if(id === "housecallpro"){
								setHousecallproStatus('disconnect');
							}else if(id === "merchantx"){
								setMerchantxStatus('disconnect');
							}else if(id === "paypal_classic_ipn"){
								setPaypalclassicIPNStatus('disconnect');
							}else if(id === "recurly"){
								setRecurlyStatus('disconnect');
							}
	        	}
	        	modals.openConfirmModal({
						  title: 'Payment Processor',
						  children: (
						    <Text size="sm">
						      This payment processor is now activated and available for selection and use in both tests and campaigns. To confirm activated you will see a green border. To deactivate simply click again and green border will be removed.
						    </Text>
						  ),
						  labels: { confirm: 'Ok', cancel: "Close" },
						  onCancel: () => console.log('Cancel'),
						  onConfirm: () => console.log('Confirmed'),
						});
	        }else if(paymentProcessorStatus === 'disconnect'){
	        	if(activePPCClass.includes(id)){
	        		setActivePPCClass(oldValues => {
					      return oldValues.filter(Value => Value !== id);
					    });
					    if(id === "chargebee"){
								setChargebeeStatus('connect');
							}else if(id === "easypaydirect"){
								setEasypaydirectStatus('connect');
							}else if(id === "hitpay"){
								setHitpayStatus('connect');
							}else if(id === "housecallpro"){
								setHousecallproStatus('connect');
							}else if(id === "merchantx"){
								setMerchantxStatus('connect');
							}else if(id === "paypal_classic_ipn"){
								setPaypalclassicIPNStatus('connect');
							}else if(id === "recurly"){
								setRecurlyStatus('connect');
							}
	        	}
	        }
	      }else{
	        setPaymentProcessingCartLoader(false);
	        notifications.show({
	          color: 'red',
	          icon: <IconX size="1.1rem" />,
	          title: 'Error',
	          message: data.message,
	        })
	      }
	    })
	    .catch(error => {
	      console.log(error);
	      setPaymentProcessingCartLoader(false);
	    });
		} else if(id === "buygoods" || id === "ClickBank" || id === "clickfunnels" || id === "digistore24" || id === "gohighlevel" || id === "katra" || id === "konnektive" || id === "ontraport" || id === "paykickstartes" || id === "sam Cart" || id === "shopify" || id === "ultracart" || id === "thrive Cart" || id === "woocommerce"){
			setPaymentProcessingCartLoader(true);
			let shoppingCartStatus = "";
			if(id === "buygoods"){
				shoppingCartStatus = buygoodsStatus;
			}else if(id === "ClickBank"){
				shoppingCartStatus = clickBankStatus;
			}else if(id === "clickfunnels"){
				shoppingCartStatus = clickfunnelsStatus;
			}else if(id === "digistore24"){
				shoppingCartStatus = digistore24Status;
			}else if(id === "gohighlevel"){
				shoppingCartStatus = gohighlevelStatus;
			}else if(id === "katra"){
				shoppingCartStatus = katraStatus;
			}else if(id === "konnektive"){
				shoppingCartStatus = konnektiveStatus;
			}else if(id === "ontraport"){
				shoppingCartStatus = ontraportStatus;
			}else if(id === "paykickstartes"){
				shoppingCartStatus = paykickstartesStatus;
			}else if(id === "sam Cart"){
				shoppingCartStatus = samCartStatus;
			}else if(id === "shopify"){
				shoppingCartStatus = shopifyStatus;
			}else if(id === "ultracart"){
				shoppingCartStatus = ultracartStatus;
			}else if(id === "thrive Cart"){
				shoppingCartStatus = thriveCartStatus;
			}else if(id === "woocommerce"){
				shoppingCartStatus = woocommerceStatus;
			}
			SettingsApi.connectdisconnectpaymentprocessor({
	      payment_integration_type : id,
	      payment_action : 'save_details',
	      type : "shopping_cart",
	      status : 	shoppingCartStatus
	    })
	    .then(response => {
	      if (response.ok) {
	        return response.json();
	      } else {
	        if (response.status === 401 || response.status === 400) {
	          setPaymentProcessingCartLoader(false);
	          navigate("/logout");
	        }
	      }
	    })
	    .then(data => {
	      if(data.status === 1){
	        setPaymentProcessingCartLoader(false);
	        if(shoppingCartStatus === 'connect'){
	        	if(!activePPCClass.includes(id)){
	        		setActivePPCClass(oldArray => [...oldArray, id]);
	        		if(id === "buygoods"){
								setBuygoodsStatus('disconnect');
							}else if(id === "ClickBank"){
								setClickBankStatus('disconnect');
							}else if(id === "clickfunnels"){
								setClickfunnelsStatus('disconnect');
							}else if(id === "digistore24"){
								setDigistore24Status('disconnect');
							}else if(id === "gohighlevel"){
								setGohighlevelStatus('disconnect');
							}else if(id === "katra"){
								setKatraStatus('disconnect');
							}else if(id === "konnektive"){
								setKonnektiveStatus('disconnect');
							}else if(id === "ontraport"){
								setOntraportStatus('disconnect');
							}else if(id === "paykickstartes"){
								setPaykickstartesStatus('disconnect');
							}else if(id === "sam Cart"){
								setSamCartStatus('disconnect');
							}else if(id === "shopify"){
								setShopifyStatus('disconnect');
							}else if(id === "ultracart"){
								setUltracartStatus('disconnect');
							}else if(id === "thrive Cart"){
								setThriveCartStatus('disconnect');
							}else if(id === "woocommerce"){
								setWoocommerceStatus('disconnect');
							}
	        	}
	        	modals.openConfirmModal({
						  title: 'Shopping Cart',
						  children: (
						    <Text size="sm">
						      This shopping cart is now activated and available for selection and use in both tests and campaigns. To confirm activated you will see a green border. To deactivate simply click again and green border will be removed.
						    </Text>
						  ),
						  labels: { confirm: 'Ok', cancel: "Close" },
						  onCancel: () => console.log('Cancel'),
						  onConfirm: () => console.log('Confirmed'),
						});
	        }else if(shoppingCartStatus === 'disconnect'){
	        	if(activePPCClass.includes(id)){
	        		setActivePPCClass(oldValues => {
					      return oldValues.filter(Value => Value !== id);
					    });
					    if(id === "buygoods"){
								setBuygoodsStatus('connect');
							}else if(id === "ClickBank"){
								setClickBankStatus('connect');
							}else if(id === "clickfunnels"){
								setClickfunnelsStatus('connect');
							}else if(id === "digistore24"){
								setDigistore24Status('connect');
							}else if(id === "gohighlevel"){
								setGohighlevelStatus('connect');
							}else if(id === "katra"){
								setKatraStatus('connect');
							}else if(id === "konnektive"){
								setKonnektiveStatus('connect');
							}else if(id === "ontraport"){
								setOntraportStatus('connect');
							}else if(id === "paykickstartes"){
								setPaykickstartesStatus('connect');
							}else if(id === "sam Cart"){
								setSamCartStatus('connect');
							}else if(id === "shopify"){
								setShopifyStatus('connect');
							}else if(id === "ultracart"){
								setUltracartStatus('connect');
							}else if(id === "thrive Cart"){
								setThriveCartStatus('connect');
							}else if(id === "woocommerce"){
								setWoocommerceStatus('connect');
							}
	        	}
	        }
	      }else{
	        setPaymentProcessingCartLoader(false);
	        notifications.show({
	          color: 'red',
	          icon: <IconX size="1.1rem" />,
	          title: 'Error',
	          message: data.message,
	        })
	      }
	    })
	    .catch(error => {
	      console.log(error);
	      setPaymentProcessingCartLoader(false);
	    });
		} else {
			setActiveId(id);
			setActivePPC('');
		}
	};
  
  const [paymentProcessorData, setPaymentProcessorData] = useState([
    { id: 1, action_name: 'autorize.net' ,img: 'img/cart-icons/authorize.png', name:'authorize.net' },
    { id: 2, action_name: 'chargebee' ,img: 'img/cart-icons/chargebee.png', name:'Chargebee' },
    { id: 3, action_name: 'easypaydirect' ,img: 'img/cart-icons/easypaydirect.png', name:'Easy Pay Direct' },
    { id: 4, action_name: 'hitpay' ,img: 'img/cart-icons/hitpay.png', name:'Hit Pay' },
    { id: 5, action_name: 'housecallpro' ,img: 'img/cart-icons/housecallpro.png', name:'Housecall pro' },
    { id: 6, action_name: 'gocardless' ,img: 'img/cart-icons/gocardless.png', name:'Go Cardless' },
    { id: 7, action_name: 'merchantx' ,img: 'img/cart-icons/merchantx.png', name:'Merchantx' },
    { id: 8, action_name: 'paypal_classic_ipn' ,img: 'img/cart-icons/paypal.png', name:'Paypal Classic IPN' },
    { id: 9, action_name: 'recurly' ,img: 'img/cart-icons/recurly.png', name:'Recurly' },
    { id: 10, action_name: 'stripe' ,img: 'img/cart-icons/stripe.png', name:'Stripe' },
  ]);

  const [shoppingCartsData, setShoppingCartsData] = useState([
    { id: 1, action_name: 'buygoods' ,img: 'img/cart-icons/buygoods.png', name:'Buy Goods' },
    { id: 2, action_name: 'ClickBank' ,img: 'img/cart-icons/clickbank.png', name:'Click Bank' },
    { id: 3, action_name: 'clickfunnels' ,img: 'img/cart-icons/clickfunnels.png', name:'ClickFunnels' },
    { id: 3, action_name: 'digistore24' ,img: 'img/cart-icons/digistore24.png', name:'Digistore24' },
    { id: 4, action_name: 'gohighlevel' ,img: 'img/cart-icons/gohighlevel.png', name:'Go High Level' },
    { id: 5, action_name: 'kajabi' ,img: 'img/cart-icons/kajabi.png', name:'Kajabi' },
    { id: 6, action_name: 'katra' ,img: 'img/cart-icons/kartra.png', name:'Katra' },
    { id: 7, action_name: 'konnektive' ,img: 'img/cart-icons/konnektive.png', name:'Konnektive' },
    { id: 8, action_name: 'ontraport' ,img: 'img/cart-icons/ontraport.png', name:'Ontraport' },
    { id: 9, action_name: 'paykickstartes' ,img: 'img/cart-icons/paykickstart.png', name:'Paykickstart' },
    { id: 10, action_name: 'sam Cart' ,img: 'img/cart-icons/samcart.png', name:'Sam Cart' },
    { id: 11, action_name: 'shopify' ,img: 'img/cart-icons/shopify.png', name:'Shopify' },
    { id: 12, action_name: 'ultracart' ,img: 'img/cart-icons/ultraCart.png', name:'UltraCart' },
    { id: 13, action_name: 'thrive Cart' ,img: 'img/cart-icons/thrivecart.png', name:'Thrive Cart' },
    { id: 14, action_name: 'woocommerce' ,img: 'img/cart-icons/woocommerce.png', name:'Woocommerce' },
    { id: 15, action_name: 'custom' ,img: 'img/cart-icons/customcart.png', name:'Custom Cart' },
  ]);

  const [PaymentProcessingCartLoader, setPaymentProcessingCartLoader] = useState(false);

  const authorize_net_form = useForm({
    initialValues: { login_id: '', transaction_key: '' },
    validate: {
      login_id: (val) => (val === '' ? 'Login Id is Required' : null),
      transaction_key: (val) => (val === '' ? 'Transaction Key is Required' : null),
    },
  });

  const stripe_form = useForm({
    initialValues: { secret_key: '' },
    validate: {
      secret_key: (val) => (val === '' ? 'Secret key is Required' : null)
    },
  });

  const gocardless_form = useForm({
    initialValues: { access_token: '', account_type: 'LIVE' },
    validate: {
      access_token: (val) => (val === '' ? 'Access Token is Required' : null),
      account_type: (val) => (val === '' ? 'Account Type is Required' : null)
    },
  });

  const handleAuthorizeNetSubmit = async (formData:any) => {
		setPaymentProcessingCartLoader(true);
		SettingsApi.connectdisconnectpaymentprocessor({
      payment_integration_type : 'autorize.net',
      payment_action : 'save_details',
      type : "payment_processor",
      status : 	authorizeNetStatus,
      api_url : formData.login_id,
      api_key : formData.transaction_key
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setPaymentProcessingCartLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setPaymentProcessingCartLoader(false);
        notifications.show({
          color: 'teal',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        setActiveId("cancel");
        setActivePPC("payment-processing-carts");
        if(authorizeNetStatus === 'connect'){
        	if(!activePPCClass.includes('autorize.net')){
        		setActivePPCClass(oldArray => [...oldArray, 'autorize.net']);
        		setAuthorizeNetStatus('disconnect');
        	}
        }else if(authorizeNetStatus === 'disconnect'){
        	if(activePPCClass.includes('autorize.net')){
        		setActivePPCClass(oldValues => {
				      return oldValues.filter(Value => Value !== 'autorize.net');
				    });
				    setAuthorizeNetStatus('connect');
				    authorize_net_form.setFieldValue('login_id', "");
				    authorize_net_form.setFieldValue('transaction_key', "");
        	}
        }
      }else{
        setPaymentProcessingCartLoader(false);
        notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: data.message,
        })
      }
    })
    .catch(error => {
      console.log(error);
      setPaymentProcessingCartLoader(false);
    });
	}

	const handleStripeSubmit = async (formData:any) => {
		setPaymentProcessingCartLoader(true);
		SettingsApi.connectdisconnectpaymentprocessor({
      payment_integration_type : 'stripe',
      payment_action : 'save_details',
      type : "payment_processor",
      status : 	stripeStatus,
      api_key : formData.secret_key
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setPaymentProcessingCartLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setPaymentProcessingCartLoader(false);
        notifications.show({
          color: 'teal',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        setActiveId("cancel");
        setActivePPC("payment-processing-carts");
        if(stripeStatus === 'connect'){
        	if(!activePPCClass.includes('stripe')){
        		setActivePPCClass(oldArray => [...oldArray, 'stripe']);
        		setStripeStatus('disconnect');
        	}
        }else if(stripeStatus === 'disconnect'){
        	if(activePPCClass.includes('stripe')){
        		setActivePPCClass(oldValues => {
				      return oldValues.filter(Value => Value !== 'stripe');
				    });
				    setStripeStatus('connect');
				    stripe_form.setFieldValue('secret_key', "");
        	}
        }
      }else{
        setPaymentProcessingCartLoader(false);
        notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: data.message,
        })
      }
    })
    .catch(error => {
      console.log(error);
      setPaymentProcessingCartLoader(false);
    });
	}

	const handleGoCardLessSubmit = async (formData:any) => {
		setPaymentProcessingCartLoader(true);
		SettingsApi.connectdisconnectpaymentprocessor({
      payment_integration_type : 'gocardless',
      payment_action : 'save_details',
      type : "payment_processor",
      status : 	goCardLessStatus,
      access_token : formData.access_token,
      account_type : formData.account_type
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setPaymentProcessingCartLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setPaymentProcessingCartLoader(false);
        notifications.show({
          color: 'teal',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        setActiveId("cancel");
        setActivePPC("payment-processing-carts");
        if(goCardLessStatus === 'connect'){
        	if(!activePPCClass.includes('gocardless')){
        		setActivePPCClass(oldArray => [...oldArray, 'gocardless']);
        		setGoCardLessStatus('disconnect');
        	}
        }else if(goCardLessStatus === 'disconnect'){
        	if(activePPCClass.includes('gocardless')){
        		setActivePPCClass(oldValues => {
				      return oldValues.filter(Value => Value !== 'gocardless');
				    });
				    setGoCardLessStatus('connect');
				    gocardless_form.setFieldValue('access_token', "");
				    gocardless_form.setFieldValue('account_type', "LIVE");
        	}
        }
      }else{
        setPaymentProcessingCartLoader(false);
        notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: data.message,
        })
      }
    })
    .catch(error => {
      console.log(error);
      setPaymentProcessingCartLoader(false);
    });
	}

	const handleCustomSubmit = async () => {
		setPaymentProcessingCartLoader(true);
		SettingsApi.connectdisconnectpaymentprocessor({
      payment_integration_type : 'custom',
      payment_action : 'save_details',
      type : "payment_processor",
      status : 	customStatus
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setPaymentProcessingCartLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setPaymentProcessingCartLoader(false);
        notifications.show({
          color: 'teal',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        setActiveId("cancel");
        setActivePPC("payment-processing-carts");
        if(customStatus === 'connect'){
        	if(!activePPCClass.includes('custom')){
        		setActivePPCClass(oldArray => [...oldArray, 'custom']);
        		setCustomStatus('disconnect');
        	}
        }else if(customStatus === 'disconnect'){
        	if(activePPCClass.includes('custom')){
        		setActivePPCClass(oldValues => {
				      return oldValues.filter(Value => Value !== 'custom');
				    });
				    setCustomStatus('connect');
        	}
        }
      }else{
        setPaymentProcessingCartLoader(false);
        notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: data.message,
        })
      }
    })
    .catch(error => {
      console.log(error);
      setPaymentProcessingCartLoader(false);
    });
	}

	useEffect(() => {
		setPaymentProcessingCartLoader(true);
		SettingsApi.getpaymentprocessorscartlist()
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setPaymentProcessingCartLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1 && data.data){
      	let activeIntegrations = [];
      	if(data.data.payment_processor_and_cart_list.payment_processor["autorize.net"] && data.data.payment_processor_and_cart_list.payment_processor["autorize.net"] === 'connected'){
      		activeIntegrations.push('autorize.net');
      	}
      	if(data.data.payment_processor_and_cart_list.payment_processor["chargebee"] && data.data.payment_processor_and_cart_list.payment_processor["chargebee"] === 'connected'){
      		activeIntegrations.push('chargebee');
      		setChargebeeStatus("disconnect");
      	}
      	if(data.data.payment_processor_and_cart_list.payment_processor["easypaydirect"] && data.data.payment_processor_and_cart_list.payment_processor["easypaydirect"] === 'connected'){
      		activeIntegrations.push('easypaydirect');
      		setEasypaydirectStatus("disconnect");
      	}
      	if(data.data.payment_processor_and_cart_list.payment_processor["hitpay"] && data.data.payment_processor_and_cart_list.payment_processor["hitpay"] === 'connected'){
      		activeIntegrations.push('hitpay');
      		setHitpayStatus("disconnect");
      	}
      	if(data.data.payment_processor_and_cart_list.payment_processor["housecallpro"] && data.data.payment_processor_and_cart_list.payment_processor["housecallpro"] === 'connected'){
      		activeIntegrations.push('housecallpro');
      		setHousecallproStatus("disconnect");
      	}
      	if(data.data.payment_processor_and_cart_list.payment_processor["gocardless"] && data.data.payment_processor_and_cart_list.payment_processor["gocardless"] === 'connected'){
      		activeIntegrations.push('gocardless');
      	}
      	if(data.data.payment_processor_and_cart_list.payment_processor["merchantx"] && data.data.payment_processor_and_cart_list.payment_processor["merchantx"] === 'connected'){
      		activeIntegrations.push('merchantx');
      		setMerchantxStatus("disconnect");
      	}
      	if(data.data.payment_processor_and_cart_list.payment_processor["paypal_classic_ipn"] && data.data.payment_processor_and_cart_list.payment_processor["paypal_classic_ipn"] === 'connected'){
      		activeIntegrations.push('paypal_classic_ipn');
      		setPaypalclassicIPNStatus("disconnect");
      	}
      	if(data.data.payment_processor_and_cart_list.payment_processor["recurly"] && data.data.payment_processor_and_cart_list.payment_processor["recurly"] === 'connected'){
      		activeIntegrations.push('recurly');
      		setRecurlyStatus("disconnect");
      	}
      	if(data.data.payment_processor_and_cart_list.payment_processor["stripe"] && data.data.payment_processor_and_cart_list.payment_processor["stripe"] === 'connected'){
      		activeIntegrations.push('stripe');
      	}
      	if(data.data.payment_processor_and_cart_list.shopping_cart["buygoods"] && data.data.payment_processor_and_cart_list.shopping_cart["buygoods"] === 'connected'){
      		activeIntegrations.push('buygoods');
      		setBuygoodsStatus("disconnect");
      	}
      	if(data.data.payment_processor_and_cart_list.shopping_cart["ClickBank"] && data.data.payment_processor_and_cart_list.shopping_cart["ClickBank"] === 'connected'){
      		activeIntegrations.push('ClickBank');
      		setClickBankStatus("disconnect");
      	}
      	if(data.data.payment_processor_and_cart_list.shopping_cart["clickfunnels"] && data.data.payment_processor_and_cart_list.shopping_cart["clickfunnels"] === 'connected'){
      		activeIntegrations.push('clickfunnels');
      		setClickfunnelsStatus("disconnect");
      	}
      	if(data.data.payment_processor_and_cart_list.shopping_cart["digistore24"] && data.data.payment_processor_and_cart_list.shopping_cart["digistore24"] === 'connected'){
      		activeIntegrations.push('digistore24');
      		setDigistore24Status("disconnect");
      	}
      	if(data.data.payment_processor_and_cart_list.shopping_cart["gohighlevel"] && data.data.payment_processor_and_cart_list.shopping_cart["gohighlevel"] === 'connected'){
      		activeIntegrations.push('gohighlevel');
      		setGohighlevelStatus("disconnect");
      	}
      	if(data.data.payment_processor_and_cart_list.shopping_cart["kajabi"] && data.data.payment_processor_and_cart_list.shopping_cart["kajabi"] === 'connected'){
      		activeIntegrations.push('kajabi');
      	}
      	if(data.data.payment_processor_and_cart_list.shopping_cart["katra"] && data.data.payment_processor_and_cart_list.shopping_cart["katra"] === 'connected'){
      		activeIntegrations.push('katra');
      		setKatraStatus("disconnect");
      	}
      	if(data.data.payment_processor_and_cart_list.shopping_cart["konnektive"] && data.data.payment_processor_and_cart_list.shopping_cart["konnektive"] === 'connected'){
      		activeIntegrations.push('konnektive');
      		setKonnektiveStatus("disconnect");
      	}
      	if(data.data.payment_processor_and_cart_list.shopping_cart["ontraport"] && data.data.payment_processor_and_cart_list.shopping_cart["ontraport"] === 'connected'){
      		activeIntegrations.push('ontraport');
      		setOntraportStatus("disconnect");
      	}
      	if(data.data.payment_processor_and_cart_list.shopping_cart["paykickstartes"] && data.data.payment_processor_and_cart_list.shopping_cart["paykickstartes"] === 'connected'){
      		activeIntegrations.push('paykickstartes');
      		setPaykickstartesStatus("disconnect");
      	}
      	if(data.data.payment_processor_and_cart_list.shopping_cart["sam Cart"] && data.data.payment_processor_and_cart_list.shopping_cart["sam Cart"] === 'connected'){
      		activeIntegrations.push('sam Cart');
      		setSamCartStatus("disconnect");
      	}
      	if(data.data.payment_processor_and_cart_list.shopping_cart["shopify"] && data.data.payment_processor_and_cart_list.shopping_cart["shopify"] === 'connected'){
      		activeIntegrations.push('shopify');
      		setShopifyStatus("disconnect");
      	}
      	if(data.data.payment_processor_and_cart_list.shopping_cart["ultracart"] && data.data.payment_processor_and_cart_list.shopping_cart["ultracart"] === 'connected'){
      		activeIntegrations.push('ultracart');
      		setUltracartStatus("disconnect");
      	}
      	if(data.data.payment_processor_and_cart_list.shopping_cart["thrive Cart"] && data.data.payment_processor_and_cart_list.shopping_cart["thrive Cart"] === 'connected'){
      		activeIntegrations.push('thrive Cart');
      		setThriveCartStatus("disconnect");
      	}
      	if(data.data.payment_processor_and_cart_list.shopping_cart["woocommerce"] && data.data.payment_processor_and_cart_list.shopping_cart["woocommerce"] === 'connected'){
      		activeIntegrations.push('woocommerce');
      		setWoocommerceStatus("disconnect");
      	}
      	if(data.data.payment_processor_and_cart_list.shopping_cart["custom"] && data.data.payment_processor_and_cart_list.shopping_cart["custom"] === 'connected'){
      		activeIntegrations.push('custom');
      		setCustomStatus("disconnect");
      	}
      	setActivePPCClass(activeIntegrations);
        setPaymentProcessingCartLoader(false);
      }
      setPaymentProcessingCartLoader(false);
    })
    .catch(error => {
      console.log(error);
      setPaymentProcessingCartLoader(false);
    });
    setPaymentProcessingCartLoader(true);
    SettingsApi.connectdisconnectpaymentprocessor({
    	payment_integration_type : "autorize.net",
    	payment_action : "get_details",
    	type : "payment_processor",
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setPaymentProcessingCartLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1 && data.data){
      	if(data.data.api_key){
      		authorize_net_form.setFieldValue('transaction_key', data.data.api_key);
      	}
      	if(data.data.api_url){
      		authorize_net_form.setFieldValue('login_id', data.data.api_url);
      	}
      	if(data.data.status){
      		setAuthorizeNetStatus(data.data.status);
      	}
      }
      setPaymentProcessingCartLoader(false);
    })
    .catch(error => {
      console.log(error);
      setPaymentProcessingCartLoader(false);
    });
    setPaymentProcessingCartLoader(true);
    SettingsApi.connectdisconnectpaymentprocessor({
    	payment_integration_type : "stripe",
    	payment_action : "get_details",
    	type : "payment_processor",
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setPaymentProcessingCartLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1 && data.data){
      	if(data.data.api_key){
      		stripe_form.setFieldValue('secret_key', data.data.api_key);
      	}
      	if(data.data.status){
      		setStripeStatus(data.data.status);
      	}
      }
      setPaymentProcessingCartLoader(false);
    })
    .catch(error => {
      console.log(error);
      setPaymentProcessingCartLoader(false);
    });
    setPaymentProcessingCartLoader(true);
    SettingsApi.connectdisconnectpaymentprocessor({
    	payment_integration_type : "gocardless",
    	payment_action : "get_details",
    	type : "payment_processor",
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setPaymentProcessingCartLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1 && data.data){
      	if(data.data.access_token){
      		gocardless_form.setFieldValue('access_token', data.data.access_token);
      	}
      	if(data.data.account_type){
      		gocardless_form.setFieldValue('account_type', data.data.account_type);
      	}
      	if(data.data.status){
      		setGoCardLessStatus(data.data.status);
      	}
      }
      setPaymentProcessingCartLoader(false);
    })
    .catch(error => {
      console.log(error);
      setPaymentProcessingCartLoader(false);
    });
	}, []);

  return (
    <>
    	<VisiLoader VisiLoaderVisible={PaymentProcessingCartLoader} />
    	<div style={{ display: activePPC === "payment-processing-carts" ? "block" : "none"}}>
	      <Group position="apart" mb="xs">
	        <Text weight={500} fz={14}>PAYMENT PROCESSING AND CARTS <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
	      </Group>
	      <div style={{display:"flex"}}>
	      	<Text fw={500} mr={5} fz={14}> Step 1: </Text>
	      	<Text fz={14}>Activate any payment processor or shopping cart you want to connect via web hook by clicking on the desired icon below.</Text>
	      </div>
	      <Text mb={15} fz={14}>Note: You will use either a single cart or a single processor in a campaign or test. There is no need to set up both a processor and a cart.</Text>
	      <div style={{display:"flex"}}>
	      	<Text fw={500} mr={5} fz={14}> Step 2: </Text>
	      	<Text fz={14}>Get the proper web hook link inside of Visiopt.</Text>
	      </div>
	      <Text mb={15} fz={14}>Note: This can be accomplished in either testing or campaigns. Once completed it will work for both testing and campaigns</Text>
	      <div style={{display:"flex"}}>
	      	<Text fw={500} mr={5} fz={14}> Step 3: </Text>
	      	<Text fz={14}>Place the web hook in your cart or processor.</Text>
	      </div>
	      <Text mb={15} fz={14}>Note : A new unique Webhook must be created for each test or campaign created.</Text>
	      <Text mb={15} fz={14}>When a campaign and test share the same funnel then the same Webhook may be used for both.</Text>
	      <div style={{display:"flex"}}>
		      <Text mb={30} mr={5} fz={14}>To learn how to get the proper webhook link in Visiopt, and how to connect it to various</Text>
		      <Anchor href="https://visiopt.com/help-videos/connect-carts.html" target="_blank" td="none" fw={700} fz={14}>
			      Carts and Payment Processors, visit our Cart and Payment Processor Connection Help Page.
		      </Anchor>
		    </div>
        <Text fw={500} mb={10}>Payment Processors:</Text>
        <Group spacing="xs" mb={25}>
        	{paymentProcessorData.map((item,index) => (
	  	      <div key={index} className={activePPCClass.includes(item.action_name) ? "email-thumb active-email" : "email-thumb"} onClick={() => handleAction(item.action_name)}>
	  	      	<div className="thumb-holder">
	  	      		<img src={item.img} />
	  	      	</div>
	  	      	<span>{item.name}</span>
	  	      </div>
  	      ))}
        </Group>
        <Text fw={500} mb={10}>Shopping Carts:</Text>
        <Group spacing="xs" mb={15}>
        	{shoppingCartsData.map((item,index) => (
  	      	<div key={index} className={activePPCClass.includes(item.action_name) ? "email-thumb active-email" : "email-thumb"} onClick={() => handleAction(item.action_name)}>
	  	      	<div className="thumb-holder">
	  	      		<img src={item.img} />
	  	      	</div>
	  	      	<span>{item.name}</span>
	  	      </div>
  	      ))}
        </Group>
	    </div>
      <div style={{ display: activeId === "autorize.net" ? "block" : "none"}}>
      	<form onSubmit={authorize_net_form.onSubmit((formData) => { handleAuthorizeNetSubmit(formData); })}>
		      <Group position="apart" mb="xs">
		        <Text weight={500}>AUTHORIZE.NET <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
		      </Group>
	  	    <Group spacing="xs" mb={10}>
	  	    <Text size="sm" fz={14} fw={500} maw={150} mb={10} mr={45}>Login ID:</Text>
	  	      <TextInput w={250} ml={0} {...authorize_net_form.getInputProps('login_id')} value={authorize_net_form.values.login_id} onChange={(event) => authorize_net_form.setFieldValue('login_id', event.currentTarget.value)}/>
	  	    </Group>
	  	    <Group spacing="xs" mb={10}>
	  	    <Text size="sm" fz={14} fw={500} maw={150} mb={10}>Transaction Key:</Text>
	  	      <TextInput w={250} ml={0} {...authorize_net_form.getInputProps('transaction_key')} value={authorize_net_form.values.transaction_key} onChange={(event) => authorize_net_form.setFieldValue('transaction_key', event.currentTarget.value)}/>
	  	    </Group>
	  	    <Group spacing="xs" mb={10}>
		  	    <Button type="submit" size="sm" mt={10}>
		  	      {authorizeNetStatus === "connect" ? "Connect" : "Disconnect"}
		  	    </Button>
		  	    <Button type="button" style={{backgroundColor: "red"}} size="sm" w={80} mt={10} onClick={() => handleAction('cancel')}>
		  	      Cancel
		  	    </Button>
	  	    </Group>
  	    </form>
	    </div>
      <div style={{ display: activeId === "gocardless" ? "block" : "none"}}>
      	<form onSubmit={gocardless_form.onSubmit((formData) => { handleGoCardLessSubmit(formData); })}>
		      <Group position="apart" mb="xs">
		        <Text weight={500}>GOCARDLESS <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
		      </Group>
	  	    <Group spacing="xs" mb={10}>
	  	    <Text size="sm" fz={14} fw={500} maw={150} mb={10} mr={3}>Access Token:</Text>
	  	      <TextInput w={250} ml={0} {...gocardless_form.getInputProps('access_token')} value={gocardless_form.values.access_token} onChange={(event) => gocardless_form.setFieldValue('access_token', event.currentTarget.value)}/>
	  	    </Group>
	  	    <Group spacing="xs" mb={10}>
		  	    <Text size="sm" fz={14} fw={500} maw={150} mb={10}>Account Type:</Text>
	  	    	<Select
	  	    		w={250}
	  	    		ml={0}
	  	    		{...gocardless_form.getInputProps('account_type')}
	  	    		value={gocardless_form.values.account_type}
	  	    		onChange={(event) => gocardless_form.setFieldValue('account_type', event)}
	  	    		data={[
			     	    { value: 'SANDBOX', label: 'SANDBOX' },
			     	    { value: 'LIVE', label: 'LIVE' }
			     	  ]}
		    		/>
	  	    </Group>
	  	    <Group spacing="xs" mb={10}>
		  	    <Button type="submit" size="sm" mt={10}>
		  	      {goCardLessStatus === "connect" ? "Connect" : "Disconnect"}
		  	    </Button>
		  	    <Button type="button" style={{backgroundColor: "red"}} size="sm" w={80} mt={10} onClick={() => handleAction('cancel')}>
		  	      Cancel
		  	    </Button>
	  	    </Group>
  	    </form>
	    </div>
      <div style={{ display: activeId === "stripe" ? "block" : "none"}}>
      	<form onSubmit={stripe_form.onSubmit((formData) => { handleStripeSubmit(formData); })}>
		      <Group position="apart" mb="xs">
		        <Text weight={500}>STRIPE <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
		      </Group>
		      <Text fw={500} mb={20}>The stripe API key is not required. But if you add it, you will get accuracy in the data.</Text>
	  	    <Group spacing="xs" mb={10}>
	  	    <Text size="sm" fz={14} fw={500} maw={150} mb={10}>Secret key:</Text>
	  	      <TextInput w={250} ml={0} {...stripe_form.getInputProps('secret_key')} value={stripe_form.values.secret_key} onChange={(event) => stripe_form.setFieldValue('secret_key', event.currentTarget.value)}/>
	  	    </Group>
	  	    <Group spacing="xs" mb={10}>
		  	    <Button type="submit" size="sm" mt={10}>
		  	      {stripeStatus === "connect" ? "Connect" : "Disconnect"}
		  	    </Button>
		  	    <Button type="button" style={{backgroundColor: "red"}} size="sm" w={80} mt={10} onClick={() => handleAction('cancel')}>
		  	      Cancel
		  	    </Button>
	  	    </Group>
  	    </form>
	    </div>
      <div style={{ display: activeId === "ClickBank" ? "block" : "none"}}>
	      <Group position="apart" mb="xs">
	        <Text weight={500}>CLICKBANK <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
	      </Group>
  	    <Group spacing="xs" mb={10}>
  	    <Text size="sm" fz={14} fw={500} maw={150} mb={10}>Secret Key:</Text>
  	      <Input w={250} ml={0}/>
  	    </Group>
  	    <Group spacing="xs" mb={10}>
	  	    <Button type="submit" size="sm" w={90} mt={10}>
	  	      Connect
	  	    </Button>
	  	    <Button type="submit" style={{backgroundColor: "red"}} size="sm" w={80} mt={10} onClick={() => handleAction('cancel')}>
	  	      Cancel
	  	    </Button>
  	    </Group>
	    </div>
      <div style={{ display: activeId === "kajabi" ? "block" : "none"}}>
	      <Group position="apart" mb="xs">
	        <Text weight={500}>KAJABI <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
	      </Group>
  	    <Text size="sm" fz={14} fw={500} mb={10}>Kajabi does not pass revenue data via web hooks. Kajabi has only 2 payment processors. Paypal and Stripe. So, Instead we recommend you connect both Stripe and Paypal Processors to track conversions and revenue.</Text>
  	    <Group spacing="xs" mb={10}>
	  	    <Button type="submit" style={{backgroundColor: "red"}} size="sm" w={80} mt={10} onClick={() => handleAction('cancel')}>
	  	      Cancel
	  	    </Button>
  	    </Group>
	    </div>
      <div style={{ display: activeId === "custom" ? "block" : "none"}}>
	      <Group position="apart" mb="xs">
	        <Text weight={500}>CUSTOM <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
	      </Group>
  	    <Text size="sm" fz={16} fw={500} mb={10}>To use a custom shopping cart, you needs pass data in JSON format using POST method :</Text>
  	    <Text mb={10}>{customJSONText}</Text>
  	    <Text size="sm" fz={16} fw={500} mb={10} c="red">If there is a refund, "refunded" has to be passed in the transaction type. And in refund, the same transaction id of the transaction from which the payment was made has to be passed.</Text>
  	    <Grid>
		      <Grid.Col span={6}>
		      	<Table withBorder withColumnBorders>
		      		<thead>
		      			<tr>
						      <th>Field</th>
						      <th>Field Description</th>
						    </tr>
		      		</thead>
		      		<tbody>
		      			<tr>
						      <td>transaction_id</td>
						      <td>Required</td>
						    </tr>
						    <tr>
						      <td>sales_amount</td>
						      <td>Required</td>
						    </tr>
						    <tr>
						      <td>email</td>
						      <td>Required</td>
						    </tr>
						    <tr>
						      <td>transaction_type</td>
						      <td>Optional</td>
						    </tr>
						    <tr>
						      <td>first_name</td>
						      <td>Optional</td>
						    </tr>
						    <tr>
						      <td>last_name</td>
						      <td>Optional</td>
						    </tr>
						    <tr>
						      <td>zipcode</td>
						      <td>Optional</td>
						    </tr>
						    <tr>
						      <td>country</td>
						      <td>Optional</td>
						    </tr>
						    <tr>
						      <td>state</td>
						      <td>Optional</td>
						    </tr>
						    <tr>
						      <td>city</td>
						      <td>Optional</td>
						    </tr>
						    <tr>
						      <td>_kx_vt</td>
						      <td>Optional</td>
						    </tr>
						    <tr>
						      <td>_kx_sid</td>
						      <td>Optional</td>
						    </tr>
						    <tr>
						      <td>_n_sid_random</td>
						      <td>Optional</td>
						    </tr>
						    <tr>
						      <td>ip_address</td>
						      <td>Optional</td>
						    </tr>
		      		</tbody>
				    </Table>
		      </Grid.Col>
		      <Grid.Col span={6}>
		      	<Text size="sm" fz={16} fw={500} mb={10}>Sample javascript code to get value of _kx_vt,_kx_sid,_n_sid_random from your LP page, you can add this code to LP Page to get the values and save it at your side and then can send in webhook body.</Text>
		      	<Textarea
				    	value={customSampleJSCode}
				    	mt={10}
				    	variant="filled"
				    	minRows={23}
				 		/>
				 		<CopyButton  timeout={1000} value={customSampleJSCode}>
				      {({ copied, copy }) => (
				      	<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        					<Button color={copied ? 'teal' : 'blue'} onClick={copy} mt={5} size="xs">
				          	{copied ? 'Copied' : 'Copy'}
				        	</Button>
      					</div>
				      )}
				    </CopyButton>
		      </Grid.Col>
		    </Grid>
  	    <Group spacing="xs" mb={10}>
	  	    <Button type="button" size="sm" mt={10} onClick={() => handleCustomSubmit()}>
	  	      {customStatus === "connect" ? "Connect" : "Disconnect"}
	  	    </Button>
	  	    <Button type="button" style={{backgroundColor: "red"}} size="sm" w={80} mt={10} onClick={() => handleAction('cancel')}>
	  	      Cancel
	  	    </Button>
  	    </Group>
	    </div>
    </>
  );
}