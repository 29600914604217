import { Group, Text, Skeleton, Image,Select, Flex } from '@mantine/core';
import FunnelApi from "../../api/FunnelApi";
import { useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useState, useEffect } from 'react';
import { useListState } from '@mantine/hooks';
import PermissionWrapper from '../../PermissionWrapper';
import cx from 'clsx';
import classes from './DndList.module.css';

export function FunnelData({selectedFunnelTitle, selectedMemberWebsite, setLoading, setselectedFunnelTitle, marksalestime, updatedtime, setSystemUsageLoader, loading, handleEditTitleOpenModal, selectedOption, handleChooseActionChange, handleSelectPageOpenModal,Avoidextracall}){

	const navigate = useNavigate();

	const [funnelData, setFunnelData] = useState(null);
	const [state, handlers] = useListState([]);

	/*Get Funnel Page List*/
	useEffect(() => {
		if(selectedMemberWebsite !== '' && Avoidextracall !== 0){
		  setLoading(true);
		  FunnelApi.getfunnelpagelist({
		    system_website_id : selectedMemberWebsite
		  })
		  .then(response => {
		    if (response.ok) {
		      return response.json();
		    } else {
		      if (response.status === 401 || response.status === 400) {
		        setLoading(false);
		        navigate("/logout");
		      }
		    }
		  })
		  .then(data => {
		    if(data.status === 1){
		      setselectedFunnelTitle(data.data.funnel_name);
		      handlers.setState([])
		      let FunnelOrderData = [];
		      if(data.data.data && data.data.data.length > 0){
		          data.data.data.forEach((item) => {
		            FunnelOrderData.push(item.pageId);
		            handlers.append(item);
		          });
		            setFunnelData(true);
		      }else{
		        setFunnelData(false); 
		      }
		    } else {
		      setFunnelData(false); 
		    }
		   setLoading(false);
		  })
		  .catch(error => {
		   setLoading(false);
		  });
		}
	}, [selectedMemberWebsite,Avoidextracall,marksalestime,updatedtime]);

	/*Update Order*/
	const handleDragEnd = (result:any) => {
		const { source, destination } = result;
		if (!destination) {
		  return;
		}
		handlers.reorder({ from: source.index, to: destination?.index || 0 });
		const reorderedItems = Array.from(state);
		const [removed] = reorderedItems.splice(source.index, 1);
		reorderedItems.splice(destination.index, 0, removed);
		let FunnelOrderData = [];
		reorderedItems.forEach(async (item, index) => {
		  FunnelOrderData.push(item.pageId);
		});
		setSystemUsageLoader(true);
		FunnelApi.updateorder({
		  system_website_id : selectedMemberWebsite,
		  order_funnel_page_array : FunnelOrderData
		})
		.then(response => {
		  if (response.ok) {
		    return response.json();
		  } else {
		    if (response.status === 401 || response.status === 400) {
		      setSystemUsageLoader(false);
		      navigate("/logout");
		    }
		  }
		})
		.then(data => {
		  if(data.status === 1){
		    setSystemUsageLoader(false);
		  }
		})
		.catch(error => {
		 setSystemUsageLoader(false);
		});
	};

	const funnelThumbnailData = state.map((item, index) => (
		<Draggable key={item.pageId} index={index} draggableId={item.pageId}>
		  {(provided, snapshot) => (
		    <div
		      className={cx(classes.item, { [classes.itemDragging]: snapshot.isDragging })}
		      {...provided.draggableProps}
		      {...provided.dragHandleProps}
		      ref={provided.innerRef}
		    >
		      <div className="visi-page">
		        <div className={item.pageClass}>
		          <a href={item.pageFullURL} title={item.pageFullURL} target="_blank" rel="noreferrer">
		            <span className="page-url">{item.title}</span>
		          </a>
		          <a href={item.pageFullURL} title={item.pageFullURL} target="_blank" rel="noreferrer">
		            <div className="thumbnail"  style={{ backgroundImage: `url(${'https://static.visiopt.com/page-thumb/'+item.imgURL})` }}>
		              <Image
		                id="track_img"
		                src={item.track_check}
		                title={item.track_check_title}
		              />
		              {(item.webhook_track_check !== '' && (
		                  <Image
		                  id="webhook_img"
		                  src={item.webhook_track_check}
		                  title={item.webhook_track_check_title}
		                />
		                ))
		              }
		              {(item.marge_img !== '' && (
		                  <Image
		                  id="merge_img"
		                  src={item.marge_img}
		                  title="This Page is merged with other links"
		                />
		                ))
		              }
		            </div>
		          </a>
		          <span className="page-title" title="ENTER CUSTOM TITLE" onClick={() => handleEditTitleOpenModal(item.custom_title,item.pageId)}>{item.custom_title || 'ENTER CUSTOM TITLE'}</span>
		        </div>
		        <Select
		          placeholder="Choose Action"
		          defaultValue={selectedOption[item.page_id]}
		          onChange={handleChooseActionChange}
		          value={selectedOption[item.page_id]}
		          data={item.funnelBoxOption}
		          maxDropdownHeight={250}
		          maw={163}
		          size="xs"
		          dropdownPosition="top"
		        />
		      </div>
		      {state.length === index+1 ? <PermissionWrapper permission="operational_access"><div className="add-page" title="Add a Page to the Funnel" onClick={handleSelectPageOpenModal}/></PermissionWrapper> : <div className="page-arrow" />}
		    </div>
		  )}
		</Draggable>
	));

	return (
		<>
		<Group spacing="xs" mb={10}>
          <Text fz={14} fw={500} >{selectedFunnelTitle}</Text>
        </Group>
        <>
				{funnelData === true ? (
				  <Group spacing="xs" mb={10}>
				    {loading ? (
				      <Flex justify="start-flex" gap="sm" mt={10}>
				        <Skeleton height={170} width={152} />
				        <Skeleton height={170} width={152} />
				        <Skeleton height={170} width={152} />
				      </Flex>
				    ) : (
				      <DragDropContext 
				      	onDragEnd={handleDragEnd}
				      >
				        <Droppable droppableId="dnd-list" direction="horizontal">
				          {(provided) => (
				            <div {...provided.droppableProps} ref={provided.innerRef}>
				              {funnelThumbnailData}
				            </div>
				          )}
				        </Droppable>
				      </DragDropContext>
				    )}
				  </Group>
				) : funnelData === false ? (
            <div className="main-content" style={{ textAlign: 'center', marginTop:'75px', height:'110px', cursor: 'pointer' }} onClick={handleSelectPageOpenModal}>
              <Text size="sm" fz={14}>You do not yet have any pages set up in this funnel.</Text>
              <Text size="sm" fz={14} style={{ color: 'grey' }}>Click here to select pages now</Text>
            </div>
        ) : funnelData === null && (
            <Flex justify="start-flex" gap="sm" mt={10}>
								<Skeleton height={170} width={152} />
				        <Skeleton height={170} width={152} />
				        <Skeleton height={170} width={152} />
					  </Flex>
        )}
        </>
		</>
	);
}